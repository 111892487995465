import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import cn from "clsx";
import React, { useId } from "react";
import { extractAspectRatioFromImageFile } from "../../client/extract-aspect-ratio-from-image-file";
import { SpacingProps } from "../../client/get-spacing-class-name";
import { imageAspectRatioValueForCSSMap } from "../../constants/image-ratio";
import { ImageRatio } from "../../types/image";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { StoryblokLink } from "../../types/storyblok/storyblok-link";
import styles from "./PageLink.module.scss";
import { PageLinkImage } from "./PageLinkImage";
import { PageLinkWrapper } from "./PageLinkWrapper";

export type PageLinkProps = {
    /**
     * The style of the page link
     * @default card
     */
    cardStyle?: "backgroundColor" | "card";
    /**
     * Image to display in page link
     */
    image?: AssetStoryblok;
    /**
     * Image ratio of the page link image
     */
    imageRatio?: ImageRatio;
    /**
     * The style of link text
     * @default regular
     */
    linkTextStyle?: "regular" | "bold";
    /**
     * The color of link text and icon
     */
    linkTextAndIconColor?: string;
    /**
     * The color of link text and icon for custom campaign
     */
    customLinkTextAndIconColor?: string;
    /**
     * The link text to display
     */
    linkText: string;
    /**
     * The destination URL and link metadata
     */
    link?: StoryblokLink;
    /**
     * Only visible when background color style is selected
     */
    backgroundColor?: string;
    /**
     * Start color of the background gradient, overrides `backgroundColor` when present with `backgroundEndColor`
     */
    backgroundStartColor?: string;
    /**
     * End color of the background gradient, overrides `backgroundColor` when present with `backgroundStartColor`
     */
    backgroundEndColor?: string;
    /**
     * Angle of the background gradient
     */
    gradientAngle?: string;
    /**
     * Number of columns in the grid
     */
    columns?: number;
} & SbBlokData &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

export const PageLink: React.FC<PageLinkProps> = (props) => {
    const {
        backgroundColor,
        backgroundEndColor,
        backgroundStartColor,
        cardStyle,
        customLinkTextAndIconColor,
        image,
        imageRatio,
        link,
        linkText,
        linkTextAndIconColor,
        linkTextStyle,
        spaceAbove,
        spaceBelow,
        gradientAngle,
        columns,
    } = props;

    const textId = useId();

    const imageAspectRatio =
        image && imageRatio === ImageRatio.Original
            ? extractAspectRatioFromImageFile(image)
            : imageAspectRatioValueForCSSMap[
                  imageRatio || ImageRatio.Horizontal16By9
              ];
    const imageRadius =
        imageRatio === ImageRatio.Circle1By1 ? "50%" : undefined;

    if (!linkText) {
        return null;
    }

    return (
        <PageLinkWrapper
            {...storyblokEditable(props)}
            backgroundColor={backgroundColor}
            backgroundEndColor={backgroundEndColor}
            backgroundStartColor={backgroundStartColor}
            cardStyle={cardStyle || "card"}
            link={link}
            gradientAngle={gradientAngle}
            spaceAbove={spaceAbove}
            spaceBelow={spaceBelow}
            textId={textId}
            linkTextStyle={linkTextStyle || "regular"}
            linkTextAndIconColor={linkTextAndIconColor}
            imageAspectRatio={imageAspectRatio}
            imageRadius={imageRadius}
            customLinkTextAndIconColor={customLinkTextAndIconColor}
        >
            {image?.filename && (
                <PageLinkImage
                    image={image}
                    imageRatio={imageRatio}
                    columns={columns}
                />
            )}
            <div
                className={cn(styles["page-link-content"], {
                    [styles["page-link-content-no-image"]]: !image?.filename,
                })}
            >
                <p
                    id={textId}
                    className={cn(
                        styles["text"],
                        styles[`text--${linkTextStyle || "regular"}`],
                        {
                            [styles[`text-and-icon-color`]]:
                                linkTextAndIconColor,
                            [styles[`custom-text-and-icon-color`]]:
                                customLinkTextAndIconColor,
                        },
                    )}
                >
                    {linkText}
                </p>
                <svg
                    className={cn(styles["icon"], {
                        [styles[`text-and-icon-color`]]: linkTextAndIconColor,
                        [styles[`custom-text-and-icon-color`]]:
                            customLinkTextAndIconColor,
                    })}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4 12.0002H20"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14 6.00018L20 12.0002L14 18.0002"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        </PageLinkWrapper>
    );
};
