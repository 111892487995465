"use client";

import { useElementSize, useIntersection, useMergedRef } from "@mantine/hooks";
import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { useI18n } from "@wojo/localization";
import { Container, LoadingSkeleton, Text } from "@wojo/ui";
import { useCallback, useEffect, useState } from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { StoryblokTags } from "../../types/storyblok/storyblok-tags";
import { CuralateScript } from "./CuralateScript";

export type UgcBlockProps = {
    /**
     * Id of a gallery, only used for gallery type
     */
    bazaarVoiceId?: string;
    /**
     * Tour code(s) to filter media, only used for product type
     */
    tourCode?: StoryblokTags;
    /**
     * How to filter the media
     */
    type?: "gallery" | "productDetail";
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const UgcBlock: React.FC<UgcBlockProps> = ({ fallback, ...props }) => {
    const { ref: ugcRef, entry: ugcEntry } = useIntersection();
    const { ref: sizeRef, height: ugcHeight } = useElementSize();
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const mergedRef = useMergedRef(ugcRef, sizeRef);
    const displayFallback = isIntersecting && loaded && ugcHeight < 1;
    const ugcBlockI18n = useI18n("storyblok").storyblok.ugcBlock;
    const onReadyCallback = useCallback(() => setLoaded(true), [setLoaded]);

    useEffect(() => {
        if (ugcEntry?.isIntersecting && !isIntersecting) {
            setIsIntersecting(true);
        }
    }, [ugcEntry?.isIntersecting, setIsIntersecting, isIntersecting]);

    const { bazaarVoiceId, spaceAbove, spaceBelow, tourCode, type } = props;

    if (!type) {
        return null;
    }

    const productFilters =
        tourCode?.value?.map((tourCode) => `productId:${tourCode}`) ?? [];

    const crl8Filter =
        type === "productDetail" ? productFilters.join(" or ") : undefined;

    const crl8ContainerId =
        type === "productDetail" ? "product" : bazaarVoiceId;

    return (
        <>
            <div ref={mergedRef}>
                <Container
                    {...storyblokEditable(props)}
                    contentsClassName={getSpacingClassName({
                        spaceAbove,
                        spaceBelow,
                    })}
                    spacing={{
                        xs: "0",
                        sm: "0",
                        md: "0",
                        lg: "0",
                        xl: "0",
                    }}
                >
                    <div
                        data-testid="crl8-container"
                        data-crl8-container-id={crl8ContainerId}
                        data-crl8-filter={crl8Filter}
                        data-crl8-auto-init="false"
                    />
                </Container>
                {isIntersecting && (
                    <CuralateScript
                        crl8ContainerId={crl8ContainerId}
                        onReady={onReadyCallback}
                    />
                )}
            </div>
            {!loaded && (
                <LoadingSkeleton
                    accessibleText={ugcBlockI18n.a11y.loadingLabel.l()}
                    height="1lh"
                    width="100%"
                />
            )}
            {displayFallback && (
                <Text.Body>{ugcBlockI18n.noPhotosFallback.l()}</Text.Body>
            )}
        </>
    );
};
