"use client";

import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { Container } from "@wojo/ui";
import cn from "clsx";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { throttle } from "../../client/throttle";
import { SectionContainerProps } from "../../containers/section-container";
import { TextSectionContainerProps } from "../../containers/text-section-container";
import styles from "./AnchorNavigation.module.scss";

export type AnchorNavigationProps = {
    anchorSections: (TextSectionContainerProps | SectionContainerProps)[];
    barStyle?: "flat" | "card";
    textAlignmentContentPosition?: "left" | "center";
    stickyToTop?: boolean;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

const getAnchorLinkId = (anchorLinkName: string) =>
    anchorLinkName
        .toLowerCase()
        .replace(/\s/g, "-")
        .replace(/[^\w\s-]/g, "");

export const AnchorNavigation: React.FC<AnchorNavigationProps> = (props) => {
    const {
        anchorSections,
        barStyle,
        textAlignmentContentPosition,
        stickyToTop,
        spaceAbove,
        spaceBelow,
    } = props;

    const [activeSection, setActiveSection] = useState<string | null>(null);
    const sectionRefs = useRef<Map<string, HTMLElement | null>>(new Map());
    const navRef = useRef<HTMLDivElement | null>(null);
    const sectionObserver = useRef<IntersectionObserver | null>(null);
    const [navHeight, setNavHeight] = useState(0);

    useEffect(() => {
        setNavHeight(navRef.current?.offsetHeight || 0);
    }, []);

    useEffect(() => {
        sectionRefs.current.clear();
        anchorSections.forEach((section) => {
            const anchorLinkId = getAnchorLinkId(
                section.anchorLinkName as string,
            );
            const ref = document.getElementById(anchorLinkId);
            if (ref) {
                sectionRefs.current.set(anchorLinkId, ref);
            }
        });
        sectionObserver.current = new IntersectionObserver(
            throttle((entries: IntersectionObserverEntry[]) => {
                const intersectingEntries = entries.filter(
                    (entry) => entry.isIntersecting,
                );
                // If there are multiple intersecting entries, select the first one
                if (intersectingEntries.length > 0) {
                    const firstIntersectingEntry = intersectingEntries[0];
                    setActiveSection(firstIntersectingEntry.target.id);
                    history.pushState(
                        null,
                        "",
                        `#${firstIntersectingEntry.target.id}`,
                    );
                }
            }, 100), // throttle the intersection observer to avoid performance issues
            { threshold: 0.5, rootMargin: "-50px 0px -50px 0px" },
        );

        const currentSectionRefs = sectionRefs.current;
        currentSectionRefs.forEach((ref) => {
            if (ref) {
                sectionObserver.current?.observe(ref);
            }
        });

        return () => {
            currentSectionRefs.forEach((ref) => {
                if (ref) {
                    sectionObserver.current?.unobserve(ref);
                }
            });
        };
    }, [anchorSections]);

    const handleAnchorClick = (
        evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        anchorLinkName: string,
    ) => {
        const sectionElement = document.getElementById(anchorLinkName);
        if (sectionElement) {
            evt.preventDefault();
            const mediaQuery = window.matchMedia(
                "(prefers-reduced-motion: reduce)",
            );
            sectionElement.scrollIntoView({
                behavior: !mediaQuery.matches ? "smooth" : "auto",
                block: "start",
                inline: "start",
            });
            setActiveSection(anchorLinkName);
            history.pushState(null, "", `#${anchorLinkName}`);

            // Disconnect the observer to avoid active state conflicts from scrollIntoView() and intersectionObserver
            sectionObserver.current?.disconnect();

            const onScrollEnd = () => {
                const currentSectionRefs = sectionRefs.current;
                anchorSections.forEach((section) => {
                    const ref = currentSectionRefs.get(
                        getAnchorLinkId(section.anchorLinkName as string),
                    );
                    if (ref) {
                        sectionObserver.current?.observe(ref);
                    }
                });

                document.removeEventListener("scrollend", onScrollEnd);
            };

            let scrollEndTimeout: NodeJS.Timeout | null = null;
            const onScroll = () => {
                if (scrollEndTimeout) {
                    clearTimeout(scrollEndTimeout);
                }

                scrollEndTimeout = setTimeout(() => {
                    const currentSectionRefs = sectionRefs.current;
                    anchorSections.forEach((section) => {
                        const ref = currentSectionRefs.get(
                            getAnchorLinkId(section.anchorLinkName as string),
                        );
                        if (ref) {
                            sectionObserver.current?.observe(ref);
                        }
                    });

                    document.removeEventListener("scroll", onScroll);
                }, 500);
            };

            // Check if the scrollend event is supported
            if (typeof window !== "undefined") {
                if ("onscrollend" in window) {
                    document.addEventListener("scrollend", onScrollEnd);
                } else {
                    // Fallback to using the scroll event if scrollend is not supported: looking at you safari
                    document.addEventListener("scroll", onScroll);
                }
            }
        }
    };

    return (
        <div
            {...storyblokEditable(props)}
            style={
                {
                    "--anchor-nav-height": `${navHeight}px`,
                } as CSSProperties
            }
            className={getSpacingClassName({ spaceAbove, spaceBelow })}
        >
            <div
                className={cn(styles["anchor-nav-container"], {
                    [styles[`anchor-nav-container--${barStyle}`]]:
                        barStyle === "card",
                    [styles["anchor-nav-container--sticky"]]: stickyToTop,
                })}
            >
                <Container>
                    <nav
                        ref={navRef}
                        className={cn(styles["anchor-nav"], {
                            [styles[`anchor-nav--${barStyle}`]]:
                                barStyle === "flat",
                            [styles[
                                `anchor-nav--${textAlignmentContentPosition}`
                            ]]: textAlignmentContentPosition,
                        })}
                    >
                        <ul>
                            {anchorSections.map((section) => {
                                const anchorLinkName = section.anchorLinkName;
                                const anchorLinkId = getAnchorLinkId(
                                    anchorLinkName as string,
                                );
                                return (
                                    <li
                                        key={anchorLinkName}
                                        className={cn({
                                            [styles["active-anchor"]]:
                                                anchorLinkId === activeSection,
                                        })}
                                    >
                                        <a
                                            href={`#${anchorLinkId}`}
                                            className="anchor"
                                            onClick={(evt) => {
                                                handleAnchorClick(
                                                    evt,
                                                    anchorLinkId as string,
                                                );
                                                gtmSendEvent({
                                                    event: "track",
                                                    ga4_object: "ANCHOR",
                                                    ga4_action: "CLICKED",
                                                    stickyToTop,
                                                    clickText: anchorLinkName,
                                                });
                                            }}
                                        >
                                            {anchorLinkName}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                </Container>
            </div>
            {anchorSections.map((section) => {
                const anchorLinkName = section.anchorLinkName;
                const anchorLinkId = getAnchorLinkId(anchorLinkName as string);
                return (
                    <div
                        // @ts-ignore fix this in a separate PR before merging
                        ref={(ref) =>
                            sectionRefs.current.set(anchorLinkId as string, ref)
                        }
                        id={anchorLinkId}
                        key={section._uid}
                        className={cn(styles["anchor-section"], {
                            [styles["anchor-section--sticky"]]: stickyToTop,
                        })}
                    >
                        <StoryblokComponent blok={section} key={section._uid} />
                    </div>
                );
            })}
        </div>
    );
};
