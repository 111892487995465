import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { getGa4Object, gtmSendEvent } from "@wojo/analytics";
import { Text, VideoPlayer } from "@wojo/ui";
import { useVideoMetadata } from "@wojo/services";
import cn from "clsx";
import React, { CSSProperties, useId } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { VideoAndCoverImageRatio } from "../../types/video";
import styles from "./VideoAtom.module.scss";

export type VideoAtomProps = {
    /**
     * When enabled, the video plays without sound upon entering the viewport. Reserve this control for videos under 5 seconds. Autoplay will ignore cover artwork.
     */
    autoplayWithoutSound?: boolean;
    /**
     * Short caption for the video
     */
    caption?: string;
    /**
     * Overrides default alignment of `caption`
     */
    captionTextAlignment?: string;
    /**
     * Overrides default color of `caption`
     */
    captionTextColor?: string;
    /**
     * When enabled, the video will repeat infinitely unless manually paused by user.
     */
    loopVideo?: boolean;
    /**
     * Aspect ratio for the video player's container
     * @default VideoAndCoverImageRatio.Horizontal16By9
     */
    videoAndCoverImageRatio?: VideoAndCoverImageRatio;
    /**
     * Id for the video as defined in wistia
     */
    wistiaVideoId?: string;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

const aspectRatioValueMap = {
    [VideoAndCoverImageRatio.Square1By1]: "1/1",
    [VideoAndCoverImageRatio.Horizontal16By9]: "16/9",
    [VideoAndCoverImageRatio.Vertical3By4]: "3/4",
    [VideoAndCoverImageRatio.Horizontal4By3]: "4/3",
    [VideoAndCoverImageRatio.Vertical9By16]: "9/16",
};

const VideoAtomInternal: React.FC<VideoAtomProps> = (props) => {
    const {
        autoplayWithoutSound,
        caption,
        captionTextAlignment,
        captionTextColor,
        loopVideo,
        spaceAbove,
        spaceBelow,
        videoAndCoverImageRatio,
        wistiaVideoId,
    } = props;

    const captionId = useId();
    const { data: videoMetadata } = useVideoMetadata(wistiaVideoId);

    if (!wistiaVideoId) {
        return null;
    }

    return (
        <div
            {...storyblokEditable(props)}
            className={cn(
                styles.wrapper,
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
            style={
                {
                    "--aspect-ratio":
                        aspectRatioValueMap[
                            videoAndCoverImageRatio ||
                                VideoAndCoverImageRatio.Horizontal16By9
                        ],
                } as CSSProperties
            }
            id="video-atom"
        >
            <VideoPlayer
                videoId={wistiaVideoId}
                requestAutoplay={autoplayWithoutSound}
                loopVideo={loopVideo}
                fallbackImageUrl={videoMetadata?.url}
                fallbackImageAlt={videoMetadata?.videoTitle}
                aria-describedby={caption ? captionId : undefined}
                className={styles["wistia-wrapper"]}
                onPlaybackChange={(videoState) => {
                    gtmSendEvent({
                        event: "track",
                        ga4_object: "VIDEO",
                        ga4_action: videoState.toUpperCase(),
                        parentObject: getGa4Object(
                            document.getElementById("video-atom"),
                        ),
                        videoId: wistiaVideoId,
                    });
                }}
            />
            {caption && (
                <p
                    id={captionId}
                    className={styles.caption}
                    style={
                        {
                            "--text-align": captionTextAlignment,
                            "--text-color": captionTextColor,
                        } as CSSProperties
                    }
                >
                    {caption}
                </p>
            )}
        </div>
    );
};
export const VideoAtom: React.FC<VideoAtomProps> = (props) => {
    return (
        <ErrorBoundary fallback={<Text.Body>Error loading content</Text.Body>}>
            <VideoAtomInternal {...props} />
        </ErrorBoundary>
    );
};
