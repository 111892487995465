"use client";

import cn from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import React, {
    HTMLAttributes,
    ReactNode,
    useEffect,
    useId,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import styles from "./MediaSlider.module.scss";
import { SliderNumberIndicator } from "./SliderNumberIndicator";
import { SliderThumbnailNav } from "./SliderThumbnailNav";
import { SliderTrack } from "./SliderTrack";

export type MediaSliderProps = Omit<
    HTMLAttributes<HTMLDivElement>,
    "children"
> & {
    /**
     * Function to call when a new slide is chosen
     * @param oldPos last slide shown
     * @param newPos new selected slide
     */
    onSlide?: (oldPos: number, newPos: number) => void;
    /**
     * Disable both navigation buttons
     * @default false
     */
    disableNavigation?: boolean;
    /**
     * Display the slider thumbnail navigation
     * @default true
     */
    showThumbnailNav?: boolean;
    /**
     * Loop through the slides infinitely
     * @default false
     */
    loop?: boolean;
    /**
     * Class name applied to the slider number indicator
     * @default false
     */
    sliderNumberIndicatorClassName?: string;
    /**
     * Start the slider at a specific zero based index
     */
    startIndex?: number;
    /**
     * callback function for when slider button is clicked
     */
    onSliderButtonClick?: (direction: "prev" | "next") => void;
    /**
     * The slides to display in the slider
     */
    slides: {
        media: ReactNode;
        thumbnail: ReactNode;
        caption?: string;
    }[];
};

export const MediaSlider: React.FC<MediaSliderProps> = ({
    slides,
    onSlide,
    disableNavigation = false,
    sliderNumberIndicatorClassName,
    showThumbnailNav = true,
    className,
    loop = false,
    startIndex = 0,
    onSliderButtonClick,
    ...rest
}) => {
    const sliderId = useId();

    const [emblaMainRef, emblaMainApi] = useEmblaCarousel({
        active: !disableNavigation,
        loop,
    });
    const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
        dragFree: true,
        active: !disableNavigation,
    });

    const [sliderState, setSliderState] = useState({
        prevBtnDisabled: true,
        nextBtnDisabled: true,
        selectedIndex: startIndex,
    });
    const thumbRefs = useRef<HTMLButtonElement[]>([]);

    useEffect(() => {
        const onSelect = () => {
            setSliderState({
                prevBtnDisabled:
                    !emblaMainApi?.canScrollPrev() || disableNavigation,
                nextBtnDisabled:
                    !emblaMainApi?.canScrollNext() || disableNavigation,
                selectedIndex: emblaMainApi?.selectedScrollSnap() ?? 0,
            });

            if (showThumbnailNav) {
                emblaThumbsApi?.scrollTo(
                    emblaMainApi?.selectedScrollSnap() ?? 0,
                );
            }

            onSlide?.(
                emblaMainApi?.previousScrollSnap() ?? 0,
                emblaMainApi?.selectedScrollSnap() ?? 0,
            );
        };

        onSelect();
        emblaMainApi?.on("reInit", onSelect);
        emblaMainApi?.on("select", onSelect);
    }, [
        sliderState.selectedIndex,
        emblaMainApi,
        emblaThumbsApi,
        onSlide,
        disableNavigation,
        showThumbnailNav,
    ]);

    useLayoutEffect(() => {
        emblaThumbsApi?.scrollTo(startIndex, true);
        emblaMainApi?.scrollTo(startIndex, true);
    }, [startIndex, emblaMainApi, emblaThumbsApi]);

    const nbItems = slides.length;
    const slideIds = slides.map((_, index) => {
        return `${sliderId}_${index}`;
    });
    const selectedScrollSnap = emblaMainApi?.selectedScrollSnap();

    return (
        <div
            {...rest}
            className={cn(styles.wrapper, className)}
            role="group"
            aria-roledescription="carousel"
        >
            <SliderTrack
                captions={slides.map(({ caption }) => caption ?? "")}
                emblaMainRef={emblaMainRef}
                totalSlides={nbItems}
                slideIds={slideIds}
                selectedScrollSnap={selectedScrollSnap}
            >
                {slides.map(({ media }, index) => (
                    <React.Fragment key={index}>{media}</React.Fragment>
                ))}
            </SliderTrack>

            <SliderNumberIndicator
                className={sliderNumberIndicatorClassName}
                prevBtnDisabled={sliderState.prevBtnDisabled}
                nextBtnDisabled={sliderState.nextBtnDisabled}
                disableNavigation={disableNavigation}
                selectedIndex={sliderState.selectedIndex}
                scrollPrev={(e) => {
                    if (sliderState.prevBtnDisabled) {
                        e.preventDefault();
                    } else {
                        onSliderButtonClick?.("prev");
                        emblaMainApi?.scrollPrev();
                    }
                }}
                scrollNext={(e) => {
                    if (sliderState.nextBtnDisabled) {
                        e.preventDefault();
                    } else {
                        onSliderButtonClick?.("next");
                        emblaMainApi?.scrollNext();
                    }
                }}
                totalSlides={nbItems}
            />

            {showThumbnailNav && (
                <SliderThumbnailNav
                    emblaThumbsRef={emblaThumbsRef}
                    slideIds={slideIds}
                    selectedScrollSnap={selectedScrollSnap}
                    thumbRefs={thumbRefs}
                    scrollToSlide={(index: number) => {
                        emblaMainApi?.scrollTo(index);
                    }}
                    scrollPrev={(index: number) => {
                        if (!sliderState.prevBtnDisabled) {
                            emblaMainApi?.scrollTo(index - 1);
                            thumbRefs.current[index - 1].focus();
                        }
                    }}
                    scrollNext={(index: number) => {
                        if (!sliderState.nextBtnDisabled) {
                            emblaMainApi?.scrollTo(index + 1);
                            thumbRefs.current[index + 1].focus();
                        }
                    }}
                >
                    {slides.map(({ thumbnail }, index) => (
                        <React.Fragment key={index}>{thumbnail}</React.Fragment>
                    ))}
                </SliderThumbnailNav>
            )}
        </div>
    );
};
