import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { Button, Card, Text } from "@wojo/ui";
import cn from "clsx";
import React, { CSSProperties, useId } from "react";
import {
    ImageGraphicAtom,
    ImageGraphicAtomProps,
} from "../../atoms/image-graphic";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { Border, BorderProps } from "../../components/border";
import { StoryblokImage } from "../../components/storyblok-image";
import { BorderRadius } from "../../types/border";
import { Opacity } from "../../types/opacity";
import { HorizontalPos, VerticalPos } from "../../types/position";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { StoryblokLink } from "../../types/storyblok/storyblok-link";
import { StoryblokSlider } from "../../types/storyblok/storyblok-slider";
import styles from "./ImageCard.module.scss";

type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export type ImageCardProps = {
    backgroundImage: AssetStoryblok;
    imageGraphic?: ImageGraphicAtomProps[];
    heading?: string;
    description?: string;
    buttonText?: string;
    buttonLink?: StoryblokLink;
    imageRatio?: "16/9" | "4/3" | "1/1" | "3/4";
    overlayOpacity?: StoryblokSlider;
    textOverlayStyle?: "light-text" | "dark-text";
    contentYPos?: VerticalPos;
    contentXPos?: HorizontalPos;
    headingSize?: "2" | "3" | "4" | "5" | "6";
    headingTag?: HeadingTag;
    buttonVariant: "primary" | "inverse";
    colorPaletteOverride?: string;
    /**
     * Index Position of the item
     * @default 0
     * @description Used for tracking purposes
     */
    itemIndex?: number;
    columns?: number;
} & SbBlokData &
    Omit<BorderProps, "children"> &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const ImageCard: React.FC<ImageCardProps> = (props) => {
    const {
        buttonText,
        heading,
        description,
        buttonLink,
        imageGraphic,
        backgroundImage,
        overlayOpacity,
        headingTag,
        headingSize,
        textOverlayStyle,
        contentXPos,
        contentYPos,
        buttonVariant,
        colorPaletteOverride,
        imageRatio,
        borderRadius,
        spaceAbove,
        spaceBelow,
        borderSize,
        borderColor,
        borderPosition,
        borderStartColor,
        borderEndColor,
        borderGradientAngle,
        borderImage,
        itemIndex = 0,
        columns = 1,
    } = props;

    const titleId = useId();
    const descriptionId = useId();
    const buttonId = useId();
    const { href } = getCleanStoryblokLink(buttonLink);

    const desktopImageWidth = Math.floor((1232 * 2) / columns);
    const mobileImageWidth = 768 * 2;

    return (
        <Card
            {...storyblokEditable(props)}
            aria-labelledby={heading ? titleId : undefined}
            aria-describedby={cn({
                [`${descriptionId}`]: description,
                [`${buttonId}`]: buttonText && href,
            })}
            data-testid="imageCardContainer"
            tag={href ? "a" : "div"}
            href={href || undefined}
            onClick={() => {
                gtmSendEvent({
                    event: "track",
                    ga4_object: "IMAGE_CARD",
                    ga4_action: "CLICKED",
                    destinationUrl: href,
                    heading: heading,
                    clickText: buttonText,
                    index: itemIndex,
                });
            }}
            className={cn(
                styles.card,
                getSpacingClassName({ spaceAbove, spaceBelow }),
                {
                    [styles["card--button"]]: buttonText && href,
                    [styles["card--no-border-radius"]]:
                        borderRadius === BorderRadius.NoBorderRadius,
                    [styles["card--no-background"]]: backgroundImage.filename,
                },
            )}
            style={
                {
                    ["--aspect-ratio"]: imageRatio || "4/3",
                    ["--gradient-angle"]:
                        contentYPos === "top" ? "0deg" : "180deg",
                    ["--overlay-color"]:
                        textOverlayStyle === "light-text"
                            ? "0, 0, 0"
                            : "255, 255, 255",
                    ["--overlay-opacity"]:
                        overlayOpacity?.value ?? Opacity.Percent40,
                } as CSSProperties
            }
        >
            <Border
                className={styles["border-container"]}
                borderRadius={borderRadius}
                borderSize={borderSize}
                borderPosition={borderPosition}
                borderColor={borderColor}
                borderStartColor={borderStartColor}
                borderEndColor={borderEndColor}
                borderGradientAngle={borderGradientAngle}
                borderImage={borderImage}
            >
                <div
                    className={cn(
                        styles["content-container"],
                        styles[`content-container--x-${contentXPos || "left"}`],
                        styles[
                            `content-container--y-${contentYPos || "bottom"}`
                        ],
                    )}
                >
                    {backgroundImage.filename && (
                        <StoryblokImage
                            coverContainer
                            mobile={{
                                asset: backgroundImage,
                                size: { width: mobileImageWidth },
                            }}
                            desktop={{
                                asset: backgroundImage,
                                size: { width: desktopImageWidth },
                            }}
                            className={styles.background}
                        />
                    )}
                    <div
                        className={cn(styles.overlay, {
                            [styles[`overlay--${contentYPos}`]]: contentYPos,
                        })}
                        aria-hidden
                    />
                    {imageGraphic?.length ? (
                        <ImageGraphicAtom
                            {...imageGraphic[0]}
                            key={imageGraphic[0]._uid}
                        />
                    ) : null}
                    {!!heading && (
                        <Text.Heading
                            id={titleId}
                            order={headingSize || "3"}
                            tag={headingTag || "h3"}
                            className={cn(
                                styles.text,
                                styles[
                                    `text--${textOverlayStyle || "light-text"}`
                                ],
                                styles[`text--${headingSize || "3"}`],
                            )}
                        >
                            {heading}
                        </Text.Heading>
                    )}
                    {!!description && (
                        <Text.Body
                            id={descriptionId}
                            variant="1"
                            emphasis="1"
                            className={cn(
                                styles.text,
                                styles[
                                    `text--${textOverlayStyle || "light-text"}`
                                ],
                            )}
                        >
                            {description}
                        </Text.Body>
                    )}
                    {buttonText && href && (
                        <Button
                            tag="span"
                            id={buttonId}
                            className={styles.cta}
                            variant={buttonVariant}
                            palette={colorPaletteOverride}
                        >
                            {buttonText}
                        </Button>
                    )}
                </div>
            </Border>
        </Card>
    );
};
