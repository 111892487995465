import { SbBlokData, StoryblokComponent } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import cn from "clsx";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";

export type GlobalContentTypeProps = {
    items: SbBlokData[];
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const GlobalContentType: React.FC<GlobalContentTypeProps> = (props) => {
    const { items, spaceAbove, spaceBelow } = props;
    return (
        <div
            {...storyblokEditable(props)}
            className={cn(getSpacingClassName({ spaceAbove, spaceBelow }))}
        >
            {items.map((item, index) => (
                <StoryblokComponent key={index} blok={item} />
            ))}
        </div>
    );
};
