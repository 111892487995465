import { SbBlokData } from "@storyblok/react";
import {
    ISbStoryData,
    StoryblokComponent,
    storyblokEditable,
} from "@storyblok/react/rsc";
import cn from "clsx";
import React from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { GlobalContentTypeProps } from "../global-content-type";

export type GlobalContainerBlockProps = {
    collectionItem: ISbStoryData<GlobalContentTypeProps>;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const GlobalBlock: React.FC<GlobalContainerBlockProps> = (props) => {
    const { collectionItem, spaceAbove, spaceBelow } = props;

    const globalBlock = collectionItem.content;

    return (
        <div
            {...storyblokEditable(props)}
            className={cn(
                getSpacingClassName({
                    spaceAbove,
                    spaceBelow,
                }),
            )}
        >
            <StoryblokComponent blok={globalBlock} key={globalBlock._uid} />
        </div>
    );
};
