import { PromoLineItem } from "../generated/graphql";
import { groupBy } from "lodash";
import currency from "currency.js";

export type UniqPromo = Pick<
    PromoLineItem,
    "promoCode" | "targetType" | "campaignCode"
> & {
    price: Pick<PromoLineItem["price"], "value">;
};

export const groupPromosByPromoCode = (promoLineItems: UniqPromo[]) => {
    return groupBy(promoLineItems, (promo) => promo.promoCode);
};

export const calculateTotalPricePerPromoTarget = (
    promoLineItems: UniqPromo[],
) => {
    const groupedPromos = groupBy(
        promoLineItems,
        ({ promoCode, targetType, campaignCode }) =>
            JSON.stringify([promoCode, targetType, campaignCode || null]),
    );
    return Object.values(groupedPromos).map((lineItems) => {
        if (!lineItems.length) {
            return null;
        }
        const totalPrice = lineItems.reduce(
            (total, lineItem) =>
                currency(total).add(lineItem.price.value).value,
            0,
        );
        const { promoCode, targetType, campaignCode } = lineItems[0];

        return {
            promoCode,
            campaignCode,
            targetType,
            totalPrice,
        };
    });
};
