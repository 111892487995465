import cookies from "js-cookie";
import qs from "qs";
import { Cookie } from "../cookie";
import { WebConversionCookie } from "./web-conversion-cookie";
import { WebConversionParams } from "./web-conversion-params";

/**
 * Save web conversion data to a session cookie, omitting dynamic fields.
 * Data will not be updated unless a UTM parameter is included.
 *
 * @param {string | WebConversionParams} params An object of parsed utm params, or a query string to be parsed.
 */
export const storeWebConversionData = (
    params: string | WebConversionParams,
): void => {
    const data: WebConversionParams =
        typeof params === "string"
            ? (qs.parse(params, {
                  ignoreQueryPrefix: true,
              }) as WebConversionParams)
            : params;

    // Only update the cookie if a UTM parameter is included
    if (
        !data.utm_campaign &&
        !data.utm_content &&
        !data.utm_group &&
        !data.utm_medium &&
        !data.utm_source &&
        !data.utm_term
    ) {
        return;
    }

    cookies.set(
        Cookie.webConversion,
        JSON.stringify({
            UTMSource: data.utm_source,
            UTMMedium: data.utm_medium,
            UTMCampaign: data.utm_campaign,
            UTMContent: data.utm_content,
            UTMGroup: data.utm_group,
            UTMTerm: data.utm_term,
            UTMReferralUrl: data.utm_referralurl,
            mkwid: data.mkwid,
            k_clickid: data.k_clickid,
            mt: data.mt,
        } as WebConversionCookie),
        { expires: undefined }, // creates a session cookie
    );
};
