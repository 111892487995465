import React, { ReactNode } from "react";
import styles from "./SliderThumbnailNav.module.scss";

type EmblaViewportRefType = <ViewportElement extends HTMLElement>(
    instance: ViewportElement | null,
) => void;

export type SliderThumbnailNavProps = {
    scrollToSlide: (index: number) => void;
    scrollPrev: (index: number) => void;
    scrollNext: (index: number) => void;
    emblaThumbsRef: EmblaViewportRefType;
    slideIds: string[];
    children: ReactNode;
    selectedScrollSnap: number | undefined;
    thumbRefs: React.MutableRefObject<HTMLButtonElement[]>;
};

export const SliderThumbnailNav: React.FC<SliderThumbnailNavProps> = ({
    emblaThumbsRef,
    scrollToSlide,
    scrollPrev,
    scrollNext,
    slideIds,
    selectedScrollSnap,
    thumbRefs,
    children,
}) => {
    return (
        <div className={styles["thumbs"]}>
            <div ref={emblaThumbsRef} className={styles["thumbs-viewport"]}>
                <div className={styles["thumbs-track"]}>
                    {React.Children.map(children, (child, index) => (
                        <button
                            onClick={() => scrollToSlide(index)}
                            onKeyDown={(e) => {
                                switch (e.key) {
                                    case "ArrowLeft":
                                        scrollPrev(index);
                                        break;
                                    case "ArrowRight":
                                        scrollNext(index);
                                        break;
                                    default:
                                        break;
                                }
                            }}
                            key={`slider_thumb_${index}`}
                            className={styles.thumb}
                            type="button"
                            aria-selected={selectedScrollSnap === index}
                            role="tab"
                            aria-controls={slideIds[index]}
                            ref={(element) => {
                                if (!element) {
                                    return;
                                }
                                thumbRefs.current.push(element);
                            }}
                            tabIndex={selectedScrollSnap === index ? 0 : -1}
                        >
                            <span className="visually-hidden">
                                Show slide {index + 1}
                            </span>
                            <span className={styles["img-wrapper"]} aria-hidden>
                                {child}
                            </span>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
