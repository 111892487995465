import { Card } from "@wojo/ui";
import cn from "clsx";
import { useId } from "react";
import { FiChevronDown } from "react-icons/fi";
import styles from "./FilterPopover.module.scss";
import { usePopoverToggle } from "./use-popover-toggle";

export type FilterPopoverProps = React.PropsWithChildren<{
    triggerText: string;
    disabled?: boolean;
}>;

export const FilterPopover: React.FC<FilterPopoverProps> = ({
    children,
    triggerText,
    disabled,
}) => {
    const id = useId();

    const { contentProps, isOpen, triggerProps } = usePopoverToggle({
        id,
    });

    return (
        <div className={styles.wrapper}>
            <button
                {...triggerProps}
                className={styles.trigger}
                type="button"
                disabled={disabled}
            >
                <div className={styles["trigger-content"]}>
                    <span className={styles["trigger-text"]}>
                        {triggerText}
                    </span>
                    <FiChevronDown
                        className={cn(styles["trigger-icon"], {
                            [styles[`trigger-icon--expanded`]]: isOpen,
                        })}
                        aria-hidden
                    />
                </div>
            </button>
            <div
                {...contentProps}
                className={cn(styles.content, {
                    [styles[`content--expanded`]]: isOpen,
                })}
                hidden={isOpen ? undefined : true}
            >
                <Card>
                    <div className={cn(styles["children-wrapper"])}>
                        {children}
                    </div>
                </Card>
            </div>
        </div>
    );
};
