import { ModalFooter } from "@wojo/ui";
import { Route, Routes } from "react-router-dom";
import { LoginViewFooter, LoginWithMagicLinkSuccessFooter } from "../footers";
import { AuthPaths } from "./AuthPaths";

export const AuthFooter = () => {
    return (
        <Routes>
            <Route
                path={AuthPaths.Login}
                Component={() => (
                    <ModalFooter>
                        <LoginViewFooter />
                    </ModalFooter>
                )}
            />
            <Route
                path={AuthPaths.LoginWithMagicLinkSuccess}
                Component={() => (
                    <ModalFooter>
                        <LoginWithMagicLinkSuccessFooter />
                    </ModalFooter>
                )}
            />
            <Route path="*" Component={null} />
        </Routes>
    );
};
