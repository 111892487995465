"use client";

import { useQueryClient } from "@tanstack/react-query";
import {
    FiltersObject,
    getBlogPostsSearchQuery,
    getDeparturesSearchQuery,
    getTourPagesSearchQuery,
    QueryType,
    UseAlgoliaResponse,
} from "@wojo/services";
import { CalendarDateRange } from "@wojo/ui";
import {
    createParser,
    parseAsArrayOf,
    parseAsInteger,
    parseAsJson,
    useQueryStates,
} from "nuqs";
import { createContext, useContext, useEffect, useState } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useAlgoliaQuery } from "../../../client/use-algolia-query";
import {
    defaultFilterValues,
    defaultFormValues,
    FilterName,
    ResultsFormValues,
    sortOptionsMap,
} from "../form-types";

/**
 * @private Only for mocking tests. Use `ResultsProvider` and `useResultsContext` in production code
 */
export const ResultsContext = createContext<ResultsContextValue<QueryType>>(
    null!,
);

export type ResultsContextValue<Type extends QueryType> = {
    clearAllFilters: () => void;
    currentPage: number;
    facets: Exclude<UseAlgoliaResponse<Type>["facets"], undefined>;
    form: UseFormReturn<ResultsFormValues>;
    hasFiltersApplied: boolean;
    hits: UseAlgoliaResponse<Type>["hits"];
    hitLabel: string;
    hitsPerPage: number;
    pages: UseAlgoliaResponse<Type>["nbPages"];
    totalHits: UseAlgoliaResponse<Type>["nbHits"];
    sortOptions: string[];
};

export const useResultsContext = <
    Type extends QueryType,
>(): ResultsContextValue<Type> => {
    return useContext(ResultsContext) as ResultsContextValue<Type>;
};

type ResultsProviderProps<Type extends QueryType> = React.PropsWithChildren<{
    hitLabel: string;
    hitsPerPage: number;
    queryType: Type;
    queryKey: string[];
    ruleContexts?: string[];
    distinct?: number;
    attributesToRetrieve?: string[];
    retrieveGatewayPricing: boolean;
    defaultSort?: string;
}>;

const parseAsDateRange = createParser<CalendarDateRange | null>({
    parse: (query) => {
        const ISORange = parseAsJson<CalendarDateRange>().parse(query);
        return {
            from: ISORange?.from ? new Date(ISORange.from) : undefined,
            to: ISORange?.to ? new Date(ISORange.to) : undefined,
        };
    },
    serialize: (value) => {
        if (!value || !value.from) {
            return "";
        }
        return `{"from":"${value.from.toISOString()}"${
            value.to ? `,"to":"${value.to.toISOString()}"` : ``
        }}`;
    },
});

const parseAsEncodedString = createParser<string>({
    parse: (value) => {
        // Decode any percent-encoding
        const decodedValue = decodeURIComponent(value);
        // Parse any URL encoding
        const key = "key";
        return new URLSearchParams(`${key}=${decodedValue}`).get(key);
    },
    serialize: (value) => (value ? `${value}` : ""),
});

export const ResultsProvider = <Type extends QueryType>({
    children,
    hitLabel,
    hitsPerPage,
    queryType,
    queryKey,
    ruleContexts,
    distinct,
    attributesToRetrieve,
    retrieveGatewayPricing,
    defaultSort,
}: ResultsProviderProps<Type>) => {
    const [formQueryState, setFormQueryState] = useQueryStates(
        {
            page: parseAsInteger.withDefault(defaultFormValues["page"]),
            sort: parseAsEncodedString.withDefault(
                defaultSort ?? defaultFormValues["sort"],
            ),
            activityLevel: parseAsArrayOf(parseAsEncodedString).withDefault(
                defaultFilterValues["activityLevel"],
            ),
            dates: parseAsDateRange,
            destinations: parseAsArrayOf(parseAsEncodedString).withDefault(
                defaultFilterValues["destinations"],
            ),
            duration: parseAsArrayOf(parseAsEncodedString).withDefault(
                defaultFilterValues["duration"],
            ),
            price: parseAsArrayOf(parseAsEncodedString).withDefault(
                defaultFilterValues["price"],
            ),
            tripType: parseAsArrayOf(parseAsEncodedString).withDefault(
                defaultFilterValues["tripType"],
            ),
            discount: parseAsArrayOf(parseAsEncodedString).withDefault(
                defaultFilterValues["discount"],
            ),
            gatewayName: parseAsEncodedString.withDefault(
                defaultFormValues["gatewayName"],
            ),
        },
        {
            history: "push",
        },
    );
    const form = useForm<ResultsFormValues>({
        defaultValues: formQueryState,
    });
    const queryClient = useQueryClient();
    const [hasFiltersApplied, setHasFiltersApplied] = useState(false);
    const gatewayName = form.watch("gatewayName");

    const { page, ...formValues } = form.getValues() as FiltersObject;
    const queryOptions = {
        page: form.getValues("page"),
        nbItems: hitsPerPage,
        ruleContexts: ruleContexts || [],
        filters: formValues,
        distinct,
        attributesToRetrieve: [
            ...(attributesToRetrieve ?? []),

            // Get the gateway pricing only for the selected gateway
            retrieveGatewayPricing && gatewayName
                ? `gatewayPricing.${gatewayName}`
                : null,
        ].filter((attr): attr is string => !!attr),
    };

    const getQueryFn = () => {
        if (queryType === "tourPage") {
            return getTourPagesSearchQuery;
        }
        if (queryType === "departure") {
            return getDeparturesSearchQuery;
        }
        if (queryType === "blogPost") {
            return getBlogPostsSearchQuery;
        }
        throw new Error(`Unsupported query type: ${queryType}`);
    };

    const { data: response, refetch } = useAlgoliaQuery<Type>(
        getQueryFn(),
        queryKey,
        queryOptions,
    );

    useEffect(() => {
        const subscription = form.watch(async (value, { name }) => {
            const filtersApplied = [
                value.activityLevel?.length,
                value.dates,
                value.destinations?.length,
                value.duration?.length,
                value.price?.length,
                value.tripType?.length,
                value.discount?.length,
            ].some(Boolean);
            if (filtersApplied !== hasFiltersApplied) {
                setHasFiltersApplied(filtersApplied);
            }

            if (name !== "page") {
                form.setValue("page", 0);
            }
            //update query params from form
            await setFormQueryState(form.getValues());

            await queryClient.invalidateQueries({
                queryKey,
                refetchType: "none",
            });
            await refetch();
        });

        return () => subscription.unsubscribe();
    }, [
        form,
        hasFiltersApplied,
        queryClient,
        queryKey,
        refetch,
        setFormQueryState,
    ]);

    return (
        <ResultsContext.Provider
            value={{
                clearAllFilters: () => {
                    Object.entries(defaultFilterValues).forEach(
                        ([name, value]) => {
                            form.setValue(name as FilterName, value);
                        },
                    );
                    setHasFiltersApplied(false);
                },
                currentPage: form.getValues("page"),
                facets: response.disjunctiveFacets,
                form,
                hasFiltersApplied,
                hitLabel,
                hits: response.hits,
                hitsPerPage,
                pages: response.nbPages,
                sortOptions: sortOptionsMap[queryType],
                totalHits: response.nbHits,
            }}
        >
            <FormProvider {...form}>{children}</FormProvider>
        </ResultsContext.Provider>
    );
};
