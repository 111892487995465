"use client";

import cn from "clsx";
import React from "react";
import { DayPicker, DayPickerProps, isDateRange } from "react-day-picker";
import { CalendarValue } from "../shared/calendar-utils";
import styles from "./Calendar.module.scss";
import { CalendarDay } from "./CalendarDay";
import { CalendarNav } from "./CalendarNav";

export type CalendarProps = {
    /**
     * Callback for when a date is selected
     * @param {CalendarValue} date the currently selected Date(s)
     * @returns
     */
    onSelect?: (date?: CalendarValue) => void;
    /**
     * Callback for when the calendar is navigated by month/year
     */
    onNavigate?: (
        event: React.MouseEvent<HTMLButtonElement>,
        name?: string,
    ) => void;
    /**
     * Disables all elements within the calendar.
     * To disable certain components within the calendar, use dayPickerOptions
     * @default false
     */
    disableAll?: boolean;
    /**
     * Applies props directly to the DayPicker component for further customization
     * @see https://react-day-picker.js.org/api/types/DayPickerProps
     */
    dayPickerOptions: DayPickerProps;
    /**
     * Prominently displays today on the calendar
     * @default false
     */
    highlightToday?: boolean;
    /**
     * Applies a custom class to the component's root
     */
    className?: string;
};

/**
 * Resuable inner calendar component
 * @returns
 */
export const Calendar: React.FC<CalendarProps> = ({
    onSelect,
    onNavigate,
    disableAll,
    dayPickerOptions,
    highlightToday,
    className = "",
}) => {
    const { mode, ...rest } = dayPickerOptions;

    const handleSingleSelect = (date: unknown) => {
        if (isDateRange(date) || date instanceof Date) {
            onSelect?.(date);
        } else {
            onSelect?.(undefined);
        }
    };

    const options: DayPickerProps = {
        mode: mode === "single" ? "single" : "range",
        //@ts-ignore https://github.com/gpbl/react-day-picker/issues/1583
        onSelect: handleSingleSelect,
        disableNavigation: disableAll,
        components: {
            Caption: (props) => CalendarNav({ ...props, onNavigate }),
            Day: CalendarDay,
        },
        classNames: {
            root: className || "rdp",
            table: cn(styles.calendar, className, {
                [styles["calendar--disabled"]]: disableAll,
            }),
            head_cell: styles.weekday,
            button: styles.date,
            cell: styles["date-cell"],
            day_range_start: styles["range--start"],
            day_range_middle: styles["range--middle"],
            day_range_end: styles["range--end"],
        },
        modifiers: {
            ariaDisabled: disableAll ?? false,
        },
        modifiersClassNames: {
            selected: styles["date--selected"],
            disabled: styles["date--disabled"],
            ariaDisabled: styles["date--disabled"],
            today: cn({ [styles["date--today"]]: highlightToday }),
        },
        ...rest,
    };
    return <DayPicker {...options} />;
};
