import { QueryType } from "@wojo/services";
import React from "react";
import {
    ResultsContextValue,
    useResultsContext,
} from "../results-provider/ResultsProvider";

export type ResultsRenderPropProps<Type extends QueryType> = {
    currentPage: number;
    hits: ResultsContextValue<Type>["hits"];
    pages: number;
    hasFiltersApplied: boolean;
};

export type ResultsRenderProp<Type extends QueryType> = (
    props: ResultsRenderPropProps<Type>,
) => React.ReactNode;

export type ResultsRenderer<Type extends QueryType> = {
    render: ResultsRenderProp<Type>;
};

export function ResultsRenderer<Type extends QueryType>({
    render,
}: ResultsRenderer<Type>) {
    const { currentPage, hits, pages, hasFiltersApplied } =
        useResultsContext<Type>();
    return (
        <>
            {render({
                currentPage,
                hits,
                pages,
                hasFiltersApplied,
            })}
        </>
    );
}
