import { apiPlugin, ISbConfig, storyblokInit } from "@storyblok/react/rsc";
import { ButtonAtom } from "../atoms/button";
import { ImageAtom } from "../atoms/image";
import { ImageGraphicAtom } from "../atoms/image-graphic";
import { TextAtom } from "../atoms/text";
import { TitleAtom } from "../atoms/title";
import { VideoAtom } from "../atoms/video";
import { GlobalBlock } from "../components/global-block";
import { GlobalContentType } from "../components/global-content-type";
import { OrganizationSchema } from "../components/organization-schema";
import { Seo } from "../components/seo";
import { SectionContainer } from "../containers/section-container";
import { TextSectionContainer } from "../containers/text-section-container";
import { CollapsibleItem } from "../molecules/collapsible-item";
import { ContentCard } from "../molecules/content-card";
import { FlexibleImage } from "../molecules/flexible-image";
import { FlexibleVideo } from "../molecules/flexible-video";
import { GraphicGridItem } from "../molecules/graphic-grid-item";
import { GraphicListItem } from "../molecules/graphic-list-item";
import { ImageCard } from "../molecules/image-card";
import { PageLink } from "../molecules/page-link";
import { TextCard } from "../molecules/text-card";
import { VerticalVideo } from "../molecules/vertical-video";
import { AnchorNavigation } from "../organisms/anchor-navigation";
import { BlogArticleGrid } from "../organisms/blog-article-grid";
import { BlogArticleSlider } from "../organisms/blog-article-slider";
import { CollapsibleContent } from "../organisms/collapsible-content";
import { ContentCardGrid } from "../organisms/content-card-grid";
import { ContentSlider } from "../organisms/content-slider";
import { CountdownTimer } from "../organisms/countdown-timer";
import { DealsTourCardGrid } from "../organisms/deals-tour-card-grid";
import { FeaturedContent } from "../organisms/featured-content";
import { GraphicGrid } from "../organisms/graphic-grid";
import { GraphicList } from "../organisms/graphic-list";
import { HeroContent } from "../organisms/hero-content";
import { ImageCardGrid } from "../organisms/image-card-grid";
import { LeadFormBlock } from "../organisms/lead-form-block";
import { LightningDealCardGrid } from "../organisms/lightning-deal-card-grid";
import { MediaGrid } from "../organisms/media-grid";
import { MediaSlider } from "../organisms/media-slider";
import { PageLinkGrid } from "../organisms/page-link-grid";
import { Quote } from "../organisms/quote";
import { ReviewsList } from "../organisms/reviews-list";
import { ReviewsSlider } from "../organisms/reviews-slider";
import { SplitScreenContent } from "../organisms/split-screen-content";
import { TabbedContent } from "../organisms/tabbed-content";
import { TextBanner } from "../organisms/text-banner";
import { TextCardGrid } from "../organisms/text-card-grid";
import { TourCardGrid } from "../organisms/tour-card-grid";
import { TourCardSlider } from "../organisms/tour-card-slider";
import { UgcBlock } from "../organisms/ugc-block";
import { VideoGallery } from "../organisms/video-gallery/VideoGallery";
import { ZendeskArticle } from "../organisms/zendesk-article";
import { BlogArticlePage } from "../pages/blog-article-page";
import { BlogAuthorPage } from "../pages/blog-author-page";
import { BlogListingPage } from "../pages/blog-listing-page";
import { Page } from "../pages/page";

export function initializeStoryblok(
    accessToken: string | undefined,
    apiOptions?: ISbConfig,
) {
    storyblokInit({
        accessToken: accessToken,
        apiOptions: {
            region: "us",
            ...apiOptions,
        },
        use: [apiPlugin],
        components: {
            "wojo-anchor-navigation": ({ blok }) => (
                <AnchorNavigation {...blok} />
            ),
            "wojo-blog-article-grid": ({ blok }) => (
                <BlogArticleGrid {...blok} />
            ),
            "wojo-blog-article-page": ({ blok }) => (
                <BlogArticlePage {...blok} />
            ),
            "wojo-blog-article-slider": ({ blok }) => (
                <BlogArticleSlider {...blok} />
            ),
            "wojo-blog-author-page": ({ blok }) => <BlogAuthorPage {...blok} />,
            "wojo-blog-category-page": ({ blok }) => (
                <BlogListingPage {...blok} />
            ),
            "wojo-blog-tag-page": ({ blok }) => <BlogListingPage {...blok} />,
            "wojo-button-atom": ({ blok }) => <ButtonAtom {...blok} />,
            "wojo-collapsible-content": ({ blok }) => (
                <CollapsibleContent {...blok} />
            ),
            "wojo-collapsible-item": ({ blok }) => (
                <CollapsibleItem {...blok} />
            ),
            "wojo-content-card": ({ blok }) => <ContentCard {...blok} />,
            "wojo-content-card-grid": ({ blok }) => (
                <ContentCardGrid {...blok} />
            ),
            "wojo-content-slider": ({ blok }) => <ContentSlider {...blok} />,
            "wojo-deals-tour-card-grid": ({ blok }) => (
                <DealsTourCardGrid {...blok} />
            ),
            "wojo-countdown-timer": ({ blok }) => <CountdownTimer {...blok} />,
            "wojo-featured-content": ({ blok }) => (
                <FeaturedContent {...blok} />
            ),
            "wojo-flexible-image": ({ blok }) => <FlexibleImage {...blok} />,
            "wojo-flexible-video": ({ blok }) => <FlexibleVideo {...blok} />,
            "wojo-global-container": ({ blok }) => (
                <GlobalContentType {...blok} />
            ),
            "wojo-global-container-block": ({ blok }) => (
                <GlobalBlock {...blok} />
            ),
            "wojo-global-organism": ({ blok }) => (
                <GlobalContentType {...blok} />
            ),
            "wojo-global-organism-block": ({ blok }) => (
                <GlobalBlock {...blok} />
            ),
            "wojo-graphic-grid": ({ blok }) => <GraphicGrid {...blok} />,
            "wojo-graphic-grid-item": ({ blok }) => (
                <GraphicGridItem {...blok} />
            ),
            "wojo-graphic-list": ({ blok }) => <GraphicList {...blok} />,
            "wojo-graphic-list-item": ({ blok }) => (
                <GraphicListItem {...blok} />
            ),
            "wojo-hero-content": ({ blok }) => <HeroContent {...blok} />,
            "wojo-image-atom": ({ blok }) => <ImageAtom {...blok} />,
            "wojo-image-card": ({ blok }) => <ImageCard {...blok} />,
            "wojo-image-card-grid": ({ blok }) => <ImageCardGrid {...blok} />,
            "wojo-image-graphic-atom": ({ blok }) => (
                <ImageGraphicAtom {...blok} />
            ),
            "wojo-lead-form-block": ({ blok }) => <LeadFormBlock {...blok} />,
            "wojo-lightning-deals-grid": ({ blok }) => (
                <LightningDealCardGrid {...blok} />
            ),
            "wojo-media-grid": ({ blok }) => <MediaGrid {...blok} />,
            "wojo-media-slider": ({ blok }) => <MediaSlider {...blok} />,
            "wojo-organization-schema": ({ blok }) => (
                <OrganizationSchema {...blok} />
            ),
            "wojo-page": ({ blok }) => <Page {...blok} />,
            "wojo-page-link": ({ blok }) => <PageLink {...blok} />,
            "wojo-page-link-grid": ({ blok }) => <PageLinkGrid {...blok} />,
            "wojo-quote": ({ blok }) => <Quote {...blok} />,
            "wojo-reviews-list": ({ blok }) => <ReviewsList {...blok} />,
            "wojo-reviews-slider": ({ blok }) => <ReviewsSlider {...blok} />,
            "wojo-section-container": ({ blok }) => (
                <SectionContainer {...blok} />
            ),
            "wojo-seo": ({ blok }) => <Seo {...blok} />,
            "wojo-split-screen-content": ({ blok }) => (
                <SplitScreenContent {...blok} />
            ),
            "wojo-tabbed-content": ({ blok }) => <TabbedContent {...blok} />,
            "wojo-text-atom": ({ blok }) => <TextAtom {...blok} />,
            "wojo-text-banner": ({ blok }) => <TextBanner {...blok} />,
            "wojo-text-card": ({ blok }) => <TextCard {...blok} />,
            "wojo-text-card-grid": ({ blok }) => <TextCardGrid {...blok} />,
            "wojo-text-section-container": ({ blok }) => (
                <TextSectionContainer {...blok} />
            ),
            "wojo-title-atom": ({ blok }) => <TitleAtom {...blok} />,
            "wojo-tour-card-grid": ({ blok }) => <TourCardGrid {...blok} />,
            "wojo-tour-card-slider": ({ blok }) => <TourCardSlider {...blok} />,
            "wojo-ugc-block": ({ blok }) => <UgcBlock {...blok} />,
            "wojo-vertical-video": ({ blok }) => <VerticalVideo {...blok} />,
            "wojo-video-gallery": ({ blok }) => <VideoGallery {...blok} />,
            "wojo-video-atom": ({ blok }) => <VideoAtom {...blok} />,
            "wojo-zendesk-article": ({ blok }) => <ZendeskArticle {...blok} />,
        },
    });
}
