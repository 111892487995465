import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { Container, Grid, GridAlignment } from "@wojo/ui";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { TextCard, TextCardProps } from "../../molecules/text-card";

export type TextCardGridProps = {
    /**
     * Sets the layout on Desktop.
     * On Tablet & Mobile, the layout will automatically optimize for the smaller width.
     * @default 2
     */
    layout?: "2" | "3" | "4";

    /**
     * Sets the position of blocks within the grid
     * @default left
     */
    cardPosition?: GridAlignment;

    /**
     * Text Card blocks to display
     *
     */
    cards?: TextCardProps[];
} & SbBlokData &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

export const TextCardGrid: React.FC<TextCardGridProps> = (props) => {
    const {
        cardPosition,
        layout,
        cards,
        spaceAbove,
        spaceBelow,
        ...otherProps
    } = props;

    return (
        <Container>
            <Grid
                {...storyblokEditable(props)}
                columns={parseInt(layout || "2")}
                alignment={cardPosition || "left"}
                className={getSpacingClassName({ spaceAbove, spaceBelow })}
                {...otherProps}
            >
                {cards?.map((cardBlok: TextCardProps, index) => (
                    <TextCard
                        {...cardBlok}
                        itemIndex={index}
                        key={cardBlok._uid}
                    />
                ))}
            </Grid>
        </Container>
    );
};
