import { SbBlokData } from "@storyblok/react";
import {
    ISbStoryData,
    StoryblokComponent,
    storyblokEditable,
} from "@storyblok/react/rsc";
import { useI18n } from "@wojo/localization";
import { Button, Container, Text } from "@wojo/ui";
import cn from "clsx";
import dayjs from "dayjs";
import React from "react";

import { ImageAtom } from "../../atoms/image";
import { TitleAtom } from "../../atoms/title";
import { SchemaScript } from "../../components/schema-script";
import { StoryblokImage } from "../../components/storyblok-image";
import { BlogArticleSlider } from "../../organisms/blog-article-slider";
import { TourCardSlider } from "../../organisms/tour-card-slider";
import { ImageRatio } from "../../types/image";
import { Spacing } from "../../types/spacing";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { BlogAuthorPageProps } from "../blog-author-page";
import { BlogListingPageProps } from "../blog-listing-page";
import styles from "./BlogArticlePage.module.scss";

export type BlogArticlePageProps = {
    _uid: string;
    featuredImage?: AssetStoryblok;
    category: ISbStoryData<BlogListingPageProps>;
    title?: string;
    leadInText?: string;
    searchExcerpt?: string;
    author: ISbStoryData<BlogAuthorPageProps>;
    publishedDate?: string;
    body: SbBlokData[];
    relatedToursHeading?: string;
    relatedToursAlgoliaContext?: string;
    relatedArticlesHeading?: string;
    relatedArticlesAlgoliaContext?: string;
    brandContent?: SbBlokData[];
    tags: ISbStoryData<BlogListingPageProps>[];
    seo?: SbBlokData[];
    analytics?: SbBlokData[];
    hideFeaturedImage?: boolean;
    hideAuthorBio?: boolean;
};

export const BlogArticlePage: React.FC<BlogArticlePageProps> = (props) => {
    const i18n = useI18n("storyblok").storyblok;
    const featuredImage = props.hideFeaturedImage
        ? undefined
        : props.featuredImage;

    return (
        <div {...storyblokEditable(props)}>
            <SchemaScript
                data={{
                    "@type": "Article",
                    "@context": "https://schema.org",
                    headline: props.title,
                    datePublished: props.publishedDate,
                    image: featuredImage?.filename,
                    author: {
                        "@type": "Person",
                        name: props.author.content.name,
                        url: `${process.env.NEXT_PUBLIC_DOMAIN}/${props.author.full_slug}`,
                    },
                    description: props.searchExcerpt,
                }}
            />
            {!!featuredImage?.filename && (
                <div className={styles.hero}>
                    <StoryblokImage
                        coverContainer
                        defer={false}
                        mobile={{
                            asset: featuredImage,
                            size: { width: 1534 },
                        }}
                        desktop={{
                            asset: featuredImage,
                            size: { width: 3840 },
                        }}
                    />
                </div>
            )}
            <Container
                width="medium"
                contentsClassName={cn(styles.column, {
                    [styles["column-with-hero"]]: !!featuredImage?.filename,
                })}
            >
                <div className={styles["column-inner"]}>
                    <TitleAtom
                        headingTag="h1"
                        heading={props.title}
                        spaceBelow={Spacing.Small}
                    />
                    <Text.DisplayBody variant="2">
                        {props.leadInText}
                    </Text.DisplayBody>
                    <Byline
                        author={props.author}
                        publishedDate={props.publishedDate}
                    />
                </div>
            </Container>
            {props.body.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
            <Container width="small" contentsClassName={styles.column}>
                <Tags category={props.category} tags={props.tags} />
                <Author
                    hideAuthorBio={props.hideAuthorBio}
                    author={props.author}
                />
            </Container>
            {!!props.relatedToursAlgoliaContext && (
                <>
                    <Container>
                        <TitleAtom
                            heading={
                                props.relatedToursHeading ||
                                i18n.blogArticlePage.relatedToursHeading.l()
                            }
                            spaceAbove={Spacing.Large}
                            spaceBelow={Spacing.Large}
                        />
                    </Container>
                    <TourCardSlider
                        _uid={props._uid}
                        algoliaRuleTriggerContext={
                            props.relatedToursAlgoliaContext
                        }
                        numberOfTours={{
                            plugin: "storyblok-slider",
                            value: 20,
                        }}
                        spaceBelow={Spacing.XLarge}
                    />
                </>
            )}
            {!!props.relatedArticlesAlgoliaContext && (
                <>
                    <Container>
                        <TitleAtom
                            heading={
                                props.relatedArticlesHeading ||
                                i18n.blogArticlePage.relatedArticlesLabel.l()
                            }
                            spaceAbove={Spacing.Large}
                            spaceBelow={Spacing.Large}
                        />
                    </Container>
                    <BlogArticleSlider
                        _uid={props._uid}
                        algoliaRuleTriggerContext={
                            props.relatedArticlesAlgoliaContext
                        }
                        numberOfArticles={{
                            plugin: "storyblok-slider",
                            value: 20,
                        }}
                        spaceBelow={Spacing.XLarge}
                    />
                </>
            )}
            {props.brandContent?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
            {props.seo?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
            {props.analytics?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </div>
    );
};

const Byline: React.FC<
    Pick<BlogArticlePageProps, "author" | "publishedDate">
> = (props) => {
    return (
        <div className={styles.byline}>
            {!!props.author.content.profileImage?.filename && (
                <div className={styles["byline-image"]}>
                    <ImageAtom
                        image={props.author.content.profileImage}
                        imageRatio={ImageRatio.Circle1By1}
                    />
                </div>
            )}
            {!!props.author.content.name && (
                <div className={styles.authoring}>
                    <Text.Body emphasis="1">
                        by{" "}
                        <Button
                            variant="quiet"
                            tag="a"
                            href={"/" + props.author.full_slug}
                        >
                            {props.author.content.name}
                        </Button>
                    </Text.Body>
                    {!!props.publishedDate && (
                        <Text.Body>
                            {dayjs
                                .utc(props.publishedDate)
                                .format("MMMM D, YYYY")}
                        </Text.Body>
                    )}
                </div>
            )}
        </div>
    );
};

const Tags: React.FC<Pick<BlogArticlePageProps, "category" | "tags">> = (
    props,
) => {
    return (
        <div className={styles.tags}>
            {props.category.content.name && (
                <Button
                    variant="quiet"
                    tag="a"
                    href={"/" + props.category.full_slug}
                >
                    {props.category.content.name}
                </Button>
            )}
            {props.tags.map((tag) => {
                if (!tag.content.name) {
                    return null;
                }
                return (
                    <Button
                        variant="quiet"
                        tag="a"
                        key={tag.full_slug}
                        href={"/" + tag.full_slug}
                    >
                        {tag.content.name}
                    </Button>
                );
            })}
        </div>
    );
};

const Author: React.FC<
    Pick<BlogArticlePageProps, "author" | "hideAuthorBio">
> = (props) => {
    const i18n = useI18n("storyblok").storyblok.blogArticlePage;
    if (props.hideAuthorBio === true) {
        return null;
    }
    return (
        <>
            <a
                href={"/" + props.author.full_slug}
                className={styles["author-link"]}
            >
                <div className={styles.author}>
                    {!!props.author.content.profileImage?.filename && (
                        <div className={styles["author-image"]}>
                            <ImageAtom
                                image={props.author.content.profileImage}
                                imageRatio={ImageRatio.Circle1By1}
                            />
                        </div>
                    )}
                    {!!props.author.content.name && (
                        <div className={styles.authoring}>
                            <Text.Detail variant="2" tag="h2">
                                {i18n.aboutAuthorLabel.l()}
                            </Text.Detail>
                            <Text.DisplayBody emphasis="2">
                                {props.author.content.name}
                            </Text.DisplayBody>
                        </div>
                    )}
                </div>
            </a>
            {!!props.author.content.shortBio && (
                <Text className={styles.bio}>
                    {props.author.content.shortBio}
                </Text>
            )}
        </>
    );
};
