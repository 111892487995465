"use client";

import React, { CSSProperties, useRef, useState } from "react";
import { VerticalVideoProps } from "../../molecules/vertical-video";
import { MobilePreviewAspectRatio } from "../../types/video";
import { FullscreenVideoSlider } from "./FullscreenVideoSlider";
import styles from "./MobileVideoGallery.module.scss";
import { Container } from "@wojo/ui";
import { useIntersection } from "@mantine/hooks";
import { TbPlayerPlayFilled } from "react-icons/tb";
import { useI18n } from "@wojo/localization";
import { VideoThumbnail } from "./VideoThumbnail";

export type MobileVideoGalleryProps = {
    /**
     * List of vertical videos
     */
    videos: VerticalVideoProps[];
    /**
     * Aspect ratio of the video preview on mobile
     */
    mobilePreviewAspectRatio?: MobilePreviewAspectRatio;
};

export const MobileVideoGallery: React.FC<MobileVideoGalleryProps> = ({
    videos,
    mobilePreviewAspectRatio,
}) => {
    const i18n = useI18n("storyblok");
    const containerRef = useRef<HTMLDivElement>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { ref: previewRef, entry: previewEntry } = useIntersection({
        root: containerRef.current,
        threshold: 1,
    });

    const aspectRatioValueMap = {
        [MobilePreviewAspectRatio.Square1By1]: "1/1",
        [MobilePreviewAspectRatio.Vertical3By4]: "3/4",
        [MobilePreviewAspectRatio.Vertical9By16]: "9/16",
    };
    const previewVideosToDisplay = 3;

    return (
        <>
            <Container contentsClassName={styles["preview-container"]}>
                <div
                    ref={previewRef}
                    className={styles.preview}
                    style={
                        {
                            "--aspect-ratio":
                                aspectRatioValueMap[
                                    mobilePreviewAspectRatio ||
                                        MobilePreviewAspectRatio.Vertical3By4
                                ],
                        } as CSSProperties
                    }
                >
                    {videos
                        .slice(0, previewVideosToDisplay)
                        .map((video, index) => {
                            const verticalTranslate =
                                previewEntry?.isIntersecting
                                    ? "--g-spacing-md"
                                    : "--g-spacing-sm";
                            const previewVideoVerticalTranslate = `calc(var(${verticalTranslate}) * ${index})`;
                            const previewVideoScale = 1 - index * 0.1;
                            return (
                                <div
                                    className={styles["video-wrapper"]}
                                    key={video.wistiaVideoId}
                                    style={
                                        {
                                            transform: `translateY(${previewVideoVerticalTranslate}) translateX(-50%)
                                                    scale(${previewVideoScale})`,
                                            zIndex:
                                                previewVideosToDisplay - index,
                                        } as CSSProperties
                                    }
                                >
                                    <VideoThumbnail
                                        altText={
                                            videos.at(index)?.heading ?? ""
                                        }
                                        className={styles.thumbnail}
                                        loadingClassName={styles.thumbnail}
                                        videoId={video.wistiaVideoId}
                                    />
                                </div>
                            );
                        })}
                    <button
                        type="button"
                        onClick={() => {
                            if (!isModalOpen) {
                                setIsModalOpen(true);
                            }
                        }}
                        className={styles["click-to-play-area"]}
                        aria-label={i18n.storyblok.mobileVideoGallery.playButtonLabel.l()}
                    >
                        <span className={styles["play-button"]}>
                            <TbPlayerPlayFilled aria-hidden />
                        </span>
                    </button>
                </div>
            </Container>
            <FullscreenVideoSlider
                isOpen={isModalOpen}
                onCloseRequested={() => {
                    setIsModalOpen(false);
                }}
                videos={videos}
            />
        </>
    );
};
