"use client";

import { StoryblokComponent } from "@storyblok/react/rsc";
import cn from "clsx";
import { FlexibleImageProps } from "../../molecules/flexible-image";
import { FlexibleVideoProps } from "../../molecules/flexible-video";
import styles from "./SplitScreenContent.module.scss";

type SplitScreenContentMediaProps = {
    media: (FlexibleImageProps | FlexibleVideoProps)[];
    reverseLayout?: boolean;
};

export const SplitScreenContentMedia: React.FC<
    SplitScreenContentMediaProps
> = ({ media, reverseLayout }) => {
    if (!media.length) {
        return null;
    }

    const isAutoPlayVideo =
        media[0]?.videoType === "autoPlay" ||
        (!Boolean(media[0]?.videoType) && Boolean(media[0]?.wistiaVideoId));

    return (
        <div
            className={cn(styles.media, {
                [styles[`video--autoplay`]]: isAutoPlayVideo,
                [styles[`video--autoplay--reverse`]]:
                    reverseLayout && isAutoPlayVideo,
            })}
        >
            <StoryblokComponent blok={media[0]} key={media[0]._uid} />
        </div>
    );
};
