import currency from "currency.js";
import {
    BookingTripFragment,
    Maybe,
    QuoteTripFragment,
} from "generated/graphql";
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";

export type LineItems = ReturnType<typeof getLineItems>;

export type LineItem = QuoteTripFragment["lineItems"][0];
type TripOpenLineItem =
    | QuoteTripFragment["openExcursionLineItems"][0]
    | QuoteTripFragment["openRoomLineItem"]
    | QuoteTripFragment["tourLineItem"]
    | QuoteTripFragment["openExtensionLineItem"];

type LineItemTypename = Exclude<LineItem["__typename"], undefined>;

/**
 * A function to get all line items and grouped line items by type for a draft quote or booking
 * @param trips The list of trips from a draft quote or booking
 * @returns an object containing an array of all line items for all trips, arrays for each type
 * of line item for all trips (ex. flight packages, extensions, etc.), and a function to get specific
 * line items of a type for a specific trip (ex. get all excursion line items for a specific trip)
 */
export const getLineItems = (
    trips: Maybe<(QuoteTripFragment | BookingTripFragment)[]>,
) => {
    const allTripsLineItemsByTrip = trips?.map<{
        id: string;
        lineItemGroups: Partial<Record<LineItemTypename, LineItem[]>>;
    }>((trip) => {
        return {
            id: trip._id,
            lineItemGroups: groupBy(
                trip.lineItems,
                (lineItem) => lineItem.__typename,
            ),
        };
    });

    const getTripLineItems = (tripId: string) =>
        allTripsLineItemsByTrip?.find((group) => group.id === tripId);

    const getLineItemsOfType = <T extends LineItem>(type: LineItemTypename) =>
        uniq(
            trips?.flatMap(
                (trip) =>
                    (getTripLineItems(trip._id)?.lineItemGroups[type] ??
                        []) as T[],
            ),
        );

    const getLineItemsOfTypeForTrip = (
        type: LineItemTypename,
        trip: QuoteTripFragment,
    ) => {
        const lineItemGroups = groupBy(
            trip.lineItems,
            (lineItem) => lineItem.__typename,
        );
        const lineItemGroup = lineItemGroups[type] ?? [];
        const appliedRollUps = lineItemGroup.map(applyRollUps);
        return appliedRollUps;
    };

    const allAdjustmentLineItems =
        getLineItemsOfType<
            Extract<LineItem, { __typename?: "AdjustmentLineItem" }>
        >("AdjustmentLineItem");

    const adjustmentLineItems = allAdjustmentLineItems.filter(
        (adjustment) =>
            !adjustment.rollsUpToTarget || !adjustment.targetLineItemId,
    );
    const adjustmentsToRollUp = allAdjustmentLineItems.filter(
        (adjustment) =>
            adjustment.rollsUpToTarget && adjustment.targetLineItemId,
    );

    const allFeeLineItems =
        getLineItemsOfType<Extract<LineItem, { __typename?: "FeeLineItem" }>>(
            "FeeLineItem",
        );

    const feeLineItems = allFeeLineItems.filter(
        (fee) => !fee.rollsUpToTarget || !fee.targetLineItemId,
    );
    const feesToRollUp = allFeeLineItems.filter(
        (fee) => fee.rollsUpToTarget && fee.targetLineItemId,
    );

    const allPrivateGroupLineItems = getLineItemsOfType<
        Extract<LineItem, { __typename?: "PrivateGroupLineItem" }>
    >("PrivateGroupLineItem");
    const privateGroupLineItems = allPrivateGroupLineItems.filter(
        (privateGroup) =>
            !privateGroup.rollsUpToTarget || !privateGroup.targetLineItemId,
    );
    const privateGroupsToRollUp = allPrivateGroupLineItems.filter(
        (privateGroup) =>
            privateGroup.rollsUpToTarget && privateGroup.targetLineItemId,
    );

    const applyRollUps = <T extends LineItem | TripOpenLineItem>(
        lineItem: T,
    ): T => {
        if (!lineItem) {
            return lineItem;
        }
        const feesTargetingLineItem = feesToRollUp.filter(
            (fee) => fee.targetLineItemId === lineItem._id,
        );
        const adjustmentsTargetingLineItem = adjustmentsToRollUp.filter(
            (adjustment) => adjustment.targetLineItemId === lineItem._id,
        );
        const privateGroupsTargetingLineItem = privateGroupsToRollUp.filter(
            (privateGroup) => privateGroup.targetLineItemId === lineItem._id,
        );
        const targetingLineItems = [
            ...feesTargetingLineItem,
            ...adjustmentsTargetingLineItem,
            ...privateGroupsTargetingLineItem,
        ];
        if (!targetingLineItems.length) {
            return lineItem;
        }
        const value = targetingLineItems.reduce(
            (acc, cur) => acc.add(cur.price.value),
            currency(lineItem.price.value),
        ).value;
        return {
            ...lineItem,
            price: {
                ...lineItem.price,
                value,
                formatted: value.toLocaleString(undefined, {
                    style: "currency",
                    currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
            },
        };
    };

    const excursionLineItems =
        trips
            ?.map((trip) => trip.openExcursionLineItems)
            .flat()
            .map((lineItem) => {
                return applyRollUps(lineItem);
            }) ?? [];
    const roomingLineItems =
        trips
            ?.map((trip) => trip.openRoomLineItem)
            .filter(
                (
                    lineItem,
                ): lineItem is Extract<
                    typeof lineItem,
                    { __typename?: "TourRoomLineItem" }
                > => !!lineItem,
            )
            .map(applyRollUps) ?? [];
    const extensionRoomingLineItems =
        trips
            ?.map((trip) => trip.openExtensionRoomLineItem)
            .filter(
                (
                    lineItem,
                ): lineItem is Extract<
                    typeof lineItem,
                    { __typename?: "TourRoomLineItem" }
                > => !!lineItem,
            )
            .map(applyRollUps) ?? [];
    const allRoomingLineItems = roomingLineItems.concat(
        extensionRoomingLineItems,
    );
    const tourLineItems =
        trips
            ?.map((trip) => trip.tourLineItem)
            .filter(
                (
                    lineItem,
                ): lineItem is Extract<
                    typeof lineItem,
                    { __typename?: "TourLineItem" }
                > => !!lineItem,
            )
            .map(applyRollUps) ?? [];

    const extensionLineItems =
        trips
            ?.map((trip) => trip.openExtensionLineItem)
            .filter(
                (
                    lineItem,
                ): lineItem is Extract<
                    typeof lineItem,
                    { __typename?: "TourExtensionLineItem" }
                > => !!lineItem,
            )
            .map(applyRollUps) ?? [];
    const promoLineItems =
        getLineItemsOfType<Extract<LineItem, { __typename?: "PromoLineItem" }>>(
            "PromoLineItem",
        ).map(applyRollUps);

    const flightPackageLineItems = getLineItemsOfType<
        Extract<LineItem, { __typename?: "FlightPackageLineItem" }>
    >("FlightPackageLineItem").map(applyRollUps);
    const insuranceLineItems =
        getLineItemsOfType<
            Extract<LineItem, { __typename?: "InsuranceLineItem" }>
        >("InsuranceLineItem").map(applyRollUps);
    const insuranceAddOnLineItems = getLineItemsOfType<
        Extract<LineItem, { __typename?: "InsuranceAddonLineItem" }>
    >("InsuranceAddonLineItem").map(applyRollUps);

    return {
        allTripsLineItems: [
            ...flightPackageLineItems,
            ...excursionLineItems,
            ...insuranceLineItems,
            ...insuranceAddOnLineItems,
            ...roomingLineItems,
            ...extensionRoomingLineItems,
            ...tourLineItems,
            ...extensionLineItems,
            ...adjustmentLineItems,
            ...feeLineItems,
            ...promoLineItems,
            ...privateGroupLineItems,
        ],
        flightPackageLineItems,
        excursionLineItems,
        insuranceLineItems,
        insuranceAddOnLineItems,
        roomingLineItems,
        extensionRoomingLineItems,
        allRoomingLineItems,
        tourLineItems,
        extensionLineItems,
        adjustmentLineItems,
        privateGroupLineItems,
        feeLineItems,
        promoLineItems,
        getLineItemsOfTypeForTrip,
    };
};
