import React from "react";
import { StoryblokImage } from "../../components/storyblok-image";
import { imageAspectRatioValueMap } from "../../constants/image-ratio";
import { ImageRatio } from "../../types/image";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import styles from "./PageLinkImage.module.scss";

type PageLinkImageProps = {
    image: AssetStoryblok;
    imageRatio?: ImageRatio;
    columns?: number;
};

export const PageLinkImage: React.FC<PageLinkImageProps> = ({
    image,
    imageRatio,
    columns = 1,
}) => {
    if (!image.filename) {
        return null;
    }
    // 1232px is the max width of desktop layouts, multiple by 2 for high density screens
    const desktopImageWidth = Math.floor((1232 * 2) / columns);
    // 768px is the max width of mobile layouts, multiple by 2 for high density screens
    const mobileImageWidth = 768 * 2;
    const ratioValue =
        imageAspectRatioValueMap[imageRatio || ImageRatio.Horizontal16By9];
    const mobileImageHeight = ratioValue
        ? Math.round(mobileImageWidth / ratioValue)
        : undefined;
    const desktopImageHeight = ratioValue
        ? Math.round(desktopImageWidth / ratioValue)
        : undefined;

    return (
        <div className={styles["image-wrapper"]}>
            <div className={styles.image}>
                <StoryblokImage
                    mobile={{
                        asset: image,
                        size: {
                            height: mobileImageHeight,
                            width: mobileImageWidth,
                        },
                    }}
                    desktop={{
                        asset: image,
                        size: {
                            height: desktopImageHeight,
                            width: desktopImageWidth,
                        },
                    }}
                />
            </div>
        </div>
    );
};
