"use client";

import cn from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import React, {
    HTMLAttributes,
    MutableRefObject,
    useEffect,
    useId,
    useRef,
    useState,
} from "react";
import { Button } from "../../button";
import { SliderButton } from "../shared/SliderButton";
import styles from "./Slider.module.scss";
import { SliderTrack } from "./SliderTrack";

export type EmblaCarouselType = ReturnType<typeof useEmblaCarousel>[1];

export type SliderProps = React.PropsWithChildren<
    HTMLAttributes<HTMLDivElement> & {
        /**
         * Function to call when a new slide is chosen
         * @param oldPos last slide shown
         * @param newPos new selected slide
         */
        onSlide?: (oldPos: number, newPos: number) => void;
        /**
         * The space between slides
         */
        gap?: number | string;
        /**
         * The width of the slides on desktop
         * @default auto
         */
        desktopWidth?: string | number | "auto";
        /**
         * The width of the slides on mobile
         * @default auto
         */
        mobileWidth?: string | number | "auto";
        /**
         * The width of the slides on tablet
         * @default auto
         */
        tabletWidth?: string | number | "auto";
        /**
         * Disable both navigation buttons
         * @default false
         */
        disableNavigation?: boolean;
        /**
         * The number of slides to scroll
         * @default 1
         */
        slidesToScroll?: number | "auto";
        /**
         * Classname to applied to slider track wrapper
         * @default 1
         */
        sliderTrackWrapperClassName?: string;
        /**
         * Automatically wrap each child in `childen` in a `SliderSlide`
         * @default true
         */
        autoWrapSlides?: boolean;
        /**
         * Accessible label for the slider
         */
        accessibleLabel?: string;
        /**
         * callback function for when slider button is clicked
         * @param direction the direction the slider is moving
         */
        onSliderButtonClick?: (direction: "left" | "right") => void;
        /**
         * emblaApi Ref
         */
        emblaApiRef?: MutableRefObject<EmblaCarouselType | null>;
    }
>;

export const Slider: React.FC<SliderProps> = ({
    children,
    onSlide,
    gap,
    desktopWidth = "auto",
    mobileWidth = "auto",
    tabletWidth = "auto",
    disableNavigation = false,
    sliderTrackWrapperClassName,
    slidesToScroll = 1,
    autoWrapSlides = true,
    accessibleLabel,
    onSliderButtonClick,
    emblaApiRef,
    ...rest
}) => {
    const skipToEndLink = useId();
    const skiptoBeginLink = useId();
    const skipToEndRef = useRef<HTMLButtonElement>(null);
    const skipToBeginRef = useRef<HTMLButtonElement>(null);

    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            dragFree: true,
            active: !disableNavigation,
            slidesToScroll,
        },
        [WheelGesturesPlugin()],
    );

    const [sliderState, setSliderState] = useState({
        prevBtnDisabled: true,
        nextBtnDisabled: true,
    });

    useEffect(() => {
        if (!emblaApi) {
            return;
        }

        const updateState = () => {
            setSliderState({
                prevBtnDisabled: !emblaApi.canScrollPrev(),
                nextBtnDisabled: !emblaApi.canScrollNext(),
            });
        };

        const onSelect = () => {
            onSlide?.(
                emblaApi.previousScrollSnap(),
                emblaApi.selectedScrollSnap(),
            );
        };

        updateState();
        emblaApi.on("reInit", updateState);
        emblaApi.on("select", updateState);
        emblaApi.on("select", onSelect);

        return () => {
            emblaApi.off("reInit", updateState);
            emblaApi.off("select", updateState);
            emblaApi.off("select", onSelect);
        };
    }, [emblaApi, onSlide]);

    useEffect(() => {
        if (!emblaApiRef) {
            return;
        }
        emblaApiRef.current = emblaApi ?? null;
    }, [emblaApiRef, emblaApi]);

    const nbItems = React.Children.count(children);
    const itemGap =
        typeof gap === "number"
            ? `${gap}px`
            : gap || "var(--c-slider-item-gap)";

    if (!nbItems) {
        return null;
    }

    return (
        <div
            {...rest}
            className={styles["outer-container"]}
            role="group"
            aria-roledescription="carousel"
            aria-label={accessibleLabel}
        >
            <Button
                tag="a"
                id={skiptoBeginLink}
                href={`#${skipToEndLink}`}
                variant="quiet"
                onClick={() => {
                    emblaApi?.scrollTo(nbItems - 1);
                }}
                className={cn(styles.skip, styles["skip--visually-hidden"])}
                ref={skipToEndRef}
            >
                Skip to end of carousel
            </Button>

            <div className={styles["slider-container"]}>
                <SliderButton
                    variant="standard"
                    aria-label="Previous slide"
                    arrowDirection="left"
                    onClick={() => {
                        onSliderButtonClick?.("left");
                        emblaApi?.scrollPrev();
                    }}
                    className={cn(styles.button, styles["button--left"])}
                    aria-disabled={
                        sliderState.prevBtnDisabled || disableNavigation
                    }
                />
                <SliderTrack
                    sliderTrackWrapperClassName={sliderTrackWrapperClassName}
                    emblaRef={emblaRef}
                    desktopWidth={desktopWidth}
                    mobileWidth={mobileWidth}
                    tabletWidth={tabletWidth}
                    nbItems={nbItems}
                    itemGap={itemGap}
                    autoWrapSlides={autoWrapSlides}
                >
                    {children}
                </SliderTrack>
                <SliderButton
                    variant="standard"
                    aria-label="Next slide"
                    arrowDirection="right"
                    onClick={() => {
                        emblaApi?.scrollNext();
                        onSliderButtonClick?.("right");
                    }}
                    className={cn(styles.button, styles["button--right"])}
                    aria-disabled={
                        sliderState.nextBtnDisabled || disableNavigation
                    }
                />
            </div>

            <Button
                tag="a"
                id={skipToEndLink}
                href={`#${skiptoBeginLink}`}
                variant="quiet"
                onClick={() => {
                    emblaApi?.scrollTo(0);
                }}
                className={cn(styles.skip, styles["skip--visually-hidden"])}
                ref={skipToBeginRef}
            >
                Skip to start of carousel
            </Button>
        </div>
    );
};
