import { SbBlokData, storyblokEditable } from "@storyblok/react";
import { gtmSendEvent } from "@wojo/analytics";
import { Collapsible, Markdown } from "@wojo/ui";
import cn from "clsx";
import React from "react";
import { ButtonAtom, ButtonAtomProps } from "../../atoms/button";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { BorderProps } from "../../components/border";
import styles from "./CollapsibleItem.module.scss";

export type CollapsedState = "collapsed" | "expanded";

export type CollapsibleItemProps = {
    /**
     * The title shown on the collapsible trigger
     */
    heading: string;
    /**
     * Markdown Content to be expanded or collapsed.
     */
    description?: string;
    /**
     * Optional List of Buttons to place below the Markdown
     */
    actions?: ButtonAtomProps[];
    /**
     * Initial State if the Card is Collapsed or Not
     */
    collapsedState: CollapsedState;
} & SbBlokData &
    Omit<BorderProps, "children"> &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

export const CollapsibleItem: React.FC<CollapsibleItemProps> = (props) => {
    const {
        heading,
        description,
        actions,
        spaceAbove,
        spaceBelow,
        collapsedState,
    } = props;
    const name = getSpacingClassName({ spaceAbove, spaceBelow });
    if (!heading) {
        return null;
    }
    return (
        <Collapsible
            {...storyblokEditable(props)}
            defaultExpanded={collapsedState !== "collapsed"}
            title={heading}
            className={name}
            onToggle={(expanded) => {
                gtmSendEvent({
                    event: "track",
                    ga4_object: "COLLAPSIBLE_ITEM",
                    ga4_action: "INTERACTION",
                    heading,
                    collapsedState: expanded ? "expanded" : "collapsed",
                });
            }}
        >
            {description ? (
                <Markdown
                    className={styles["collapsible-description"]}
                    markdown={description}
                />
            ) : null}
            {actions?.map((actionItem: ButtonAtomProps) => {
                const { href } = getCleanStoryblokLink(actionItem.link);
                return (
                    <div
                        className={cn(styles["collapsible-action"])}
                        key={actionItem._uid}
                    >
                        <ButtonAtom
                            {...actionItem}
                            onClick={() => {
                                gtmSendEvent({
                                    event: "track",
                                    ga4_object: "COLLAPSIBLE_ITEM",
                                    ga4_action: "CLICKED",
                                    heading,
                                    collapsedState: "expanded",
                                    clickText: actionItem.text ?? "",
                                    destinationUrl: href,
                                });
                            }}
                        />
                    </div>
                );
            })}
        </Collapsible>
    );
};
