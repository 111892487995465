import { AuthPaths, useAuth } from "@wojo/auth";
import { BusinessCode } from "@wojo/auth/generated/graphql";
import {
    useCreateWishlistItemMutation,
    useDeleteWishlistItemMutation,
} from "../../generated/graphql";

export const useWishlist = () => {
    const { customer, refetchCustomer, loadingCustomer, isLoggedIn, setView } =
        useAuth();
    const [createWishlistItem, { loading: loadingCreate }] =
        useCreateWishlistItemMutation();
    const [deleteWishlistItem, { loading: loadingDelete }] =
        useDeleteWishlistItemMutation();

    const toggleWishlist = async (tourCode: string) => {
        if (!isLoggedIn || !customer?.customerId) {
            setView?.({
                view: AuthPaths.VerifyEmailWishlist,
                onLoginComplete: async (customerId) => {
                    if (loadingCustomer || !customerId) {
                        return;
                    }
                    await createWishlistItem({
                        variables: {
                            input: {
                                businessCode: process.env
                                    .NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
                                customerId,
                                tourCode,
                            },
                        },
                        onCompleted: () => {
                            refetchCustomer?.();
                        },
                    });
                },
            });
            return;
        }

        const wishlistItem = customer.wishlist?.find(
            (item) => item.tourCode === tourCode,
        );

        if (wishlistItem) {
            await deleteWishlistItem({
                variables: {
                    id: wishlistItem._id,
                },
                onCompleted: () => {
                    refetchCustomer?.();
                },
            });
        } else {
            await createWishlistItem({
                variables: {
                    input: {
                        businessCode: process.env
                            .NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
                        customerId: customer.customerId,
                        tourCode,
                    },
                },
                onCompleted: () => {
                    refetchCustomer?.();
                },
            });
        }
    };

    const getIsInWishlist = (tourCode: string) => {
        return (
            customer?.wishlist?.some((item) => item.tourCode === tourCode) ??
            false
        );
    };

    return {
        loading: loadingCreate || loadingDelete || loadingCustomer || false,
        getIsInWishlist,
        toggleWishlist,
        wishlist: customer?.wishlist || [],
    };
};
