import { DotIndicator } from "@wojo/ui/src/components/sliders/shared/DotIndicator";
import { SliderButton } from "@wojo/ui/src/components/sliders/shared/SliderButton";
import cn from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import { HTMLAttributes, useEffect } from "react";
import styles from "./FullscreenVideoSliderNavigation.module.scss";

export type FullscreenVideoSliderNavigationProps =
    HTMLAttributes<HTMLElement> & {
        /**
         * Selected index
         */
        selectedIndex?: number;
        /**
         * An array containing all the snap point positions from embla
         */
        scrollSnaps: number[];
        /**
         * Whether to disable the previous button
         */
        prevBtnDisabled: boolean;
        /**
         * Whether to disable the previous button
         */
        nextBtnDisabled: boolean;
        /**
         * Void function to be called when clicking previous arrow
         */
        scrollPrev: (
            e:
                | React.MouseEvent<HTMLButtonElement, MouseEvent>
                | React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
        ) => void | undefined;
        /**
         * Void function to be called when clicking next arrow
         */
        scrollNext: (
            e:
                | React.MouseEvent<HTMLButtonElement, MouseEvent>
                | React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
        ) => void | undefined;
        /**
         * Void function to be called when clicking on dot in navigation
         */
        scrollTo: (index: number) => void | undefined;
        /**
         * The ids of the slides that the dots are controlling
         */
        slideIds: string[];
    };

export const FullscreenVideoSliderNavigation: React.FC<
    FullscreenVideoSliderNavigationProps
> = ({
    selectedIndex = 0,
    scrollSnaps,
    prevBtnDisabled,
    nextBtnDisabled,
    scrollPrev,
    scrollNext,
    scrollTo,
    slideIds,
}) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({
        axis: "y",
        containScroll: "keepSnaps",
    });
    const maxVisibleIndicators = 5;

    useEffect(() => {
        if (!emblaApi) {
            return;
        }
        emblaApi.scrollTo(selectedIndex);
    }, [emblaApi, selectedIndex]);

    return (
        <nav className={styles.wrapper}>
            <SliderButton
                variant="quiet-inverse"
                aria-label="Previous slide"
                arrowDirection="up"
                onClick={(e) => scrollPrev(e)}
                aria-disabled={prevBtnDisabled}
                className={cn(styles.button, styles["button--visually-hidden"])}
            />
            <div
                ref={emblaRef}
                className={cn(styles.slider, {
                    [styles["slider--over-max-items"]]:
                        scrollSnaps.length > maxVisibleIndicators,
                })}
            >
                <div className={styles.track}>
                    {scrollSnaps.map((_, index) => {
                        return (
                            <div
                                key={index}
                                className={styles["indicator-wrapper"]}
                            >
                                <DotIndicator
                                    // TODO: Add styling for indicators based on selectedIndex per designs - https://ef-wojo.atlassian.net/browse/WWB-870
                                    className={styles.indicator}
                                    onClick={() => scrollTo(index)}
                                    selected={index === selectedIndex}
                                    variant={"inverse"}
                                    aria-label={`Slide ${index + 1}`}
                                    role="tab"
                                    aria-selected={index === selectedIndex}
                                    name={"sliderNavDots"}
                                    aria-controls={slideIds[index]}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            <SliderButton
                variant="quiet-inverse"
                aria-label="Next slide"
                arrowDirection="down"
                onClick={(e) => scrollNext(e)}
                aria-disabled={nextBtnDisabled}
                className={cn(styles.button, styles["button--visually-hidden"])}
            />
        </nav>
    );
};
