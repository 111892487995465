import { getStoryblokApi, ISbStoriesParams } from "@storyblok/react/rsc";
import { unstable_cache } from "next/cache";

const isStoryblokFetchNotFoundError = (
    err: unknown,
): err is { status: number; slug?: string } =>
    !!(err && typeof err === "object" && "status" in err && err.status === 404);

export async function fetchStoryblokData(
    fullSlug: string,
    sbParams: ISbStoriesParams,
) {
    return typeof window !== "undefined"
        ? fetchData(fullSlug, sbParams)
        : fetchDataSsr(JSON.stringify({ fullSlug, sbParams }));
}

const fetchData = async (fullSlug: string, sbParams: ISbStoriesParams) => {
    const storyblokApi = getStoryblokApi();
    try {
        return await storyblokApi.get(`cdn/stories/${fullSlug}`, sbParams);
    } catch (sbError) {
        if (typeof sbError === "string") {
            try {
                sbError = JSON.parse(sbError);
            } catch {}
        }
        if (isStoryblokFetchNotFoundError(sbError)) {
            sbError.slug = `cdn/stories/${fullSlug}`;
        }
        throw sbError;
    }
};

const fetchDataSsr = unstable_cache(
    (key) => {
        const {
            fullSlug,
            sbParams,
        }: { fullSlug: string; sbParams: ISbStoriesParams } = JSON.parse(key);
        return fetchData(fullSlug, sbParams);
    },
    [],
    {
        revalidate: 120,
    },
);
