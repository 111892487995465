import { useI18n } from "@wojo/localization";
import { Button, Card, StatusLabel, Text } from "@wojo/ui";
import cn from "clsx";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import pluralize from "pluralize";
import React, { useId } from "react";
import { ContentAsset } from "../../../generated/graphql";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { StoryblokImage } from "../storyblok-image";
import { WishlistButton } from "../wishlist-button/WishlistButton";
import styles from "./LightningDealCard.module.scss";
import { getProductBadgeIcon } from "../../client/get-product-badge-icon";
import { DepartureHit } from "@wojo/services";
import { useUrgencyBadgesExperiment } from "@wojo/services";

dayjs.extend(utc);

const stripDecimalPlaces = (price: string): string => {
    return price.replace(/(\.\d{2})/, "");
};

export type LightningDealCardProps = {
    /**
     * Departure ID
     */
    id: string;
    /**
     * Departure information for the lightning deal card
     */
    hideDepartureBadge?: boolean;

    /**
     * Number of bookable spots for the lightning deal card
     */
    bookableSpots: number;

    /**
     * Departure date for the lightning deal card in Unix timestamp format
     */
    departureDate: number;

    /**
     * Status badge for the departure date for the lightning deal card
     */
    departureStatusBadge?: DepartureHit["departureStatusBadge"];

    /**
     * Formatted discount amount for the lightning deal card
     */
    discountAmountFormatted: string;

    /**
     * Name of the lightning deal card
     */
    name: string;

    /**
     * Card Image asset for the lightning deal card
     */
    cardImage: ContentAsset | AssetStoryblok;

    /**
     * Formatted price for the lightning deal card
     */
    priceFormatted: string;

    /**
     * Formatted sale price for the lightning deal card
     */
    salePriceFormatted: string;

    /**
     * Indicates if the tour is on sale
     */
    isOnSale: boolean;

    /**
     * Tour code for the lightning deal card
     */
    tourCode: string;

    /**
     * URL for the tour related to the lightning deal card
     */
    tourUrl: string;

    /**
     * Number of cities visited during the tour related to the lightning deal card
     */
    nbCities: number;

    /**
     * Number of days for the tour related to the lightning deal card
     */
    nbDays: number;

    /**
     * Optional number of days with extension for the tour related to the lightning deal card
     */
    nbDaysWithExtension?: number;

    /**
     * Number of spots left for the tour related to the lightning deal card
     */
    nbSpotsLeft: number;

    /**
     * Optional name of the extension for the tour related to the lightning deal card
     */
    extensionName?: string;

    /**
     * Class Level FKA "accommodation level" or trip type
     */
    classLevel?: string;

    /**
     * Is this tour in the current customer's wishlist
     */
    isInWishlist: boolean;

    /**
     * Add or remove this tour from the current customer's wishlist
     */
    toggleWishlist: () => void;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
} & Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const LightningDealCard: React.FC<LightningDealCardProps> = (props) => {
    const {
        id,
        bookableSpots,
        hideDepartureBadge,
        departureDate,
        departureStatusBadge,
        discountAmountFormatted,
        name,
        priceFormatted,
        cardImage,
        salePriceFormatted,
        tourUrl,
        nbCities,
        nbDays,
        nbDaysWithExtension,
        nbSpotsLeft,
        extensionName,
        classLevel,
        spaceAbove,
        spaceBelow,
        isInWishlist,
        isOnSale,
        toggleWishlist,
        onClick,
    } = props;
    const titleId = useId();
    const i18n = useI18n("storyblok").storyblok;
    const soldOut = departureStatusBadge?.text === "Sold out";
    const formattedDepartureDate = dayjs
        .unix(departureDate)
        .utc()
        .format("MMM D, YYYY");
    const dayPart = `${nbDays} ${pluralize("day", nbDays)}`;
    const subtitlePart = `with ${extensionName}`;
    const extensionPart =
        nbDaysWithExtension && extensionName
            ? ` | ${nbDaysWithExtension} ${pluralize(
                  "day",
                  nbDaysWithExtension,
              )} ${subtitlePart} Extension`
            : "";
    const cityPart = nbCities
        ? `, ${nbCities} ${pluralize("city", nbCities)}`
        : "";
    const duration = `${dayPart}${extensionPart || cityPart}`;
    const experimentBadge = useUrgencyBadgesExperiment(
        departureStatusBadge ?? null,
        nbSpotsLeft,
    );

    const desktopImageWidth = 1232 * 2;
    const mobileImageWidth = 768 * 2;
    const isInInfoSpotsLeft = bookableSpots > 2 && bookableSpots < 10;
    const fallbackStatusBadgeVariant = isInInfoSpotsLeft ? "info" : "neutral";
    return (
        <Card
            className={cn(
                styles["lightning-deal-card"],
                { [styles["lightning-deal-card--sold-out"]]: soldOut },
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
            aria-labelledby={titleId}
        >
            <div className={styles.content}>
                <div>
                    {(!hideDepartureBadge || soldOut) && experimentBadge && (
                        <div className={styles.badge}>
                            <StatusLabel
                                size="small"
                                status={
                                    experimentBadge.variant === "urgent"
                                        ? experimentBadge.variant
                                        : fallbackStatusBadgeVariant
                                }
                                icon={getProductBadgeIcon(experimentBadge.icon)}
                                text={experimentBadge.text}
                            />
                        </div>
                    )}
                    {tourUrl && !soldOut ? (
                        <a
                            href={`${tourUrl}?departure=${id}#tripbuilder`}
                            className={styles.anchor}
                            onClick={(e) => onClick?.(e)}
                        >
                            <Text.Body className={styles.title} id={titleId}>
                                {name}
                            </Text.Body>
                        </a>
                    ) : (
                        <Text.Body className={styles.title} id={titleId}>
                            {name}
                        </Text.Body>
                    )}
                    <Text.Body className={styles.duration}>
                        {duration}
                    </Text.Body>
                    {!extensionName && classLevel && (
                        <span className={styles.type}>
                            {classLevel == "Plus" && (
                                <img
                                    alt=""
                                    src="https://a-us.storyblok.com/f/1018767/21x22/7becb69ac0/icon-ultimate-plus-lightmode.svg"
                                />
                            )}
                            {classLevel}
                        </span>
                    )}
                </div>
                <div>
                    <div className={styles.price} aria-hidden={isOnSale}>
                        <span>{i18n.lightningDealCard.fromPriceLabel.l()}</span>
                        <span
                            className={cn(styles["original-price"], {
                                [styles["original-price--strike-through"]]:
                                    isOnSale,
                            })}
                        >
                            {stripDecimalPlaces(priceFormatted)}
                        </span>
                        {isOnSale && (
                            <>
                                <span className={styles["sale-price"]}>
                                    {stripDecimalPlaces(salePriceFormatted)}
                                </span>
                            </>
                        )}
                    </div>
                    {isOnSale && (
                        <>
                            <span className={"visually-hidden"}>
                                {i18n.lightningDealCard.visuallyHiddenSaleText.l(
                                    {
                                        discountAmountFormatted,
                                        salePriceFormatted,
                                        priceFormatted,
                                    },
                                )}
                            </span>
                            <div className={styles.discount} aria-hidden={true}>
                                <StatusLabel
                                    size="small"
                                    status="deal"
                                    text={i18n.lightningDealCard.discountStatusLabel.l(
                                        {
                                            discountAmountFormatted:
                                                stripDecimalPlaces(
                                                    discountAmountFormatted,
                                                ),
                                        },
                                    )}
                                />
                            </div>
                        </>
                    )}
                    <Button
                        tag="span"
                        variant="quiet"
                        className={styles.departure}
                    >
                        {i18n.lightningDealCard.departureDateLabel.l({
                            formattedDepartureDate,
                        })}
                    </Button>
                </div>
            </div>
            <div className={styles.image}>
                <span className={styles["wishlist-button-wrapper"]}>
                    <WishlistButton
                        isInWishlist={isInWishlist}
                        toggleWishlist={toggleWishlist}
                        tourName={name}
                    />
                </span>
                <StoryblokImage
                    coverContainer={true}
                    mobile={{
                        asset: cardImage,
                        size: {
                            width: mobileImageWidth,
                        },
                    }}
                    desktop={{
                        asset: cardImage,
                        size: {
                            width: desktopImageWidth,
                        },
                    }}
                />
            </div>
        </Card>
    );
};
