import cn from "clsx";
import { FC, SVGAttributes } from "react";
import styles from "./LoadingSkeleton.module.scss";

export type LoadingSkeletonProps = SVGAttributes<SVGElement> & {
    /**
     * Determines the height. Accepts a valid CSS sizing unit
     * @default 50px
     */
    height?: string;
    /**
     * Determines the width. Accepts a valid CSS sizing unit
     * @default 100%
     */
    width?: string;
    /**
     * Screen reader text
     */
    accessibleText: string;
};

export const LoadingSkeleton: FC<LoadingSkeletonProps> = ({
    height,
    width,
    accessibleText,
    className,
    ...svgProps
}) => {
    return (
        <svg
            {...svgProps}
            aria-hidden={accessibleText.trim() === "" || undefined}
            aria-label={accessibleText.trim() || undefined}
            className={cn(styles["loading-skeleton"], className)}
            height="1"
            role="img"
            style={{
                ...svgProps.style,
                height,
                width,
            }}
            viewBox="0 0 1 1"
            width="1"
        >
            {accessibleText && <title>{accessibleText}</title>}
            <rect width="1" height="1" />
        </svg>
    );
};
