import { fetchStoryblokData, WojoCheckoutConfig } from "@wojo/storyblok";
import { getStoryblokParams } from "./get-storyblok-params";

export const getCheckoutConfigData = async function fetchData() {
    const { fullSlug, sbParams } = getStoryblokParams(
        "configurations/web-checkout-config/checkout-config",
        {},
        "wojo-checkout-config.footer",
    );

    const result = (await fetchStoryblokData(fullSlug, sbParams)).data?.story
        ?.content;

    return result
        ? ({
              ...result,
              footer: result.footer.content,
          } as WojoCheckoutConfig)
        : null;
};
