"use client";

import { storyblokEditable } from "@storyblok/react";
import { Container } from "@wojo/ui";
import cn from "clsx";
import { getSpacingClassName } from "../../client/get-spacing-class-name";
import { SearchResults } from "../../components/search-results";
import styles from "./BlogArticleGrid.module.scss";
import { BlogArticleGridContent } from "./BlogArticleGridContent";
import { BlogArticleGridFallback } from "./BlogArticleGridFallback";
import { BlogArticleGridProps } from "./types";

export const BlogArticleGrid: React.FC<BlogArticleGridProps> = (props) => {
    const {
        _uid,
        numberOfArticles,
        algoliaRuleTriggerContext,
        spaceAbove,
        spaceBelow,
    } = props;

    const nbArticles = numberOfArticles ? parseInt(numberOfArticles) : 12;

    return (
        <Container
            {...storyblokEditable(props)}
            className={cn(
                styles.container,
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
        >
            <SearchResults
                queryKey={["blog-grid", _uid]}
                queryType="blogPost"
                hitLabel="articles"
                hitsPerPage={nbArticles}
                filters={[]}
                loadingFallback={
                    <BlogArticleGridFallback
                        numberOfResults={nbArticles}
                        layout={parseInt(props.layout || "3")}
                        hitLabel="articles"
                    />
                }
                showSort={false}
                showResultsTopLabel={false}
                ruleContexts={
                    algoliaRuleTriggerContext ? [algoliaRuleTriggerContext] : []
                }
                render={({ currentPage, pages, hits }) => (
                    <BlogArticleGridContent
                        {...props}
                        currentPage={currentPage}
                        pages={pages}
                        articles={hits}
                    />
                )}
            />
        </Container>
    );
};
