import { Grid, LoadingSkeleton } from "@wojo/ui";
import styles from "./BlogArticleGridFallback.module.scss";

type BlogArticleGridFallbackProps = {
    numberOfResults: number;
    layout: number;
    hitLabel: string;
};

export const BlogArticleGridFallback: React.FC<
    BlogArticleGridFallbackProps
> = ({ numberOfResults, layout, hitLabel }) => {
    return (
        <>
            <Grid columns={layout} className={styles.grid}>
                {new Array(numberOfResults).fill(null).map((_, index) => (
                    <div className={styles.card} key={index}>
                        <LoadingSkeleton
                            className={styles.image}
                            accessibleText={`Loading ${hitLabel}`}
                        />
                        <div className={styles["card-content"]}>
                            <LoadingSkeleton
                                width="100%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="100%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="50%"
                                height="30px"
                                accessibleText=""
                            />
                        </div>
                    </div>
                ))}
            </Grid>
            <LoadingSkeleton
                width="260px"
                height="50px"
                accessibleText=""
                style={{ margin: "0 auto", display: "block" }}
            />
        </>
    );
};
