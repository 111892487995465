/**
 * Throttle function
 * @param func
 * @param rateLimit  - a "cooldown" period - the time during which subsequent function calls are ignored
 */
export function throttle(func: Function, rateLimit: number) {
    let inThrottle: boolean;
    return function (this: unknown) {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), rateLimit);
        }
    };
}
