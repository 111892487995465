import cn from "clsx";
import { Text } from "../text";
import styles from "./Modal.module.scss";

export type ModalFooterProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

export const ModalFooter = ({
    children,
    className,
    ...rest
}: React.PropsWithChildren<ModalFooterProps>) => {
    return (
        <Text.Body
            tag="div"
            className={cn([styles.footer, className])}
            {...rest}
        >
            {children}
        </Text.Body>
    );
};
