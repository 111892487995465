import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { Container, Text, VideoPlayer } from "@wojo/ui";
import { useVideoMetadata } from "@wojo/services";
import cn from "clsx";
import React, { CSSProperties } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ButtonAtom, ButtonAtomProps } from "../../atoms/button";
import {
    ImageGraphicAtom,
    ImageGraphicAtomProps,
} from "../../atoms/image-graphic";
import { TextAtomProps } from "../../atoms/text";
import { TitleAtom, TitleAtomProps } from "../../atoms/title";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { Border, BorderProps } from "../../components/border";
import { StoryblokImage } from "../../components/storyblok-image";
import { Opacity } from "../../types/opacity";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { StoryblokLink } from "../../types/storyblok/storyblok-link";
import { StoryblokSlider } from "../../types/storyblok/storyblok-slider";
import { TextWidth } from "../../types/text";
import styles from "./HeroContent.module.scss";

export type HeroContentProps = {
    /**
     * ImageGraphic bloks to display. ImageGraphicAtom
     */
    imageGraphic?: ImageGraphicAtomProps[];
    /**
     * Title Atom bloks to display. TitleAtom
     */
    text?: TitleAtomProps[];
    /**
     * Actions bloks to display. ButtonAtom | TextAtom
     */
    actions?: ButtonAtomProps[] | TextAtomProps[];
    /**
     * Background Image to display. AssetStoryblok
     */
    backgroundImage?: AssetStoryblok;
    /**
     * Background Video to display. Wistia Video ID
     */
    backgroundVideoId?: string;
    /**
     * Sets the horizontal alignment of content.
     * @default "left"
     */
    textAlignmentContentPosition?: "left" | "center";
    /**
     * Sets the width of the text content.
     * @default "full"
     */
    textWidth?: TextWidth;
    /**
     * Overlay opacity of the background image
     * @default Opacity.Percent20
     */
    overlayOpacity?: StoryblokSlider<Opacity>;
    /**
     * Background color of the section
     */
    backgroundColor?: string;
    /**
     * Start color of the background gradient, overrides `backgroundColor` when present with `backgroundEndColor`
     */
    backgroundStartColor?: string;
    /**
     * End color of the background gradient, overrides `backgroundColor` when present with `backgroundStartColor`
     */
    backgroundEndColor?: string;
    /**
     * Angle of the background gradient
     */
    gradientAngle?: string;
} & Pick<SpacingProps, "spaceAbove" | "spaceBelow"> &
    Omit<BorderProps, "children"> &
    SbBlokData;

const HeroContentInternal: React.FC<HeroContentProps> = (props) => {
    const {
        imageGraphic,
        text,
        actions,
        backgroundImage,
        backgroundVideoId,
        textAlignmentContentPosition,
        textWidth,
        overlayOpacity,
        backgroundColor,
        backgroundStartColor,
        backgroundEndColor,
        gradientAngle,
        borderColor,
        borderEndColor,
        borderGradientAngle,
        borderImage,
        borderPosition,
        borderRadius,
        borderSize,
        borderStartColor,
        spaceAbove,
        spaceBelow,
    } = props;
    const showBackgroundGradient =
        !backgroundImage?.filename &&
        backgroundEndColor &&
        backgroundStartColor;
    const backgroundStartColorOnly =
        backgroundStartColor && !backgroundEndColor;
    const showBackgroundColor = backgroundColor && !showBackgroundGradient;
    //if video or background image, override and set text color to white
    const colorOverride =
        backgroundImage?.filename || backgroundVideoId
            ? "var(--g-color-white)"
            : undefined;
    const textColorPropsOverride = colorOverride
        ? {
              descriptionColorOverride: colorOverride,
              headingColorOverride: colorOverride,
              eyebrowColorOverride: colorOverride,
          }
        : {};
    const { data: videoMetadata } = useVideoMetadata(backgroundVideoId);

    return (
        <Border
            {...storyblokEditable(props)}
            borderColor={borderColor}
            borderEndColor={borderEndColor}
            borderGradientAngle={borderGradientAngle}
            borderImage={borderImage}
            borderPosition={borderPosition}
            borderRadius={borderRadius}
            borderSize={borderSize}
            borderStartColor={borderStartColor}
            className={getSpacingClassName({
                spaceAbove,
                spaceBelow,
            })}
            data-ga4-object="HERO_CONTENT"
        >
            {backgroundImage?.filename && (
                <StoryblokImage
                    coverContainer
                    mobile={{
                        asset: backgroundImage,
                    }}
                    className={styles.background}
                />
            )}
            {(showBackgroundGradient || showBackgroundColor) &&
            !(backgroundImage?.filename || backgroundVideoId) ? (
                <div
                    className={cn({
                        [styles["hero-background-gradient"]]:
                            showBackgroundGradient,
                        [styles["hero-background-color"]]:
                            showBackgroundColor || backgroundStartColorOnly,
                    })}
                    style={
                        {
                            "--section-background-color":
                                backgroundColor || backgroundStartColor,
                            "--section-background-start-color":
                                backgroundStartColor,
                            "--section-background-end-color":
                                backgroundEndColor,
                            "--section-background-gradient-angle":
                                gradientAngle || "180deg",
                        } as React.CSSProperties
                    }
                ></div>
            ) : null}
            {backgroundVideoId && (
                <div
                    className={styles["video-container"]}
                    data-testid="video-container"
                >
                    <VideoPlayer
                        videoId={backgroundVideoId}
                        requestAutoplay={true}
                        loopVideo={true}
                        fallbackImageUrl={videoMetadata?.url}
                        fallbackImageAlt={videoMetadata?.videoTitle}
                        className={cn(styles["wistia-container"])}
                        onPlaybackChange={(videoState) => {
                            gtmSendEvent({
                                event: "track",
                                ga4_object: "HERO_CONTENT_VIDEO",
                                ga4_action: videoState.toUpperCase(),
                                videoId: backgroundVideoId,
                                heading: text?.[0]?.heading,
                            });
                        }}
                    />
                </div>
            )}
            {backgroundImage?.filename || backgroundVideoId ? (
                <div
                    data-testid="overlay"
                    className={styles.overlay}
                    style={
                        {
                            ["--overlay-opacity"]:
                                overlayOpacity?.value ?? Opacity.Percent20,
                        } as CSSProperties
                    }
                />
            ) : null}
            <Container
                contentsClassName={cn(styles.container, {
                    [styles["container--min-height"]]:
                        backgroundImage?.filename || backgroundVideoId,
                })}
            >
                <div
                    className={cn(
                        styles["hero-container"],
                        styles[
                            `hero-container--x-${
                                textAlignmentContentPosition || "left"
                            }`
                        ],
                        styles[`hero-container--${textWidth || "full"}`],
                    )}
                >
                    {imageGraphic?.length ? (
                        <ImageGraphicAtom
                            className={styles["hero-item"]}
                            {...imageGraphic[0]}
                            key={imageGraphic[0]._uid}
                        />
                    ) : null}
                    {text?.map((blok) => (
                        <TitleAtom
                            className={styles["hero-item"]}
                            {...blok}
                            key={blok._uid}
                            {...textColorPropsOverride}
                        />
                    ))}
                    {actions?.length ? (
                        <div className={styles["hero-item"]}>
                            {actions.map((actionsItem: SbBlokData) => {
                                return (
                                    <div
                                        key={actionsItem._uid}
                                        className={styles["action-item"]}
                                    >
                                        {actionsItem.component ===
                                        "wojo-button-atom" ? (
                                            <ButtonAtom
                                                {...actionsItem}
                                                onClick={() => {
                                                    const { href } =
                                                        getCleanStoryblokLink(
                                                            actionsItem.link as StoryblokLink,
                                                        );
                                                    gtmSendEvent({
                                                        event: "track",
                                                        ga4_object:
                                                            "HERO_CONTENT",
                                                        ga4_action: "CLICKED",
                                                        heading:
                                                            text?.[0]?.heading,
                                                        clickText:
                                                            actionsItem.text,
                                                        destinationUrl: href,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <StoryblokComponent
                                                blok={actionsItem}
                                                key={actionsItem._uid}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
            </Container>
        </Border>
    );
};

export const HeroContent: React.FC<HeroContentProps> = (props) => {
    return (
        <ErrorBoundary fallback={<Text.Body>Error loading content</Text.Body>}>
            <HeroContentInternal {...props} />
        </ErrorBoundary>
    );
};
