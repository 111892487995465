"use client";
import { TripComponentType } from "../generated/graphql";
import { useI18n } from "@wojo/localization";

export const useTripComponentType = () => {
    const { global } = useI18n("global");
    const getComponentLabel = (targetType?: TripComponentType | null) => {
        switch (targetType) {
            case TripComponentType.Adjustment:
                return global.targetComponentType.adjustment.l();
            case TripComponentType.ExtendedInsurance:
                return global.targetComponentType.extendedInsurance.l();
            case TripComponentType.ExtendedStay:
                return global.targetComponentType.extendedStay.l();
            case TripComponentType.Fee:
                return global.targetComponentType.fee.l();
            case TripComponentType.FlightPackage:
                return global.targetComponentType.flightPackage.l();
            case TripComponentType.Insurance:
                return global.targetComponentType.insurance.l();
            case TripComponentType.InsuranceAddon:
                return global.targetComponentType.insuranceAddon.l();
            case TripComponentType.PrivateGroup:
                return global.targetComponentType.privateGroup.l();
            case TripComponentType.Promo:
                return global.targetComponentType.promo.l();
            case TripComponentType.Tour:
                return global.targetComponentType.tour.l();
            case TripComponentType.TourExcursion:
                return global.targetComponentType.tourExcursion.l();
            case TripComponentType.TourExtension:
                return global.targetComponentType.tourExtension.l();
            case TripComponentType.TourRoom:
                return global.targetComponentType.tourRoom.l();
            default:
                return "";
        }
    };
    return {
        getComponentLabel,
    };
};
