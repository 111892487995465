import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { Button } from "@wojo/ui";
import cn from "clsx";
import React, { useId } from "react";
import {
    ImageGraphicAtom,
    ImageGraphicAtomProps,
} from "../../atoms/image-graphic";
import { TextAtomProps } from "../../atoms/text";
import { TitleAtomProps } from "../../atoms/title";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { StoryblokLink } from "../../types/storyblok/storyblok-link";
import styles from "./GraphicGridItem.module.scss";

export type GraphicGridItemProps = {
    /**
     * Image Graphic Blok : ImageGraphicAtomProps
     */
    imageGraphic?: ImageGraphicAtomProps[];
    /**
     * Title Blok : TitleAtomProps | TextAtomProps
     */
    caption?: TitleAtomProps[] | TextAtomProps[];
    /**
     * Button Text
     */
    buttonText?: string;
    /**
     * Button Link
     */
    buttonLink?: StoryblokLink;
    /**
     * Alignment of text and content
     */
    textAlignmentContentPosition?: "left" | "center";
    /**
     * Color Palette Override
     */
    colorPaletteOverride?: string;
    /**
     * Index Position of the item
     * @default 0
     * @description Used for tracking purposes
     */
    itemIndex?: number;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const GraphicGridItem: React.FC<GraphicGridItemProps> = (props) => {
    const {
        imageGraphic,
        caption,
        buttonText,
        buttonLink,
        colorPaletteOverride,
        textAlignmentContentPosition,
        spaceAbove,
        spaceBelow,
        itemIndex = 0,
    } = props;
    const buttonId = useId();
    const { href, isExternal } = getCleanStoryblokLink(buttonLink);

    return (
        <div
            {...storyblokEditable(props)}
            className={cn(
                styles["content-container"],
                {
                    [styles[
                        `content-container--${textAlignmentContentPosition}`
                    ]]: textAlignmentContentPosition,
                },
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
            data-ga4-object="GRAPHIC_GRID_ITEM"
        >
            {imageGraphic?.length ? (
                <ImageGraphicAtom
                    className={styles["content-item"]}
                    {...imageGraphic[0]}
                    key={imageGraphic[0]._uid}
                />
            ) : null}
            {caption?.length ? (
                <div className={styles["content-item"]}>
                    <StoryblokComponent
                        blok={caption[0]}
                        key={caption[0]._uid}
                    />
                </div>
            ) : null}
            {buttonText && href ? (
                <Button
                    tag="a"
                    id={buttonId}
                    className={styles.link}
                    variant={"quiet"}
                    palette={colorPaletteOverride}
                    href={href}
                    target={isExternal ? "_blank" : undefined}
                    onClick={() => {
                        gtmSendEvent({
                            event: "track",
                            ga4_object: "GRAPHIC_GRID_ITEM",
                            ga4_action: "CLICKED",
                            clickText: buttonText,
                            destinationUrl: href,
                            index: itemIndex,
                        });
                    }}
                >
                    {buttonText}
                </Button>
            ) : null}
        </div>
    );
};
