import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent } from "@storyblok/react/rsc";
import cn from "clsx";
import React from "react";
import { ButtonAtom, ButtonAtomProps } from "../../atoms/button";
import {
    ImageGraphicAtom,
    ImageGraphicAtomProps,
} from "../../atoms/image-graphic";
import { TextAtomProps } from "../../atoms/text";
import { TitleAtom, TitleAtomProps } from "../../atoms/title";
import { GraphicListProps } from "../graphic-list";
import styles from "./FeaturedContent.module.scss";

type FeaturedContentTextActionsComponentProps = {
    imageGraphic?: ImageGraphicAtomProps[];
    title?: TitleAtomProps[];
    description?: TextAtomProps[] | GraphicListProps[];
    actions?: ButtonAtomProps[];
    textAlignmentContentPosition?: "left" | "center";
};

export const FeaturedContentTextActions: React.FC<
    FeaturedContentTextActionsComponentProps
> = ({
    imageGraphic,
    title,
    description,
    actions,
    textAlignmentContentPosition,
}) => {
    return (
        <div
            className={cn(styles["text-container"], {
                [styles[`text-container--x-${textAlignmentContentPosition}`]]:
                    textAlignmentContentPosition,
            })}
        >
            {imageGraphic?.length ? (
                <ImageGraphicAtom
                    className={cn(styles["content-item--spacing-sm"])}
                    {...imageGraphic[0]}
                    key={imageGraphic[0]._uid}
                />
            ) : null}
            {title?.length ? (
                <TitleAtom
                    className={cn(styles["content-item"])}
                    {...title[0]}
                    key={title[0]._uid}
                />
            ) : null}
            {description?.length
                ? description.map((block) => (
                      <div
                          className={cn(styles["content-item"])}
                          key={block._uid}
                      >
                          <StoryblokComponent blok={block} />
                      </div>
                  ))
                : null}
            {actions?.length
                ? actions.map((actionsItem: SbBlokData) => (
                      <div
                          key={actionsItem._uid}
                          className={cn(
                              styles["content-item"],
                              styles["actions"],
                          )}
                      >
                          <ButtonAtom key={actionsItem._uid} {...actionsItem} />
                      </div>
                  ))
                : null}
        </div>
    );
};
