"use client";
import {
    autoUpdate,
    flip,
    FloatingFocusManager,
    offset,
    size,
    useDismiss,
    useFloating,
    useInteractions,
    useListNavigation,
    useMergeRefs,
    useRole,
} from "@floating-ui/react";
import cn from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Button } from "../button";
import { DropdownItem, DropdownList } from "../dropdown-list";
import {
    FieldDescription,
    getFieldDescriptionId,
} from "../form-fields/field-description";
import { FieldLabel } from "../form-fields/field-label";
import { FieldProps } from "../form-fields/field-props";
import { Textbox, TextboxProps } from "../text-fields/textbox";
import styles from "./ComboBox.module.scss";

type T = any;

export type ComboBoxItem<T> = {
    value: T;
    label: string;
    isNonResult?: boolean;
};

export type ComboBoxProps<T> = FieldProps &
    Omit<TextboxProps, "hasError" | "onChange"> & {
        /**
         * The items to to display in the menu dropdown
         * @required
         */
        items: ComboBoxItem<T>[];
        /**
         * Sets the selected item to the item with the given value
         */
        value?: T;
        /**
         * Sets the selected item to the item with the given value for the initial render
         */
        defaultValue?: string;
        /**
         * Force into controlled component mode even if `value` is undefined
         * @default false
         */
        forceControlledMode?: boolean;
        /**
         * Callback function after an item has been selected
         * @param value the value of the selected item
         */
        onChange?: (value?: T) => void;
        /**
         * Text to display when there are no results based on the filter
         * @default "No results"
         */
        noResultsLabel?: string;
        /**
         * Optionally pass in a function that will fire when the "clear search" button is clicked
         */
        onClear?: React.MouseEventHandler<HTMLButtonElement>;

        /**
         * Optionally change the text of the "clear search" button
         * @default "Clear search"
         */
        clearButtonText?: React.ReactNode;
        wrapperClassName?: string;
    };

export const ComboBox = React.forwardRef<HTMLInputElement, ComboBoxProps<T>>(
    (
        {
            required = false,
            errorText,
            hideErrorText = false,
            descriptionText,
            label,
            showOptionalValidationText,
            items,
            fieldId,
            onChange,
            value,
            defaultValue,
            disabled,
            loading,
            noResultsLabel = "No results",
            hideLabel = false,
            clearButtonText,
            onClear,
            wrapperClassName,
            hideMarker,
            hideErrorIcon,
            forceControlledMode,
            ref: legacyRef,
            ...rest
        },
        ref,
    ) => {
        const hasError = !!errorText;
        const defaultSelected = items.find(
            (item) => item.value === defaultValue,
        );
        const [isOpen, setIsOpen] = useState(false);
        const [inputValue, setInputValue] = useState(
            defaultSelected?.label ?? "",
        );
        const [activeIndex, setActiveIndex] = useState<number | null>(null);
        const [selectedIndex, setSelectedIndex] = useState<number | null>(
            defaultSelected ? items.indexOf(defaultSelected) : null,
        );
        const listRef = useRef<Array<HTMLElement | null>>([]);
        const selectedItem =
            selectedIndex !== null && selectedIndex > -1
                ? items[selectedIndex]
                : null;

        const isControlled = value !== undefined || forceControlledMode;

        if (isControlled) {
            const controlledIndex = value
                ? items.findIndex((item) => item.value === value)
                : null;
            const isValidValue =
                controlledIndex !== null && controlledIndex > -1;

            if (!isValidValue && selectedIndex !== null) {
                setSelectedIndex(null);
                setInputValue("");
            } else if (isValidValue && controlledIndex !== selectedIndex) {
                setSelectedIndex(controlledIndex);
                setInputValue(items[controlledIndex].label);
            }
        }

        const { refs, floatingStyles, context } = useFloating<HTMLInputElement>(
            {
                whileElementsMounted: autoUpdate,
                open: isOpen,
                onOpenChange: setIsOpen,
                placement: "bottom-start",
                middleware: [
                    flip({ padding: 10 }),
                    offset(2),
                    size({
                        apply({ availableHeight, elements }) {
                            Object.assign(elements.floating.style, {
                                maxHeight: `${
                                    availableHeight < 266
                                        ? availableHeight
                                        : 266
                                }px`,
                            });
                        },
                        padding: 10,
                    }),
                ],
            },
        );

        const { getReferenceProps, getFloatingProps, getItemProps } =
            useInteractions([
                useRole(context, { role: "listbox" }),
                useDismiss(context, {
                    outsidePress: (event) => {
                        return !(
                            refs.reference.current as any
                        )?.parentElement?.contains(event.target);
                    },
                }),
                useListNavigation(context, {
                    listRef,
                    activeIndex,
                    selectedIndex,
                    onNavigate: setActiveIndex,
                    virtual: true,
                    loop: true,
                    openOnArrowKeyDown: !disabled,
                }),
            ]);

        const mergedRef = useMergeRefs([ref, refs.setReference]);

        const filteredItems = items.filter(
            (item) =>
                item.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                `${item.value}`
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()),
        );

        if (!filteredItems.length) {
            filteredItems.push({
                label: noResultsLabel,
                isNonResult: true,
                value: null as unknown as T,
            });
        }

        useEffect(() => {
            if (!isOpen && selectedItem) {
                setInputValue(selectedItem.label);
            }
        }, [isOpen, selectedItem]);

        const handleInputChange = (
            event: React.ChangeEvent<HTMLInputElement>,
        ) => {
            if (!disabled) {
                const value = event.target.value;
                setInputValue(value);

                if (value) {
                    setIsOpen(true);
                    setActiveIndex(0);
                }
            }
        };

        const handleInputClick = () => {
            if (!isOpen && !disabled) {
                setIsOpen(true);
                setInputValue("");
            }
        };

        const handleInputKey = (
            event: React.KeyboardEvent<HTMLInputElement>,
        ) => {
            if (disabled) {
                return;
            }
            switch (event.key) {
                case "Enter":
                    if (activeIndex != null && filteredItems[activeIndex]) {
                        event.preventDefault();
                        if (filteredItems[activeIndex].value) {
                            setInputValue(filteredItems[activeIndex].label);
                            setActiveIndex(null);
                            setIsOpen(false);
                            const index = items.findIndex(
                                (item) =>
                                    item.value ===
                                    filteredItems[activeIndex].value,
                            );
                            setSelectedIndex(index);
                            onChange?.(filteredItems[activeIndex].value);
                        } else {
                            setInputValue("");
                        }
                    }
                    break;
                case "ArrowDown":
                    if (!isOpen) {
                        setInputValue("");
                    }
                    break;
            }
        };

        const renderListItems = () =>
            filteredItems.map((item, index) => {
                return (
                    <DropdownItem
                        key={`${item.value}`}
                        isHighlighted={activeIndex === index}
                        isSelected={selectedItem?.value === item.value}
                        {...getItemProps({
                            ref(node) {
                                listRef.current[index] = node;
                            },
                            role: "option",
                            id: `${item.value}${index}`,
                            "aria-selected": activeIndex === index,
                            onClick() {
                                if (item.isNonResult) {
                                    setInputValue("");
                                } else {
                                    const newIndex = items.findIndex(
                                        (ogItem) => ogItem.value === item.value,
                                    );
                                    setInputValue(item.label);
                                    setIsOpen(false);
                                    setSelectedIndex(newIndex);
                                    onChange?.(item.value);
                                }
                                refs.domReference.current?.focus();
                            },
                        })}
                    >
                        {item.label}&nbsp;
                        {item.isNonResult && (
                            <Button
                                onClick={onClear}
                                variant="quiet"
                                type="button"
                            >
                                {clearButtonText || "Clear search"}
                            </Button>
                        )}
                    </DropdownItem>
                );
            });

        return (
            <div
                aria-labelledby={`${fieldId}_FIELD-LABEL`}
                className={cn([wrapperClassName, styles["wrapper"]])}
            >
                {!hideLabel && (
                    <FieldLabel
                        required={required}
                        fieldId={fieldId}
                        hideMarker={hideMarker}
                        showOptionalValidationText={showOptionalValidationText}
                        disabled={disabled}
                        hasError={hasError}
                        id={`${fieldId}_FIELD-LABEL`}
                    >
                        {label}
                    </FieldLabel>
                )}
                <div
                    className={cn(styles.menu, {
                        [styles["menu--with-label"]]: label && !hideLabel,
                    })}
                >
                    <Textbox
                        {...rest}
                        {...getReferenceProps({
                            ref: mergedRef,
                            id: fieldId,
                            disabled,
                            autoComplete: "off",
                            "aria-describedby": getFieldDescriptionId(fieldId),
                            "aria-required": required || undefined,
                            "aria-busy": loading,
                            "aria-invalid": hasError,
                            onChange: handleInputChange,
                            onClick: handleInputClick,
                            onKeyDown: handleInputKey,
                            value: inputValue,
                            "aria-autocomplete": "list",
                        })}
                        className={cn([styles["input-action"]])}
                        loading={loading}
                        hasError={hasError}
                        adornRight={
                            <button
                                type="button"
                                className={styles["toggle-button"]}
                                tabIndex={-1}
                                onClick={() => {
                                    if (!disabled) {
                                        setIsOpen((open) => !open);
                                        setInputValue(
                                            isOpen && selectedItem
                                                ? selectedItem.label
                                                : "",
                                        );
                                    }
                                }}
                            >
                                <FiChevronDown
                                    aria-label="Toggle menu"
                                    data-testid="select-icon"
                                    size={16}
                                />
                            </button>
                        }
                    />

                    {isOpen && (
                        <FloatingFocusManager
                            context={context}
                            initialFocus={-1}
                            visuallyHiddenDismiss
                        >
                            <DropdownList
                                {...getFloatingProps({
                                    ref: refs.setFloating,
                                    style: {
                                        ...floatingStyles,
                                        overflowY: "auto",
                                    },
                                })}
                            >
                                {!!filteredItems.length && renderListItems()}
                            </DropdownList>
                        </FloatingFocusManager>
                    )}
                </div>
                <FieldDescription
                    fieldId={fieldId}
                    errorText={errorText}
                    hideErrorIcon={hideErrorIcon}
                    hideErrorText={hideErrorText}
                    disabled={disabled}
                >
                    {descriptionText}
                </FieldDescription>
            </div>
        );
    },
);

ComboBox.displayName = "ComboBox";
