"use client";

import {
    GlobalMerchandisingCard,
    GlobalMerchandisingCardContextProvider,
    initializeStoryblok,
} from "@wojo/storyblok";

initializeStoryblok(process.env.NEXT_PUBLIC_STORYBLOK_TOKEN);

export const StoryblokProvider: React.FC<
    React.PropsWithChildren<{
        globalMerchandisingCard?: GlobalMerchandisingCard;
    }>
> = ({ children, globalMerchandisingCard }) => {
    return (
        <GlobalMerchandisingCardContextProvider
            globalMerchandisingCard={globalMerchandisingCard}
        >
            {children}
        </GlobalMerchandisingCardContextProvider>
    );
};
