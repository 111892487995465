import { Grid, LoadingSkeleton } from "@wojo/ui";
import styles from "./ZendeskArticleFallback.module.scss";

type ZendeskArticleFallbackProps = {
    numberOfResults: number;
    layout: number;
    hitLabel: string;
};

export const ZendeskArticleFallback: React.FC<ZendeskArticleFallbackProps> = ({
    numberOfResults,
    layout,
    hitLabel,
}) => {
    return (
        <Grid columns={layout} className={styles.grid}>
            {new Array(numberOfResults).fill(null).map((_, index) => (
                <div className={styles.article} key={index}>
                    <LoadingSkeleton
                        className={styles.icon}
                        width="24px"
                        height="24px"
                        accessibleText={
                            index === 0 ? `Loading ${hitLabel}` : ""
                        }
                    />
                    <div className={styles.content}>
                        <LoadingSkeleton
                            width="100%"
                            height="24px"
                            accessibleText=""
                        />
                        <LoadingSkeleton
                            width="50%"
                            height="24px"
                            accessibleText=""
                        />
                    </div>
                </div>
            ))}
        </Grid>
    );
};
