import { Text } from "@wojo/ui";
import React from "react";
import { useResultsContext } from "../results-provider/ResultsProvider";
import { useI18n } from "@wojo/localization";

export const ResultsLabel: React.FC = () => {
    const { currentPage, hitLabel, hitsPerPage, totalHits } =
        useResultsContext();
    const resultsLabelI18n =
        useI18n("storyblok").storyblok.searchResults.resultsLabel;

    const pageStart = currentPage * hitsPerPage + 1;
    const pageEnd =
        pageStart + hitsPerPage - 1 >= totalHits
            ? totalHits
            : pageStart + hitsPerPage - 1;

    const getResultLabel = () => {
        const noHitsLabel = resultsLabelI18n.noHits.l({
            totalHits: totalHits.toString(),
            hitLabel,
        });

        switch (totalHits) {
            case 0:
                return noHitsLabel;
            case 1:
                return resultsLabelI18n.singleHit.l({
                    totalHits: totalHits.toString(),
                    hitLabel,
                });
            default:
                if (totalHits > 1) {
                    return resultsLabelI18n.multipleHits.l({
                        totalHits: totalHits.toString(),
                        hitLabel,
                        pageStart: pageStart.toString(),
                        pageEnd: pageEnd.toString(),
                    });
                }
                return noHitsLabel;
        }
    };

    return <Text.Body>{getResultLabel()}</Text.Body>;
};
