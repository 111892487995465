import type { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { Tab, Text } from "@wojo/ui";
import cn from "clsx";
import { TextAlignment } from "../../types/text";
import styles from "./TabbedContent.module.scss";

export type TabItemProps = {
    /**
     * Identifier for this tab within TabbedContent
     */
    id: string;
    /**
     * Supplamental text above the main text
     */
    tabEyebrowText?: string;
    /**
     * Main text in the tab
     */
    tabTitleText?: string;
    /**
     * Alignment of all text within the tab
     */
    tabTextAlignment?: TextAlignment;
    /**
     * Storyblok data the serves as the tabs main content
     */
    blocks?: SbBlokData[];
    /**
     * If this tab is the one set to display by default.
     */
    isDefaultTab?: boolean;
} & SbBlokData;

export const TabItem: React.FC<TabItemProps> = (props) => {
    const { id, tabEyebrowText, tabTitleText, tabTextAlignment, isDefaultTab } =
        props;

    return (
        <Tab
            {...storyblokEditable(props)}
            id={id}
            onClick={() => {
                gtmSendEvent({
                    event: "track",
                    ga4_object: "TAB",
                    ga4_action: "CLICKED",
                    isDefaultTab: isDefaultTab,
                    objectID: id,
                    clickText: `${tabEyebrowText} ${tabTitleText}`.trim(),
                });
            }}
        >
            <div
                className={cn(
                    styles["tab-text"],
                    styles[`tab-text--${tabTextAlignment || "left"}`],
                )}
            >
                {tabEyebrowText ? (
                    <Text.Detail variant="2" className={styles["tab-eyebrow"]}>
                        {tabEyebrowText}
                    </Text.Detail>
                ) : null}
                <div className={styles["tab-title"]}>{tabTitleText}</div>
            </div>
        </Tab>
    );
};
