"use client";

import { storyblokEditable } from "@storyblok/react";
import { Container } from "@wojo/ui";
import cn from "clsx";
import React from "react";
import { getSpacingClassName } from "../../client/get-spacing-class-name";
import { useWishlist } from "../../client/use-wishlist";
import { SearchResults } from "../../components/search-results";
import { LightningDealCardGridContent } from "./lightning-deal-card-grid-content/LightningDealCardGridContent";
import styles from "./LightningDealCardGrid.module.scss";
import { LightningDealCardGridFallback } from "./LightningDealCardGridFallback";
import { LightningDealCardGridProps } from "./types";
import { NoResultsBanner } from "../../components/search-results/no-results-banner/NoResultsBanner";
import { useI18n } from "@wojo/localization";
import { SchemaScript } from "../../components/schema-script";

const attributesToRetrieve = [
    "activityLevel",
    "bookableSpots",
    "cardImage",
    "classLevel",
    "dateRangeLabel",
    "departureStatusBadge",
    "departureDate",
    "discountAmount",
    "endDate",
    "extensionName",
    "hasExtension",
    "hasExtensionAvailable",
    "id",
    "isExtensionHidden",
    "isOnSale",
    "name",
    "nbCities",
    "nbDays",
    "nbDaysBeforeDeparture",
    "nbDaysWithExtension",
    "nbSpotsLeft",
    "price",
    "salePrice",
    "specialDepartureContent",
    "tourCode",
    "tourLengthRange",
    "tourPagePath",
];

export const LightningDealCardGrid: React.FC<LightningDealCardGridProps> = (
    props,
) => {
    const {
        _uid,
        hideDepartureBadge,
        algoliaRuleTriggerContext,
        defaultSort,
        spaceAbove,
        spaceBelow,
        ...rest
    } = props;

    const { getIsInWishlist, toggleWishlist } = useWishlist();
    const lightningDealCardGridI18n =
        useI18n("storyblok").storyblok.lightningDealCardGrid;

    return (
        <Container
            {...storyblokEditable(props)}
            className={cn(
                styles["component-wrapper"],
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
        >
            <SearchResults
                queryKey={["lightning-deal-card-grid", _uid]}
                queryType="departure"
                ruleContexts={
                    algoliaRuleTriggerContext ? [algoliaRuleTriggerContext] : []
                }
                loadingFallback={
                    <LightningDealCardGridFallback
                        numberOfResults={18}
                        hitLabel={lightningDealCardGridI18n.hitLabel.l()}
                    />
                }
                hitLabel={lightningDealCardGridI18n.hitLabel.l()}
                hitsPerPage={18}
                filters={
                    [
                        "destinations",
                        "dates",
                        "duration",
                        "price",
                        "discount",
                    ] as const
                }
                showSort={true}
                defaultSort={defaultSort}
                attributesToRetrieve={attributesToRetrieve}
                render={({ hits, hasFiltersApplied }) => {
                    if (!hits.length) {
                        return (
                            <NoResultsBanner
                                hasFiltersApplied={hasFiltersApplied}
                                noResults={lightningDealCardGridI18n.noResults}
                            />
                        );
                    }
                    return (
                        <>
                            <SchemaScript
                                data={{
                                    "@context": "https://schema.org",
                                    "@type": "ItemList",
                                    itemListElement: hits.map((hit, index) => ({
                                        "@type": "ListItem",
                                        position: index + 1,
                                        item: {
                                            "@type": "Product",
                                            name: hit.name,
                                            url:
                                                process.env.NEXT_PUBLIC_DOMAIN +
                                                hit.tourPagePath,
                                            image: hit.cardImage?.filename,
                                            description:
                                                hit.overviewDescription,
                                            sku: hit.tourCode,
                                            brand: {
                                                "@type": "Brand",
                                                name: process.env
                                                    .NEXT_PUBLIC_BUSINESS_NAME,
                                            },
                                            ...((hit.salePrice ||
                                                hit.price) && {
                                                offers: {
                                                    "@type": "Offer",
                                                    priceCurrency:
                                                        process.env
                                                            .NEXT_PUBLIC_CURRENCY_CODE,
                                                    price:
                                                        hit.salePrice ||
                                                        hit.price,
                                                    url:
                                                        process.env
                                                            .NEXT_PUBLIC_DOMAIN +
                                                        hit.tourPagePath,
                                                    itemCondition:
                                                        "https://schema.org/NewCondition",
                                                    availability:
                                                        "https://schema.org/InStock",
                                                },
                                            }),
                                            ...(hit.nbRatings > 0 && {
                                                aggregateRating: {
                                                    "@type": "AggregateRating",
                                                    ratingValue: hit.rating,
                                                    reviewCount: hit.nbRatings,
                                                },
                                            }),
                                        },
                                    })),
                                }}
                            />
                            <LightningDealCardGridContent
                                {...rest}
                                hideDepartureBadge={hideDepartureBadge}
                                departures={hits}
                                getIsInWishlist={getIsInWishlist}
                                toggleWishlist={toggleWishlist}
                                algoliaRuleTriggerContext={
                                    algoliaRuleTriggerContext
                                }
                            />
                        </>
                    );
                }}
            />
        </Container>
    );
};
