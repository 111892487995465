import cn from "clsx";
import { addMonths, addYears, format, subMonths, subYears } from "date-fns";
import React, { ButtonHTMLAttributes, useState } from "react";
import { CaptionProps, useDayPicker, useNavigation } from "react-day-picker";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import styles from "./CalendarNav.module.scss";

export type CalendarNavProps = CaptionProps & {
    disabled?: boolean;
    onNavigate?: (
        event: React.MouseEvent<HTMLButtonElement>,
        name?: string,
    ) => void;
};

/**
 * Custom month/year navigation heading for the calendar
 */
export const CalendarNav = ({ id, onNavigate }: CalendarNavProps) => {
    const [hasFocus, setHasFocus] = useState(false);
    const { goToDate, currentMonth } = useNavigation();
    const { disableNavigation: disabled } = useDayPicker();

    const handleFocusCapture = (event: React.FocusEvent<HTMLDivElement>) => {
        setHasFocus(!!event.currentTarget.querySelector("*:focus"));
    };

    const renderNavButton = (
        direction: "left" | "right",
        props: ButtonHTMLAttributes<HTMLButtonElement> = {},
    ) => {
        const IconTag = direction === "left" ? FiChevronLeft : FiChevronRight;
        const { onClick, ...rest } = props;
        return (
            <button
                type="button"
                className={cn(
                    styles["nav-button"],
                    "calendar-nav-button-default-palette",
                    {
                        [styles["nav-button--disabled"]]: disabled,
                    },
                )}
                aria-disabled={disabled || undefined}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    if (!disabled) {
                        onClick?.(event);
                        onNavigate?.(event, rest["aria-label"]);
                    }
                }}
                {...rest}
            >
                <IconTag className={styles.icon} />
            </button>
        );
    };

    const title = `${format(currentMonth, "MMMM")} ${format(
        currentMonth,
        "yyy",
    )}`;

    return (
        <div
            id={id}
            className={cn(styles.nav, {
                [styles["nav--disabled"]]: disabled,
            })}
            aria-label={title}
            aria-relevant="additions"
            onFocusCapture={handleFocusCapture}
            onBlurCapture={handleFocusCapture}
        >
            <p
                className="visually-hidden"
                aria-live={hasFocus ? "assertive" : "off"}
            >
                {title}
            </p>
            <div className={styles.group}>
                {renderNavButton("left", {
                    onClick: () => goToDate(subMonths(currentMonth, 1)),
                    "aria-label": "Previous month",
                })}
                <span>{format(currentMonth, "MMMM")}</span>
                {renderNavButton("right", {
                    onClick: () => goToDate(addMonths(currentMonth, 1)),
                    "aria-label": "Next month",
                })}
            </div>
            <div className={styles.group}>
                {renderNavButton("left", {
                    onClick: () => goToDate(subYears(currentMonth, 1)),
                    "aria-label": "Previous year",
                })}
                <span>{format(currentMonth, "yyyy")}</span>
                {renderNavButton("right", {
                    onClick: () => goToDate(addYears(currentMonth, 1)),
                    "aria-label": "Next year",
                })}
            </div>
        </div>
    );
};
