"use client";

import { useEffect, useState } from "react";

const getPropertyValue = (property: string) => {
    if (typeof window === "undefined") {
        return "";
    }

    const computedStyle = window.getComputedStyle(document.body);
    return computedStyle.getPropertyValue(property).trim();
};

/**
 *  @summary This hook is used to observe changes in the computed style of the body element
 *  @param {string} property The CSS property to observe
 */
export const useComputedStyleObserver = (property: string): string => {
    const [value, setValue] = useState<string>(() =>
        getPropertyValue(property),
    );

    useEffect(() => {
        const handleMutation = () => {
            setValue(getPropertyValue(property));
        };
        handleMutation();

        const observer = new MutationObserver(handleMutation);
        observer.observe(document.body, {
            attributes: true,
        });

        return () => {
            observer.disconnect();
        };
    }, [property]);

    return value;
};
