import React, { HTMLAttributes } from "react";
import styles from "./SliderNavIndicator.module.scss";

export type SliderNavIndicatorProps = HTMLAttributes<HTMLElement> & {
    /**
     * Void function to be called when clicking previous arrow
     */
    scrollPrev: (
        e:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    ) => void | undefined;
    /**
     * Void function to be called when clicking next arrow
     */
    scrollNext: (
        e:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    ) => void | undefined;
    /**
     * An array containing all the snap point positions from embla
     */
    scrollSnaps: number[];
    /**
     * The selected slide
     */
    selectedIndex: number;
};

export const SliderNavIndicator: React.FC<SliderNavIndicatorProps> = ({
    scrollPrev,
    scrollNext,
    scrollSnaps,
    selectedIndex,
}) => {
    return (
        <div className={styles["nav-wrapper"]}>
            <button
                type="button"
                aria-label="Previous image"
                aria-disabled={false}
                onClick={(e) => {
                    e.preventDefault();
                    scrollPrev(e);
                }}
                className={styles.button}
            >
                <svg
                    width="20"
                    height="20"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12.5 15L7.5 10L12.5 5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>

            <div className={styles["slide-indicator"]}>
                <span className="visually-hidden">
                    {`Showing image ${selectedIndex + 1} of ${
                        scrollSnaps.length
                    }`}
                </span>
                <p aria-hidden={true}>{`${selectedIndex + 1}/${
                    scrollSnaps.length
                }`}</p>
            </div>

            <button
                type="button"
                aria-label="Next image"
                aria-disabled={false}
                className={styles.button}
                onClick={(e) => {
                    e.preventDefault();
                    scrollNext(e);
                }}
            >
                <svg
                    width="20"
                    height="20"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.5 15L12.5 10L7.5 5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </div>
    );
};
