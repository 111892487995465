"use client";

import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

export type IpLocation = {
    city: String;
    region_code: String;
    country_code: String;
};
export const useCurrentLocationByIp = () => {
    const [data, setData] = useState<IpLocation | null>(null);
    const [checkedLocation, setCheckedLocation] = useState(false);
    useEffect(() => {
        let isSubscribed = true;

        const savedLoc = localStorage.getItem("ipdata_loc");
        const fetchData = async () => {
            if (!isEmpty(savedLoc)) {
                setData(JSON.parse(savedLoc ?? ""));
                setCheckedLocation(true);
                return;
            }
            try {
                fetch(process.env.NEXT_PUBLIC_IPDATA_URL!).then(async (res) => {
                    const result = await res.json();
                    setCheckedLocation(true);
                    if (isSubscribed) {
                        localStorage.setItem(
                            "ipdata_loc",
                            JSON.stringify(result),
                        );
                        setData(result);
                    }
                });
            } catch {
                setCheckedLocation(true);
            }
        };
        fetchData();

        return () => (isSubscribed = false) as any;
    }, []);
    return { ...data, checkedLocation };
};
