import i18nISOCountries, { alpha2ToAlpha3 } from "i18n-iso-countries";
import localeDataEn from "i18n-iso-countries/langs/en.json";
import { useMemo } from "react";

export type Option = { value: string; label: string };

const US: Option = { label: "United States of America", value: "USA" };
const CA: Option = { label: "Canada", value: "CAN" };

const regions = {
    usStates: [
        { label: "Alabama", value: "AL" },
        { label: "Alaska", value: "AK" },
        { label: "Arizona", value: "AZ" },
        { label: "Arkansas", value: "AR" },
        { label: "California", value: "CA" },
        { label: "Colorado", value: "CO" },
        { label: "Connecticut", value: "CT" },
        { label: "District of Columbia", value: "DC" },
        { label: "Delaware", value: "DE" },
        { label: "Florida", value: "FL" },
        { label: "Georgia", value: "GA" },
        { label: "Hawaii", value: "HI" },
        { label: "Idaho", value: "ID" },
        { label: "Illinois", value: "IL" },
        { label: "Indiana", value: "IN" },
        { label: "Iowa", value: "IA" },
        { label: "Kansas", value: "KS" },
        { label: "Kentucky", value: "KY" },
        { label: "Louisiana", value: "LA" },
        { label: "Maine", value: "ME" },
        { label: "Maryland", value: "MD" },
        { label: "Massachusetts", value: "MA" },
        { label: "Michigan", value: "MI" },
        { label: "Minnesota", value: "MN" },
        { label: "Mississippi", value: "MS" },
        { label: "Missouri", value: "MO" },
        { label: "Montana", value: "MT" },
        { label: "Nebraska", value: "NE" },
        { label: "Nevada", value: "NV" },
        { label: "New Hampshire", value: "NH" },
        { label: "New Jersey", value: "NJ" },
        { label: "New Mexico", value: "NM" },
        { label: "New York", value: "NY" },
        { label: "North Carolina", value: "NC" },
        { label: "North Dakota", value: "ND" },
        { label: "Ohio", value: "OH" },
        { label: "Oklahoma", value: "OK" },
        { label: "Oregon", value: "OR" },
        { label: "Pennsylvania", value: "PA" },
        { label: "Rhode Island", value: "RI" },
        { label: "South Carolina", value: "SC" },
        { label: "South Dakota", value: "SD" },
        { label: "Tennessee", value: "TN" },
        { label: "Texas", value: "TX" },
        { label: "Utah", value: "UT" },
        { label: "Vermont", value: "VT" },
        { label: "Virginia", value: "VA" },
        { label: "Washington", value: "WA" },
        { label: "West Virginia", value: "WV" },
        { label: "Wisconsin", value: "WI" },
        { label: "Wyoming", value: "WY" },
        { label: "American Samoa", value: "AS" },
        { label: "Guam", value: "GU" },
        { label: "Northern Mariana Islands", value: "MP" },
        { label: "Puerto Rico", value: "PR" },
        { label: "United States Minor Outlying Islands", value: "UM" },
        { label: "Virgin Islands", value: "VI" },
    ],
    caProvinces: [
        { label: "Alberta", value: "AB" },
        { label: "British Columbia", value: "BC" },
        { label: "Manitoba", value: "MB" },
        { label: "New Brunswick", value: "NB" },
        { label: "Newfoundland and Labrador", value: "NL" },
        { label: "Nova Scotia", value: "NS" },
        { label: "Nunavut", value: "NU" },
        { label: "Northwest Territories", value: "NT" },
        { label: "Ontario", value: "ON" },
        { label: "Prince Edward Island", value: "PE" },
        { label: "Quebec", value: "QC" },
        { label: "Saskatchewan", value: "SK" },
        { label: "Yukon", value: "YT" },
    ],
};

export const useCountriesAndStates = (market?: string) => {
    const isCanada = market === "CA";
    i18nISOCountries.registerLocale(localeDataEn);

    const getStateName = (alpha2Code: string): string => {
        const stateOrProvince = [
            ...regions.usStates,
            ...regions.caProvinces,
        ].find(({ value }) => value === alpha2Code);
        return stateOrProvince?.label ?? alpha2Code;
    };
    const allCountryOptions = useMemo(() => {
        const countries = i18nISOCountries.getNames("en");
        const isoList = Object.entries(countries)
            .filter(([iso2]) => !["US", "CA"].includes(iso2))
            .map(([iso2, label]) => ({ value: alpha2ToAlpha3(iso2), label }))
            .sort((a, b) => a.label.localeCompare(b.label));
        return [US, CA, ...isoList];
    }, []);
    const getCountryName = (alpha2Or3Code: string): string => {
        const countryName = i18nISOCountries.getName(alpha2Or3Code, "en");
        return countryName ?? alpha2Or3Code;
    };

    return {
        allCountryOptions,
        regionOptions: isCanada ? regions.caProvinces : regions.usStates,
        caProvinces: regions.caProvinces,
        usStates: regions.usStates,
        getCountryName,
        getStateName,
        isCanada,
    };
};
