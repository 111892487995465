import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { Container, Grid } from "@wojo/ui";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import {
    GraphicGridItem,
    GraphicGridItemProps,
} from "../../molecules/graphic-grid-item";

export type GraphicGridProps = {
    /**
     * Graphic Grid Item Blok : GraphicGridItemProps
     */
    blocks?: GraphicGridItemProps[];
    /**
     * Layout of the grid
     * @default 4
     */
    layout?: "3" | "4" | "6";
    /**
     * Card Position
     * @default left
     */
    cardPosition?: "left" | "right" | "center";
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const GraphicGrid: React.FC<GraphicGridProps> = (props) => {
    const { blocks, layout, cardPosition, spaceAbove, spaceBelow } = props;

    return (
        <Container>
            <Grid
                {...storyblokEditable(props)}
                columns={parseInt(layout || "4")}
                mobileColumns={2}
                tabletColumns={3}
                alignment={cardPosition || "left"}
                className={getSpacingClassName({ spaceAbove, spaceBelow })}
            >
                {blocks?.map((block, index) => (
                    <GraphicGridItem
                        key={block._uid}
                        {...block}
                        itemIndex={index}
                    />
                ))}
            </Grid>
        </Container>
    );
};
