"use client";

import { useI18n } from "@wojo/localization";
import { Button, Modal, ModalHeader } from "@wojo/ui";
import React, { useState } from "react";
import { FiSliders } from "react-icons/fi";
import { PopoverToggleProvider } from "../filter-popover/use-popover-toggle";
import { FilterName } from "../form-types";
import { useResultsContext } from "../results-provider/ResultsProvider";
import styles from "./FilterBar.module.scss";
import { FilterList } from "./FilterList";

export type FilterBarProps = {
    filters: FilterName[];
    showSort: boolean;
};

export const FilterBar: React.FC<FilterBarProps> = ({ filters, showSort }) => {
    const i18n = useI18n("storyblok").storyblok;
    const [isOpen, setIsOpen] = useState(false);
    const { clearAllFilters, hasFiltersApplied, hitLabel, totalHits } =
        useResultsContext();
    return (
        <>
            <div className={styles.bar}>
                <PopoverToggleProvider>
                    <FilterList
                        filters={filters}
                        showSort={showSort}
                        variant="popover"
                    />
                </PopoverToggleProvider>
            </div>
            <div className={styles["open-button-wrapper"]}>
                <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    className={styles["open-button"]}
                >
                    <span className={styles["open-button-text"]}>
                        <FiSliders
                            aria-hidden
                            className={styles["open-button-icon"]}
                        />{" "}
                        {i18n.filterBar.filterSortLabel.l()}
                    </span>
                </button>
            </div>
            <Modal
                accessibleTitle={i18n.filterBar.a11y.filterOptionsLabel.l({
                    hitLabel,
                })}
                isOpen={isOpen}
                hideDefaultHeader
                onCloseRequested={() => setIsOpen(false)}
                variant="fullscreen"
            >
                <ModalHeader>
                    {hasFiltersApplied && (
                        <Button
                            variant="quiet"
                            onClick={() => clearAllFilters()}
                        >
                            {i18n.filterBar.clearAllLabel.l()}{" "}
                            <span className="visually-hidden">
                                {i18n.filterBar.a11y.filtersLabel.l({
                                    hitLabel,
                                })}
                            </span>
                        </Button>
                    )}
                </ModalHeader>
                <FilterList
                    filters={filters}
                    showSort={showSort}
                    variant="collapsible"
                />

                <div className={styles["modal-show-button-wrapper"]}>
                    <div className={styles["modal-show-button-content"]}>
                        <Button
                            variant="primary"
                            type="button"
                            onClick={() => setIsOpen(false)}
                        >
                            {i18n.filterBar.showHitsLabel.l({
                                totalHits: totalHits.toString(),
                                hitLabel,
                            })}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
