import cn from "clsx";
import React, { HTMLAttributes } from "react";
import { SliderSlide } from "../slider-slide";
import styles from "./SliderTrack.module.scss";

type EmblaViewportRefType = <ViewportElement extends HTMLElement>(
    instance: ViewportElement | null,
) => void;

export type SliderTrackProps = React.PropsWithChildren<
    HTMLAttributes<HTMLDivElement> & {
        emblaRef: EmblaViewportRefType;
        itemGap: number | string;
        desktopWidth: string | number | "auto";
        mobileWidth: string | number | "auto";
        tabletWidth: string | number | "auto";
        nbItems: number;
        sliderTrackWrapperClassName?: string;
        autoWrapSlides?: boolean;
    }
>;

export const SliderTrack: React.FC<SliderTrackProps> = ({
    emblaRef,
    children,
    desktopWidth,
    mobileWidth,
    tabletWidth,
    nbItems,
    itemGap,
    sliderTrackWrapperClassName,
    autoWrapSlides,
    ...rest
}) => {
    const childrenCount = React.Children.count(children);
    return (
        <div
            {...rest}
            className={cn(styles["track-wrapper"], sliderTrackWrapperClassName)}
            ref={emblaRef}
        >
            <div
                className={styles.track}
                style={
                    {
                        "--nb-items": `${nbItems}`,
                        "--desktop-width": `${desktopWidth}`,
                        "--tablet-width": `${tabletWidth}`,
                        "--mobile-width": `${mobileWidth}`,
                        "--item-gap": `${itemGap}`,
                    } as React.CSSProperties
                }
            >
                {React.Children.map(children, (child, index) => (
                    <React.Fragment key={`slide_${index}`}>
                        {autoWrapSlides ? (
                            <SliderSlide
                                accessibleLabel={`Slide ${
                                    index + 1
                                } of ${childrenCount}`}
                            >
                                {child}
                            </SliderSlide>
                        ) : (
                            child
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
