import { Container, Grid, LoadingSkeleton } from "@wojo/ui";
import styles from "./TourCardSliderFallback.module.scss";

type TourCardSliderFallbackProps = {
    numberOfResults: number;
};

export const TourCardSliderFallback: React.FC<TourCardSliderFallbackProps> = ({
    numberOfResults,
}) => {
    const numberOfItemsToDisplay = Math.min(numberOfResults, 4);

    return (
        <Container className={styles.container}>
            <Grid
                columns={numberOfItemsToDisplay}
                gap={{
                    xs: "var(--c-slider-item-gap)",
                }}
                className={styles.grid}
            >
                {new Array(numberOfItemsToDisplay)
                    .fill(null)
                    .map((_, index) => (
                        <div className={styles.card} key={index}>
                            <LoadingSkeleton
                                className={styles.image}
                                accessibleText={
                                    index === 0 ? "Loading tours" : ""
                                }
                            />
                            <div className={styles["card-content"]}>
                                <LoadingSkeleton
                                    width="100%"
                                    height="30px"
                                    accessibleText=""
                                />
                                <LoadingSkeleton
                                    width="100%"
                                    height="30px"
                                    accessibleText=""
                                />
                                <LoadingSkeleton
                                    width="50%"
                                    height="30px"
                                    accessibleText=""
                                />
                            </div>
                        </div>
                    ))}
            </Grid>
        </Container>
    );
};
