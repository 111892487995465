import { useSuspenseQuery } from "@tanstack/react-query";
import { gtmSendEvent } from "@wojo/analytics";
import { NoIterpString, useI18n } from "@wojo/localization";
import { tourPageFilterToFacet } from "@wojo/services";
import type { SearchResults } from "algoliasearch-helper";
import cn from "clsx";
import currency from "currency.js";
import { ChangeEvent } from "react";
import { DateRange } from "react-day-picker";
import { fetchStoryblokData } from "../../../client/fetch-storyblok-data";
import { CalendarFilter } from "../filter-item/CalendarFilter";
import { CheckboxFilter } from "../filter-item/CheckboxFilter";
import itemStyles from "../filter-item/FilterItem.module.scss";
import { RadioButtonFilter } from "../filter-item/RadioButtonFilter";
import { FilterName } from "../form-types";
import { useResultsContext } from "../results-provider/ResultsProvider";
import styles from "./FilterList.module.scss";

type Facet = SearchResults.Facet;
type FilterRange = {
    start?: string;
    end?: string;
    label: string;
};

const customSortedFacetItems = (
    facetItems: { label: string; value: string }[],
    customSortedItems: string[],
): { label: string; value: string }[] => {
    const customOrderMap = new Map(
        customSortedItems.map((item, index) => [item, index]),
    );

    return facetItems.sort(
        (a, b) =>
            (customOrderMap.get(a.value) ?? Infinity) -
            (customOrderMap.get(b.value) ?? Infinity),
    );
};

const customSortPrices = (
    facetItems: { label: string; value: string }[],
    customSortedItems: FilterRange[],
) => {
    return customSortedFacetItems(
        facetItems,
        customSortedItems.map((item) => item.label),
    );
};

const customSortDuration = (facetItems: { label: string; value: string }[]) => {
    const customSortedItems = [
        "9 days or less",
        "10–13 days",
        "14 days or more",
    ];
    return customSortedFacetItems(facetItems, customSortedItems);
};

const customActivityLevelSort = (
    facetItems: { label: string; value: string }[],
): { label: string; value: string }[] => {
    const customSortedItems = ["Low", "Moderate", "High", "Very high"];
    return customSortedFacetItems(facetItems, customSortedItems);
};

export const reduceFilterNamesToFilterListItems = ({
    facets,
    filters,
    filterNameLabelMap,
}: {
    filters: FilterName[];
    facets: Facet[];
    filterNameLabelMap: { [key: string]: NoIterpString };
}): FilterItems => {
    return filters.reduce((acc, filter) => {
        const facetsObj: { [key: string]: Facet | undefined } = facets.reduce(
            (acc, facet) => {
                return { ...acc, [facet.name]: facet };
            },
            {},
        );
        const facetName = tourPageFilterToFacet[filter];
        if (facetName && facetsObj[facetName]?.data) {
            return {
                ...acc,
                [filter]: {
                    label: filterNameLabelMap[filter].l() || filter,
                    options: Object.entries(
                        facetsObj[facetName]?.data ?? {},
                    ).map(([value, count]) => ({
                        label: `${value} (${count})`,
                        value,
                    })),
                },
            };
        }
        return {
            ...acc,
            [filter]: {
                label:
                    filter in filterNameLabelMap
                        ? filterNameLabelMap[filter].l()
                        : filter,
                options: [],
            },
        };
    }, {});
};

type FilterListItem = {
    label: string;
    options: {
        label: string;
        value: string;
    }[];
};

type FilterItems = { [k in FilterName]?: FilterListItem };

export type FilterListProps = {
    filters: FilterName[];
    showSort: boolean;
    variant: "collapsible" | "popover";
};

export const FilterList: React.FC<FilterListProps> = ({
    filters,
    showSort,
    variant,
}) => {
    const i18n = useI18n("storyblok").storyblok;
    const { facets, form, sortOptions } = useResultsContext();
    const {
        activityLevel,
        dates,
        destinations,
        duration,
        price,
        tripType,
        discount,
    } = reduceFilterNamesToFilterListItems({
        facets,
        filters,
        filterNameLabelMap: i18n.filterList.filterNameLabelMap,
    });
    const [
        activityLevelValue,
        datesValue,
        destinationsValue,
        durationValue,
        priceValue,
        sortValue,
        tripTypeValue,
        discountValue,
    ] = form.watch([
        "activityLevel",
        "dates",
        "destinations",
        "duration",
        "price",
        "sort",
        "tripType",
        "discount",
    ]);
    const filterCount = [
        destinations,
        dates,
        duration,
        price,
        activityLevel,
        tripType,
        discount,
        showSort,
    ].filter(Boolean).length;
    const formatDateRangeToString = (dateRange: DateRange): string => {
        if (!dateRange.from && !dateRange.to) {
            return "undefined";
        }

        let formattedString = "";

        if (dateRange.from) {
            formattedString += dateRange.from.toDateString();
        }

        if (dateRange.to) {
            formattedString += ` to ${dateRange.to.toDateString()}`;
        }

        return formattedString;
    };
    const sendFilterEvent = (
        filterType: string,
        event: ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.currentTarget.checked) {
            if (filterType === "Sort") {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_object: "ELEMENT",
                        ga4_action: "SORTED",
                        sortOption: event.currentTarget.value,
                    },
                });
            } else {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_object: "ELEMENT",
                        ga4_action: "FILTERED",
                        filterType: filterType,
                        filteredBy: event.currentTarget.value,
                    },
                });
            }
        }
    };
    const { data: webConfigData } = useSuspenseQuery({
        queryKey: ["configurations/web-config/web-config"],
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            return fetchStoryblokData(
                "configurations/web-config/web-config",
                {},
            );
        },
    });
    return (
        <div
            className={cn(
                styles[`list--${variant}`],
                itemStyles[`list--${variant}`],
            )}
            style={
                {
                    "--filter-count": filterCount,
                    maxWidth: filterCount <= 3 ? 276 * filterCount : undefined,
                } as React.CSSProperties
            }
        >
            {destinations && (
                <CheckboxFilter
                    triggerText={`${destinations.label}${
                        destinationsValue.length
                            ? ` (${destinationsValue.length})`
                            : ""
                    }`}
                    showAllText={(showAll) =>
                        showAll
                            ? i18n.filterList.showFewerDestinations.l()
                            : i18n.filterList.showAllDestinations.l()
                    }
                    variant={variant}
                    name="destinations"
                    items={destinations.options}
                    onChange={(evt) => {
                        sendFilterEvent("Destinations", evt);
                    }}
                />
            )}
            {dates && (
                <CalendarFilter
                    triggerText={`${dates.label}${datesValue ? " (1)" : ""}`}
                    variant={variant}
                    label={i18n.filterList.departLabel.l()}
                    name="dates"
                    onChange={(selectedItem) => {
                        if (selectedItem instanceof Date) {
                            return;
                        }
                        const formattedDateRange = selectedItem
                            ? formatDateRangeToString(selectedItem)
                            : "undefined";
                        gtmSendEvent({
                            event: "track",
                            ga4Tracking: {
                                ga4_object: "ELEMENT",
                                ga4_action: "FILTERED",
                                filterType: "Dates",
                                filteredBy: formattedDateRange,
                            },
                        });
                    }}
                />
            )}
            {duration && (
                <CheckboxFilter
                    triggerText={`${duration.label}${
                        durationValue.length ? ` (${durationValue.length})` : ""
                    }`}
                    variant={variant}
                    name="duration"
                    items={customSortDuration(duration.options)}
                    onChange={(evt) => {
                        sendFilterEvent("Duration", evt);
                    }}
                />
            )}
            {price && (
                <CheckboxFilter
                    triggerText={`${price.label}${
                        priceValue.length ? ` (${priceValue.length})` : ""
                    }`}
                    variant={variant}
                    name="price"
                    items={customSortPrices(
                        price.options,
                        webConfigData.data?.story?.content?.priceFilterRanges ??
                            [],
                    )}
                    onChange={(evt) => {
                        sendFilterEvent("Price", evt);
                    }}
                />
            )}
            {discount && (
                <CheckboxFilter
                    triggerText={`${discount.label}${
                        discountValue.length ? ` (${discountValue.length})` : ""
                    }`}
                    variant={variant}
                    name="discount"
                    items={discount.options.map((option) => {
                        // option.label format defined in reduceFilterNamesToFilterListItems label: `${value} (${count})`,
                        const [_, labelCount] = option.label.split(" ");
                        return {
                            label: `${currency(parseInt(option.value) || 0, {
                                precision: 0,
                            }).format()} ${labelCount}`,
                            value: option.value,
                        };
                    })}
                    onChange={(evt) => {
                        sendFilterEvent("Discount", evt);
                    }}
                />
            )}
            {activityLevel && (
                <CheckboxFilter
                    triggerText={`${activityLevel.label}${
                        activityLevelValue.length
                            ? ` (${activityLevelValue.length})`
                            : ""
                    }`}
                    variant={variant}
                    name="activityLevel"
                    items={customActivityLevelSort(activityLevel.options)}
                    onChange={(evt) => {
                        sendFilterEvent("Activity Level", evt);
                    }}
                />
            )}
            {tripType && (
                <CheckboxFilter
                    triggerText={`${tripType.label}${
                        tripTypeValue.length ? ` (${tripTypeValue.length})` : ""
                    }`}
                    variant={variant}
                    name="tripType"
                    items={tripType.options}
                    onChange={(evt) => {
                        sendFilterEvent("Trip Type", evt);
                    }}
                />
            )}
            {showSort && (
                <RadioButtonFilter
                    triggerText={`${i18n.filterList.sortLabel.l()}${
                        sortValue && sortValue !== "Featured"
                            ? ` by ${sortValue}`
                            : ""
                    }`}
                    variant={variant}
                    name="sort"
                    items={sortOptions.map((option: string) => ({
                        label: option,
                        value: option,
                    }))}
                    onChange={(evt) => {
                        sendFilterEvent("Sort", evt);
                    }}
                />
            )}
        </div>
    );
};
