import { ComboBox, ComboBoxProps } from "../../combo-box";
import {
    FormFieldController,
    FormFieldControllerProps,
} from "../form-field-controller";

export type FormComboboxProps<T> = FormFieldControllerProps<ComboBoxProps<T>>;

export function FormCombobox<T>({
    validation,
    name,
    id,
    ref,
    ...comboboxProps
}: FormComboboxProps<T>) {
    return (
        <FormFieldController name={name} validation={validation}>
            <ComboBox
                {...comboboxProps}
                fieldId={id ?? name}
                forceControlledMode
            />
        </FormFieldController>
    );
}
