import { fetchStoryblokData } from "@wojo/storyblok";
import { getStoryblokParams } from "./get-storyblok-params";

// TODO: use ContactInfoContent query https://ef-wojo.atlassian.net/browse/WWR-1195
export const getContactInfo = async function fetchData(searchParams?: {
    [key: string]: string | string[] | undefined;
}) {
    const { fullSlug, sbParams } = getStoryblokParams(
        "configurations/shared/contact-info",
        searchParams,
    );

    return fetchStoryblokData(fullSlug, sbParams);
};
