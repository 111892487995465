import { useMediaQuery } from "@mantine/hooks";
import { Container, Grid, LoadingSkeleton } from "@wojo/ui";
import styles from "./ReviewsSliderFallback.module.scss";

type ReviewsSliderFallbackProps = {};

export const ReviewsSliderFallback: React.FC<
    ReviewsSliderFallbackProps
> = ({}) => {
    const breakpointDesktop = "(min-width: 992px)";
    const isDesktop = useMediaQuery(breakpointDesktop, true);
    const numberOfResults = isDesktop ? 2 : 1;
    return (
        <Container>
            <Grid
                columns={numberOfResults}
                gap={{
                    xs: "var(--c-slider-item-gap)",
                }}
                className={styles.grid}
            >
                {new Array(numberOfResults).fill(null).map((_, index) => (
                    <div className={styles.review} key={index}>
                        <div>
                            <LoadingSkeleton
                                width="30%"
                                height="30px"
                                accessibleText={
                                    index === 0 ? "Loading reviews" : ""
                                }
                                className={styles["review-rating"]}
                            />
                            <LoadingSkeleton
                                width="80%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="50%"
                                height="30px"
                                accessibleText=""
                                className={styles["review-author"]}
                            />

                            <LoadingSkeleton
                                width="100%"
                                height="80px"
                                accessibleText=""
                                className={styles["review-content"]}
                            />
                            <LoadingSkeleton
                                width="60%"
                                height="30px"
                                accessibleText=""
                                className={styles["review-footer"]}
                            />
                        </div>
                    </div>
                ))}
            </Grid>
        </Container>
    );
};
