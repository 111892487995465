"use client";

import {
    Container,
    EmblaCarouselType,
    LoadingSkeleton,
    Slider,
} from "@wojo/ui";
import React, { useRef, useState } from "react";
import {
    VerticalVideo,
    VerticalVideoProps,
} from "../../molecules/vertical-video";
import styles from "./DesktopVideoGallerySlider.module.scss";
import { getVideoPlayerHandler } from "@wojo/ui";
import { SliderSlide } from "@wojo/ui";
import cn from "clsx";
import { WistiaVideoState } from "@wojo/ui";
import { gtmSendEvent } from "@wojo/analytics";
import { useMediaQuery } from "@mantine/hooks";
import { useI18n } from "@wojo/localization";

export type DesktopVideoGallerySliderProps = {
    /**
     * List of videos to display in the slider
     */
    videos: VerticalVideoProps[];
};

export const DesktopVideoGallerySlider: React.FC<
    DesktopVideoGallerySliderProps
> = ({ videos }) => {
    const i18n = useI18n("storyblok");
    const isTabletBreakpoint = useMediaQuery("(min-width: 768px)");
    const [activeVideoId, setActiveVideoId] = useState(
        videos[0]?.wistiaVideoId,
    );
    const [isFirstVideoInAutoplayMode, setIsFirstVideoInAutoplayMode] =
        useState(true);
    const [hasFirstVideoAutoPlayed, setHasFirstVideoAutoPlayed] =
        useState(false);

    const emblaApiRef = useRef<EmblaCarouselType | null>(null);
    const slideWidth = "368px";

    const handlePlaybackChange: VerticalVideoProps["onPlaybackChange"] = (
        eventVideoState,
        playbackEvent,
    ) => {
        const eventVideo = playbackEvent.video;
        const eventVideoId = eventVideo.data.media.hashedId;
        const eventVideoIndex = videos.findIndex(
            ({ wistiaVideoId }) => wistiaVideoId === eventVideoId,
        );
        const eventVideoProps =
            eventVideoIndex >= 0 ? videos.at(eventVideoIndex) : null;

        if (
            // Note when the first video auto plays
            eventVideoIndex === 0 &&
            isFirstVideoInAutoplayMode &&
            eventVideoState === WistiaVideoState.Playing &&
            playbackEvent.source !== "user-event" &&
            !hasFirstVideoAutoPlayed
        ) {
            setHasFirstVideoAutoPlayed(true);

            gtmSendEvent({
                event: "track",
                ga4_object: "VERTICAL_VIDEO",
                ga4_action: eventVideoState.toUpperCase(),
                heading: eventVideoProps?.heading,
                parentObject: "VIDEO_GALLERY",
                videoId: eventVideoId,
                videoTitle: eventVideo.name(),
                videoType: "VideoGallery",
            });
        }

        if (
            // When ended, replay first video if in autoplay mode
            eventVideoIndex === 0 &&
            isFirstVideoInAutoplayMode &&
            eventVideoState === WistiaVideoState.Ended
        ) {
            eventVideo.time(0);
            eventVideo.play();
        }

        if (
            // When the user interacts in autoplay mode, unmute first video, and disable autoplay mode
            playbackEvent.source === "user-event" &&
            isFirstVideoInAutoplayMode
        ) {
            const firstVideoId = videos.at(0)?.wistiaVideoId;
            const firstVideo = firstVideoId
                ? getVideoPlayerHandler(firstVideoId)
                : null;
            firstVideo?.unmute();
            setIsFirstVideoInAutoplayMode(false);
        }

        if (
            // When a new video starts playing, slide the slider, pause other videos, and send tracking
            eventVideoId !== activeVideoId
        ) {
            emblaApiRef.current?.scrollTo(eventVideoIndex);
            setActiveVideoId(eventVideoId);

            const otherVideos = videos.filter(
                ({ wistiaVideoId }) => wistiaVideoId !== eventVideoId,
            );
            for (const otherVideo of otherVideos) {
                const otherVideoHandle = getVideoPlayerHandler(
                    otherVideo.wistiaVideoId,
                );
                if (otherVideoHandle?.state() === WistiaVideoState.Playing) {
                    otherVideoHandle.pause();
                }
            }
        }

        if (
            // Track user interactions
            playbackEvent.source === "user-event"
        ) {
            gtmSendEvent({
                event: "track",
                ga4_object: "VERTICAL_VIDEO",
                ga4_action: eventVideoState.toUpperCase(),
                heading: eventVideoProps?.heading,
                parentObject: "VIDEO_GALLERY",
                videoId: eventVideoId,
                videoTitle: eventVideo.name(),
                videoType: "VideoGallery",
            });

            if (eventVideoId !== activeVideoId) {
                gtmSendEvent({
                    event: "track",
                    ga4_object: "VIDEO_GALLERY",
                    ga4_action: "Changed Video",
                    heading: eventVideoProps?.heading,
                    videoId: eventVideoId,
                    videoPosition: `Video ${eventVideoIndex + 1}`,
                    videoTitle: eventVideo.name(),
                });
            }
        }
    };

    return (
        <Container className={styles.container}>
            <Slider
                desktopWidth={slideWidth}
                tabletWidth={slideWidth}
                gap="var(--g-spacing-lg)"
                autoWrapSlides={false}
                sliderTrackWrapperClassName={styles["track-wrapper"]}
                emblaApiRef={emblaApiRef}
            >
                {videos.map((video, index) => (
                    <SliderSlide
                        key={video._uid}
                        accessibleLabel={`Slide ${index + 1} of ${
                            videos.length
                        }`}
                        className={cn(styles.slide, {
                            [styles["slide--active"]]:
                                video.wistiaVideoId === activeVideoId,
                        })}
                        tabOnToSlide={false}
                    >
                        {isTabletBreakpoint ? (
                            <VerticalVideo
                                {...video}
                                requestAutoplay={
                                    index === 0 && isFirstVideoInAutoplayMode
                                }
                                onPlaybackChange={handlePlaybackChange}
                            />
                        ) : (
                            <LoadingSkeleton
                                accessibleText={i18n.storyblok.desktopVideoGallery.loadingVideoLabel.l()}
                                height="100%"
                            />
                        )}
                    </SliderSlide>
                ))}
            </Slider>
        </Container>
    );
};
