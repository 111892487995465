import { gtmSendEvent } from "@wojo/analytics";
import { Button, Card, Rating, StatusLabel } from "@wojo/ui";
import cn from "clsx";
import pluralize from "pluralize";
import React from "react";
import { StoryblokImage } from "../../storyblok-image";
import { WishlistButton } from "../../wishlist-button";
import { DepartureListItem } from "../departure-list-item";
import { getIsOnSaleFiltersParam } from "../get-is-on-sale-filters-param";
import { DealsTourCardProps } from "../types";
import styles from "./MobileCard.module.scss";

export const MobileCard: React.FC<DealsTourCardProps> = ({
    departures,
    isInWishlist,
    toggleWishlist,
    showGatewayPricing,
}) => {
    if (!departures[0]) {
        return null;
    }
    const departure = departures[0];
    const {
        cardImage,
        extensionName = "",
        tourBadge,
        nbCities = 0,
        nbDays = 0,
        nbDaysWithExtension = 0,
        name = "",
        rating,
        tourPagePath,
    } = departure;

    const dayText = `${nbDays} ${pluralize("day", nbDays ?? 0)}`;
    const cityText = `${nbCities} ${pluralize("city", nbCities ?? 0)}`;
    const extensionText = `${nbDaysWithExtension} ${pluralize(
        "day",
        nbDaysWithExtension ?? 0,
    )}`;
    const daysWithCitiesText = `${dayText}, ${cityText}`;
    const daysWithExtensionText = `${dayText} | ${extensionText} with ${extensionName} extension`;
    const showExtension = nbDaysWithExtension && extensionName;
    const tourPagePathWithFilters = `${tourPagePath}${getIsOnSaleFiltersParam(
        "?",
    )}#tripbuilder`;

    return (
        <Card aria-label={name ?? undefined} className={styles.wrapper}>
            <div className={styles["card-header"]}>
                <div className={styles["tour-details"]}>
                    {tourBadge && (
                        <div className={styles["merchandising-label"]}>
                            <StatusLabel
                                size="standard"
                                status={tourBadge.variant}
                                text={tourBadge.text}
                            />
                        </div>
                    )}
                    <a
                        href={tourPagePathWithFilters}
                        onClick={() => {
                            gtmSendEvent({
                                event: "track",
                                ga4_object: "DEAL_TOUR_CARD_GRID",
                                ga4_action: "CLICKED",
                                tourCode: departure.tourCode ?? "",
                                tourName: departure.name,
                            });
                        }}
                        className={styles["product-page-link"]}
                    >
                        {name}
                    </a>
                    <div className={styles["trip-length"]}>
                        {showExtension
                            ? daysWithExtensionText
                            : daysWithCitiesText}
                    </div>
                    {rating && rating > 0 ? (
                        <div className={styles["rating-wrapper"]}>
                            <Rating
                                size="small"
                                value={rating}
                                variant="bold"
                            />
                        </div>
                    ) : null}

                    <span className={styles.wishlist}>
                        <WishlistButton
                            isInWishlist={isInWishlist}
                            toggleWishlist={toggleWishlist}
                            tourName={name}
                        />
                    </span>
                </div>
                <div className={styles["tour-image"]}>
                    {cardImage && (
                        <StoryblokImage
                            coverContainer={true}
                            mobile={{
                                asset: cardImage,
                                size: { height: 392, width: 218 },
                            }}
                        />
                    )}
                </div>
            </div>
            <ul
                aria-label={`Deals: ${name}`}
                className={cn(styles["deals-list"], {
                    [styles["deals-list--no-bottom-border"]]:
                        departures.length <= 4,
                })}
            >
                {departures.slice(0, 4).map((departure, index) => (
                    <DepartureListItem
                        key={departure.objectID}
                        departure={departure}
                        showGatewayPricing={showGatewayPricing}
                        itemIndex={index}
                    />
                ))}
            </ul>
            {departures.length > 4 && (
                <div className={styles["trip-builder-link-wrapper"]}>
                    <Button
                        href={tourPagePathWithFilters}
                        variant="quiet"
                        tag="a"
                        onClick={() => {
                            gtmSendEvent({
                                event: "track",
                                ga4_object: "DEAL_TOUR_CARD_GRID",
                                ga4_action: "CLICKED",
                                tourCode: departure.tourCode ?? "",
                                tourName: departure.name ?? "",
                            });
                        }}
                    >
                        See all
                        <span className="visually-hidden"> deals: {name}</span>
                    </Button>
                </div>
            )}
        </Card>
    );
};
