import { useSuspenseQuery } from "@apollo/client";
import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { useI18n } from "@wojo/localization";
import { Container, Text } from "@wojo/ui";
import cn from "clsx";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FiBookOpen } from "react-icons/fi";
import {
    BusinessCode,
    HelpCenterArticle,
    SearchHelpCenterDocument,
    SearchHelpCenterQuery,
    SearchHelpCenterQueryVariables,
} from "../../../generated/graphql";
import { ButtonAtom, type ButtonAtomProps } from "../../atoms/button";
import {
    getSpacingClassName,
    type SpacingProps,
} from "../../client/get-spacing-class-name";
import styles from "./ZendeskArticle.module.scss";
import { ZendeskArticleFallback } from "./ZendeskArticleFallback";

export type ZendeskArticleProps = {
    zendeskCategoryQuery: string;
    numberOfArticles: string;
    hideCategoryName?: boolean;
    layout?: "2" | "3" | "4";
    action?: ButtonAtomProps[];
    onArticleClick?: (article: HelpCenterArticle) => void;
    disableContainerPadding?: boolean;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

const noContainerPadding = { xs: "0", sm: "0", md: "0", lg: "0", xl: "0" };

const ZendeskArticleInternal: React.FC<ZendeskArticleProps> = (props) => {
    const {
        zendeskCategoryQuery,
        hideCategoryName,
        numberOfArticles,
        action,
        layout,
        spaceAbove,
        spaceBelow,
        disableContainerPadding,
        onArticleClick,
    } = props;
    const i18n = useI18n("storyblok").storyblok;
    const articleNumber = parseInt(numberOfArticles, 10);
    const shouldSkipQuery = !zendeskCategoryQuery || articleNumber === 0;

    const { data } = useSuspenseQuery<
        SearchHelpCenterQuery,
        SearchHelpCenterQueryVariables
    >(SearchHelpCenterDocument, {
        variables: {
            businessCode: process.env.NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
            query: zendeskCategoryQuery,
            perPage: articleNumber,
        },
        skip: shouldSkipQuery,
        context: {
            fetchOptions: { next: { revalidate: 900 } },
            includeCookie: false,
        },
    });

    const results = data?.searchHelpCenter?.results ?? [];

    return (
        <Container
            spacing={disableContainerPadding ? noContainerPadding : undefined}
            {...storyblokEditable(props)}
            className={cn(
                styles["zendesk-article"],
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
            data-ga4-object="ZENDESK_ARTICLE"
        >
            <nav className={styles["list"]} aria-label="Help center">
                <ul
                    aria-label={i18n.zendeskArticle.helpCenterArticlesLabel.l()}
                    className={cn(styles.grid)}
                    style={
                        {
                            "--layout-columns": layout,
                        } as React.CSSProperties
                    }
                >
                    {results.map((result, index) => (
                        <li key={index} className={styles["article-item"]}>
                            <span className={styles["icon"]} aria-hidden="true">
                                <FiBookOpen />
                            </span>
                            <a
                                href={result.url || "#"}
                                className={styles["link"]}
                                onClick={() => onArticleClick?.(result)}
                            >
                                <span className={styles["link-title"]}>
                                    {result.title || ""}
                                </span>
                                {!hideCategoryName && result.sectionName && (
                                    <span className={styles["category"]}>
                                        {result.sectionName}
                                    </span>
                                )}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
            {action?.length ? (
                <div className={cn(styles["button"])}>
                    {action.map((actionBlok: ButtonAtomProps) => (
                        <ButtonAtom {...actionBlok} key={actionBlok._uid} />
                    ))}
                </div>
            ) : null}
        </Container>
    );
};

export const ZendeskArticle: React.FC<ZendeskArticleProps> = (props) => {
    const i18n = useI18n("storyblok").storyblok;
    return (
        <ErrorBoundary
            fallback={
                <Container
                    spacing={
                        props.disableContainerPadding
                            ? noContainerPadding
                            : undefined
                    }
                >
                    <Text.Body>
                        {i18n.zendeskArticle.failedToLoadHelpCenter.l()}
                    </Text.Body>
                </Container>
            }
        >
            <Suspense
                fallback={
                    <ZendeskArticleFallback
                        numberOfResults={parseInt(props.numberOfArticles, 10)}
                        layout={parseInt(props.layout || "2")}
                        hitLabel={i18n.zendeskArticle.helpCenterArticlesLabel.l()}
                    />
                }
            >
                <ZendeskArticleInternal {...props} />
            </Suspense>
        </ErrorBoundary>
    );
};
