import React from "react";
import { TextAtom, TextAtomProps } from "../../atoms/text";
import { TitleAtom, TitleAtomProps } from "../../atoms/title";
import styles from "./ContentCard.module.scss";

type ContentCardTitleDescriptionProps = {
    title?: TitleAtomProps[];
    description?: TextAtomProps[];
    cardStyle?: "flat" | "card";
    titleId?: string;
    descriptionId?: string;
};

export const ContentCardTitleDescription: React.FC<
    ContentCardTitleDescriptionProps
> = ({ title, description, titleId, descriptionId }) => {
    return (
        <div className={styles["title-description"]}>
            {title?.length ? (
                <TitleAtom id={titleId} {...title[0]} key={title[0]._uid} />
            ) : null}
            {description?.length ? (
                <TextAtom
                    id={descriptionId}
                    {...description[0]}
                    key={description[0]._uid}
                />
            ) : null}
        </div>
    );
};
