import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { Button } from "@wojo/ui";
import cn from "clsx";
import React, { useId } from "react";
import { TextAtomProps } from "../../atoms/text";
import { TitleAtomProps } from "../../atoms/title";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";
import { SpacingProps } from "../../client/get-spacing-class-name";
import { BorderProps } from "../../components/border";
import { ImageRatio } from "../../types/image";
import { HorizontalPos } from "../../types/position";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { StoryblokLink } from "../../types/storyblok/storyblok-link";
import styles from "./ContentCard.module.scss";
import { ContentCardImage } from "./ContentCardImage";
import { ContentCardTitleDescription } from "./ContentCardTitleDescription";
import { ContentCardWrapper } from "./ContentCardWrapper";

export type ContentCardProps = {
    /**
     * The image to be rendered
     * @required
     */
    image: AssetStoryblok;
    /**
     * The title blok to be rendered
     */
    title?: TitleAtomProps[];
    /**
     * The description blok to be rendered
     */
    description?: TextAtomProps[];
    /**
     * The card style to be rendered
     * @default flat
     */
    cardStyle?: "flat" | "card";
    /**
     * The image ratio to display
     * @default ImageRatio.Horizontal2By1
     */
    imageRatio?: ImageRatio;
    /**
     * The horizontal position of the content within the card
     * @default left
     */
    contentXPos?: HorizontalPos;
    /**
     * Call to action text to display. If not set, the CTA will not display.
     */
    buttonText?: string;
    /**
     * The destination URL and link metadata
     */
    buttonLink?: StoryblokLink;
    /**
     * Design variant of the button
     * @default quiet
     */
    buttonVariant?: "primary" | "secondary" | "quiet" | "inverse";
    /**
     * Color palette for the specified `variant`
     */
    colorPaletteOverride?: string;
    /**
     * Index Position of the item
     * @default 0
     * @description Used for tracking purposes
     */
    itemIndex?: number;
} & SbBlokData &
    Omit<BorderProps, "children"> &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

export const ContentCard: React.FC<ContentCardProps> = (props) => {
    const {
        image,
        title,
        description,
        cardStyle,
        imageRatio,
        contentXPos,
        buttonText,
        buttonLink,
        buttonVariant,
        colorPaletteOverride,
        borderRadius,
        itemIndex,
    } = props;
    const titleId = useId();
    const descriptionId = useId();
    const buttonId = useId();
    const { href, isExternal } = getCleanStoryblokLink(buttonLink);

    return (
        <ContentCardWrapper
            {...storyblokEditable(props)}
            cardStyle={cardStyle || "flat"}
            href={href}
            isExternal={isExternal}
            titleId={title ? titleId : undefined}
            descriptionId={description ? descriptionId : undefined}
            buttonId={href ? buttonId : undefined}
            contentXPos={contentXPos || "left"}
            borderRadius={borderRadius}
            {...props}
        >
            <ContentCardImage
                image={image}
                imageRatio={imageRatio || ImageRatio.Horizontal2By1}
            />
            <div
                className={cn(styles["text-container"], {
                    [styles["text-container--flat-padding"]]:
                        cardStyle === "flat",
                })}
            >
                <ContentCardTitleDescription
                    title={title}
                    description={description}
                    cardStyle={cardStyle || "flat"}
                    titleId={title ? titleId : undefined}
                    descriptionId={description ? descriptionId : undefined}
                />
                {buttonText && href ? (
                    <Button
                        tag="span"
                        id={buttonId}
                        variant={buttonVariant || "quiet"}
                        palette={colorPaletteOverride}
                        className={styles.cta}
                        onClick={() => {
                            gtmSendEvent({
                                event: "track",
                                ga4_object: "CONTENT_CARD",
                                ga4_action: "CLICKED",
                                heading: title?.[0]?.heading || "",
                                clickText: buttonText,
                                destinationUrl: href,
                                index: itemIndex,
                            });
                        }}
                    >
                        {buttonText}
                    </Button>
                ) : null}
            </div>
        </ContentCardWrapper>
    );
};
