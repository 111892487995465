import { ApolloLink, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import {
    ApolloClient,
    InMemoryCache,
    SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support";
import { EnqueueMutationLink } from "./enqueue-mutation-link";
type ApolloMakeClientProps = {
    cookie?: string;
    /**
     * @summary initialize logrocket before apollo to attach logrocket to `fetch` and `xhr` requests
     */
    initLogRocket?: () => void;
    logRocketOnError?: (message: string) => void;
};
export function apolloMakeClient({
    cookie,
    initLogRocket,
    logRocketOnError,
}: ApolloMakeClientProps) {
    initLogRocket?.();

    // retry network errors only. Graphql errors are handled in the errorLink
    const retryLink = new RetryLink();
    const httpLink = createHttpLink({
        uri: process.env.NEXT_PUBLIC_GQL_GATEWAY_URL,
        credentials: "include",
    });

    const errorLink = onError(
        ({ graphQLErrors, networkError, operation, forward }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message }) => {
                    logRocketOnError?.(message);
                });
                // retry the request one more time
                return forward(operation);
            }
            if (networkError) {
                logRocketOnError?.(networkError.message);
            }
        },
    );
    const authLink = setContext(
        async (_, { headers = {}, includeCookie = true }) => {
            return {
                headers: {
                    ...headers,
                    cookie: includeCookie ? cookie : undefined,
                },
            };
        },
    );

    return new ApolloClient({
        cache: getInMemoryCache(),
        name: "wojo-web",
        link:
            typeof window === "undefined"
                ? ApolloLink.from([
                      new SSRMultipartLink({
                          stripDefer: true,
                      }),
                      authLink,
                      errorLink,
                      retryLink,
                      httpLink,
                  ])
                : ApolloLink.from([
                      new EnqueueMutationLink(),
                      authLink,
                      errorLink,
                      retryLink,
                      httpLink,
                  ]),
    });
}

export const getInMemoryCache = () => {
    return new InMemoryCache({
        typePolicies: {
            QuoteTrip: {
                fields: {
                    lineItems: {
                        merge(_, incoming) {
                            return incoming;
                        },
                    },
                    openExcursionLineItems: {
                        merge(_, incoming) {
                            return incoming;
                        },
                    },
                    openInsuranceAddonLineItems: {
                        merge(_, incoming) {
                            return incoming;
                        },
                    },
                },
            },
            Review: {
                fields: {
                    images: {
                        merge(_, incoming) {
                            return incoming;
                        },
                    },
                },
            },
        },
        possibleTypes: {
            Trip: ["QuoteTrip", "BookingTrip"],
            Order: ["Booking", "Quote"],
            LineItem: [
                "TourLineItem",
                "TourExcursionLineItem",
                "TourRoomLineItem",
                "TourExtensionLineItem",
                "InsuranceLineItem",
                "InsuranceAddonLineItem",
                "AdjustmentLineItem",
                "FeeLineItem",
                "FlightPackageLineItem",
                "InsuranceExtensionLineItem",
                "PromoLineItem",
                "PrivateGroupLineItem",
                "ExtendedStayLineItem",
            ],
            TransactionPaymentMethod: ["ACHPaymentMethod", "CardPaymentMethod"],
            TourItineraryElementUnion: [
                "TourDayContent",
                "TourItineraryItemContent",
            ],
        },
    });
};
