import { createContext, useContext } from "react";
import { TextCardProps } from "../molecules/text-card";
import { GlobalMerchandisingCard } from "../types/global-merchandising-card";

const emptyCard = {
    hideMerchandisingCard: false,
    merchandisingCard: [] as unknown as [TextCardProps],
    merchandisingCardPlacement: "1",
};

const GlobalMerchandisingCardContext =
    createContext<GlobalMerchandisingCard>(emptyCard);

export const GlobalMerchandisingCardContextProvider: React.FC<
    React.PropsWithChildren<{
        globalMerchandisingCard?: GlobalMerchandisingCard;
    }>
> = ({ children, globalMerchandisingCard }) => {
    return (
        <GlobalMerchandisingCardContext.Provider
            value={globalMerchandisingCard ?? emptyCard}
        >
            {children}
        </GlobalMerchandisingCardContext.Provider>
    );
};

export const useGlobalMerchandisingCard = (): GlobalMerchandisingCard => {
    return useContext(GlobalMerchandisingCardContext);
};
