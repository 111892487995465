"use client";

import cn from "clsx";
import { useLayoutEffect, useRef, useState } from "react";
import { useTabs } from "./internal/TabsContext";
import styles from "./Tabs.module.scss";

type BreakpointValues = {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
};
type BaseTabListProps = {
    /**
     * Title labeling the purpose of the tabs
     */
    accessibleTitle: string;
};

type StandardVariantProps = React.PropsWithChildren<
    BaseTabListProps & {
        /**
         * Variant with pre-defined styles
         */
        variant: "full" | "minimal";
    }
>;

type CustomVariantProps = React.PropsWithChildren<
    BaseTabListProps &
        StyleProps & {
            /**
             * Variant allowing for customized styles
             */
            variant: "custom";
        }
>;

type StyleProps = {
    /**
     * Values for padding at the far left and right of the tab list
     */
    customListPadding?: BreakpointValues;
    /**
     * Values for padding within tabs when tabPadding is "custom"
     */
    customTabPadding?: BreakpointValues;
    /**
     * Values for spacing between tabs when tabSpacing is "custom"
     */
    customTabSpacing?: BreakpointValues;
    /**
     * Values for width of tabs when tabWidth is "custom"
     */
    customTabWidth?: BreakpointValues;
    /**
     * Position of selected indicator on the selected tab
     * @default "bottom"
     */
    indicatorPosition?: "top" | "bottom";
    /**
     * Should show shadow along with the flat indicator
     * @default false
     */
    indicatorShadow?: boolean;
    /**
     * Padding to the left and right of the list to show the shadow
     * @default "none"
     */
    listPadding?: "none" | "default" | "custom";
    /**
     *  Type of background to use for each tab
     * @default "transparent"
     */
    tabBackground?: "opaque" | "transparent";
    /**
     * Padding within each tab
     * @default "default"
     */
    tabPadding?: "default" | "custom";
    /**
     * Spacing between each tab
     * @default "default"
     */
    tabSpacing?: "none" | "default" | "custom";
    /**
     * Width of in each tab
     * @default "auto"
     */
    tabWidth?: "auto" | "full" | "custom";
};

export type TabListProps = StandardVariantProps | CustomVariantProps;

const getStyleProps = (props: TabListProps): StyleProps => {
    if (props.variant === "full") {
        return {
            indicatorPosition: "top",
            indicatorShadow: false,
            listPadding: "none",
            tabBackground: "opaque",
            tabPadding: "default",
            tabSpacing: "none",
            tabWidth: "full",
        };
    }
    const customPropValues = props as Extract<
        CustomVariantProps,
        { variant: "custom" }
    >;
    return {
        customListPadding: customPropValues.customListPadding,
        customTabPadding: customPropValues.customTabPadding,
        customTabSpacing: customPropValues.customTabSpacing,
        customTabWidth: customPropValues.customTabWidth,
        indicatorPosition: customPropValues.indicatorPosition ?? "bottom",
        indicatorShadow: customPropValues.indicatorShadow ?? false,
        listPadding: customPropValues.listPadding ?? "none",
        tabBackground: customPropValues.tabBackground ?? "transparent",
        tabPadding: customPropValues.tabPadding ?? "default",
        tabSpacing: customPropValues.tabSpacing ?? "default",
        tabWidth: customPropValues.tabWidth ?? "auto",
    };
};

export const TabList = (props: TabListProps) => {
    const { accessibleTitle, children } = props;

    const {
        customListPadding,
        customTabPadding,
        customTabSpacing,
        customTabWidth,
        indicatorPosition,
        indicatorShadow,
        listPadding,
        tabBackground,
        tabPadding,
        tabSpacing,
        tabWidth,
    } = getStyleProps(props);

    const { currentTab, getTabId } = useTabs();
    const [animation, setAnimation] = useState({
        animating: false,
        previousTab: currentTab,
        from: "",
        to: "",
        fromWidth: "",
        toWidth: "",
    });
    const tabListRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const currentTabId = getTabId(currentTab);
        const previousTabId = getTabId(animation.previousTab);
        if (currentTabId === previousTabId) {
            return;
        }
        const currentTabRef =
            tabListRef.current?.querySelector<HTMLButtonElement>(
                `#${CSS.escape(currentTabId)}`,
            );
        const previousTabRef =
            tabListRef.current?.querySelector<HTMLButtonElement>(
                `#${CSS.escape(previousTabId)}`,
            );
        setAnimation({
            animating: true,
            previousTab: currentTab,
            from: `${previousTabRef?.offsetLeft ?? 0}px`,
            to: `${currentTabRef?.offsetLeft ?? 0}px`,
            fromWidth: `${previousTabRef?.offsetWidth ?? 0}px`,
            toWidth: `${currentTabRef?.offsetWidth ?? 0}px`,
        });
        setTimeout(() => {
            setAnimation((prev) => ({
                ...prev,
                animating: false,
            }));
        }, 300);
    }, [animation, currentTab, getTabId]);

    const animationStyle = animation.animating
        ? ({
              "--cursor-from": animation.from,
              "--cursor-to": animation.to,
              "--cursor-from-width": animation.fromWidth,
              "--cursor-to-width": animation.toWidth,
          } as React.CSSProperties)
        : {};

    const className = cn(
        styles.tablist,
        styles[`indicator--${indicatorPosition}`],
        styles[`list-padding--${listPadding}`],
        styles[`tab-background--${tabBackground}`],
        styles[`tab-padding--${tabPadding}`],
        styles[`tab-spacing--${tabSpacing}`],
        styles[`tab-width--${tabWidth}`],
        {
            [styles["indicator-shadow"]]: indicatorShadow,
            [styles["animate-selection"]]: animation.animating,
        },
    );

    const customStyleVariables = {
        "--list-padding-xs": customListPadding?.xs,
        "--list-padding-sm": customListPadding?.sm,
        "--list-padding-md": customListPadding?.md,
        "--list-padding-lg": customListPadding?.lg,
        "--list-padding-xl": customListPadding?.xl,
        "--tab-padding-xs": customTabPadding?.xs,
        "--tab-padding-sm": customTabPadding?.sm,
        "--tab-padding-md": customTabPadding?.md,
        "--tab-padding-lg": customTabPadding?.lg,
        "--tab-padding-xl": customTabPadding?.xl,
        "--tab-spacing-xs": customTabSpacing?.xs,
        "--tab-spacing-sm": customTabSpacing?.sm,
        "--tab-spacing-md": customTabSpacing?.md,
        "--tab-spacing-lg": customTabSpacing?.lg,
        "--tab-spacing-xl": customTabSpacing?.xl,
        "--tab-width-xs": customTabWidth?.xs,
        "--tab-width-sm": customTabWidth?.sm,
        "--tab-width-md": customTabWidth?.md,
        "--tab-width-lg": customTabWidth?.lg,
        "--tab-width-xl": customTabWidth?.xl,
    };

    return (
        <div
            aria-label={accessibleTitle || undefined}
            className={className}
            role="tablist"
            ref={tabListRef}
            style={{ ...animationStyle, ...customStyleVariables }}
        >
            {children}
        </div>
    );
};
