"use client";

import { HTMLAttributes, MouseEventHandler, useEffect, useRef } from "react";
import { useTabs } from "./internal/TabsContext";
import styles from "./Tabs.module.scss";

export type TabProps = React.PropsWithChildren<{
    /**
     * Text to display to the left of the tab content
     */
    adornRight?: React.ReactNode;
    /**
     * Unique identifier for this tab in a list of tabs
     */
    id: string;
    /**
     *
     */
    onClick?: MouseEventHandler<HTMLButtonElement>;
}> &
    HTMLAttributes<HTMLButtonElement>;

export const Tab = ({
    adornRight,
    children,
    id,
    onClick,
    ...rest
}: TabProps) => {
    const { currentTab, setCurrentTab, getTabId, getTabPanelId } = useTabs();
    const isSelected = id === currentTab;
    const tabId = getTabId(id);
    const panelId = getTabPanelId(id);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (isSelected) {
            const mediaQuery = window.matchMedia(
                "(prefers-reduced-motion: reduce)",
            );
            buttonRef.current?.scrollIntoView({
                behavior: mediaQuery.matches ? "instant" : "smooth",
                block: "nearest",
                inline: "nearest",
            });
        }
    }, [isSelected]);

    return (
        <span className={styles["tab"]}>
            <button
                {...rest}
                ref={buttonRef}
                aria-controls={panelId}
                aria-selected={isSelected}
                className={styles["tab-button"]}
                id={tabId}
                onClick={(e) => {
                    setCurrentTab(id);
                    onClick?.(e);
                }}
                role="tab"
                type="button"
            >
                {children}{" "}
                {adornRight && (
                    <span className={styles.adornment}>{adornRight}</span>
                )}
            </button>
            <span className={styles["tab-shadow"]} />
        </span>
    );
};
