"use client";

import { TabsProvider } from "./internal/TabsContext";

export type TabsProps = React.PropsWithChildren<{
    /**
     * Id of the tab list whose panel should be visible by default
     */
    defaultTabId: string;
    /**
     * handler for when a tab is selected
     */
    onTabChange?: (tabId: string) => void;
}>;

export const Tabs = ({ children, defaultTabId, onTabChange }: TabsProps) => {
    return (
        <TabsProvider defaultTab={defaultTabId} onTabChange={onTabChange}>
            {children}
        </TabsProvider>
    );
};
