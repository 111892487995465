"use client";
import { createContext, useContext, useId, useState } from "react";

type TabsContextValue = {
    contextId: string;
    currentTab: string;
    setCurrentTab: (name: string) => void;
};

const getTabId = (contextId: string, name: string) =>
    `${contextId}_${name}_tab`;

const getTabPanelId = (contextId: string, name: string) =>
    `${contextId}_${name}_panel`;

const TabsContext = createContext<TabsContextValue>(null!);

export type TabsProviderProps = React.PropsWithChildren<{
    defaultTab: string;
    /**
     * handler for when a tab is selected
     */
    onTabChange?: (tabId: string) => void;
}>;

export const TabsProvider = ({
    defaultTab,
    onTabChange,
    children,
}: TabsProviderProps) => {
    const contextId = useId();
    const [currentTab, setCurrentTab] = useState(defaultTab);
    const handleTabChange = (name: string) => {
        setCurrentTab(name);
        onTabChange?.(name);
    };
    return (
        <TabsContext.Provider
            value={{
                contextId,
                currentTab,
                setCurrentTab: handleTabChange,
            }}
        >
            {children}
        </TabsContext.Provider>
    );
};

export type ControlledTabsProviderProps =
    React.PropsWithChildren<TabsContextValue>;

export const ControlledTabsProvider = ({
    children,
    contextId,
    currentTab,
    setCurrentTab,
}: ControlledTabsProviderProps) => {
    return (
        <TabsContext.Provider
            value={{
                contextId,
                currentTab,
                setCurrentTab,
            }}
        >
            {children}
        </TabsContext.Provider>
    );
};

export const useTabs = () => {
    const { currentTab, setCurrentTab, contextId } = useContext(TabsContext);
    return {
        currentTab,
        setCurrentTab,
        getTabId: (name: string) => getTabId(contextId, name),
        getTabPanelId: (name: string) => getTabPanelId(contextId, name),
    };
};
