import {
    endOfMonth,
    format,
    isValid,
    startOfDay,
    startOfMonth,
} from "date-fns";
import { ReactNode } from "react";
import {
    DateRange,
    DayPickerBase,
    DayPickerProps,
    isDateRange,
} from "react-day-picker";

export type CalendarDateRange = DateRange;

export type CalendarValue = CalendarDateRange | Date;

export type SharedCalendarProps = {
    /**
     * If defined, the calendar will be controlled. Use a null value to clear the selection.
     */
    value?: string | number | readonly string[] | CalendarValue | null;
    /**
     * The default date to set for an uncontrolled component
     */
    defaultValue?: string | number | readonly string[] | CalendarValue | null;
    /**
     * Callback for when a date is selected
     * @param {CalendarValue} date the selected Date(s)
     * @returns
     */
    onSelected?: (date?: CalendarValue | null) => void;
    /**
     * Allows for a date range to be selected
     * @default false
     */
    isRangeSelect?: boolean;
    /**
     * Applies props directly to the DayPicker component for further customization
     * @see https://react-day-picker.js.org/api/types/DayPickerProps
     */
    dayPickerOptions?: DayPickerProps;
    /**
     * Render elements inline with the reset button
     */
    footer?: ReactNode;
    /**
     * Callback for when the calendar is reset
     */
    onReset?: () => void;
    /**
     * Callback for when the calendar month/year is navigated
     */
    onNavigate?: (
        event: React.MouseEvent<HTMLButtonElement>,
        name?: string,
    ) => void;
    /** Additional styles to add to the element wrapping the calendar */
    wrapperClassName?: string;
};

/**
 * Formats a date or date range selection to a user friendly string
 * @param {string} placeholder default text to display if no date is selected
 * @param {DateRange} range the selected date
 * @param {boolean} isRangeFormat if a ranged date format should be displayed
 * @returns {string} formatted label
 */
export const formatLabel = (
    placeholder: string = "",
    range?: CalendarValue | null,
) => {
    if (range instanceof Date) {
        return format(range, "MMM d, yyy");
    }
    if (range?.from && !range.to) {
        return `${format(range.from, "MMM d, yyy")} to later`;
    }
    if (range?.from && range.to) {
        const formatStyle =
            range.from.getFullYear() === range.to.getFullYear()
                ? "MMM d"
                : "MMM d, yyy";
        return `${format(range.from, formatStyle)}–${format(
            range.to,
            "MMM d, yyy",
        )}`;
    }
    return placeholder;
};

/** Return the `fromDate` and `toDate` prop values values parsing the DayPicker props. */
export function parseFromToProps(
    props: Pick<
        DayPickerBase,
        "fromYear" | "toYear" | "fromDate" | "toDate" | "fromMonth" | "toMonth"
    >,
): {
    fromDate: Date | undefined;
    toDate: Date | undefined;
} {
    const { fromYear, toYear, fromMonth, toMonth } = props;
    let { fromDate, toDate } = props;

    if (fromMonth) {
        fromDate = startOfMonth(fromMonth);
    } else if (fromYear) {
        fromDate = new Date(fromYear, 0, 1);
    }
    if (toMonth) {
        toDate = endOfMonth(toMonth);
    } else if (toYear) {
        toDate = new Date(toYear, 11, 31);
    }

    return {
        fromDate: fromDate ? startOfDay(fromDate) : undefined,
        toDate: toDate ? startOfDay(toDate) : undefined,
    };
}

export const convertToCalendarValue = (
    value?: string | number | readonly string[] | null | CalendarValue,
): CalendarValue | undefined => {
    if (isDateRange(value) || value instanceof Date) {
        return value;
    }
    if (!value || typeof value !== "string") {
        return;
    }
    const valueArray = value.split("-");
    if (isValid(new Date(value))) {
        return new Date(value);
    } else if (valueArray.length === 2) {
        const [from, to] = valueArray;
        const fromDate = isValid(from) ? new Date(from) : undefined;
        const toDate = isValid(to) ? new Date(to) : undefined;
        return fromDate || toDate
            ? {
                  from: fromDate,
                  to: toDate,
              }
            : undefined;
    } else {
        return;
    }
};
