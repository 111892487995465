"use client";

import { SbBlokData, storyblokEditable } from "@storyblok/react";
import React from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { VerticalVideoProps } from "../../molecules/vertical-video";
import { MobilePreviewAspectRatio } from "../../types/video";
import { DesktopVideoGallerySlider } from "./DesktopVideoGallerySlider";
import { MobileVideoGallery } from "./MobileVideoGallery";

export type VideoGalleryProps = {
    /**
     * List of videos: VerticalVideoProps[]
     */
    videos: VerticalVideoProps[];
    /**
     * Aspect ratio of the video preview on mobile
     */
    mobilePreviewAspectRatio?: MobilePreviewAspectRatio;
} & SbBlokData &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

export const VideoGallery: React.FC<VideoGalleryProps> = (props) => {
    const { videos, mobilePreviewAspectRatio, spaceAbove, spaceBelow } = props;

    if (!videos.length) {
        return null;
    }

    return (
        <div
            {...storyblokEditable(props)}
            className={getSpacingClassName({ spaceAbove, spaceBelow })}
        >
            <DesktopVideoGallerySlider videos={videos} />
            <MobileVideoGallery
                videos={videos}
                mobilePreviewAspectRatio={mobilePreviewAspectRatio}
            />
        </div>
    );
};
