import cn from "clsx";
import { HTMLAttributes } from "react";
import { SliderButton } from "../shared/SliderButton";
import styles from "./SliderNumberIndicator.module.scss";

export type SliderNumberIndicatorProps = HTMLAttributes<HTMLElement> & {
    /**
     * Void function to be called when clicking previous arrow
     */
    scrollPrev: (
        e:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    ) => void | undefined;
    /**
     * Void function to be called when clicking next arrow
     */
    scrollNext: (
        e:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    ) => void | undefined;
    /**
     * Whether to disable the previous button
     */
    prevBtnDisabled: boolean;
    /**
     * Whether to disable the previous button
     */
    nextBtnDisabled: boolean;
    /**
     * Disable both navigation buttons and dots
     * @default false
     */
    disableNavigation?: boolean;
    /**
     * The selected slide
     */
    selectedIndex: number;
    /**
     * Total number of slides
     */
    totalSlides: number;
};

export const SliderNumberIndicator: React.FC<SliderNumberIndicatorProps> = ({
    scrollPrev,
    scrollNext,
    prevBtnDisabled,
    nextBtnDisabled,
    disableNavigation = false,
    selectedIndex,
    totalSlides,
    className,
}) => {
    return (
        <div className={cn(styles["nav-wrapper"], className)}>
            <SliderButton
                variant="quiet"
                aria-label="Previous slide"
                arrowDirection="left"
                onClick={(e) => scrollPrev(e)}
                aria-disabled={prevBtnDisabled || disableNavigation}
            />
            <div className={styles.text} role="group">
                <span className="visually-hidden">Showing slide</span>{" "}
                {selectedIndex + 1} of {totalSlides}
            </div>
            <SliderButton
                variant="quiet"
                aria-label="Next slide"
                arrowDirection="right"
                onClick={(e) => scrollNext(e)}
                aria-disabled={nextBtnDisabled || disableNavigation}
            />
        </div>
    );
};
