import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { Container } from "@wojo/ui";
import React from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import {
    CollapsibleItem,
    CollapsibleItemProps,
} from "../../molecules/collapsible-item";
import { CollapsedState } from "../../molecules/collapsible-item/CollapsibleItem";

type DisplaySettings = "allCollapsed" | "firstExpanded" | "allExpanded";

export type CollapsibleContentProps = {
    /**
     * Collapsed State of the Child Cards.
     * @default  "allCollapsed",
     */
    displaySettings?: DisplaySettings;
    /**
     * Justification of the Collapsible Content in the Grid
     * @default  "center",
     */
    contentPosition?: "left" | "center";
    /**
     * Width of the Collapsible Content
     * @default  "medium",
     */
    collapsibleWidth?: "xlarge" | "large" | "medium" | "small" | "xsmall";
    /**
     * Collection of Child Items to expand or collapse.
     */
    collapsibles?: CollapsibleItemProps[];
} & SbBlokData &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

const getCardStates = (
    displaySettings: DisplaySettings,
): [CollapsedState, CollapsedState] => {
    switch (displaySettings) {
        case "allCollapsed":
            return ["collapsed", "collapsed"];
        case "firstExpanded":
            return ["expanded", "collapsed"];
        case "allExpanded":
            return ["expanded", "expanded"];
    }
};

export const CollapsibleContent: React.FC<CollapsibleContentProps> = (
    props,
) => {
    const {
        displaySettings,
        contentPosition,
        collapsibleWidth,
        collapsibles,
        spaceAbove,
        spaceBelow,
    } = props;
    const [firstCardState, restCardState] = getCardStates(
        displaySettings || "allCollapsed",
    );

    return (
        <Container
            {...storyblokEditable(props)}
            width={collapsibleWidth || "medium"}
            position={contentPosition || "center"}
            className={getSpacingClassName({ spaceAbove, spaceBelow })}
        >
            {collapsibles?.map((collapsibleItemBlok, index) => (
                <CollapsibleItem
                    {...collapsibleItemBlok}
                    collapsedState={
                        index === 0 ? firstCardState : restCardState
                    }
                    key={collapsibleItemBlok._uid}
                />
            ))}
        </Container>
    );
};
