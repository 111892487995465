import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { Card } from "@wojo/ui";
import cn from "clsx";
import React, { CSSProperties, useId } from "react";
import { ButtonAtom, ButtonAtomProps } from "../../atoms/button";
import {
    ImageGraphicAtom,
    ImageGraphicAtomProps,
} from "../../atoms/image-graphic";
import { TextAtomProps } from "../../atoms/text";
import { TitleAtom, TitleAtomProps } from "../../atoms/title";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";
import {
    getSpacingClassName,
    type SpacingProps,
} from "../../client/get-spacing-class-name";
import { Border, BorderProps } from "../../components/border";
import { BorderRadius } from "../../types/border";
import { HorizontalPos, VerticalPos } from "../../types/position";
import { StoryblokLink } from "../../types/storyblok/storyblok-link";
import { GraphicListItemProps } from "../graphic-list-item";
import styles from "./TextCard.module.scss";

export type TextCardProps = {
    imageGraphic?: ImageGraphicAtomProps[];
    title?: TitleAtomProps[];
    description?: TextAtomProps[] | GraphicListItemProps[];
    action?: ButtonAtomProps[] | TextAtomProps[];
    cardStyle?: "flat" | "backgroundColor" | "card";
    contentYPos?: VerticalPos;
    contentXPos?: HorizontalPos;
    backgroundColor?: string;
    backgroundStartColor?: string;
    backgroundEndColor?: string;
    gradientAngle?: string;
    /**
     * Index Position of the item
     * @default 0
     * @description Used for tracking purposes
     */
    itemIndex?: number;
} & SbBlokData &
    Omit<BorderProps, "children" | "title"> &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

export const TextCard: React.FC<TextCardProps> = (props) => {
    const {
        imageGraphic,
        title,
        description,
        action,
        cardStyle,
        contentXPos,
        contentYPos,
        backgroundColor,
        borderRadius,
        borderSize,
        borderPosition,
        backgroundStartColor,
        backgroundEndColor,
        gradientAngle,
        borderColor,
        borderStartColor,
        borderEndColor,
        borderGradientAngle,
        borderImage,
        spaceAbove,
        spaceBelow,
        itemIndex = 0,
    } = props;
    const titleId = useId();
    const Wrapper = cardStyle == "card" ? Card : "div";
    const showBackgroundGradient =
        cardStyle === "backgroundColor" &&
        backgroundEndColor &&
        backgroundStartColor;
    function getBackgroundColor() {
        if (cardStyle !== "backgroundColor") {
            return undefined;
        }
        return backgroundColor || "var(--s-color-surface-secondary)";
    }

    return (
        <Wrapper
            data-ga4-object="TEXT_CARD"
            {...storyblokEditable(props)}
            aria-labelledby={title?.length ? titleId : undefined}
            className={cn(
                styles.card,
                styles[`card--${cardStyle || "flat"}`],
                getSpacingClassName({
                    spaceAbove,
                    spaceBelow,
                }),
                {
                    [styles["card--border-radius"]]:
                        borderRadius !== BorderRadius.NoBorderRadius,
                },
            )}
            style={
                {
                    ["--gradient-angle"]:
                        contentYPos === "top" ? "0deg" : "180deg",
                    "--background-color": getBackgroundColor(),
                    ["--background-start-color"]: backgroundStartColor,
                    ["--background-end-color"]: backgroundEndColor,
                    ["--background-color-gradient-angle"]:
                        gradientAngle || "0deg",
                } as CSSProperties
            }
        >
            <Border
                borderRadius={borderRadius}
                borderSize={borderSize}
                borderPosition={borderPosition}
                borderColor={borderColor}
                borderStartColor={borderStartColor}
                borderEndColor={borderEndColor}
                borderGradientAngle={borderGradientAngle}
                borderImage={borderImage}
                className={styles["border-container"]}
            >
                <div
                    className={cn(
                        styles["content-container"],
                        styles[`content-container--${cardStyle || "flat"}`],
                        styles[`content-container--x-${contentXPos || "left"}`],
                        styles[
                            `content-container--y-${contentYPos || "bottom"}`
                        ],
                        {
                            [styles["content-container--background-color"]]:
                                getBackgroundColor(),
                            [styles["content-container--background-gradient"]]:
                                showBackgroundGradient,
                        },
                    )}
                >
                    {imageGraphic?.length ? (
                        <ImageGraphicAtom
                            className={cn(styles["card-item"])}
                            {...imageGraphic[0]}
                            key={imageGraphic[0]._uid}
                        />
                    ) : null}
                    {title?.length ? (
                        <TitleAtom
                            titleId={titleId}
                            className={cn(styles["card-item"])}
                            {...(title[0] as TitleAtomProps)}
                            key={title[0]._uid}
                        />
                    ) : null}
                    {description?.length
                        ? description.map((block) => (
                              <StoryblokComponent
                                  className={cn(styles["card-item"])}
                                  blok={block}
                                  key={block._uid}
                              />
                          ))
                        : null}
                    {action?.length ? (
                        <div className={cn(styles["card-item"])}>
                            {action.map(
                                (
                                    actionBlok: TextAtomProps | ButtonAtomProps,
                                ) => (
                                    <div
                                        className={styles.actions}
                                        key={actionBlok._uid}
                                    >
                                        {actionBlok.component ===
                                        "wojo-button-atom" ? (
                                            <ButtonAtom
                                                {...actionBlok}
                                                onClick={() => {
                                                    const { href } =
                                                        getCleanStoryblokLink(
                                                            actionBlok.link as StoryblokLink,
                                                        );
                                                    gtmSendEvent({
                                                        event: "track",
                                                        ga4_object: "TEXT_CARD",
                                                        ga4_action: "CLICKED",
                                                        heading:
                                                            title?.[0]?.heading,
                                                        index: itemIndex,
                                                        clickText:
                                                            actionBlok.text,
                                                        destinationUrl: href,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <StoryblokComponent
                                                blok={actionBlok}
                                                key={actionBlok._uid}
                                            />
                                        )}
                                    </div>
                                ),
                            )}
                        </div>
                    ) : null}
                </div>
            </Border>
        </Wrapper>
    );
};
