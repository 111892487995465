import { HtmlHTMLAttributes, useRef } from "react";
import { Button, DayProps, useDayRender } from "react-day-picker";

type CalendarDayProps = DayProps & HtmlHTMLAttributes<HTMLButtonElement>;

/**
 * Customized rendering of a calendar Day
 * @see https://github.com/gpbl/react-day-picker/blob/6fc2614/src/components/Day/Day.tsx#L19
 * @see https://github.com/gpbl/react-day-picker/pull/1708
 */
export const CalendarDay = ({
    date,
    displayMonth,
    ...otherProps
}: CalendarDayProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dayRender = useDayRender(date, displayMonth, buttonRef);
    const ariaDisabled =
        dayRender.activeModifiers.hasOwnProperty("ariaDisabled");
    if (dayRender.isHidden) {
        return <div role="gridcell"></div>;
    }
    if (!dayRender.isButton) {
        return <div {...dayRender.divProps} />;
    }
    return (
        <div role="gridcell">
            <Button
                {...otherProps}
                {...dayRender.buttonProps}
                name="day"
                ref={buttonRef}
                role="button"
                aria-selected={undefined}
                aria-disabled={ariaDisabled ? true : undefined}
                aria-pressed={dayRender.buttonProps["aria-selected"] || false}
                onClick={(evt) => {
                    if (!ariaDisabled) {
                        dayRender.buttonProps.onClick?.(evt);
                        otherProps.onClick?.(evt);
                    }
                }}
            />
        </div>
    );
};
