import { storyblokEditable } from "@storyblok/react";
import { gtmSendEvent } from "@wojo/analytics";
import { Container, Slider } from "@wojo/ui";
import React from "react";
import { getSpacingClassName } from "../../client/get-spacing-class-name";
import { BlogCard } from "../../molecules/blog-card";
import styles from "./BlogArticleSlider.module.scss";
import { BlogArticleSliderContentProps } from "./types";

export const BlogArticleSliderContent: React.FC<
    BlogArticleSliderContentProps
> = (props) => {
    const { layout, cardStyle, spaceAbove, spaceBelow, articles } = props;

    const desktopWidth = layout === "4" ? "25%" : "33.33%";

    return (
        <Container
            {...storyblokEditable(props)}
            className={getSpacingClassName({ spaceAbove, spaceBelow })}
        >
            <Slider
                desktopWidth={desktopWidth}
                tabletWidth="50%"
                mobileWidth="100%"
                slidesToScroll={1}
                gap="var(--g-spacing-xl)"
                onSliderButtonClick={(direction) =>
                    gtmSendEvent({
                        event: "track",
                        ga4_object: "BLOG_ARTICLE_SLIDER",
                        ga4_action: "SCROLLED",
                        direction,
                    })
                }
            >
                {articles.map((card) => {
                    return (
                        <div key={card.title} className={styles.slide}>
                            <BlogCard {...card} variant={cardStyle} />
                        </div>
                    );
                })}
            </Slider>
        </Container>
    );
};
