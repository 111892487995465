import cn from "clsx";
import spacingStyles from "../styles/spacing.module.scss";
import { Spacing } from "../types/spacing";

export type SpacingProps = {
    /**
     * Space (margin) above
     * @default Spacing.None
     */
    spaceAbove?: Spacing;
    /**
     * Space (margin) below
     * @default Spacing.None
     */
    spaceBelow?: Spacing;
    /**
     * Inner spacing top (padding)
     * @default Spacing.None
     */
    innerSpacingTop?: Spacing;
    /**
     * Inner spacing bottom (padding)
     * @default Spacing.None
     */
    innerSpacingBottom?: Spacing;
};

export function getSpacingClassName(props: Partial<SpacingProps>): string {
    const className = cn({
        [spacingStyles[`space-above--${props.spaceAbove}`]]: props.spaceAbove,
        [spacingStyles[`space-below--${props.spaceBelow}`]]: props.spaceBelow,
        [spacingStyles[`inner-spacing-top--${props.innerSpacingTop}`]]:
            props.innerSpacingTop,
        [spacingStyles[`inner-spacing-bottom--${props.innerSpacingBottom}`]]:
            props.innerSpacingBottom,
    });

    return className;
}
