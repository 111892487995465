import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchStoryblokData, WebConfigStory } from "@wojo/storyblok";
import { getStoryblokParams } from "./get-storyblok-params";

export const useWebConfigSuspenseQuery = (): WebConfigStory => {
    const { data: sbResponse } = useSuspenseQuery({
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["configurations/web-config"],
        queryFn: async () => {
            const { fullSlug, sbParams } = getStoryblokParams(
                "configurations/web-config/web-config",
                {},
                "wojo-web-config.footer,wojo-web-config.header,wojo-web-config.sliderMerchandisingCard",
            );
            return fetchStoryblokData(fullSlug, sbParams);
        },
    });

    return {
        ...sbResponse.data?.story?.content,
        footer: sbResponse.data?.story?.content.footer.content,
        header: sbResponse.data?.story?.content.header.content,
    };
};
