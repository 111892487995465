import { Grid } from "@wojo/ui";
import { useId } from "react";
import { BlogCard } from "../../molecules/blog-card";
import styles from "./BlogArticleGrid.module.scss";
import { BlogArticleGridContentProps } from "./types";

export const BlogArticleGridContent: React.FC<BlogArticleGridContentProps> = (
    props,
) => {
    const { articles, layout, cardStyle, cardPosition } = props;

    const wrapperId = useId();
    const blogArticlesWrapperId = useId();

    return (
        <div
            id={wrapperId}
            className={styles.wrapper}
            aria-label={"Articles"}
            tabIndex={-1}
            role="region"
        >
            <div id={blogArticlesWrapperId} role="group" tabIndex={-1}>
                <Grid
                    columns={parseInt(layout || "3")}
                    alignment={cardPosition || "left"}
                    className={styles["grid-wrapper"]}
                >
                    {articles.map((card, index) => (
                        <BlogCard
                            key={index}
                            {...card}
                            variant={cardStyle || "card"}
                        />
                    ))}
                </Grid>
            </div>
        </div>
    );
};
