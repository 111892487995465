import get from "lodash.get";
import React from "react";
import { useController } from "react-hook-form";
import {
    RadioButtonGroup,
    RadioButtonGroupProps,
} from "../../radio-button-group";
import { FormFieldProps } from "../form-field";

export type FormRadioGroupProps = FormFieldProps<RadioButtonGroupProps>;

export const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
    validation,
    name,
    onChange,
    ...radioProps
}) => {
    const {
        field,
        formState: { errors },
    } = useController({ name, rules: validation });

    const error = get(errors, name);
    const errorText = typeof error?.message === "string" ? error.message : "";
    const required = !!validation?.required;

    return (
        <RadioButtonGroup
            {...radioProps}
            {...field}
            errorText={errorText}
            required={required}
            onChange={(event) => {
                onChange?.(event);
                field.onChange(event);
            }}
            checkedValue={
                radioProps.items.find((item) => item.value === field.value)
                    ?.value
            }
            name={name}
            variant="row"
        />
    );
};
