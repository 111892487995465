import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { Container, Grid, GridAlignment } from "@wojo/ui";
import cn from "clsx";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { PageLink, PageLinkProps } from "../../molecules/page-link";
import styles from "./PageLinkGrid.module.scss";

export type PageLinkGridProps = {
    /**
     * Sets the position of page links within the grid
     * @default left
     */
    cardPosition?: GridAlignment;
    /**
     * Sets number of columns in the grid.
     * On Tablet and Mobile, the layout will automatically optimize for the smaller width.
     * @default 2
     */
    layout?: "2" | "3" | "4";
    /**
     * Page links to display in the grid
     */
    pageLinks: PageLinkProps[];
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const PageLinkGrid: React.FC<PageLinkGridProps> = (props) => {
    const { cardPosition, layout, pageLinks, spaceAbove, spaceBelow, ...rest } =
        props;
    const columns = parseInt(layout || "2");
    return (
        <Container>
            <Grid
                {...storyblokEditable(props)}
                columns={columns}
                alignment={cardPosition || "left"}
                className={cn(
                    getSpacingClassName({ spaceAbove, spaceBelow }),
                    styles["page-link-grid"],
                )}
                {...rest}
            >
                {pageLinks.map((pageLinkBlok: PageLinkProps) => (
                    <PageLink
                        key={pageLinkBlok._uid}
                        {...pageLinkBlok}
                        columns={columns}
                    />
                ))}
            </Grid>
        </Container>
    );
};
