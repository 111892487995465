"use client";

import { getTourPagesSearchQuery } from "@wojo/services";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useAlgoliaQuery } from "../../client/use-algolia-query";
import { useGlobalMerchandisingCard } from "../../client/use-global-merchandising-card";
import { useWishlist } from "../../client/use-wishlist";
import { TextCardProps } from "../../molecules/text-card";
import { TourCardSliderContent } from "./tour-card-slider-content/TourCardSliderContent";
import { TourCardSliderFallback } from "./TourCardSliderFallback";
import { TourCardSliderProps } from "./types";

const emptyMerchandisingCard: [TextCardProps] | [] = [];

const TourCardSliderLoader: React.FC<TourCardSliderProps> = ({
    _uid,
    algoliaRuleTriggerContext,
    numberOfTours,
    ...rest
}) => {
    const { getIsInWishlist, toggleWishlist } = useWishlist();

    const queryOptions = {
        page: 0,
        nbItems: numberOfTours?.value || 3,
        ruleContexts: algoliaRuleTriggerContext
            ? [algoliaRuleTriggerContext]
            : [],
        filters: {},
    };
    const { data: response } = useAlgoliaQuery<"tourPage">(
        getTourPagesSearchQuery,
        ["tour-slider", _uid],
        queryOptions,
    );

    // Global merchandising card values
    const {
        hideMerchandisingCard: hideGlobalMerchandisingCard,
        merchandisingCard: globalMerchandisingCard,
        merchandisingCardPlacement: globalMerchandisingCardPlacement,
    } = useGlobalMerchandisingCard();

    // Local merchandising card values
    const {
        hideMerchandisingCard: hideAllMerchandisingCards = false,
        merchandisingCard: localMerchandisingCard = emptyMerchandisingCard,
        merchandisingCardPlacement: localMerchandisingCardPlacement = "1",
    } = rest;

    let merchandisingCard: [TextCardProps] | [] = emptyMerchandisingCard;
    if (!hideAllMerchandisingCards && localMerchandisingCard.length) {
        merchandisingCard = localMerchandisingCard;
    } else if (!hideAllMerchandisingCards && !hideGlobalMerchandisingCard) {
        merchandisingCard = globalMerchandisingCard;
    }

    const merchandisingCardPlacement =
        localMerchandisingCardPlacement ||
        globalMerchandisingCardPlacement ||
        "1";

    return (
        <TourCardSliderContent
            {...rest}
            getIsInWishlist={getIsInWishlist}
            merchandisingCard={merchandisingCard}
            merchandisingCardPlacement={merchandisingCardPlacement}
            toggleWishlist={toggleWishlist}
            tours={response.hits}
        />
    );
};

export const TourCardSlider: React.FC<TourCardSliderProps> = (props) => {
    return (
        <ErrorBoundary fallback={<div>Error loading tours</div>}>
            <Suspense
                fallback={
                    <TourCardSliderFallback
                        numberOfResults={props.numberOfTours?.value || 3}
                    />
                }
            >
                <TourCardSliderLoader {...props} />
            </Suspense>
        </ErrorBoundary>
    );
};
