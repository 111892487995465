import { Button, Text } from "@wojo/ui";
import React from "react";
import styles from "./NoResultsBanner.module.scss";
import { NoIterpString, useI18n } from "@wojo/localization";
import { LuWind } from "react-icons/lu";

type NoResultsBannerProps = {
    hasFiltersApplied: boolean;
    noResults: {
        title: NoIterpString;
        buttonText: NoIterpString;
        buttonLink: NoIterpString;
    };
};

export const NoResultsBanner: React.FC<NoResultsBannerProps> = ({
    hasFiltersApplied,
    noResults,
}) => {
    const noResultsForFilters =
        useI18n("storyblok").storyblok.searchResults.noResultsForFilters;

    return (
        <div className={styles["no-results-banner"]}>
            <LuWind aria-hidden />
            <Text.Body emphasis="1">
                {hasFiltersApplied
                    ? noResultsForFilters.l()
                    : noResults.title.l()}
            </Text.Body>
            {!hasFiltersApplied && (
                <Button variant="quiet" tag="a" href={noResults.buttonLink.l()}>
                    {noResults.buttonText.l()}
                </Button>
            )}
        </div>
    );
};
