"use client";
import { useEffect, useState } from "react";
import { Maybe } from "../../generated/graphql";

export enum WebViewEvents {
    TOKENIZATION_SUCCESSFUL = "tokenizationSuccessful",
    TOKENIZATION_FAILED = "tokenizationFailed",
    CHANGE_TRAVELERS = "changeTravelers",
    TRAVEL_CHECKLIST = "travelChecklist",
    CHANGE_DEPARTURE_DATE = "changeDepartureDate",
    SUCCESSFUL_BOOKING = "successfulBooking",
    BOOK_INITIATED = "bookInitiated",
    BOOK_FAILED = "bookFailed",
}

declare var window: Window & {
    ReactNativeWebView:
        | {
              postMessage(msg: string): void;
          }
        | null
        | undefined;
};

export type TokenizationSuccessfulEvent = {
    type: WebViewEvents.TOKENIZATION_SUCCESSFUL;
    token: string;
};

export type TokenizationFailedEvent = {
    type: WebViewEvents.TOKENIZATION_FAILED;
    message: string;
};
export type ChangeTravelersEvent = {
    type: WebViewEvents.CHANGE_TRAVELERS;
};

export type TravelChecklistEvent = {
    type: WebViewEvents.TRAVEL_CHECKLIST;
    tripId: Maybe<string> | undefined;
};

export type ChangeDepartureDateEvent = {
    type: WebViewEvents.CHANGE_DEPARTURE_DATE;
};

export type SuccessfulBookingEvent = {
    type: WebViewEvents.SUCCESSFUL_BOOKING;
    tripId: Maybe<string> | undefined;
    orderId: Maybe<string> | undefined;
    departureId: Maybe<string> | undefined;
    tourYear: Maybe<number> | undefined;
    tourCode: Maybe<string> | undefined;
    numTravelers: Maybe<number> | undefined;
    orderPrice: Maybe<number> | undefined;
    orderDiscountAmount: Maybe<number> | undefined;
    hasTripProtection: Maybe<boolean> | undefined;
    hasExcursion: Maybe<boolean> | undefined;
    hasExtension: Maybe<boolean> | undefined;
    hasFlights: Maybe<boolean> | undefined;
};

export type BookInitiatedEvent = {
    type: WebViewEvents.BOOK_INITIATED;
};

export type BookFailedEvent = {
    type: WebViewEvents.BOOK_FAILED;
    message: string;
};

export type WebViewEvent =
    | TokenizationSuccessfulEvent
    | TokenizationFailedEvent
    | ChangeTravelersEvent
    | TravelChecklistEvent
    | ChangeDepartureDateEvent
    | SuccessfulBookingEvent
    | BookInitiatedEvent
    | BookFailedEvent;

/**
 * Determine if checkout is running within a React Native webview, and post messages via the React Native window object.
 * TODO: Replace the hook in the mobile-app app router directory with this hook - see https://github.com/eftours/wojo-web/pull/1224
 */
export const useReactNativeWebView = () => {
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(true);
    }, []);
    const isWebView =
        isClient &&
        typeof window !== "undefined" &&
        !!window.ReactNativeWebView;
    /**
     * When in a React Native Webview, we can post messages to the native app.
     * https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#postmessagestr
     */
    const postMessage = (event: WebViewEvent) => {
        const message = JSON.stringify(event);
        window.ReactNativeWebView?.postMessage(message);
    };
    // force checking if we're in a webview before using the postMessage function
    if (isWebView) {
        return { isWebView, postMessage };
    }
    return { isWebView };
};
