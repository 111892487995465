"use client";

import { useEffect } from "react";
import { storeWebConversionData } from "../web-conversion/store-web-conversion-data";
import { useAnalyticsCustomer } from "./use-analytics-customer";

export const useAppAnalytics = (): void => {
    useAnalyticsCustomer();
    useEffect(() => {
        storeWebConversionData(window.location.search);
    }, []);
};
