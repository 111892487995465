import cn from "clsx";
import styles from "./DotIndicator.module.scss";

export type DotIndicatorProps = React.InputHTMLAttributes<HTMLInputElement> & {
    /**
     * Pick which variant to use: standard, inverse
     * @default standard
     */
    variant?: "standard" | "inverse";
    /**
     * Is this dot selected
     */
    selected: boolean;
};

export const DotIndicator = ({
    variant = "standard",
    selected,
    className,
    ...rest
}: DotIndicatorProps) => {
    const disabled = (rest as JSX.IntrinsicElements["input"]).disabled ?? false;

    const buttonClassName = cn(
        styles.dot,
        { [styles["dot--inverse"]]: variant === "inverse" },
        `dot-indicator-${variant}-palette`,
        className,
    );

    return (
        <input
            {...rest}
            className={buttonClassName}
            disabled={disabled}
            aria-label={rest["aria-label"] ?? undefined}
            data-selected={selected}
            type="radio"
            aria-checked={selected}
            checked={selected}
            readOnly={true}
        />
    );
};
