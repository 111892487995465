import { Card } from "@wojo/ui";
import cn from "clsx";
import React from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { BorderRadius } from "../../types/border";
import styles from "./ContentCard.module.scss";

type ContentCardWrapperProps = {
    cardStyle: "flat" | "card";
    href?: string;
    isExternal?: boolean;
    titleId?: string;
    descriptionId?: string;
    buttonId?: string;
    children: React.ReactNode;
    borderRadius?: BorderRadius;
    contentXPos?: "left" | "center" | "right";
} & Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

export const ContentCardWrapper: React.FC<ContentCardWrapperProps> = (
    props,
) => {
    const {
        cardStyle,
        href,
        isExternal,
        titleId,
        descriptionId,
        buttonId,
        children,
        borderRadius,
        contentXPos,
        spaceAbove,
        spaceBelow,
    } = props;
    const [target, rel] = isExternal
        ? ["_blank", "noopener"]
        : [undefined, undefined];

    const getWrapperType = () => {
        if (cardStyle === "card") {
            return Card;
        } else {
            return href ? "a" : "div";
        }
    };

    const Wrapper = getWrapperType();

    return (
        <Wrapper
            aria-labelledby={titleId}
            aria-describedby={descriptionId ? descriptionId : buttonId}
            tag={href && cardStyle === "card" ? "a" : undefined}
            href={href || undefined}
            target={target}
            rel={rel}
            className={cn(
                styles.card,
                getSpacingClassName({ spaceAbove, spaceBelow }),
                {
                    [styles["card--border-radius"]]:
                        borderRadius !== BorderRadius.NoBorderRadius,
                    [styles["card--no-bottom-border-radius"]]:
                        cardStyle === "flat",
                    [styles["card--flat-link"]]: href && cardStyle === "flat",
                },
            )}
        >
            <div
                className={cn(styles["content-container"], {
                    [styles[`content-container--x-${contentXPos}`]]:
                        contentXPos,
                })}
            >
                {children}
            </div>
        </Wrapper>
    );
};
