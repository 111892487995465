import {
    autoUpdate,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
} from "@floating-ui/react";
import { createContext, useContext, useId, useState } from "react";

const PopoverToggleContext = createContext({
    openPopoverId: null as string | null,
    setOpenPopoverId: (() =>
        ({} as React.Dispatch<React.SetStateAction<string | null>>))(),
});

export const PopoverToggleProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
    return (
        <PopoverToggleContext.Provider
            value={{ openPopoverId, setOpenPopoverId }}
        >
            {children}
        </PopoverToggleContext.Provider>
    );
};

type UsePopoverToggleOptions = {
    id: string;
};

export const usePopoverToggle = ({ id }: UsePopoverToggleOptions) => {
    const { openPopoverId, setOpenPopoverId } =
        useContext(PopoverToggleContext);
    const contentId = useId();
    const isOpen = openPopoverId === id;

    const offsetValue =
        typeof window !== "undefined"
            ? parseInt(
                  window
                      .getComputedStyle(document.body)
                      .getPropertyValue("--g-spacing-sm"),
              ) || 0
            : 0;

    const { refs, floatingStyles, context } = useFloating({
        placement: "bottom-start",
        open: openPopoverId === id,
        onOpenChange: (open) => {
            setOpenPopoverId(!open && isOpen ? null : id);
        },
        middleware: [offset(offsetValue), shift()],
        whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context, { enabled: true }),
        useDismiss(context),
    ]);

    return {
        isOpen,
        contentProps: {
            ...getFloatingProps(),
            ref: refs.setFloating,
            style: floatingStyles,
            id: contentId,
        },
        triggerProps: {
            ...getReferenceProps(),
            ["aria-expanded"]: isOpen,
            ["aria-controls"]: contentId,
            ref: refs.setReference,
            onClick: () => {
                setOpenPopoverId(isOpen ? null : id);
            },
        },
    };
};
