import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import React from "react";
import styles from "./Page.module.scss";

export type PageProps = {
    seo?: SbBlokData[];
    body: SbBlokData[];
};

export const Page: React.FC<PageProps> = (props) => {
    return (
        <div {...storyblokEditable(props)} className={styles.main}>
            {props.seo?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
            {props.body.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </div>
    );
};
