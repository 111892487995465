import { storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { BusinessCode } from "@wojo/services/generated/graphql";
import { Container, Slider, SliderSlide } from "@wojo/ui";
import uniq from "lodash/uniq";
import React from "react";
import { getSpacingClassName } from "../../../client/get-spacing-class-name";
import { SchemaScript } from "../../../components/schema-script";
import { TourCard } from "../../../components/tour-card";
import { TextCard } from "../../../molecules/text-card";
import { TourCardSliderContentProps } from "../types";
import styles from "./TourCardSliderContent.module.scss";

export const TourCardSliderContent: React.FC<TourCardSliderContentProps> = (
    props,
) => {
    const {
        actionCard,
        getIsInWishlist,
        merchandisingCard,
        merchandisingCardPlacement,
        spaceAbove,
        spaceBelow,
        toggleWishlist,
        tours,
    } = props;
    const tourSlides = tours.map((tour, index) => {
        return (
            <SliderSlide
                data-ga4-object="TOUR_SLIDER"
                key={tour.objectID}
                tabOnToSlide={false}
            >
                <TourCard
                    {...tour}
                    key={tour.objectID}
                    isInWishlist={getIsInWishlist(tour.tourCode)}
                    toggleWishlist={() => {
                        !getIsInWishlist(tour.tourCode) &&
                            gtmSendEvent({
                                event: "add_to_wishlist",
                                ecommerce: {
                                    currency:
                                        process.env.NEXT_PUBLIC_CURRENCY_CODE,
                                    value: tour.price,
                                    items: [
                                        {
                                            item_id: tour.tourCode,
                                            item_name: tour.name,
                                            item_brand:
                                                process.env
                                                    .NEXT_PUBLIC_BUSINESS_CODE,
                                            item_category:
                                                tour.countries.join(","),
                                            item_category2:
                                                tour.cities.join(","),
                                            item_variant: "Tour",
                                            market: process.env
                                                .NEXT_PUBLIC_MARKET_CODE,
                                            tourCode: tour.tourCode,
                                            price: tour.salePrice || tour.price,
                                            quantity: 1,
                                            activityLevel:
                                                tour.activityLevel?.name,
                                            classLevel: tour.classLevel?.name,
                                            rating: tour.rating,
                                        },
                                    ],
                                },
                                ga4Tracking: {
                                    ga4_object: "TOUR",
                                    ga4_action: "ADD_TO_WISHLIST",
                                    tourCode: tour.tourCode,
                                    tourName: tour.name,
                                    parentObject: "TOUR_CARD",
                                },
                            });

                        toggleWishlist(tour.tourCode);
                    }}
                    onClick={() =>
                        gtmSendEvent({
                            event: "select_item",
                            ga4Tracking: {
                                ga4_object: "TOUR_CARD",
                                ga4_action: "CLICKED",
                                tourCode: tour.tourCode,
                                tourName: tour.name,
                                parentObject: "TOUR_SLIDER",
                                tourBadge: tour.tourBadge?.text ?? "",
                                isInWishlist: `${getIsInWishlist(
                                    tour.tourCode,
                                )}`,
                            },
                            ecommerce: {
                                item_list_name: "Tour Slider",
                                items: [
                                    {
                                        item_id: tour.tourCode,
                                        item_name: tour.name,
                                        highestDiscount: tour.highestDiscount,
                                        index,
                                        item_brand: process.env
                                            .NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
                                        item_category: uniq(
                                            tour.countries,
                                        ).join(","),
                                        item_category2: uniq(tour.cities).join(
                                            ",",
                                        ),
                                        item_variant: "Tour",
                                        item_list_name: "Tour Slider",
                                        market: process.env
                                            .NEXT_PUBLIC_MARKET_CODE,
                                        onSale: tour.isOnSale,
                                        tourCode: tour.tourCode,
                                        classLevel: tour.classLevel?.name,
                                        price: tour.price,
                                        salePrice: tour.salePrice,
                                        activityLevel: tour.activityLevel?.name,
                                        rating: tour.rating,
                                        isFeatured: tour.isFeatured,
                                    },
                                ],
                            },
                        })
                    }
                />
            </SliderSlide>
        );
    });

    const merchandisingCardIndex =
        parseInt(merchandisingCardPlacement || "1") - 1;
    const merchSlide =
        merchandisingCard?.map((card) => (
            <SliderSlide key={card._uid}>
                <div className={styles["merch-card-wrapper"]}>
                    <TextCard {...card} itemIndex={merchandisingCardIndex} />
                </div>
            </SliderSlide>
        )) || [];

    const actionSlide =
        actionCard?.map((card) => (
            <SliderSlide key={card._uid}>
                <div className={styles["merch-card-wrapper"]}>
                    <TextCard
                        {...card}
                        itemIndex={merchSlide.length + tourSlides.length + 1}
                    />
                </div>
            </SliderSlide>
        )) || [];

    const allSlides = [
        ...tourSlides.slice(0, merchandisingCardIndex),
        ...merchSlide,
        ...tourSlides.slice(merchandisingCardIndex),
        ...actionSlide,
    ];

    return (
        <Container
            {...storyblokEditable(props)}
            className={getSpacingClassName({ spaceAbove, spaceBelow })}
        >
            <SchemaScript
                data={{
                    "@context": "https://schema.org",
                    "@type": "ItemList",
                    itemListElement: tours.map((tour, index) => ({
                        "@type": "ListItem",
                        position: index + 1,
                        item: {
                            "@type": "Product",
                            name: tour.name,
                            url: process.env.NEXT_PUBLIC_DOMAIN + tour.url,
                            image: tour.cardImage?.filename,
                            description: tour.overviewDescription,
                            sku: tour.tourCode,
                            brand: {
                                "@type": "Brand",
                                name: process.env.NEXT_PUBLIC_BUSINESS_NAME,
                            },
                            ...((tour.salePrice || tour.price) && {
                                offers: {
                                    "@type": "Offer",
                                    priceCurrency:
                                        process.env.NEXT_PUBLIC_CURRENCY_CODE,
                                    price: tour.salePrice || tour.price,
                                    url:
                                        process.env.NEXT_PUBLIC_DOMAIN +
                                        tour.url,
                                    itemCondition:
                                        "https://schema.org/NewCondition",
                                    availability: "http://schema.org/InStock",
                                },
                            }),
                            ...(tour.nbRatings > 0 && {
                                aggregateRating: {
                                    "@type": "AggregateRating",
                                    ratingValue: tour.rating,
                                    reviewCount: tour.nbRatings,
                                },
                            }),
                        },
                    })),
                }}
            />
            <Slider
                accessibleLabel="Tours"
                autoWrapSlides={false}
                slidesToScroll="auto"
                desktopWidth="max-content"
                mobileWidth="max-content"
                tabletWidth="max-content"
                onSliderButtonClick={(direction) =>
                    gtmSendEvent({
                        event: "track",
                        ga4_object: "TOUR_CARD_SLIDER",
                        ga4_action: "SCROLLED",
                        direction,
                    })
                }
            >
                {allSlides.map((slide, index) => {
                    return React.cloneElement(slide, {
                        key: slide.key || index,
                        accessibleLabel: `Slide ${index + 1} of ${
                            allSlides.length
                        }`,
                    });
                })}
            </Slider>
        </Container>
    );
};
