import { fetchStoryblokData, WebConfigStory } from "@wojo/storyblok";
import { getStoryblokParams } from "./get-storyblok-params";

export const getWebConfigData = async function fetchData(searchParams?: {
    [key: string]: string | string[] | undefined;
}) {
    const { fullSlug, sbParams } = getStoryblokParams(
        "configurations/web-config/web-config",
        searchParams,
        "wojo-web-config.footer,wojo-web-config.header",
    );

    const result = (await fetchStoryblokData(fullSlug, sbParams)).data?.story
        ?.content;

    return result
        ? ({
              ...result,
              footer: result.footer.content,
              header: result.header.content,
          } as WebConfigStory)
        : null;
};
