import { useMediaQuery } from "@mantine/hooks";
import { useI18n } from "@wojo/localization";
import {
    Container,
    Modal,
    ModalBody,
    Rating,
    Text,
    transformAsset,
} from "@wojo/ui";
import cn from "clsx";
import dayjs from "dayjs";
import ordinal from "ordinal";
import React, { forwardRef, useEffect, useRef } from "react";
import { ReviewsAggregateQuery } from "../../../../generated/graphql";
import styles from "./ReviewModal.module.scss";

type Review = ReviewsAggregateQuery["reviews"]["results"][number];

export type ReviewModalProps = {
    isOpen: boolean;
    onCloseRequested: () => void;
    review: Review;
    selectedImageIndex: number;
};

const Images = forwardRef<HTMLDivElement, { review: Review }>(
    ({ review }, ref) => {
        return (
            <div className={styles["image-container"]} ref={ref}>
                {review.images.map((image, index) => (
                    <div className={styles.image} key={image.asset?._id}>
                        <img
                            src={transformAsset(image.asset?.location ?? null, {
                                size: {
                                    width: 1200,
                                },
                            })}
                            alt={"Traveler photo " + (index + 1)}
                        />
                    </div>
                ))}
            </div>
        );
    },
);
Images.displayName = "Images";

export const ReviewModal: React.FC<ReviewModalProps> = ({
    isOpen,
    onCloseRequested,
    review,
    selectedImageIndex,
}) => {
    const i18n = useI18n("storyblok").storyblok.reviewModal;
    const containerRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (
            containerRef.current &&
            selectedImageIndex >= 0 &&
            selectedImageIndex < (review.images.length || 0)
        ) {
            const divElement = containerRef.current.children[
                selectedImageIndex
            ] as HTMLDivElement;
            const imageElement = divElement.querySelector(
                "img",
            ) as HTMLImageElement;
            const scrollToImage = () => {
                const imagePosition = imageElement.offsetTop;
                if (containerRef.current) {
                    containerRef.current.scrollTo({
                        top: imagePosition,
                        behavior: "smooth",
                    });
                }
            };
            if (imageElement.complete) {
                scrollToImage();
            } else {
                imageElement.addEventListener("load", scrollToImage);
            }
            return () => {
                imageElement.removeEventListener("load", scrollToImage);
            };
        }
    }, [isOpen, review.images.length, selectedImageIndex]);
    const travelDate = dayjs(review.travelDate).format("MMM YYYY");
    const photoText =
        review.images.length > 1
            ? i18n.photosLabel.l({
                  count: review.images.length.toString(),
              })
            : i18n.photoLabel.l();
    const breakpointMobile = "(max-width: 767px)";
    const isMobile = useMediaQuery(breakpointMobile, false);
    return (
        <Modal
            isOpen={isOpen}
            onCloseRequested={onCloseRequested}
            accessibleTitle={review.detail?.title || "Review"}
            variant="fullscreen"
            contentClassName={styles.modal}
        >
            <ModalBody className={styles["modal-body"]}>
                <Container width="xlarge">
                    <Text.Body
                        emphasis="1"
                        variant="1"
                        className={styles["image-count"]}
                    >
                        {photoText}
                    </Text.Body>
                    <div
                        className={cn({
                            [styles["split-content"]]: review.images.length,
                        })}
                    >
                        {!isMobile && (
                            <Images ref={containerRef} review={review} />
                        )}

                        <div className={styles["content-container"]}>
                            {review.detail?.title && (
                                <Text.Heading
                                    order="5"
                                    tag="h1"
                                    className={styles["detail-title"]}
                                >
                                    {review.detail.title}
                                </Text.Heading>
                            )}
                            <Rating
                                value={review.rating}
                                variant="standard"
                                size="small"
                                className={styles.rating}
                            />

                            <Text.Body
                                variant="2"
                                className={styles["author-and-travel-date"]}
                            >
                                {review.nbPastTrips
                                    ? ordinal(review.nbPastTrips) +
                                      "-time traveler "
                                    : ""}
                                {review.detail?.author || ""}, traveled in{" "}
                                {travelDate}
                            </Text.Body>
                            <Text.Body className={styles.description}>
                                {review.detail?.body}
                            </Text.Body>
                        </div>
                        {isMobile && (
                            <Images ref={containerRef} review={review} />
                        )}
                    </div>
                </Container>
            </ModalBody>
        </Modal>
    );
};
