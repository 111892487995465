import { ImageRatio } from "../types/image";

export const imageAspectRatioValueForCSSMap = {
    [ImageRatio.Circle1By1]: "1/1",
    [ImageRatio.Horizontal16By9]: "16/9",
    [ImageRatio.Horizontal2By1]: "2/1",
    [ImageRatio.Horizontal4By3]: "4/3",
    [ImageRatio.Original]: "original",
    [ImageRatio.Square1By1]: "1/1",
    [ImageRatio.Vertical3By4]: "3/4",
};

export const imageAspectRatioValueMap = {
    [ImageRatio.Circle1By1]: 1,
    [ImageRatio.Horizontal16By9]: 16 / 9,
    [ImageRatio.Horizontal2By1]: 2,
    [ImageRatio.Horizontal4By3]: 4 / 3,
    [ImageRatio.Original]: null,
    [ImageRatio.Square1By1]: 1,
    [ImageRatio.Vertical3By4]: 0.75,
};
