import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { Container, Grid, GridAlignment } from "@wojo/ui";
import { ImageAtomProps } from "../../atoms/image";
import { VideoAtomProps } from "../../atoms/video";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import styles from "./MediaGrid.module.scss";

export type MediaGridProps = {
    /**
     * Sets the position of media items within the grid
     * @default left
     */
    mediaPosition?: GridAlignment;
    /**
     * Sets number of columns in the grid.
     * On Tablet and Mobile, the layout will automatically optimize for the smaller width.
     * @default 1
     */
    layout?: "1" | "2" | "3" | "4";
    /**
     * Media items to display. Either ImageAtom or VideoAtom
     */
    media: (ImageAtomProps | VideoAtomProps)[];
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const MediaGrid: React.FC<MediaGridProps> = (props) => {
    const { mediaPosition, layout, media, spaceAbove, spaceBelow } = props;
    return (
        <Container>
            <Grid
                {...storyblokEditable(props)}
                columns={parseInt(layout || "1")}
                alignment={mediaPosition || "left"}
                className={getSpacingClassName({ spaceAbove, spaceBelow })}
            >
                {media.map((mediaBlok: SbBlokData) => (
                    <div className={styles["media-item"]} key={mediaBlok._uid}>
                        <StoryblokComponent blok={mediaBlok} />
                    </div>
                ))}
            </Grid>
        </Container>
    );
};
