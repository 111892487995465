import cn from "clsx";
import React from "react";
import styles from "./SliderSlide.module.scss";

export type SliderSlideProps = React.PropsWithChildren<{
    accessibleLabel?: string;
    tabOnToSlide?: boolean;
}> &
    React.HTMLAttributes<HTMLDivElement>;

export const SliderSlide: React.FC<SliderSlideProps> = ({
    accessibleLabel,
    children,
    className,
    tabOnToSlide = true,
    ...rest
}) => {
    return (
        <div
            {...rest}
            aria-label={accessibleLabel}
            aria-roledescription="slide"
            role="group"
            tabIndex={tabOnToSlide ? 0 : undefined}
            className={cn(styles.slide, className)}
        >
            {children}
        </div>
    );
};
