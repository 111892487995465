"use client";
import { useSuspenseQuery } from "@apollo/client";
import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { useI18n } from "@wojo/localization";
import { Button, Container, Rating, Text } from "@wojo/ui";
import cn from "clsx";
import React, { Suspense, useId, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import {
    BusinessCode,
    ReviewsAggregateDocument,
    ReviewsAggregateQuery,
    ReviewsAggregateQueryVariables,
    ReviewSortField,
    ReviewStatus,
} from "../../../generated/graphql";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { ContentPosition } from "../../types/position";
import { StoryblokTags } from "../../types/storyblok/storyblok-tags";
import { ReviewItem } from "./review-item/ReviewItem";
import styles from "./ReviewsList.module.scss";
import { ReviewsListFallback } from "./ReviewsListFallback";
import { ReviewsSort, reviewsSortModes } from "./ReviewsSort";

export type ReviewsListProps = {
    /**
     * Alignment of the list in its container
     */
    listPosition?: ContentPosition;
    /**
     * Number of reviews to display
     * @default 8
     */
    numberOfReviews?: string;
    /**
     * Show the tour name with a link to its itinerary page
     * @default with-tour-name
     */
    reviewStyle?: "with-tour-name" | "without-tour-name";
    /**
     * Tour code(s) to filter reviews by
     */
    tourCode?: StoryblokTags;
    /**
     * callback function when the sort mode is changed
     */
    onSortChange?: (sort: string) => void;
    /**
     * callback function when the pagination is clicked
     */
    onPagination?: () => void;
    /**
     * callback function when the sort modal is opened
     */
    onSortModalOpened?: () => void;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const mapSortModeToSortField = (
    sortMode: string,
): { sortBy: ReviewSortField; sortAscending: boolean } => {
    switch (sortMode) {
        case "FEATURED":
            return { sortBy: ReviewSortField.IsFeatured, sortAscending: false };
        case "HIGHEST_RATED":
            return { sortBy: ReviewSortField.Rating, sortAscending: false };
        case "LOWEST_RATED":
            return { sortBy: ReviewSortField.Rating, sortAscending: true };
        case "MOST_RECENT":
            return { sortBy: ReviewSortField.TravelDate, sortAscending: false };
        default:
            return { sortBy: ReviewSortField.IsFeatured, sortAscending: false };
    }
};

export const ReviewsListContent: React.FC<ReviewsListProps> = (props) => {
    const {
        tourCode,
        numberOfReviews,
        reviewStyle,
        onSortModalOpened,
        onSortChange,
        onPagination,
    } = props;

    const i18n = useI18n("storyblok").storyblok.reviewsList;
    const [currentPage, setCurrentPage] = useState(1);
    const reviewsListRef = useRef<HTMLDivElement | null>(null);
    const [sortedBy, setSortedBy] = useState(reviewsSortModes[0].value);
    const reviewListLabelId = useId();
    const skipToStartOfListLinkId = useId();
    const skipToSelectorLinkId = useId();
    const limit = numberOfReviews ? parseInt(numberOfReviews) : 6;
    const { sortBy, sortAscending } = mapSortModeToSortField(sortedBy);
    const defaultVariables = {
        businessCode: process.env.NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
        tourCodes: tourCode?.value || [],
        language: process.env.NEXT_PUBLIC_STORYBLOK_LANGUAGE ?? "",
        filter: {
            businessCode: process.env.NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
            tourCodes: tourCode?.value,
            status: ReviewStatus.Approved,
            hasDetail: true,
        },
        sort: {
            sortAscending: sortAscending,
            sortBy: sortBy,
        },
        pagination: {
            limit: limit,
        },
        status: ReviewStatus.Approved,
    };
    const { data, refetch } = useSuspenseQuery<
        ReviewsAggregateQuery,
        ReviewsAggregateQueryVariables
    >(ReviewsAggregateDocument, {
        variables: {
            ...defaultVariables,
        },
        context: {
            fetchOptions: { next: { revalidate: 900 } },
            includeCookie: false,
        },
    });
    const start = (currentPage - 1) * limit + 1;
    const end = currentPage * limit;
    const results = data.reviews.results;

    if (!results.length) {
        return <Text.Body>There are no reviews for this trip yet.</Text.Body>;
    }

    const { hasNext, hasPrevious, next, previous } = data.reviews;
    const nbReviews = data.reviewAggregate?.nbReviews;
    const avgRating = data.reviewAggregate?.avgRating;
    const showPagination = hasNext || hasPrevious;
    const showRating = !!avgRating || avgRating === 0;
    return (
        <>
            <div className={styles["reviews-summary"]} ref={reviewsListRef}>
                {showRating && (
                    <div className={styles["average-rating"]}>
                        <span aria-hidden>{avgRating}</span>{" "}
                        <Rating value={avgRating} size="medium" />
                    </div>
                )}
                {nbReviews && (
                    <Text.Body>
                        Overall rating based on {nbReviews} traveler reviews
                    </Text.Body>
                )}
            </div>
            <div aria-labelledby={reviewListLabelId}>
                <div className={styles["pagination-header"]}>
                    {nbReviews && (
                        <Text.Body id={reviewListLabelId}>
                            Showing {start}-{end > nbReviews ? nbReviews : end}{" "}
                            of {nbReviews} traveler reviews
                        </Text.Body>
                    )}
                    <ReviewsSort
                        selectedSort={sortedBy}
                        onSortModalOpened={onSortModalOpened}
                        onSortChange={(sort) => {
                            onSortChange?.(sort);
                            setSortedBy(sort);
                        }}
                    />
                </div>
                {showPagination && (
                    <Button
                        tag="a"
                        href={`#${skipToSelectorLinkId}`}
                        id={skipToStartOfListLinkId}
                        variant="quiet"
                        className={cn(
                            styles.skip,
                            styles["skip--visually-hidden"],
                        )}
                    >
                        {i18n.skipToSelectorLabel.l()}
                    </Button>
                )}
                {results.map((review) => (
                    <ReviewItem
                        key={review._id}
                        review={review}
                        reviewStyle={reviewStyle || "without-tour-name"}
                    />
                ))}
                {showPagination && (
                    <div className={styles.pagination}>
                        <div>
                            {nbReviews && (
                                <Text.Body>
                                    Showing {start}-
                                    {end > nbReviews ? nbReviews : end} of{" "}
                                    {nbReviews} traveler reviews
                                </Text.Body>
                            )}
                        </div>
                        <Button
                            tag="a"
                            href={`#${skipToStartOfListLinkId}`}
                            id={skipToSelectorLinkId}
                            variant="quiet"
                            className={cn(
                                styles.skip,
                                styles["skip--visually-hidden"],
                            )}
                        >
                            {i18n.skipToSelectorLabel.l()}
                        </Button>

                        <div className={styles.buttons}>
                            {/*TODO: Post MVP switch to Wojo/Pagination once api supports pagination*/}
                            <Button
                                disabled={!hasPrevious}
                                variant="quiet"
                                type="button"
                                adornLeft={<FaArrowLeft />}
                                onClick={async () => {
                                    onPagination?.();
                                    reviewsListRef.current?.scrollIntoView();
                                    await refetch({
                                        ...defaultVariables,
                                        pagination: {
                                            ...defaultVariables.pagination,
                                            previous: previous,
                                        },
                                    });
                                    setCurrentPage(currentPage - 1);
                                }}
                            >
                                Prev
                            </Button>
                            <Button
                                disabled={!hasNext}
                                variant="quiet"
                                type="button"
                                adornRight={<FaArrowRight />}
                                onClick={async () => {
                                    onPagination?.();
                                    reviewsListRef.current?.scrollIntoView();
                                    await refetch({
                                        ...defaultVariables,
                                        pagination: {
                                            ...defaultVariables.pagination,
                                            next: next,
                                        },
                                    });
                                    setCurrentPage(currentPage + 1);
                                }}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export const ReviewsList: React.FC<ReviewsListProps> = (props) => {
    const { listPosition, spaceAbove, spaceBelow } = props;

    const fallback = (
        <ReviewsListFallback
            numberOfResults={
                props.numberOfReviews ? parseInt(props.numberOfReviews) : 6
            }
        />
    );

    return (
        <Container
            {...storyblokEditable(props)}
            aria-label="Traveler reviews"
            role="region"
            position={listPosition}
            width="medium"
            className={cn(
                styles["reviews-slider"],
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
        >
            <ErrorBoundary fallback={<>Failed to load reviews</>}>
                <Suspense fallback={fallback}>
                    <ReviewsListContent {...props} />
                </Suspense>
            </ErrorBoundary>
        </Container>
    );
};
