import { getBlogPostsSearchQuery } from "@wojo/services";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useAlgoliaQuery } from "../../client/use-algolia-query";
import { BlogArticleSliderContent } from "./BlogArticleSliderContent";
import { BlogArticleSliderFallback } from "./BlogArticleSliderFallback";
import { BlogArticleSliderProps } from "./types";

const BlogArticleSliderLoader: React.FC<BlogArticleSliderProps> = (props) => {
    const { _uid, numberOfArticles, algoliaRuleTriggerContext } = props;

    const queryOptions = {
        page: 0,
        nbItems: numberOfArticles.value || 3,
        ruleContexts: algoliaRuleTriggerContext
            ? [algoliaRuleTriggerContext]
            : [],
        filters: {},
    };
    const { data: response } = useAlgoliaQuery<"blogPost">(
        getBlogPostsSearchQuery,
        ["blog-slider", _uid],
        queryOptions,
    );

    return <BlogArticleSliderContent {...props} articles={response.hits} />;
};

export const BlogArticleSlider: React.FC<BlogArticleSliderProps> = (props) => {
    return (
        //TODO: localize https://ef-wojo.atlassian.net/browse/WWB-379
        <ErrorBoundary fallback={<div>Error loading blog articles</div>}>
            <Suspense
                fallback={
                    <BlogArticleSliderFallback
                        layout={parseInt(props.layout || "3")}
                    />
                }
            >
                <BlogArticleSliderLoader {...props} />
            </Suspense>
        </ErrorBoundary>
    );
};
