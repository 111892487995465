"use client";

import { useFullscreen } from "@mantine/hooks";
import { SbBlokData, storyblokEditable } from "@storyblok/react";
import {
    LoadingSkeleton,
    Text,
    VideoPlayer,
    VideoPlayerProps,
    WistiaVideoState,
} from "@wojo/ui";
import cn from "clsx";
import React, { CSSProperties, Suspense, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Opacity } from "../../types/opacity";
import { StoryblokSlider } from "../../types/storyblok/storyblok-slider";
import styles from "./VerticalVideo.module.scss";
import { useI18n } from "@wojo/localization";
import { useVideoMetadata } from "@wojo/services";

export enum TextAndOverlayStyle {
    lightTextOverDarkOverlay = "lightTextOverDarkOverlay",
    darkTextOverLightOverlay = "darkTextOverLightOverlay",
}

export type VerticalVideoProps = {
    /**
     * Id for the video as defined in wistia
     */
    wistiaVideoId: string;
    /**
     * When enabled, the video will repeat infinitely unless manually paused by user
     */
    loopVideo?: boolean;
    /**
     * Heading positioned on the video overlay
     */
    heading?: string;
    /**
     * Opacity of the video overlay
     * @default Opacity.Percent40
     */
    overlayOpacity?: StoryblokSlider<Opacity>;
    /**
     * Contrast between text and overlay
     * @default TextAndOverlayStyle.lightTextOverDarkOverlay
     */
    textAndOverlayStyle?: TextAndOverlayStyle;
    /**
     * Request autoplay for the video
     */
    requestAutoplay?: boolean;
    /**
     * Callback when the video playback state changes
     */
    onPlaybackChange?: VideoPlayerProps["onPlaybackChange"];
    onPlaybackReady?: VideoPlayerProps["onPlaybackReady"];
} & SbBlokData;

const VerticalVideoInternal: React.FC<VerticalVideoProps> = (props) => {
    const {
        wistiaVideoId,
        loopVideo,
        heading,
        textAndOverlayStyle,
        overlayOpacity,
        requestAutoplay,
        onPlaybackChange,
        onPlaybackReady,
    } = props;

    const [videoState, setVideoState] = useState<WistiaVideoState>();
    const { fullscreen, ref } = useFullscreen();
    const textAndOverlayStyleMap = {
        [TextAndOverlayStyle.lightTextOverDarkOverlay]:
            "light-text-over-dark-overlay",
        [TextAndOverlayStyle.darkTextOverLightOverlay]:
            "dark-text-over-light-overlay",
    };
    const { data: videoMetadata } = useVideoMetadata(wistiaVideoId);

    return (
        <div
            {...storyblokEditable(props)}
            ref={ref}
            className={cn(
                styles.wrapper,
                styles[
                    `wrapper--${
                        textAndOverlayStyleMap[
                            textAndOverlayStyle ??
                                TextAndOverlayStyle.lightTextOverDarkOverlay
                        ]
                    }`
                ],
                {
                    [styles["wrapper--fullscreen"]]: fullscreen,
                },
            )}
            style={
                {
                    ["--overlay-opacity"]:
                        overlayOpacity?.value ?? Opacity.Percent40,
                } as CSSProperties
            }
        >
            {heading && (
                <Text.Heading className={styles.heading} tag="p" order="6">
                    {heading}
                </Text.Heading>
            )}

            <VideoPlayer
                videoId={wistiaVideoId}
                className={cn(styles["video-player"], {
                    [styles["video-player--playing"]]:
                        videoState === WistiaVideoState.Playing,
                })}
                requestAutoplay={requestAutoplay}
                fallbackImageUrl={videoMetadata?.url}
                fallbackImageAlt={heading || videoMetadata?.videoTitle}
                loopVideo={loopVideo}
                playerColorToken="--g-color-ink-black"
                utilityButtonPosition="hidden"
                onPlaybackChange={(videoState, event) => {
                    setVideoState(videoState);
                    onPlaybackChange?.(videoState, event);
                }}
                onPlaybackReady={onPlaybackReady}
            />
        </div>
    );
};

export const VerticalVideo: React.FC<VerticalVideoProps> = (props) => {
    const i18n = useI18n("storyblok").storyblok;
    return (
        <ErrorBoundary
            fallback={
                <Text.Body>
                    {i18n.verticalVideo.failedToLoadVideo.l()}
                </Text.Body>
            }
        >
            <Suspense
                fallback={
                    <LoadingSkeleton
                        accessibleText={`${
                            props.heading ?? i18n.verticalVideo.loadingVideo.l()
                        }`}
                        width="100%"
                        height="100%"
                    />
                }
            >
                <VerticalVideoInternal {...props} />
            </Suspense>
        </ErrorBoundary>
    );
};
