import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { Container } from "@wojo/ui";
import cn from "clsx";
import { CSSProperties } from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import {
    GraphicListItem,
    GraphicListItemProps,
} from "../../molecules/graphic-list-item";
import styles from "./GraphicList.module.scss";

export type GraphicListProps = {
    /**
     * Graphic List Item Bloks : GraphicGridItemProps.
     */
    listItems?: GraphicListItemProps[];
    /**
     * Number of columns of the graphic list.
     * @default 1
     */
    layout?: "1" | "2";
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const GraphicList: React.FC<GraphicListProps> = (props) => {
    const { listItems, layout, spaceAbove, spaceBelow } = props;

    const gridRows = listItems?.length ? Math.ceil(listItems.length / 2) : 1;

    return (
        <Container width={layout == "2" ? "xlarge" : "medium"}>
            <div
                {...storyblokEditable(props)}
                className={cn(
                    styles["graphic-list"],
                    { [styles["graphic-list-two-across"]]: layout === "2" },
                    getSpacingClassName({ spaceAbove, spaceBelow }),
                )}
                style={
                    {
                        "--row-count": gridRows,
                    } as CSSProperties
                }
            >
                {listItems?.map((block, index) => (
                    <GraphicListItem
                        key={block._uid}
                        {...block}
                        itemIndex={index}
                    />
                ))}
            </div>
        </Container>
    );
};
