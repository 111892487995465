import { makeVar, useReactiveVar } from "@apollo/client";
/**
 * Reactive variable to track currently executing mutations by mutation name.
 */
export const executingMutations = makeVar(new Array<string>());
/**
 * @param mutationNames - Mutation names to check if any of them are currently executing. If not provided, checks if any mutation is executing.
 */
export const useIsExecutingMutation = (mutationNames?: string[]) => {
    const mutations = useReactiveVar(executingMutations);

    return mutationNames?.length
        ? mutations.some((item) => mutationNames.indexOf(item) !== -1)
        : mutations.length > 0;
};
