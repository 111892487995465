import { Text } from "@wojo/ui";
import { TitleHeadingSize, TitleHeadingTag } from "../../types/title";

export type TitleHeadingProps = {
    size: TitleHeadingSize;
    tag: TitleHeadingTag;
    color?: string;
    content?: string;
    id?: string;
};

export const TitleHeading: React.FC<TitleHeadingProps> = ({
    size,
    tag,
    color,
    content,
    id,
}) => {
    const colorStyle = color ? { color } : undefined;
    switch (size) {
        case "display-h1":
        case "display-h2":
            return (
                <Text.DisplayHeading
                    order={size.slice(-1) as any}
                    tag={tag}
                    style={colorStyle}
                    id={id}
                >
                    {content}
                </Text.DisplayHeading>
            );
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
            return (
                <Text.Heading
                    order={size.replace("h", "") as any}
                    tag={tag}
                    style={colorStyle}
                    id={id}
                >
                    {content}
                </Text.Heading>
            );
        default:
            return null;
    }
};
