import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { Button } from "@wojo/ui";
import cn from "clsx";
import React, { useId } from "react";
import {
    ImageGraphicAtom,
    ImageGraphicAtomProps,
} from "../../atoms/image-graphic";
import { TextAtomProps } from "../../atoms/text";
import { TitleAtomProps } from "../../atoms/title";
import { SpacingProps } from "../../client/get-spacing-class-name";
import { Border, BorderProps } from "../../components/border";
import { BorderRadius } from "../../types/border";
import { StoryblokLink } from "../../types/storyblok/storyblok-link";
import styles from "./TextBanner.module.scss";
import { TextBannerWrapper } from "./TextBannerWrapper";

export type TextBannerProps = {
    /**
     * Sets the spacing of content elements within the background
     * @default standard
     */
    layout?: "standard" | "compact";
    /**
     * The style of the banner
     * @default backgroundColor
     */
    bannerStyle?: "backgroundColor" | "card";
    /**
     * Image graphic to display
     */
    imageGraphic?: ImageGraphicAtomProps[];
    /**
     * The text to display: TitleAtomProps | TextAtomProps
     */
    text: TitleAtomProps[] | TextAtomProps[];
    /**
     * Text to display on the button
     */
    buttonText?: string;
    /**
     * Design variant of the button
     * @default primary
     */
    buttonVariant?: "primary" | "secondary" | "quiet" | "inverse";
    /**
     * The link to navigate to when the button is clicked
     */
    buttonLink?: StoryblokLink;
    /**
     * Overrides the default color of the button variant with a brand color palette.
     */
    buttonColorPaletteOverride?: string;
    /**
     * Only visible when Background Color style is selected
     */
    backgroundColor?: string;
    /**
     * Start color of the background gradient, overrides `backgroundColor` when present with `backgroundEndColor`
     */
    backgroundStartColor?: string;
    /**
     * End color of the background gradient, overrides `backgroundColor` when present with `backgroundStartColor`
     */
    backgroundEndColor?: string;
    /**
     * Angle of the background gradient
     */
    gradientAngle?: string;
} & SbBlokData &
    Omit<BorderProps, "children"> &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

export const TextBanner: React.FC<TextBannerProps> = (props) => {
    const {
        backgroundColor,
        backgroundEndColor,
        backgroundStartColor,
        bannerStyle,
        borderColor,
        borderEndColor,
        borderGradientAngle,
        borderImage,
        borderPosition,
        borderRadius,
        borderStartColor,
        buttonColorPaletteOverride,
        buttonLink,
        buttonText,
        buttonVariant,
        imageGraphic,
        layout,
        text,
        borderSize,
        spaceAbove,
        spaceBelow,
        gradientAngle,
    } = props;

    const titleId = useId();
    const descriptionId = useId();
    const buttonId = useId();
    const showBackgroundGradient =
        bannerStyle !== "card" && backgroundEndColor && backgroundStartColor;
    const showBackgroundColor =
        bannerStyle !== "card" && backgroundColor && !showBackgroundGradient;

    return (
        <TextBannerWrapper
            {...storyblokEditable(props)}
            backgroundColor={backgroundColor}
            backgroundEndColor={backgroundEndColor}
            backgroundStartColor={backgroundStartColor}
            bannerStyle={bannerStyle || "backgroundColor"}
            borderRadius={borderRadius}
            buttonLink={buttonLink}
            gradientAngle={gradientAngle}
            spaceAbove={spaceAbove}
            spaceBelow={spaceBelow}
            text={text}
            titleId={titleId}
            buttonText={buttonText}
            descriptionId={text[0]?.description ? descriptionId : undefined}
        >
            <Border
                borderColor={borderColor}
                borderStartColor={borderStartColor}
                borderEndColor={borderEndColor}
                borderGradientAngle={borderGradientAngle}
                borderImage={borderImage}
                borderPosition={borderPosition}
                borderRadius={borderRadius || BorderRadius.ThemeDefault}
                borderSize={borderSize}
                className={cn(
                    styles["border-container"],
                    styles[`border-container--${layout || "standard"}`],
                )}
            >
                <section
                    className={cn(
                        styles["text-banner-container"],
                        styles[
                            `text-banner-container--${layout || "standard"}`
                        ],
                        {
                            [styles[
                                "text-banner-container-background-gradient"
                            ]]: showBackgroundGradient,
                            [styles["text-banner-container-background-color"]]:
                                showBackgroundColor,
                        },
                    )}
                >
                    {imageGraphic?.length ? (
                        <ImageGraphicAtom
                            className={cn(styles["image-graphic"])}
                            {...imageGraphic[0]}
                            key={imageGraphic[0]._uid}
                        />
                    ) : null}
                    <div
                        className={cn(
                            styles["text-banner-content"],
                            styles[
                                `text-banner-content--${layout || "standard"}`
                            ],
                        )}
                    >
                        {text.length ? (
                            <div className={styles["text"]}>
                                <StoryblokComponent
                                    key={text[0]._uid}
                                    blok={{
                                        ...text[0],
                                        titleId: text[0]?.heading
                                            ? titleId
                                            : undefined,
                                        descriptionId: text[0]?.description
                                            ? descriptionId
                                            : undefined,
                                    }}
                                />
                            </div>
                        ) : null}
                        {buttonText &&
                            (buttonLink?.url || buttonLink?.cached_url) && (
                                <Button
                                    tag="span"
                                    id={buttonId}
                                    className={styles.cta}
                                    variant={buttonVariant || "primary"}
                                    palette={buttonColorPaletteOverride}
                                >
                                    {buttonText}
                                </Button>
                            )}
                    </div>
                </section>
            </Border>
        </TextBannerWrapper>
    );
};
