import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { Container } from "@wojo/ui";
import cn from "clsx";
import { ButtonAtomProps } from "../../atoms/button";
import { ImageAtomProps } from "../../atoms/image";
import { ImageGraphicAtomProps } from "../../atoms/image-graphic";
import { TextAtomProps } from "../../atoms/text";
import { TitleAtomProps } from "../../atoms/title";
import { VideoAtomProps } from "../../atoms/video";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { GraphicListItemProps } from "../../molecules/graphic-list-item";
import styles from "./FeaturedContent.module.scss";
import { FeaturedContentTextActions } from "./FeaturedContentTextActions";

export type FeaturedContentProps = {
    /**
     * Media bloks to display. ImageAtom | VideoAtom
     */
    media: (ImageAtomProps | VideoAtomProps)[];
    /**
     *  ImageGraphic bloks to display. ImageGraphicAtom
     */
    imageGraphic?: ImageGraphicAtomProps[];
    /**
     * Title Atom bloks to display. TitleAtom
     */
    title?: TitleAtomProps[];
    /**
     * Description bloks to display. TextAtom | Graphic List Item
     */
    description?: (TextAtomProps | GraphicListItemProps)[];
    /**
     * Button bloks to display. ButtonAtom
     */
    actions?: ButtonAtomProps[];
    /**
     * Layout of the featured content
     * @default "equal"
     */
    layout?: "equal" | "larger-media" | "smaller-media";
    /**
     * Reverse the order of the content
     */
    reverseLayout?: boolean;
    /**
     * Sets the horizontal alignment of content.
     * @default "left"
     */
    textAlignmentContentPosition?: "left" | "center";
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const FeaturedContent: React.FC<FeaturedContentProps> = (props) => {
    const {
        media,
        imageGraphic,
        title,
        description,
        actions,
        layout,
        reverseLayout,
        textAlignmentContentPosition,
        spaceAbove,
        spaceBelow,
    } = props;
    return (
        <Container
            {...storyblokEditable(props)}
            className={getSpacingClassName({ spaceAbove, spaceBelow })}
            contentsClassName={cn(
                styles["container"],
                styles[`container--${layout || "equal"}`],
                {
                    [styles[`container--reverse`]]: reverseLayout,
                },
            )}
            data-ga4-object="FEATURED_CONTENT"
        >
            {media.length ? (
                <StoryblokComponent blok={media[0]} key={media[0]?._uid} />
            ) : null}

            <FeaturedContentTextActions
                imageGraphic={imageGraphic}
                title={title}
                description={description}
                actions={actions}
                textAlignmentContentPosition={
                    textAlignmentContentPosition || "left"
                }
            />
        </Container>
    );
};
