export enum BorderRadius {
    ThemeDefault = "standard",
    NoBorderRadius = "none",
}

export enum BorderSize {
    Border0 = "0px",
    Border2 = "2px",
    Border4 = "4px",
    Border8 = "8px",
    Border16 = "16px",
    Border24 = "24px",
}

export enum BorderPosition {
    AllSides = "all",
    TopBottom = "top-bottom",
    LeftRight = "left-right",
    Left = "left",
    Right = "right",
    Top = "top",
    Bottom = "bottom",
}
