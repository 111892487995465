"use client";

import { Button, Modal, RadioButtonGroup, Text } from "@wojo/ui";
import { useState } from "react";
import styles from "./ReviewsSort.module.scss";
import { SortIcon } from "./SortIcon";

export type ReviewsSortProps = {
    /**
     * Selected sort mode.
     */
    selectedSort: string;
    /**
     * Callback function when the sort mode is changed.
     */
    onSortChange: (sortedBy: string) => void;
    /**
     * Callback function when the sort modal is opened.
     */
    onSortModalOpened?: () => void;
};
const allSortModes = [
    {
        value: "FEATURED",
        label: "Featured",
        buttonLabel: "featured",
    },
    {
        value: "HIGHEST_RATED",
        label: "Highest rated",
        buttonLabel: "highest rated",
    },
    {
        value: "LOWEST_RATED",
        label: "Lowest rated",
        buttonLabel: "lowest rated",
    },
    {
        value: "MOST_RECENT",
        label: "Most recent",
        buttonLabel: "most recent",
    },
];

export const reviewsSortModes =
    process.env.NEXT_PUBLIC_REVIEWS_ISFEATURED_ENABLED === "true"
        ? allSortModes
        : allSortModes.filter((mode) => mode.value !== "FEATURED");

export const ReviewsSort: React.FC<ReviewsSortProps> = (props) => {
    const { onSortChange, selectedSort, onSortModalOpened } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const buttonLabel = reviewsSortModes.find(
        (input) => input.value === selectedSort,
    )?.buttonLabel;

    return (
        <>
            <Button
                variant="quiet"
                adornLeft={<SortIcon />}
                type="button"
                onClick={() => {
                    onSortModalOpened?.();
                    setIsModalOpen(true);
                }}
            >
                Sort by {buttonLabel}
            </Button>

            <Modal
                isOpen={isModalOpen}
                onCloseRequested={() => setIsModalOpen(false)}
                accessibleTitle="Sort reviews"
                contentClassName={styles.modal}
            >
                <Text.Heading
                    tag="h1"
                    order="3"
                    className={styles["modal-heading"]}
                >
                    Sort by
                </Text.Heading>
                <RadioButtonGroup
                    name="sort"
                    variant="row"
                    checkedValue={selectedSort}
                    items={reviewsSortModes.map(
                        ({ buttonLabel, ...rest }) => rest,
                    )}
                    onChange={(event) => {
                        const selectedSortMode = reviewsSortModes.find(
                            (input) => input.value === event.target.value,
                        );
                        if (selectedSortMode) {
                            onSortChange(selectedSortMode.value);
                        }
                    }}
                />
            </Modal>
        </>
    );
};
