import { useApolloClient } from "@apollo/client";
import { useSuspenseQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
    AutopayCheckoutOptionFragment,
    PaymentScheduleDocument,
    PaymentScheduleMutation,
    PaymentScheduleMutationVariables,
    QuoteCheckoutType,
} from "../../generated/graphql";
import { useDraftQuote } from "../context";

export const usePaymentScheduleSuspenseMutation = () => {
    const { draftQuote, paymentFrequency } = useDraftQuote();
    const autoPayCheckoutOption = draftQuote.order.checkoutOptions.find(
        (option): option is AutopayCheckoutOptionFragment =>
            option.type === QuoteCheckoutType.AutoPay,
    );
    const apollo = useApolloClient();

    const { data } = useSuspenseQuery({
        queryKey: [
            "PaymentScheduleMutation",
            draftQuote.order.grandTotal.value,
            dayjs.utc().format("YYYY-MM-DD"),
        ],
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            if (!autoPayCheckoutOption) {
                return null;
            }
            return apollo.mutate<
                PaymentScheduleMutation,
                PaymentScheduleMutationVariables
            >({
                mutation: PaymentScheduleDocument,
                variables: {
                    draftOrderId: draftQuote._id,
                    input: {
                        frequency: paymentFrequency,
                    },
                },
            });
        },
    });

    if (!data?.data) {
        return null;
    }

    if (data.data.calculatePaymentSchedule.__typename !== "PaymentSchedule") {
        return null;
    }

    return {
        recurringPayment:
            data.data.calculatePaymentSchedule.installments[0].amount.value,
        numberOfPayments:
            data.data.calculatePaymentSchedule.installments.length,
        autoPayCheckoutOption,
    };
};
