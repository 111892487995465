import { FormRadioGroup, FormRadioGroupProps } from "@wojo/ui";
import { ChangeEventHandler } from "react";
import { FilterItem } from "./FilterItem";

type RadioButtonFilterProps = {
    name: string;
    items: FormRadioGroupProps["items"];
    triggerText: string;
    variant: "collapsible" | "popover";
    onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const RadioButtonFilter: React.FC<RadioButtonFilterProps> = ({
    items,
    name,
    triggerText,
    variant,
    onChange,
}) => {
    return (
        <FilterItem
            triggerText={triggerText}
            variant={variant}
            disabled={items.length === 0}
        >
            <FormRadioGroup name={name} items={items} onChange={onChange} />
        </FilterItem>
    );
};
