"use client";
import { usePathname } from "next/navigation";
import { ChatWindow } from "../chat-scripts";
import { useEffect, useState } from "react";

declare var window: ChatWindow;

export const useOpenChat = () => {
    const pathname = usePathname();
    const [hasChat, setHasChat] = useState<boolean>(false);
    useEffect(() => {
        if (typeof window !== "undefined") {
            setHasChat(!!window.drift || !!window.kpde || !!window.zE);
        }
    }, []);

    return {
        openChat: hasChat
            ? () => {
                  if (pathname.includes("myaccount")) {
                      window.zE?.("messenger", "open");
                  } else if (!!window.drift) {
                      window.drift.on("ready", () => {
                          window.drift?.api.toggleChat();
                          window.drift?.api.openChat();
                      });
                  } else if (!window.kpde?.chatting) {
                      document.getElementById("kpd_koopidtag")?.click();
                  }
              }
            : null,
    };
};
