import { Collapsible } from "@wojo/ui";
import { FilterPopover } from "../filter-popover/FilterPopover";
import styles from "./FilterItem.module.scss";

type FilterItemProps = React.PropsWithChildren<{
    triggerText: string;
    variant: "collapsible" | "popover";
    disabled?: boolean;
}>;

export const FilterItem: React.FC<FilterItemProps> = ({
    children,
    triggerText,
    variant,
    disabled,
}) => {
    if (variant === "collapsible") {
        return (
            <Collapsible title={triggerText} disabled={disabled}>
                <div className={styles["content-wrapper"]}>{children}</div>
            </Collapsible>
        );
    }

    return (
        <FilterPopover disabled={disabled} triggerText={triggerText}>
            {children}
        </FilterPopover>
    );
};
