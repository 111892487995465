"use client";

import { SbBlokData, storyblokEditable } from "@storyblok/react";
import { Container } from "@wojo/ui";
import { useEffect } from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";

export type LeadFormBlockProps = {
    /**
     * The embed ID is the campaign ID.
     * For Inline Optinmonster campaigns only.
     */
    embedId: string;
    /**
     * Controls the width on desktop.
     * On Tablet and Mobile, the width automatically adjusts to optimize the space.
     * @default small
     */
    blockWidth?: "xsmall" | "small" | "medium" | "large" | "xlarge" | "full";
    /**
     * Sets the position of the block within the grid.
     * @default center
     */
    blockPosition?: "left" | "center" | "right";
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const LeadFormBlock: React.FC<LeadFormBlockProps> = (props) => {
    const { embedId, blockWidth, blockPosition, spaceAbove, spaceBelow } =
        props;

    useEffect(() => {
        // Reset OM when embedId is updated in Storyblok editor mode only
        const omKey = `om${process.env.NEXT_PUBLIC_OPTINMONSTER_ACCOUNT}_${process.env.NEXT_PUBLIC_OPTINMONSTER_USER}`;
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has("_storyblok")) {
            (
                window as unknown as {
                    [key: string]: { reset: Function } | undefined;
                }
            )[omKey]?.reset();
        }
    }, [embedId]);

    return (
        <Container
            {...storyblokEditable(props)}
            width={blockWidth || "small"}
            position={blockPosition || "center"}
            className={getSpacingClassName({ spaceAbove, spaceBelow })}
        >
            <div id={embedId} />
        </Container>
    );
};
