import type { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import cn from "clsx";
import { CSSProperties, useId } from "react";
import { extractAspectRatioFromImageFile } from "../../client/extract-aspect-ratio-from-image-file";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { Border, StoryblokBorderProps } from "../../components/border";
import { StoryblokImage } from "../../components/storyblok-image";
import {
    imageAspectRatioValueForCSSMap,
    imageAspectRatioValueMap,
} from "../../constants/image-ratio";
import { BorderRadius } from "../../types/border";
import { ImageRatio } from "../../types/image";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { TextAlignment } from "../../types/text";
import styles from "./ImageAtom.module.scss";

export type ImageAtomProps = {
    /**
     * Short description of the image separate from the alt text
     */
    caption?: string;

    /**
     * Overrides default alignment of `caption`
     */
    captionTextAlignment?: TextAlignment;

    /**
     * Overrides default color of `caption`
     */
    captionTextColor?: string;

    /**
     * Image displayed
     */
    image?: AssetStoryblok;

    /**
     * Aspect ratio and cropping for `image`
     * @default ImageRatio.Original
     */
    imageRatio?: ImageRatio;
} & Omit<StoryblokBorderProps, "children"> &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow"> &
    SbBlokData;

// 1232px is the max width of desktop layouts, multiple by 2 for high density screens
const desktopImageWidth = 1232 * 2;
// 768px is the max width of mobile layouts, multiple by 2 for high density screens
const mobileImageWidth = 768 * 2;

export const ImageAtom: React.FC<ImageAtomProps> = (props) => {
    const {
        borderColor,
        borderEndColor,
        borderGradientAngle,
        borderImage,
        borderPosition,
        borderRadius,
        borderSize,
        borderStartColor,
        caption,
        captionTextAlignment,
        captionTextColor,
        image,
        imageRatio,
        spaceAbove,
        spaceBelow,
    } = props;

    const captionId = useId();
    if (!image?.filename) {
        return null;
    }

    let imageBorderRadius = undefined;
    if (imageRatio === ImageRatio.Circle1By1) {
        imageBorderRadius = "50%";
    } else if (borderRadius === BorderRadius.ThemeDefault) {
        imageBorderRadius = "var(--s-border-radius-background-standard)";
    }

    const ratioValue =
        imageAspectRatioValueMap[imageRatio || ImageRatio.Horizontal16By9];
    const mobileImageHeight = ratioValue
        ? Math.round(mobileImageWidth / ratioValue)
        : undefined;
    const desktopImageHeight = ratioValue
        ? Math.round(desktopImageWidth / ratioValue)
        : undefined;

    const aspectRatio =
        imageRatio === ImageRatio.Original
            ? extractAspectRatioFromImageFile(image)
            : imageAspectRatioValueForCSSMap[imageRatio || ImageRatio.Original];

    return (
        <div
            {...storyblokEditable(props)}
            className={cn(
                styles.wrapper,
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
            style={
                {
                    "--aspect-ratio": aspectRatio,
                } as CSSProperties
            }
            data-testid="imageWrapper"
        >
            <Border
                borderColor={borderColor}
                borderEndColor={borderEndColor}
                borderGradientAngle={borderGradientAngle}
                borderImage={borderImage}
                borderPosition={borderPosition}
                borderRadius={borderRadius}
                borderSize={borderSize}
                borderStartColor={borderStartColor}
                className={cn(
                    styles.border,
                    imageRatio === ImageRatio.Circle1By1 &&
                        styles["border--circular"],
                )}
                style={
                    {
                        "--border-radius": imageBorderRadius,
                    } as CSSProperties
                }
            >
                <StoryblokImage
                    mobile={{
                        asset: image,
                        size: {
                            height: mobileImageHeight,
                            width: mobileImageWidth,
                        },
                    }}
                    desktop={{
                        asset: image,
                        size: {
                            height: desktopImageHeight,
                            width: desktopImageWidth,
                        },
                    }}
                    aria-describedby={caption ? captionId : undefined}
                    className={styles.image}
                />
            </Border>
            {caption && (
                <p
                    id={captionId}
                    className={styles.caption}
                    style={
                        {
                            "--text-align": captionTextAlignment,
                            "--text-color": captionTextColor,
                        } as CSSProperties
                    }
                >
                    {caption}
                </p>
            )}
        </div>
    );
};
