export enum VideoAndCoverImageRatio {
    Horizontal16By9 = "16:9",
    Horizontal4By3 = "4:3",
    Square1By1 = "1:1",
    Vertical3By4 = "3:4",
    Vertical9By16 = "9:16",
}

export enum MobilePreviewAspectRatio {
    Square1By1 = "1:1",
    Vertical3By4 = "3:4",
    Vertical9By16 = "9:16",
}
