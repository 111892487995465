"use client";

import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";
import { LoadingSkeleton } from "@wojo/ui";
import { useVideoMetadata } from "@wojo/services";
import { useI18n } from "@wojo/localization";

export type VideoThumbnailProps = {
    altText: string;
    className?: string;
    fallback?: React.ReactNode;
    loadingAccessibleText?: string;
    loadingClassName?: string;
    videoId: string;
};

const VideoThumbnailInternal: React.FC<VideoThumbnailProps> = ({
    altText,
    className,
    fallback,
    videoId,
}) => {
    const { data } = useVideoMetadata(videoId);

    if (!data?.url) {
        return <>{fallback}</>;
    }

    return (
        <img
            alt={altText}
            className={className}
            src={data.url}
            width={data.width}
            height={data.height}
        />
    );
};

export const VideoThumbnail: React.FC<VideoThumbnailProps> = (props) => {
    const i18n = useI18n("storyblok");

    return (
        <ErrorBoundary fallback={<>{props.fallback}</>}>
            <Suspense
                fallback={
                    <LoadingSkeleton
                        accessibleText={
                            props.loadingAccessibleText ??
                            i18n.storyblok.videoThumbnail.loading.l()
                        }
                        className={props.loadingClassName}
                    />
                }
            >
                <VideoThumbnailInternal {...props} />
            </Suspense>
        </ErrorBoundary>
    );
};
