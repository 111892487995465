"use client";
import { useMediaQuery } from "@mantine/hooks";
import { Button, Card, Modal, ModalBody, Rating, Text } from "@wojo/ui";
import dayjs from "dayjs";
import ordinal from "ordinal";
import React, { useId, useState } from "react";
import { ReviewsQuery } from "../../../generated/graphql";
import { SchemaScript } from "../../components/schema-script";
import styles from "./ReviewsSlider.module.scss";

export type ReviewItemProps = {
    review: ReviewsQuery["reviews"]["results"][number];
    maxDescriptionLength?: number;
};

const truncateToNearestWord = (
    body: string,
    maxDescriptionLength: number,
): string => {
    if (body.length <= maxDescriptionLength) {
        return body;
    }

    let lastSpaceIndex = -1;
    for (let i = 0; i < maxDescriptionLength; i++) {
        if (body[i].match(/\s/)) {
            lastSpaceIndex = i;
        }
    }

    if (lastSpaceIndex === -1) {
        return `${body.slice(0, maxDescriptionLength)}...`;
    } else {
        return `${body.slice(0, lastSpaceIndex)}...`;
    }
};

export const ReviewItem: React.FC<ReviewItemProps> = ({
    review,
    maxDescriptionLength = 280,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const breakpointMobile = "(max-width: 767px)";
    const isMobile = useMediaQuery(breakpointMobile, false);
    const titleId = useId();

    const detail = review.detail;
    const detailTitle = detail?.title || "";
    const detailBody = detail?.body || "";

    if (!detail || !detailTitle || !detailBody) {
        return null;
    }

    const tourName = review.tour?.content?.name;
    const truncatedBody = truncateToNearestWord(
        detailBody,
        maxDescriptionLength,
    );
    const truncatedTitle =
        isMobile && detailTitle.length && detailTitle.length > 45
            ? detailTitle.substring(0, 45) + "..."
            : detailTitle;
    const truncatedTourName =
        isMobile && tourName?.length && tourName.length > 60
            ? tourName.substring(0, 60) + "..."
            : tourName;
    const formattedTravelDate = review.travelDate
        ? dayjs(review.travelDate).format("MMM YYYY")
        : null;

    let detailAuthor = "";
    detailAuthor += review.nbPastTrips
        ? `${ordinal(review.nbPastTrips)}-time `
        : "";
    detailAuthor += detail.author
        ? `traveler ${detail.author}`
        : "anonymous traveler";
    detailAuthor = detailAuthor.trim();
    detailAuthor = detailAuthor.charAt(0).toUpperCase() + detailAuthor.slice(1);

    return (
        <>
            {!!tourName && (
                <SchemaScript
                    data={{
                        "@type": "Review",
                        "@context": "https://schema.org",
                        "@id": `slider-${review._id}`,
                        reviewRating: {
                            "@type": "Rating",
                            ratingValue: review.rating,
                            bestRating: 5,
                        },
                        reviewBody: detailBody,
                        name: detailTitle,
                        itemReviewed: {
                            "@type": "Product",
                            name: tourName,
                            //TODO: Add URL post MVP https://ef-wojo.atlassian.net/browse/WWB-434
                            url: "#",
                            review: {
                                "@id": `slider-${review._id}`,
                            },
                        },
                        author: {
                            "@type": "Person",
                            name: detail.author ?? "anonymous traveler",
                        },
                    }}
                />
            )}
            <Card
                className={styles["review-card"]}
                aria-labelledby={detailTitle ? titleId : undefined}
            >
                <Rating
                    value={review.rating}
                    variant="standard"
                    size="medium"
                    className={styles.rating}
                />
                <Text.Heading
                    id={titleId}
                    order={"4"}
                    tag={"div"}
                    className={styles.title}
                >
                    {truncatedTitle.trim()}
                </Text.Heading>
                <Text.Body variant={"2"} className={styles.name}>
                    {detailAuthor}
                </Text.Body>
                <Text.Body className={styles.description}>
                    {truncatedBody}
                    <span className={styles.spacer}></span>
                    {detailBody.length > maxDescriptionLength ? (
                        <Button
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                            variant="quiet"
                            type="button"
                        >
                            Read more{" "}
                            <span className="visually-hidden">
                                of {detailTitle}
                            </span>
                        </Button>
                    ) : null}
                </Text.Body>
                {(formattedTravelDate || truncatedTitle) && (
                    <Text.Body variant={"2"} className={styles.tour}>
                        Traveled{" "}
                        {formattedTravelDate && <>in {formattedTravelDate}</>}{" "}
                        {truncatedTourName && (
                            <>
                                on{" "}
                                <Text.Body
                                    tag="span"
                                    variant={"2"}
                                    emphasis={"1"}
                                    className={styles.link}
                                >
                                    {truncatedTourName}
                                </Text.Body>
                            </>
                        )}
                    </Text.Body>
                )}
                <Modal
                    isOpen={isModalOpen}
                    onCloseRequested={() => {
                        setIsModalOpen(false);
                    }}
                    accessibleTitle={detailTitle || "Review"}
                >
                    <ModalBody>
                        <Rating
                            value={review.rating}
                            variant="standard"
                            size="medium"
                            className={styles.rating}
                        />
                        <Text.Heading
                            order={"4"}
                            tag={"h1"}
                            className={styles.title}
                        >
                            {detailTitle}
                        </Text.Heading>
                        <Text.Body variant={"2"} className={styles.name}>
                            {detailAuthor}
                        </Text.Body>
                        <Text.Body className={styles.description}>
                            {detailBody}
                        </Text.Body>
                        <Text.Body variant={"2"} className={styles.tour}>
                            Traveled in {formattedTravelDate} on{" "}
                            <Text.Body
                                tag="span"
                                variant={"2"}
                                emphasis={"1"}
                                className={styles.link}
                            >
                                {tourName}
                            </Text.Body>
                        </Text.Body>

                        {/* No URL link for MVP
                       <Button
                            variant="primary"
                            tag="a"
                            href={tourUrl ?? "/"}
                            className={styles.cta}
                        >
                            Shop the tour
                        </Button>*/}
                    </ModalBody>
                </Modal>
            </Card>
        </>
    );
};
