"use client";

import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { useComputedStyleObserver } from "@wojo/ui";
import cn from "clsx";
import { CSSProperties } from "react";
import {
    getSpacingClassName,
    type SpacingProps,
} from "../../client/get-spacing-class-name";
import { StoryblokImage } from "../../components/storyblok-image";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { Theme } from "../../types/theme";
import styles from "./ImageGraphicAtom.module.scss";

export type ImageGraphicAtomProps = SbBlokData & {
    /**
     * The height of the image graphic in pixels. Image will scale down proportionally.
     */
    imageHeight?: string;

    /**
     * The height of the image graphic in pixels. If no mobile height is supplied, component will calculate 80% of the desktop height.
     */
    mobileImageHeight?: string;

    /**
     * Image to display in light mode.
     */
    lightModeImage?: AssetStoryblok;

    /**
     * Image to display in dark mode.
     */
    darkModeImage?: AssetStoryblok;
} & Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const ImageGraphicAtom: React.FC<ImageGraphicAtomProps> = (props) => {
    const {
        imageHeight,
        mobileImageHeight,
        spaceAbove,
        lightModeImage,
        darkModeImage,
        spaceBelow,
    } = props;

    const theme = useComputedStyleObserver("--g-ui-theme");
    const prefersDarkTheme = theme === Theme.dark && !!darkModeImage?.filename;
    const imageForCurrentTheme = prefersDarkTheme
        ? darkModeImage
        : lightModeImage;

    const desktopHeight = imageHeight ? parseInt(imageHeight) : null;

    // Fallback to 80% of desktop height if no mobile height is supplied
    const fallbackMobileHeight = imageHeight
        ? parseInt(imageHeight) * 0.8
        : null;
    const mobileHeight = mobileImageHeight
        ? parseInt(mobileImageHeight)
        : fallbackMobileHeight;

    if (!imageForCurrentTheme?.filename || !(desktopHeight && mobileHeight)) {
        return null;
    }

    return (
        <div
            {...storyblokEditable(props)}
            className={cn(
                styles["image-graphic-wrapper"],
                getSpacingClassName({ spaceAbove, spaceBelow }),
                props.className,
            )}
            style={
                {
                    "--desktop-image-height": `${desktopHeight}px`,
                    "--mobile-image-height": `${mobileHeight}px`,
                } as CSSProperties
            }
        >
            <StoryblokImage
                mobile={{
                    asset: imageForCurrentTheme,
                    size: {
                        // Multiple by 2 for high density screens
                        height: mobileHeight * 2,
                    },
                }}
                desktop={{
                    asset: imageForCurrentTheme,
                    size: {
                        // Multiple by 2 for high density screens
                        height: desktopHeight * 2,
                    },
                }}
                className={styles["image-graphic"]}
            />
        </div>
    );
};

export default ImageGraphicAtom;
