import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { getGa4Object, gtmSendEvent } from "@wojo/analytics";
import { Button } from "@wojo/ui";
import cn from "clsx";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { StoryblokLink } from "../../types/storyblok/storyblok-link";

export type ButtonAtomProps = {
    /**
     * Call to action text to display
     */
    text?: string;
    /**
     * The destination URL and link metadata
     */
    link?: StoryblokLink;
    /**
     * Design variant of the button
     * @default primary
     */
    buttonVariant?: "primary" | "secondary" | "quiet" | "inverse";
    /**
     * Color palette for the specified `variant`
     */
    colorPaletteOverride?: string;
    onClick?: (
        e:
            | React.MouseEvent<HTMLOrSVGElement, MouseEvent>
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => void;
    /**
     * Optional Heading to pass to ga4 object
     */
    heading?: string;
} & SbBlokData &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

export const ButtonAtom: React.FC<ButtonAtomProps> = ({
    text,
    link,
    buttonVariant,
    colorPaletteOverride,
    spaceAbove,
    spaceBelow,
    onClick,
    heading,
    ...props
}) => {
    const { href, isExternal } = getCleanStoryblokLink(link);
    const [target, rel] = isExternal
        ? ["_blank", "noopener"]
        : [undefined, undefined];

    if (!href || !text) {
        return null;
    }

    return (
        <Button
            {...storyblokEditable(props)}
            {...props}
            className={cn(
                getSpacingClassName({ spaceAbove, spaceBelow }),
                props.className,
            )}
            tag="a"
            href={href}
            target={target}
            variant={buttonVariant || "primary"}
            palette={colorPaletteOverride}
            rel={rel}
            onClick={(e) => {
                if (onClick) {
                    onClick(e);
                } else {
                    const eventPayload = {
                        event: "track",
                        ga4_object:
                            (e.currentTarget instanceof HTMLElement &&
                                getGa4Object(e.currentTarget)) ||
                            "BUTTON",
                        ga4_action: "CLICKED",
                        clickText: text,
                        destinationUrl: href,
                        ...(heading && { heading }),
                    };
                    gtmSendEvent(eventPayload);
                }
            }}
        >
            {text}
        </Button>
    );
};
