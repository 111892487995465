import { gtmSendEvent } from "@wojo/analytics";
import { Button, Card, Rating, StatusLabel } from "@wojo/ui";

import cn from "clsx";
import pluralize from "pluralize";
import React from "react";
import { ContentAsset, TourImage } from "../../../../generated/graphql";
import { StoryblokImage } from "../../storyblok-image";
import { WishlistButton } from "../../wishlist-button";
import { DepartureListItem } from "../departure-list-item";
import { getIsOnSaleFiltersParam } from "../get-is-on-sale-filters-param";
import { DealsTourCardImageSlider } from "../image-slider";
import { DealsTourCardProps } from "../types";
import styles from "./DesktopCard.module.scss";

export const DesktopCard: React.FC<DealsTourCardProps> = ({
    departures,
    isInWishlist,
    toggleWishlist,
    showGatewayPricing,
}) => {
    if (!departures[0]) {
        return null;
    }
    const departure = departures[0];
    const {
        assets,
        cardImage,
        extensionName = "",
        tourBadge,
        nbCities = 0,
        nbDays = 0,
        nbDaysWithExtension = 0,
        name = "",
        tourPagePath,
        rating = 0,
    } = departure;

    const dayText = `${nbDays} ${pluralize("day", nbDays ?? 0)}`;
    const cityText = `${nbCities} ${pluralize("city", nbCities ?? 0)}`;
    //prettier-ignore
    const extensionText = `${nbDaysWithExtension} ${pluralize("day", nbDaysWithExtension ?? 0)}`;

    const daysWithCitiesText = `${dayText}, ${cityText}`;
    const daysWithExtensionText = `${dayText} | ${extensionText} with ${extensionName} extension`;

    const showExtension = nbDaysWithExtension && extensionName;

    const filteredImages: TourImage[] =
        assets?.filter(
            (image): image is TourImage => image.kind === "tour-image",
        ) || [];
    const mapAssets = filteredImages.map((image) => image.image);

    const mappedImages = [cardImage, ...mapAssets.slice(1, 3)].filter(
        (image) => image !== null,
    );
    const tourPagePathWithFilters = `${tourPagePath}${getIsOnSaleFiltersParam(
        "?",
    )}#tripbuilder`;

    return (
        <Card aria-label={name ?? undefined} className={styles.wrapper}>
            <div className={styles["left-wrapper"]}>
                {tourBadge && (
                    <div className={styles["merchandising-label"]}>
                        <StatusLabel
                            size="standard"
                            status={tourBadge.variant}
                            text={tourBadge.text}
                        />
                    </div>
                )}

                <span className={styles.wishlist}>
                    <WishlistButton
                        isInWishlist={isInWishlist}
                        toggleWishlist={toggleWishlist}
                        tourName={name}
                    />
                </span>
                {mappedImages.length && (
                    <DealsTourCardImageSlider
                        accessibleLabel={`Images of ${name}`}
                        enableSwipeNavigation={true}
                        href={tourPagePathWithFilters}
                    >
                        {mappedImages.map((asset, index) => (
                            <StoryblokImage
                                key={index}
                                coverContainer
                                mobile={{
                                    asset: asset as ContentAsset,
                                    size: { height: 756, width: 812 },
                                }}
                            />
                        ))}
                    </DealsTourCardImageSlider>
                )}
            </div>
            <div className={styles["right-wrapper"]}>
                <div className={styles["tour-details"]}>
                    <div className={styles["spacer"]}></div>
                    <div>
                        <a
                            href={tourPagePathWithFilters}
                            className={styles["product-page-link"]}
                            onClick={() => {
                                gtmSendEvent({
                                    event: "track",
                                    ga4_object: "DEAL_TOUR_CARD_GRID",
                                    ga4_action: "CLICKED",
                                    tourCode: departure.tourCode ?? "",
                                    tourName: departure.name,
                                });
                            }}
                        >
                            {name}
                        </a>
                        <div className={styles["trip-length"]}>
                            {showExtension
                                ? daysWithExtensionText
                                : daysWithCitiesText}
                        </div>
                        {rating && rating > 0 ? (
                            <div className={styles["rating-wrapper"]}>
                                <Rating
                                    size="small"
                                    value={rating}
                                    variant="bold"
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <ul
                    aria-label={`Deals: ${name}`}
                    className={cn(styles["deals-list"], {
                        [styles["deals-list--no-bottom-border"]]:
                            departures.length >= 2 && departures.length <= 4,
                    })}
                >
                    {departures.slice(0, 4).map((departure, index) => (
                        <DepartureListItem
                            key={departure.objectID}
                            departure={departure}
                            showGatewayPricing={showGatewayPricing}
                            itemIndex={index}
                        />
                    ))}
                </ul>
                {departures.length > 4 && (
                    <div className={styles["trip-builder-link-wrapper"]}>
                        <Button
                            variant="quiet"
                            href={tourPagePathWithFilters}
                            tag="a"
                            onClick={() => {
                                gtmSendEvent({
                                    event: "track",
                                    ga4_object: "DEAL_TOUR_CARD_GRID",
                                    ga4_action: "CLICKED",
                                    tourCode: departure.tourCode ?? "",
                                    tourName: departure.name ?? "",
                                });
                            }}
                        >
                            See all
                            <span className="visually-hidden">
                                {" "}
                                deals: {name}
                            </span>
                        </Button>
                    </div>
                )}
            </div>
        </Card>
    );
};
