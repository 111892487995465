"use client";
import type { SbBlokData } from "@storyblok/react";
import { StoryblokComponent } from "@storyblok/react/rsc";
import {
    Container,
    defaultContainerSpacing,
    TabList,
    TabPanel,
    Tabs,
} from "@wojo/ui";
import { useQueryState } from "nuqs";
import { useId } from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { TextAlignment } from "../../types/text";
import styles from "./TabbedContent.module.scss";
import { TabItem, TabItemProps } from "./TabItem";

export type TabbedContentProps = {
    /**
     * Title labeling the purpose of the tabs
     */
    accessibleTitle?: string;
    /**
     * Tabs and tab panels which make up the tabbed content
     */
    tabs: TabItemProps[];
    /**
     * Alignment of text in the tabs
     */
    tabTextAlignment?: TextAlignment;
    /**
     * The One Indexed default tab to show
     */
    defaultTab?: string;
} & Pick<SpacingProps, "spaceAbove" | "spaceBelow"> &
    SbBlokData;

export const TabbedContent: React.FC<TabbedContentProps> = (props) => {
    const {
        accessibleTitle,
        spaceAbove,
        spaceBelow,
        tabs,
        tabTextAlignment,
        defaultTab,
    } = props;

    const idBase = useId();
    const getTabId = (i: number) => `${idBase}${tabs[i]?._uid || i}`;
    const getIndexFromTabId = (tabId: string) => {
        const subString = tabId.substring(idBase.length);
        if (!subString) {
            return undefined;
        }
        if (subString.length < 3) {
            //If someone puts more 99 tabs they deserve to have it not work.
            return parseInt(subString);
        } else {
            return tabs.findIndex((tab) => tab._uid === subString);
        }
    };

    const toStoryblokIndex = (tabIndex?: number) => {
        if (tabIndex === undefined) {
            return null;
        }
        return (tabIndex + 1).toString();
    };

    const [selectedTab, setSelectedTab] = useQueryState("tab");
    // this is needed for the events on the `TabItem`
    const storyblokSetTab = defaultTab ? parseInt(defaultTab) - 1 : 0;

    const defaultTabId = (() => {
        const selectedTabInt = selectedTab
            ? parseInt(selectedTab) - 1
            : tabs.length;
        if (selectedTabInt < tabs.length && selectedTabInt >= 0) {
            return getTabId(selectedTabInt);
        } else if (storyblokSetTab < tabs.length && storyblokSetTab >= 0) {
            return getTabId(storyblokSetTab);
        } else {
            return getTabId(0);
        }
    })();

    if (!tabs.length) {
        return null;
    }

    return (
        <div className={getSpacingClassName({ spaceAbove, spaceBelow })}>
            <Tabs
                defaultTabId={defaultTabId}
                onTabChange={(tabId) =>
                    setSelectedTab(toStoryblokIndex(getIndexFromTabId(tabId)))
                }
            >
                <Container
                    spacing={{
                        xs: "0",
                        sm: "0",
                        md: "0",
                        lg: "0",
                        xl: "0",
                    }}
                >
                    <div className={styles["tab-list"]}>
                        <TabList
                            variant="custom"
                            accessibleTitle={
                                accessibleTitle || "Tabbed content"
                            }
                            indicatorPosition="top"
                            indicatorShadow
                            listPadding="custom"
                            tabBackground="opaque"
                            tabPadding="custom"
                            tabSpacing="none"
                            tabWidth="custom"
                            customListPadding={{
                                xs: defaultContainerSpacing.xs,
                                sm: defaultContainerSpacing.sm,
                                md: defaultContainerSpacing.md,
                                lg: defaultContainerSpacing.lg,
                                xl: defaultContainerSpacing.xl,
                            }}
                            customTabPadding={{
                                xs: "var(--g-spacing-md)",
                                sm: "var(--g-spacing-md)",
                                md: "var(--g-spacing-lg)",
                                lg: "var(--g-spacing-lg)",
                                xl: "var(--g-spacing-lg)",
                            }}
                            customTabWidth={{
                                xs: "160px",
                                sm: "160px",
                                md: "280px",
                                lg: "1fr",
                                xl: "1fr",
                            }}
                        >
                            {tabs.map((tab, i) => (
                                <TabItem
                                    {...tab}
                                    key={getTabId(i)}
                                    id={getTabId(i)}
                                    tabTextAlignment={tabTextAlignment}
                                    isDefaultTab={i === storyblokSetTab}
                                />
                            ))}
                        </TabList>
                    </div>
                </Container>
                {tabs.map((tab, i) => (
                    <TabPanel key={getTabId(i)} tabId={getTabId(i)}>
                        {tab.blocks?.map((blok) => (
                            <StoryblokComponent blok={blok} key={blok._uid} />
                        ))}
                    </TabPanel>
                ))}
                <Container
                    contentsClassName={styles["content-bottom-border"]}
                    spacing={{
                        xs: "0",
                        sm: "0",
                        md: "0",
                        lg: "0",
                        xl: "0",
                    }}
                />
            </Tabs>
        </div>
    );
};
