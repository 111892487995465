"use client";

import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { useComputedStyleObserver } from "@wojo/ui";
import cn from "clsx";
import React, { CSSProperties } from "react";
import { ButtonAtom, ButtonAtomProps } from "../../atoms/button";
import { TextAtomProps } from "../../atoms/text";
import { TitleAtomProps } from "../../atoms/title";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { StoryblokImage } from "../../components/storyblok-image";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { Theme } from "../../types/theme";
import styles from "./GraphicListItem.module.scss";

export type GraphicListItemProps = {
    /**
     * Image to display in light mode.
     */
    lightModeImage: AssetStoryblok;
    /**
     * Image to display in dark mode.
     */
    darkModeImage?: AssetStoryblok;
    /**
     * Text to display: TitleAtomProps | TextAtomProps.
     */
    text: TitleAtomProps[] | TextAtomProps[];
    /**
     * Width of the image graphic in pixels. Will scale to 80% of value on mobile.
     * @default 40
     */
    imageWidth?: "20" | "30" | "40" | "50" | "60" | "70" | "80" | "90" | "100";
    /**
     * Alignment of image graphic in relation to content.
     * @default top
     */
    verticalContentPosition?: "top" | "middle" | "bottom";
    /**
     * Space between image graphic and text.
     * @default medium
     */
    spaceBetweenGraphicAndText?: "xsmall" | "small" | "medium" | "large";
    /**
     * Optional action button displayed in graphic list item.
     */
    action?: ButtonAtomProps[];
    /**
     * Index Position of the item
     * @default 0
     * @description Used for tracking purposes
     */
    itemIndex?: number;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const GraphicListItem: React.FC<GraphicListItemProps> = (props) => {
    const {
        lightModeImage,
        darkModeImage,
        text,
        action,
        imageWidth,
        verticalContentPosition,
        spaceBetweenGraphicAndText,
        spaceAbove,
        spaceBelow,
        itemIndex = 0,
    } = props;

    const theme = useComputedStyleObserver("--g-ui-theme");
    const prefersDarkTheme = theme === Theme.dark && !!darkModeImage?.filename;
    const imageForCurrentTheme = prefersDarkTheme
        ? darkModeImage
        : lightModeImage;

    // Multiple by 2 for high density screens
    const desktopWidth = imageWidth ? parseInt(imageWidth) * 2 : 40;
    // Mobile automatically scales to 80% of this width
    // Multiple by 2 for high density screens
    const mobileWidth = imageWidth ? parseInt(imageWidth) * 2 * 0.8 : 40 * 0.8;

    return (
        <div
            {...storyblokEditable(props)}
            className={cn(
                styles["container"],
                styles[
                    `container-gap--${spaceBetweenGraphicAndText || "medium"}`
                ],
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
            style={
                {
                    "--image-graphic-width": `${imageWidth || 40}px`,
                } as CSSProperties
            }
            data-ga4-object="GRAPHIC_LIST_ITEM"
        >
            <StoryblokImage
                className={cn(
                    styles["image-graphic"],
                    styles[
                        `image-graphic--${verticalContentPosition || "top"}`
                    ],
                )}
                mobile={{
                    asset: imageForCurrentTheme,
                    size: {
                        width: desktopWidth,
                    },
                }}
                desktop={{
                    asset: imageForCurrentTheme,
                    size: {
                        width: mobileWidth,
                    },
                }}
            />

            {text.length ? (
                <div className={styles.content}>
                    <StoryblokComponent blok={text[0]} key={text[0]._uid} />
                    {action?.length ? (
                        <ButtonAtom
                            {...action[0]}
                            onClick={() => {
                                const { href } = getCleanStoryblokLink(
                                    action[0].link,
                                );
                                gtmSendEvent({
                                    event: "track",
                                    ga4_object: "GRAPHIC_LIST_ITEM",
                                    ga4_action: "CLICKED",
                                    clickText: action[0].text,
                                    destinationUrl: href,
                                    index: itemIndex,
                                });
                            }}
                        />
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};
