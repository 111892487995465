import cn from "clsx";
import React, { ChangeEventHandler, forwardRef, Fragment, useId } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { Card } from "../card";
import { RadioButton } from "../radio-button";
import styles from "./RadioButtonGroup.module.scss";

export type RadioButtonGroupItem = {
    /**
     * Label text
     */
    label: React.ReactNode;
    /**
     * Secondary text related to `label`
     */
    labelDetail?: React.ReactNode;
    /**
     * Form value
     */
    value: string;
    /**
     * Additional elements to display with this option outside of the label tag
     */
    moreDetails?: React.ReactNode;
};

export type RadioButtonGroupProps = {
    /**
     * Value of the item considered checked (controlled mode)
     */
    checkedValue?: string;
    /**
     * Short description to supplement the title
     */
    description?: React.ReactNode;
    /**
     * Placement of `labelDetail`
     * @default "right"
     */
    detailPosition?: "below" | "right";
    /**
     * Disables all items in the group
     */
    disabled?: boolean;
    /**
     * Error text directly related to this group
     */
    errorText?: string;
    /**
     * Hides the required indicator
     * @default false
     */
    hideRequired?: boolean;
    /**
     * Items to be displayed as checkboxes
     */
    items: RadioButtonGroupItem[];
    /**
     * Form name for every checkbox
     */
    name: string;
    /**
     * Change event handler shared by all radio buttons
     */
    onChange?: ChangeEventHandler<HTMLInputElement>;
    /**
     * A styling classname to add to the radio button wrapper
     * */
    radioButtonWrapperClassname?: string;
    /**
     * Does at least one radio button need to be selected?
     */
    required?: boolean;
    /**
     * Show visual separates between radio buttons
     */
    separators?: boolean;
    /**
     * Title of this group
     */
    title?: React.ReactNode;
    /**
     * Display items in rows or columns
     */
    variant?: "column" | "row";
    /**
     * Variant of the wrapper around the radio buttons
     * @default "none"
     */
    wrapperVariant?: "card" | "none";
    /**
     * shows the optional validation text
     * @default false
     */
    showOptionalValidationText?: boolean;
    /**
     * Id for the aria description which is passed to each radio button
     */
    radioButtonDescribedBy?: string;
};
export const RadioButtonGroup = forwardRef<
    HTMLInputElement,
    RadioButtonGroupProps
>(
    (
        {
            checkedValue,
            description,
            detailPosition = "below",
            disabled,
            errorText,
            hideRequired = false,
            items = [],
            name,
            onChange,
            radioButtonWrapperClassname,
            required = true,
            separators,
            title,
            variant = "column",
            wrapperVariant = "none",
            showOptionalValidationText,
            radioButtonDescribedBy,
        }: RadioButtonGroupProps,
        ref,
    ) => {
        const hasError = !!errorText;
        const showRequired = required && !hideRequired;
        const titleId = useId();
        const errorId = useId();
        const descriptionId = useId();
        const Wrapper = wrapperVariant === "card" ? Card : Fragment;
        return (
            <fieldset
                data-has-error={hasError}
                className={styles["group-wrapper"]}
                aria-describedby={
                    cn({
                        [errorId]: hasError,
                        [descriptionId]: description,
                    }) || undefined
                }
                aria-labelledby={title ? titleId : undefined}
                aria-invalid={hasError || undefined}
                aria-required={required || undefined}
                role="radiogroup"
            >
                {(title || description) && (
                    <div
                        className={cn(
                            styles["group-intro"],
                            "radio-group-intro",
                        )}
                    >
                        {title && (
                            <legend
                                id={titleId}
                                className={cn(styles["group-title"], {
                                    [styles["group-title--error"]]: hasError,
                                })}
                            >
                                {title}{" "}
                                {showRequired && (
                                    <span
                                        className={cn(
                                            styles["required-marker"],
                                            {
                                                [styles[
                                                    "required-marker--error"
                                                ]]: hasError,
                                            },
                                        )}
                                    >
                                        required
                                    </span>
                                )}
                                {!showRequired &&
                                    showOptionalValidationText && (
                                        <span
                                            className={
                                                styles["optional-marker"]
                                            }
                                        >
                                            optional
                                        </span>
                                    )}
                            </legend>
                        )}
                        <div id={descriptionId}>{description}</div>
                    </div>
                )}
                {hasError && (
                    <div className={styles["error-wrapper"]} id={errorId}>
                        <FiAlertCircle
                            className={styles["error-icon"]}
                            aria-hidden={true}
                        />{" "}
                        {errorText}
                    </div>
                )}
                <Wrapper>
                    {items.map((item) => {
                        return (
                            <div
                                className={cn(
                                    styles[`group-${variant}`],
                                    {
                                        [styles[
                                            `group-${variant}--with-separator`
                                        ]]: separators,
                                    },
                                    radioButtonWrapperClassname,
                                )}
                                key={item.value}
                            >
                                <RadioButton
                                    {...item}
                                    aria-describedby={cn(
                                        {
                                            [errorId]: hasError,
                                        },
                                        radioButtonDescribedBy,
                                    )}
                                    ref={ref}
                                    checked={checkedValue === item.value}
                                    detailPosition={detailPosition}
                                    errorText={errorText}
                                    hideErrorText={true}
                                    name={name}
                                    onChange={onChange}
                                    disabled={disabled}
                                />
                            </div>
                        );
                    })}
                </Wrapper>
            </fieldset>
        );
    },
);
RadioButtonGroup.displayName = "RadioButtonGroup";
