import { Button, Tag } from "@wojo/ui";
import dayjs from "dayjs";
import React from "react";
import { ResultsFormValues } from "../form-types";
import { useResultsContext } from "../results-provider/ResultsProvider";
import styles from "./SelectedFiltersList.module.scss";

type FilterValue = {
    filter: keyof ResultsFormValues;
    label: string;
    value: string;
};

export const SelectedFiltersList: React.FC = () => {
    const { clearAllFilters, form, hitLabel } = useResultsContext();

    const [activityLevel, dates, destinations, duration, price, tripType] =
        form.watch([
            "activityLevel",
            "dates",
            "destinations",
            "duration",
            "price",
            "tripType",
        ]);

    const values = [
        ...destinations.map((destination) => ({
            value: destination,
            label: destination,
            filter: "destinations",
        })),
        dates?.from && {
            value: dates,
            label: dates.to
                ? `${dayjs(dates.from).format("MMM D")}–${dayjs(
                      dates.to,
                  ).format("MMM D, YYYY")}`
                : `${dayjs(dates.from).format("MMM D, YYYY")} to later`,
            filter: "dates",
        },
        ...duration.map((duration) => ({
            value: duration,
            label: duration,
            filter: "duration",
        })),
        ...price.map((price) => ({
            value: price,
            label: price,
            filter: "price",
        })),
        ...activityLevel.map((activityLevel) => ({
            value: activityLevel,
            label: activityLevel,
            filter: "activityLevel",
        })),
        ...tripType.map((tripType) => ({
            value: tripType,
            label: tripType,
            filter: "tripType",
        })),
    ].filter((v): v is FilterValue => !!v);

    if (!values.length) {
        return null;
    }

    return (
        <>
            <ul
                aria-label={`Selected ${hitLabel} filters`}
                className={styles.list}
            >
                {values.map(({ value, label, filter }) => (
                    <li key={value + filter} className={styles.item}>
                        <Tag
                            isDismissible
                            accessibleDismissText={`Remove ${label} filter`}
                            onClick={() => {
                                const filterValues = form.getValues(filter);
                                if (Array.isArray(filterValues)) {
                                    const newValues = filterValues.filter(
                                        (v) => v !== value,
                                    );
                                    form.setValue(filter, newValues);
                                } else {
                                    form.setValue(filter, null);
                                }
                            }}
                        >
                            {label}
                        </Tag>
                    </li>
                ))}
            </ul>
            <Button
                type="button"
                variant="quiet"
                onClick={() => clearAllFilters()}
            >
                Clear all{" "}
                <span className="visually-hidden">{`${hitLabel} filters`}</span>
            </Button>
        </>
    );
};
