import { QueryType } from "@wojo/services";
import { CalendarDateRange } from "@wojo/ui";

export type FilterName =
    | "activityLevel"
    | "dates"
    | "destinations"
    | "duration"
    | "price"
    | "tripType"
    | "discount";

export const defaultFilterValues = {
    activityLevel: [] as string[],
    dates: null as CalendarDateRange | null,
    destinations: [] as string[],
    duration: [] as string[],
    price: [] as string[],
    tripType: [] as string[],
    discount: [] as string[],
} satisfies Record<FilterName, any>;

export type FormValueNames = FilterName | "sort" | "page" | "gatewayName";

export const defaultFormValues = {
    ...defaultFilterValues,
    gatewayName: "" as string,
    page: 0,
    sort: "Featured" as string,
} satisfies Record<FormValueNames, any>;

export const sortOptionsMap: Record<QueryType, string[]> = {
    departure: [
        "Featured",
        "Price: low to high",
        "Discount: high to low",
        "Duration: short to long",
        "Duration: long to short",
        "Departs: soonest to latest",
        "Departs: latest to soonest",
        "Rating: high to low",
    ],
    tourPage: [
        "Featured",
        "Price: low to high",
        "Duration: short to long",
        "Duration: long to short",
        "Rating: high to low",
    ],
    blogPost: [],
};
export type ResultsFormValues = typeof defaultFormValues;
