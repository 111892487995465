"use client";

import cn from "clsx";
import { CSSProperties, ReactNode, useId, useState } from "react";
import { useCollapse } from "react-collapsed";
import { FiChevronDown } from "react-icons/fi";
import styles from "./Collapsible.module.scss";

export type IconOnlyButtonMode = {
    accessibleTitle: string;
};

export type ButtonMode = "Full" | IconOnlyButtonMode;

export type CollapsibleProps = React.PropsWithChildren<{
    /**
     * The title shown on the collapsible trigger
     */
    title: ReactNode;
    /**
     * Determines the default expanded state of the collapsible
     * @default false
     */
    defaultExpanded?: boolean;
    /**
     * Determines the controlled state of the collapsible
     */
    expanded?: boolean;
    /**
     * Determines if the collapsible is disabled
     * @default false
     */
    disabled?: boolean;
    /**
     * Determines the visibility of the top border
     * @default false
     */
    hideTopBorder?: boolean;
    /**
     * Determines the visibility of the bottom border
     * @default false
     */
    hideBottomBorder?: boolean;
    /**
     * Determines if the default padding should be present.
     * @default false
     */
    hidePadding?: boolean;
    /**
     * Determines if only the icon and it's padding are the tappable button.
     * @default "Full"
     */
    buttonMode?: ButtonMode;
    /**
     * Icon shown on the collapsible trigger, typically from react-icons. Should have aria-hidden="true" on the icon
     * @default FiChevronDown
     */
    icon?: ReactNode;
    /**
     * Determines the visibility of the icon. For use cases where the icon layout is custom.
     * @default false
     */
    hideIcon?: boolean;
    /**
     * Column Gap for the Header Grid
     *
     * Raw String use `var` for variables
     *
     * @default var(--g-size-xxs)
     */
    headerColumnGap?: string;
    /**
     * Optional Header className to push down style.
     */
    headerClassName?: string;
    /**
     * Additional Class Names
     */
    className?: string;
    /**
     * Additional class names for when the collapsible is expanded
     */
    expandedClassName?: string;
    /**
     * Callback function that is called when the collapsible expands and collapses
     */
    onToggle?: (isExpanded: boolean) => void;
    forceDefaultState?: boolean;
    dataTestId?: string;
}>;

export const Collapsible: React.FC<CollapsibleProps> = ({
    title,
    children,
    defaultExpanded = false,
    expanded,
    disabled,
    hideTopBorder,
    hideBottomBorder,
    buttonMode = "Full",
    headerColumnGap,
    hidePadding = false,
    headerClassName,
    icon = <FiChevronDown size={16} aria-hidden="true" />,
    hideIcon,
    className,
    expandedClassName = "",
    onToggle,
    dataTestId,
}) => {
    const iconOnly = buttonMode != "Full";
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const { getCollapseProps, getToggleProps } = useCollapse({
        isExpanded: !disabled && isExpanded,
        defaultExpanded,
        duration: 300,
    });

    if (expanded !== undefined && isExpanded != expanded) {
        setIsExpanded(expanded);
    }
    const collapsibleClassName = cn(
        styles.collapsible,
        {
            [styles["top-border"]]: !hideTopBorder,
            [styles["bottom-border"]]: !hideBottomBorder,
            [styles["padding"]]: !hidePadding,
            [styles["expanded"]]: isExpanded,
            [styles["collapsed"]]: !isExpanded,
            [expandedClassName]: isExpanded,
        },
        className,
    );

    const { id: buttonId, ...toggleProps } = getToggleProps({
        onClick: () => {
            if (!disabled) {
                onToggle?.(!isExpanded);
                setIsExpanded((prevExpanded) => !prevExpanded);
            }
        },
    });

    const tempId = useId();

    const labelId = iconOnly ? tempId : buttonId;

    if (iconOnly && hideIcon) {
        return null;
    }

    const topSection = iconOnly ? (
        <div
            className={cn(styles.header, headerClassName)}
            style={
                headerColumnGap
                    ? ({ gridColumnGap: headerColumnGap } as CSSProperties)
                    : undefined
            }
        >
            <span id={labelId} className={styles.title}>
                {title}
            </span>
            <button
                aria-label={buttonMode.accessibleTitle}
                className={cn(
                    styles.trigger,
                    styles.icon,
                    styles["trigger-icon-only"],
                )}
                {...toggleProps}
                id={buttonId}
                aria-disabled={disabled}
            >
                {icon}
            </button>
        </div>
    ) : (
        <button
            className={cn(styles.header, styles.trigger, headerClassName, {
                [styles["header--no-icon"]]: hideIcon,
            })}
            {...toggleProps}
            id={buttonId}
            aria-disabled={disabled}
        >
            <span>{title}</span>
            {!hideIcon && <span className={styles.icon}>{icon}</span>}
        </button>
    );

    return (
        <div className={collapsibleClassName} data-testid={dataTestId}>
            {topSection}
            <div {...getCollapseProps()} aria-labelledby={labelId}>
                <div
                    className={cn(styles.content, {
                        [styles["content-padding"]]: !hidePadding,
                    })}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
