"use client";

import cn from "clsx";
import {
    forwardRef,
    InputHTMLAttributes,
    useEffect,
    useId,
    useMemo,
    useState,
} from "react";
import { DayPickerProps } from "react-day-picker";
import { FiAlertCircle, FiCalendar } from "react-icons/fi";
import { getFieldDescriptionId } from "../../form-fields/field-description";
import { FieldProps } from "../../form-fields/field-props";
import { Calendar } from "../calendar";
import {
    CalendarValue,
    convertToCalendarValue,
    formatLabel,
    SharedCalendarProps,
} from "../shared/calendar-utils";
import { CalendarFooter } from "../shared/CalendarFooter";
import { CalendarWrapper } from "../shared/CalendarWrapper";
import styles from "./InlineCalendar.module.scss";

export type InlineCalendarProps = SharedCalendarProps &
    FieldProps &
    Omit<
        InputHTMLAttributes<HTMLInputElement>,
        "onChange" | "value" | "defaultValue"
    > & {
        onChange?: (date?: CalendarValue | null) => void;
    };

export const InlineCalendar = forwardRef<HTMLInputElement, InlineCalendarProps>(
    (
        {
            isRangeSelect = false,
            fieldId,
            errorText,
            label,
            required,
            disabled,
            descriptionText,
            placeholder = "",
            footer,
            onSelected,
            dayPickerOptions,
            value,
            onChange,
            onReset,
            onNavigate,
            defaultValue,
            wrapperClassName,
            ...inputProps
        },
        ref,
    ) => {
        const fallbackId = useId();
        const calendarId = fieldId || fallbackId;
        const [selected, setSelected] = useState<
            CalendarValue | undefined | null
        >(convertToCalendarValue(defaultValue));
        const dateValue = useMemo(
            () =>
                value !== undefined
                    ? convertToCalendarValue(value) || null
                    : undefined,
            [value],
        );
        //@ts-ignore https://github.com/gpbl/react-day-picker/issues/1583
        const pickerOptions: DayPickerProps = {
            ...dayPickerOptions,
            selected: selected || undefined,
            mode: isRangeSelect ? "range" : "single",
        };

        const handleSelect = (selected?: CalendarValue) => {
            if (!disabled) {
                const isControlled = value !== undefined;
                const selectedValue =
                    isControlled && !selected ? null : selected;
                setSelected(selectedValue);
                onSelected?.(selectedValue);
                onChange?.(selectedValue);
            }
        };

        useEffect(() => {
            if (dateValue !== undefined) {
                setSelected(dateValue || null);
            }
        }, [dateValue]);

        return (
            <CalendarWrapper
                className={cn(styles.wrapper, wrapperClassName)}
                fieldId={calendarId}
                errorText={errorText}
                disabled={disabled}
                descriptionText={descriptionText}
                required={required}
                label={label}
            >
                <div
                    className={cn(styles.calendar, {
                        [styles["calendar--selected"]]: selected,
                        [styles["calendar--error"]]: errorText,
                        [styles["calendar--disabled"]]: disabled,
                    })}
                >
                    <div className={styles.field}>
                        <input
                            className={styles.date}
                            readOnly
                            value={formatLabel(placeholder, selected)}
                            aria-required={required || undefined}
                            aria-invalid={!!errorText || undefined}
                            aria-disabled={disabled || undefined}
                            aria-describedby={
                                getFieldDescriptionId(calendarId) || undefined
                            }
                            ref={ref}
                            id={calendarId}
                            {...inputProps}
                        />

                        <div className={styles["icon-wrapper"]}>
                            {!errorText ? (
                                <FiCalendar
                                    className={styles.icon}
                                    aria-hidden
                                    role="img"
                                />
                            ) : (
                                <FiAlertCircle
                                    title="Error"
                                    aria-label="Error"
                                    role="img"
                                    className={styles["icon--error"]}
                                />
                            )}
                        </div>
                    </div>
                    <Calendar
                        className={styles["inner-calendar"]}
                        dayPickerOptions={pickerOptions}
                        onSelect={handleSelect}
                        onNavigate={onNavigate}
                        disableAll={disabled}
                    />
                </div>
                <CalendarFooter
                    className={styles.footer}
                    onReset={() => {
                        onReset?.();
                        handleSelect(undefined);
                    }}
                    disableReset={disabled || !selected}
                    disableDone={disabled}
                >
                    {footer}
                </CalendarFooter>
            </CalendarWrapper>
        );
    },
);

InlineCalendar.displayName = "InlineCalendar";
