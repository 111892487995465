import cn from "clsx";
import { ComponentPropsWithoutRef, HTMLAttributes } from "react";
import {
    FiArrowDown,
    FiArrowLeft,
    FiArrowRight,
    FiArrowUp,
    FiChevronDown,
    FiChevronLeft,
    FiChevronRight,
    FiChevronUp,
} from "react-icons/fi";
import styles from "./SliderButton.module.scss";

type ValidTags = "button";

export type SliderButtonProps<T extends ValidTags> =
    (ComponentPropsWithoutRef<T> & HTMLAttributes<HTMLOrSVGElement>) & {
        /**
         * Pick which variant to use: standard, quiet, quiet-inverse
         * @default standard
         */
        variant?: "standard" | "quiet" | "quiet-inverse";
        /**
         * Which direction the arrow is going, left, right, up or down
         */
        arrowDirection: "left" | "right" | "up" | "down";
        /**
         * Alternate JSX element to use for the button
         * @default button
         */
        tag?: T | ValidTags;
        /**
         * Should we show the button
         * @default true
         */
        visible?: boolean;
    };

const DEFAULT_TAG = "button" as const;

const getArrow = (
    variant: "standard" | "quiet" | "quiet-inverse",
    arrowDirection: "left" | "right" | "up" | "down",
) => {
    if (variant === "standard") {
        switch (arrowDirection) {
            case "left":
                return <FiChevronLeft aria-hidden />;
            case "right":
                return <FiChevronRight aria-hidden />;
            case "up":
                return <FiChevronUp aria-hidden />;
            case "down":
                return <FiChevronDown aria-hidden />;
            default:
                return <FiChevronLeft aria-hidden />;
        }
    }

    switch (arrowDirection) {
        case "left":
            return <FiArrowLeft aria-hidden />;
        case "right":
            return <FiArrowRight aria-hidden />;
        case "up":
            return <FiArrowUp aria-hidden />;
        case "down":
            return <FiArrowDown aria-hidden />;
        default:
            return <FiArrowLeft aria-hidden />;
    }
};

export const SliderButton = <T extends ValidTags = typeof DEFAULT_TAG>({
    variant = "standard",
    arrowDirection,
    tag = DEFAULT_TAG,
    className,
    ...rest
}: SliderButtonProps<T>) => {
    const disabled =
        (rest as JSX.IntrinsicElements["button"])["aria-disabled"] ?? false;

    const buttonClassName = cn(
        styles.container,
        styles[`container--${variant}`],
        `slider-button-${variant}-palette`,
        className,
        { [styles[`container--${variant}--visually-hidden`]]: disabled },
    );

    const Tag: ValidTags = tag;
    return (
        <Tag {...rest} className={buttonClassName}>
            {getArrow(variant, arrowDirection)}
        </Tag>
    );
};
