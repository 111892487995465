"use client";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const useCountdown = (targetDate: string, showDays: boolean) => {
    const countDownEndDate = dayjs.utc(targetDate);
    const [countDown, setCountDown] = useState(null as number | null);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {
        setCountDown(countDownEndDate.diff(dayjs().utc(), "second"));
        setIsInitialized(true);
        const interval = setInterval(() => {
            setCountDown(countDownEndDate.diff(dayjs().utc(), "second"));
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownEndDate]);

    if (!countDown) {
        return null;
    }

    const days = showDays ? Math.floor(countDown / (3600 * 24)) : 0;
    const hours = Math.floor(countDown / 3600) % (showDays ? 24 : 3600);
    const minutes = Math.floor((countDown % 3600) / 60);
    const seconds = Math.floor(countDown % 60);

    const isZero = countDown <= 0;

    return { days, hours, minutes, seconds, isInitialized, isZero };
};
