import { CalendarValue, FormInlineCalendar } from "@wojo/ui";
import { FilterItem } from "./FilterItem";
import styles from "./FilterItem.module.scss";

type CalendarFilterProps = {
    label: string;
    name: string;
    triggerText: string;
    variant: "collapsible" | "popover";
    onChange?: (date?: CalendarValue | null) => void;
};

export const CalendarFilter: React.FC<CalendarFilterProps> = ({
    label,
    name,
    triggerText,
    variant,
    onChange,
}) => {
    return (
        <FilterItem triggerText={triggerText} variant={variant}>
            <FormInlineCalendar
                name={name}
                label={label}
                isRangeSelect
                wrapperClassName={styles["calendar-filter"]}
                dayPickerOptions={{
                    fromDate: new Date(),
                }}
                onChange={onChange}
            />
        </FilterItem>
    );
};
