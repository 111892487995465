import cn from "clsx";
import { ChangeEventHandler, forwardRef, Fragment, useId } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { Card } from "../card";
import { Checkbox } from "../checkbox";
import styles from "./CheckboxGroup.module.scss";

export type CheckboxGroupItem = {
    /**
     * Label text
     */
    label: React.ReactNode;
    /**
     * Secondary text related to `label`
     */
    labelDetail?: React.ReactNode;
    /**
     * Form value
     */
    value: string;
};

export type CheckboxGroupProps = {
    /**
     * Values of the `items` that are considered checked (controlled mode)
     */
    checkedValues?: string[];
    /**
     * Values of the `items` that are disabled
     */
    disabledValues?: string[];
    /**
     * Values of the `items` that are initially considered checked (uncontrolled mode)
     */
    defaultCheckedValues?: string[];
    /**
     * Short description to supplement the title
     */
    description?: React.ReactNode;
    /**
     * Placement of `labelDetail`
     * @default "right"
     */
    detailPosition?: "below" | "right";
    /**
     * Disables all items in the group
     */
    disabled?: boolean;
    /**
     * Error text directly related to this group
     */
    errorText?: string;
    /**
     * Items to be displayed as checkboxes
     */
    items: CheckboxGroupItem[];
    /**
     * Form name for every checkbox
     */
    name: string;
    /**
     * Change event handler shared by all checkboxes
     */
    onChange?: ChangeEventHandler<HTMLInputElement>;
    /**
     * Does at least one checkbox need to be checked?
     */
    required?: boolean;
    /**
     * Show visual separates between checkboxes
     */
    separators?: boolean;
    /**
     * Title of this group
     */
    title?: React.ReactNode;
    /**
     * Display items in rows or columns
     */
    variant?: "column" | "row";
    /**
     * Variant of the wrapper around the checkboxes
     * @default "none"
     */
    wrapperVariant?: "card" | "none";
};
export const CheckboxGroup = forwardRef<HTMLInputElement, CheckboxGroupProps>(
    (
        {
            checkedValues,
            disabledValues = [],
            defaultCheckedValues,
            description,
            detailPosition = "right",
            disabled,
            errorText,
            items = [],
            name,
            onChange,
            required,
            separators,
            title,
            variant = "row",
            wrapperVariant = "none",
        }: CheckboxGroupProps,
        ref,
    ) => {
        const hasError = !!errorText;
        const titleId = useId();
        const errorId = useId();
        const descriptionId = useId();
        const Wrapper = wrapperVariant === "card" ? Card : Fragment;
        return (
            <fieldset
                className={styles["group-wrapper"]}
                aria-labelledby={title ? titleId : undefined}
                aria-describedby={
                    cn({
                        [errorId]: hasError,
                        [descriptionId]: description,
                    }) || undefined
                }
                role={title ? "group" : undefined}
            >
                {(title || description) && (
                    <div
                        className={cn(
                            styles["group-intro"],
                            "checkbox-group-intro",
                        )}
                    >
                        {title && (
                            <legend
                                id={titleId}
                                className={cn(styles["group-title"], {
                                    [styles["group-title--error"]]: hasError,
                                })}
                            >
                                {title}{" "}
                                {required && (
                                    <span
                                        className={cn(
                                            styles["required-marker"],
                                            {
                                                [styles[
                                                    "required-marker--error"
                                                ]]: hasError,
                                            },
                                        )}
                                    >
                                        required
                                    </span>
                                )}
                            </legend>
                        )}
                        <div id={descriptionId}>{description}</div>
                    </div>
                )}
                {hasError && (
                    <div className={styles["error-wrapper"]} id={errorId}>
                        <FiAlertCircle
                            className={styles["error-icon"]}
                            aria-hidden={true}
                        />{" "}
                        {errorText}
                    </div>
                )}
                <Wrapper>
                    {items.map((item) => {
                        const values =
                            typeof checkedValues !== "undefined"
                                ? checkedValues
                                : defaultCheckedValues;
                        const checked = !!values?.includes(item.value);
                        return (
                            <div
                                className={cn(styles[`group-${variant}`], {
                                    [styles[
                                        `group-${variant}--with-separator`
                                    ]]: separators,
                                })}
                                key={item.value}
                            >
                                <Checkbox
                                    {...item}
                                    aria-describedby={
                                        hasError ? errorId : undefined
                                    }
                                    ref={ref}
                                    checked={
                                        typeof checkedValues !== "undefined"
                                            ? checked
                                            : undefined
                                    }
                                    defaultChecked={
                                        typeof defaultCheckedValues !==
                                        "undefined"
                                            ? checked
                                            : undefined
                                    }
                                    detailPosition={detailPosition}
                                    errorText={errorText}
                                    hideErrorText={true}
                                    name={name}
                                    onChange={onChange}
                                    disabled={
                                        disabled ||
                                        disabledValues.includes(item.value)
                                    }
                                />
                            </div>
                        );
                    })}
                </Wrapper>
            </fieldset>
        );
    },
);

CheckboxGroup.displayName = "CheckboxGroup";
