"use client";

import React, { forwardRef } from "react";
import { useTabs } from "./internal/TabsContext";
import styles from "./Tabs.module.scss";

export type TabPanelProps = React.PropsWithChildren<{
    /**
     * Id of the tab this panel is associated with. Only associate one tab with one panel
     */
    tabId: string;
}>;

export const TabPanel = forwardRef<HTMLDivElement, TabPanelProps>(
    ({ children, tabId }, ref) => {
        const { currentTab, getTabId, getTabPanelId } = useTabs();
        const tabIdForLabel = getTabId(tabId);
        const panelId = getTabPanelId(tabId);

        return (
            <div
                aria-labelledby={tabIdForLabel}
                className={styles.tabpanel}
                id={panelId}
                role="tabpanel"
                hidden={tabId !== currentTab}
                ref={ref}
                tabIndex={-1}
            >
                {children}
            </div>
        );
    },
);

TabPanel.displayName = "TabPanel";
