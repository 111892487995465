import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { SchemaScript } from "../schema-script";

export type OrganizationSchemaProps = {
    legalName?: string;
    sameAs?: string;
    description?: string;
    url?: string;
    contactType?: string;
    city?: string;
    stateOrProvince?: string;
    postalCode?: string;
    telephone?: string;
    logo?: AssetStoryblok;
    streetAddress?: string;
};

export const OrganizationSchema: React.FC<OrganizationSchemaProps> = ({
    streetAddress,
    city,
    stateOrProvince,
    postalCode,
    contactType,
    sameAs,
    legalName,
    description,
    url,
    logo,
    telephone,
}) => {
    return (
        <SchemaScript
            data={{
                "@type": "Corporation",
                "@context": "https://schema.org",
                legalName: legalName ?? "",
                name: legalName ?? "",
                description: description ?? "",
                url: url ?? "",
                logo: logo?.filename ?? "",
                image: logo?.filename ?? "",
                contactPoint: {
                    "@type": "ContactPoint",
                    contactType: contactType ?? "",
                },
                address: {
                    "@type": "PostalAddress",
                    addressLocality: city ?? "",
                    addressRegion: stateOrProvince ?? "",
                    postalCode: postalCode ?? "",
                    streetAddress: streetAddress ?? "",
                },
                sameAs: sameAs?.split(",") ?? "",
                telephone: telephone ?? "",
            }}
        />
    );
};
