import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import React from "react";
import { BlogArticleGrid } from "../../organisms/blog-article-grid";
import { Spacing } from "../../types/spacing";
import styles from "./BlogListingPage.module.scss";
import { BlogListingTitle } from "./BlogListingTitle";

export type BlogListingPageProps = {
    _uid: string;
    articlesAlgoliaContext?: string;
    name?: string;
    brandContent?: SbBlokData[];
    seo?: SbBlokData[];
    analytics?: SbBlokData[];
};

export const BlogListingPage: React.FC<BlogListingPageProps> = (props) => {
    return (
        <div {...storyblokEditable(props)}>
            <div className={styles.title}>
                <BlogListingTitle name={props.name} />
            </div>
            {props.articlesAlgoliaContext && (
                <BlogArticleGrid
                    _uid={props._uid}
                    algoliaRuleTriggerContext={props.articlesAlgoliaContext}
                    numberOfArticles="16"
                    layout="4"
                    spaceBelow={Spacing.XLarge}
                />
            )}
            {props.brandContent?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
            {props.seo?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
            {props.analytics?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </div>
    );
};
