import currencyJs from "currency.js";
import { CurrencyCode, Money } from "../generated/graphql";

const CAD = (value: number, roundToInteger: boolean) =>
    currencyJs(value, {
        symbol: "$",
        pattern: `!# CAD`,
        negativePattern: "-!# CAD",
        precision: Number.isInteger(value) || roundToInteger ? 0 : 2,
    }).format();
const USD = (value: number, roundToInteger: boolean) =>
    currencyJs(value, {
        symbol: "$",
        precision: Number.isInteger(value) || roundToInteger ? 0 : 2,
    }).format();

const getPrice = (prices: Money[], currencyCode: CurrencyCode): Money =>
    prices.find((price) => price.currencyCode === currencyCode) || {
        currencyCode: CurrencyCode.Usd,
        formatted: "",
        value: 0,
    };

export const getCurrency = (currencyCode?: CurrencyCode) => {
    const activeCurrencyCode =
        currencyCode ?? (process.env.NEXT_PUBLIC_CURRENCY_CODE as CurrencyCode);

    const formatCurrency = (value: number, roundToInteger = false) =>
        activeCurrencyCode === CurrencyCode.Cad
            ? CAD(value, roundToInteger)
            : USD(value, roundToInteger);
    const price = (prices: Money[]) => getPrice(prices, activeCurrencyCode);

    const formattedPrice = (prices: Money[]) =>
        formatCurrency(getPrice(prices, activeCurrencyCode).value);

    return {
        /**
         * Returns the price object with the currency code based on the passed in  currency code.
         * @default currencyCode: USD
         */
        price,
        /**
         * Formats a number as a currency string with the currency symbol based on the passed in currency code.
         * @default currencyCode: USD
         */
        formatCurrency,
        /**
         * From a given array of localized prices returns a formatted price string with the currency symbol based on the passed in currency code.
         * @default currencyCode: USD
         */
        formattedPrice,
    };
};
