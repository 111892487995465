"use client";

import { Button, FormCheckboxGroup, FormCheckboxGroupProps } from "@wojo/ui";
import { ChangeEventHandler, useId, useState } from "react";
import { FilterItem } from "./FilterItem";

type CheckboxFilterProps = {
    name: string;
    items: FormCheckboxGroupProps["items"];
    showAllText?: (showAll: boolean) => string;
    triggerText: string;
    variant: "collapsible" | "popover";
    onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
    items,
    name,
    showAllText,
    triggerText,
    variant,
    onChange,
}) => {
    const expandedListId = useId();
    const isTruncated =
        items.length >= 9 && variant === "collapsible" && !!showAllText;
    const [showAll, setShowAll] = useState(!isTruncated);

    return (
        <FilterItem
            triggerText={triggerText}
            variant={variant}
            disabled={items.length === 0}
        >
            <FormCheckboxGroup
                name={name}
                items={isTruncated ? items.slice(0, 9) : items}
                onChange={onChange}
            />
            {isTruncated && (
                <>
                    <Button
                        variant="quiet"
                        size="small"
                        aria-expanded={showAll}
                        aria-controls={expandedListId}
                        onClick={() => setShowAll(!showAll)}
                        style={{
                            marginBottom: showAll
                                ? "var(--g-spacing-md)"
                                : undefined,
                            marginTop: "var(--g-spacing-md)",
                        }}
                    >
                        {showAllText(showAll)}
                    </Button>
                    <div id={expandedListId} hidden={!showAll}>
                        <FormCheckboxGroup
                            name={name}
                            items={items.slice(9)}
                            onChange={onChange}
                        />
                    </div>
                </>
            )}
        </FilterItem>
    );
};
