"use client";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { QueryType, UseAlgoliaResponse } from "@wojo/services";
import { useEffect, useRef } from "react";

type UseAlgoliaQueryOptions = {
    page: number;
    nbItems: number;
    ruleContexts: string[];
    filters: {};
    distinct?: number;
    attributesToRetrieve?: string[];
};

type QueryFunction = (opts: {
    page: number;
    hitsPerPage: number;
    ruleContexts: string[];
    filters: {};
    distinct?: number;
    attributesToRetrieve?: string[];
}) => () => Promise<UseAlgoliaResponse<QueryType>>;

export const useAlgoliaQuery = <T extends QueryType>(
    queryFn: QueryFunction,
    queryKey: string[],
    opts: UseAlgoliaQueryOptions,
) => {
    const {
        page,
        nbItems,
        ruleContexts,
        filters,
        distinct,
        attributesToRetrieve,
    } = opts;
    const { data, isLoading, refetch } = useSuspenseQuery({
        queryKey: queryKey,
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryFn: queryFn({
            page,
            hitsPerPage: nbItems,
            ruleContexts,
            filters,
            distinct,
            attributesToRetrieve,
        }),
    });

    const queryClient = useQueryClient();
    const prevOpts = useRef<UseAlgoliaQueryOptions>(opts);

    useEffect(() => {
        const optionKeys = Object.keys(
            prevOpts.current,
        ) as (keyof UseAlgoliaQueryOptions)[];
        const hasChanged = optionKeys.some(
            (key) => prevOpts.current[key] !== opts[key],
        );
        if (hasChanged) {
            queryClient.invalidateQueries({
                queryKey: queryKey,
            });
            refetch();
        }
    }, [opts, page, queryClient, refetch, queryKey]);

    return { data: data as UseAlgoliaResponse<T>, isLoading, refetch };
};
