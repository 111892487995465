import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { Container, Grid, GridAlignment } from "@wojo/ui";
import React from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { ImageCardProps } from "../../molecules/image-card";
import { TextCardProps } from "../../molecules/text-card";

export type ImageCardGridProps = {
    layout?: "2" | "3";
    cardPosition?: GridAlignment;
    cards?: (ImageCardProps | TextCardProps)[];
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const ImageCardGrid: React.FC<ImageCardGridProps> = (props) => {
    const {
        layout,
        cardPosition,
        cards,
        spaceAbove,
        spaceBelow,
        ...otherProps
    } = props;
    const columns = parseInt(layout || "2");

    return (
        <Container>
            <Grid
                {...storyblokEditable(props)}
                columns={columns}
                alignment={cardPosition || "left"}
                className={getSpacingClassName({ spaceAbove, spaceBelow })}
                {...otherProps}
            >
                {cards?.map(
                    (cardBlok: ImageCardProps | TextCardProps, index) => (
                        <StoryblokComponent
                            blok={{ ...cardBlok, itemIndex: index, columns }}
                            key={cardBlok._uid}
                        />
                    ),
                )}
            </Grid>
        </Container>
    );
};
