import { ISbStoriesParams } from "@storyblok/react";

export const getStoryblokParams = (
    slug: string | string[],
    searchParams?: { [key: string]: string | string[] | undefined },
    resolveRelations: string | string[] = [],
) => {
    const isPreview = !!searchParams?._storyblok;
    const cacheVersion =
        process.env.NEXT_PUBLIC_ENV === "development" || isPreview
            ? Date.now()
            : undefined;

    let realPath = Array.isArray(slug) ? slug.join("/") : slug;

    const language = process.env.NEXT_PUBLIC_STORYBLOK_LANGUAGE || "default";
    const marketFolder = process.env.NEXT_PUBLIC_STORYBLOK_MARKET_FOLDER;
    const blogFolder = process.env.NEXT_PUBLIC_BLOG_FOLDER;

    const prefix =
        (blogFolder && realPath.startsWith(blogFolder)) ||
        realPath.startsWith("sandbox") ||
        realPath.startsWith("configurations")
            ? ""
            : `${marketFolder}/`;

    const fullSlug = `${prefix + realPath}`;

    const sbParams: ISbStoriesParams = {
        version: isPreview ? "draft" : "published",
        cv: cacheVersion,
        from_release: searchParams?._storyblok_release?.toString() ?? "",
        language,
        resolve_relations: resolveRelations,
    };

    return {
        fullSlug,
        sbParams,
    };
};
