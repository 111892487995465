"use client";

import { useMediaQuery } from "@mantine/hooks";
import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { Container, Slider } from "@wojo/ui";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { ContentCardProps } from "../../molecules/content-card";
import { ImageCardProps } from "../../molecules/image-card";
import { TextCardProps } from "../../molecules/text-card";
import styles from "./ContentSlider.module.scss";

export type ContentSliderProps = {
    /**
     * Media bloks to display in the slider
     */
    cards: (ContentCardProps | ImageCardProps | TextCardProps)[];
    /**
     * Sets the layout on Desktop. On Tablet & Mobile, the layout will automatically optimize for the smaller width.
     * @default "2"
     */
    layout?: "2" | "3" | "4";
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const ContentSlider: React.FC<ContentSliderProps> = (props) => {
    const { cards, layout, spaceAbove, spaceBelow } = props;

    const breakpointDesktop = "(min-width: 992px)";
    const isDesktop = useMediaQuery(breakpointDesktop, false);
    const desktopItemOffset = layout === "4" ? 0.8 : 0.5;
    const desktopWidth = `calc(${Math.round(
        100 / parseInt(layout || "2"),
    )}% - ${desktopItemOffset} * var(--item-gap))`;
    const tabletWidth = `${Math.round(100 / parseInt(layout || "2")) + 20}%`;

    return (
        <Container
            {...storyblokEditable(props)}
            className={getSpacingClassName({ spaceAbove, spaceBelow })}
        >
            <Slider
                desktopWidth={desktopWidth}
                tabletWidth={tabletWidth}
                mobileWidth="280px"
                slidesToScroll={isDesktop ? parseInt(layout || "2") : 1}
                sliderTrackWrapperClassName={styles["slider-track-wrapper"]}
                gap={isDesktop ? "var(--g-spacing-xl)" : "var(--g-spacing-md)"}
                onSliderButtonClick={(direction) =>
                    gtmSendEvent({
                        event: "track",
                        ga4_object: "CONTENT_SLIDER",
                        ga4_action: "SCROLLED",
                        direction,
                    })
                }
            >
                {cards.map(
                    (
                        card: ContentCardProps | ImageCardProps | TextCardProps,
                        index,
                    ) => (
                        <div key={card._uid} className={styles.slide}>
                            <StoryblokComponent
                                blok={{ ...card, itemIndex: index }}
                            />
                        </div>
                    ),
                )}
            </Slider>
        </Container>
    );
};

export default ContentSlider;
