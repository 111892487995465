import { getCurrency } from "@wojo/web-components";
import { useDraftQuote } from "../context";

/**
 * Returns the currency and price objects based on the draft quote currency code.
 */
export const useDraftQuoteCurrency = () => {
    const { draftQuote } = useDraftQuote();
    const currencyCode = draftQuote.order.currencyCode;

    const { formatCurrency, formattedPrice, price } = getCurrency(currencyCode);
    return {
        /**
         * Returns the price object with the currency code based on the draft quote currency code.
         */
        price,
        /**
         * From a given array of localized prices returns a formatted price string with the currency symbol based on the draft quote currency code.
         */
        formattedPrice,
        /**
         * Formats a number as a currency string with the currency symbol based on the draft quote currency code.
         */
        formatCurrency,
    };
};
