"use client";
import { useIntersection } from "@mantine/hooks";
import cn from "clsx";
import Image from "next/image";

import {
    HTMLAttributes,
    MouseEventHandler,
    useEffect,
    useId,
    useState,
} from "react";
import { TbPlayerPauseFilled, TbPlayerPlayFilled } from "react-icons/tb";
import styles from "./VideoPlayer.module.scss";
import {
    WistiaApi,
    WistiaConfig,
    WistiaPlaybackEvent,
    WistiaQueueItem,
    WistiaVideo,
    WistiaVideoControl,
    WistiaVideoState,
} from "./wistia";

declare const window: Window & {
    Wistia?: WistiaApi;
    _wq?: WistiaQueueItem[];
};

type VisibilityMap = Record<
    Extract<
        WistiaVideoControl,
        | "audioDescriptionButton"
        | "captionsButton"
        | "chapters"
        | "playbar"
        | "fullscreenControl"
        | "settingsButton"
        | "smallPlayButton"
        | "shareButton"
        | "volumeButton"
    >,
    boolean
>;

/**
 * Toggle the visibility of the Wistia controls such as the playbar, volume, etc.
 * This is useful for the auto play feature because we only want to show the play button
 * See wistia.ts for descriptions of each control
 */
const setControlsVisibility = (
    video: WistiaVideo,
    visibilityMap: VisibilityMap,
) => {
    Object.entries(visibilityMap).forEach(([control, isVisible]) => {
        video.setControlEnabled(control as keyof VisibilityMap, isVisible);
    });

    // Hide controls that we never want to show
    // See wistia.ts for descriptions of each control
    video.setControlEnabled("backgroundFocus", false);
    video.setControlEnabled("contextMenu", false);
    video.setControlEnabled("playPauseNotifier", false);
    video.setControlEnabled("focusOutline", false);
    video.setControlEnabled("wistiaLogo", false);
};

type PlaybackChangeEvent = Pick<WistiaPlaybackEvent, "source"> & {
    video: WistiaVideo;
};

export type VideoPlayerProps = {
    /**
     * When enabled, the video will repeat infinitely unless manually paused by user.
     */
    loopVideo?: boolean;
    /**
     * When enabled, the video will play automatically unless the browser
     * requests reduced motion i.e. `@media (prefers-reduced-motion: reduce)`
     */
    requestAutoplay?: boolean;
    /**
     * Play video in fullscreen mode
     */
    requestFullscreenOnPlay?: boolean;
    /**
     * Id for the video as defined by the video provider
     */
    videoId: string;
    /**
     * The hex color to apply to the control bar.
     */
    playerColor?: string;
    /**
     * A token name to apply to the control bar.
     */
    playerColorToken?: string;
    /**
     * Class name applied to the loading skeleton
     */
    loadingSkeletonClassName?: string;
    /**
     * Horizontal position of the utility button
     */
    utilityButtonPosition?: "left" | "right" | "hidden";
    /**
     * Hide main playback button
     */
    hideMainButton?: boolean;
    /**
     * Hide fullscreen control in playbar controls
     */
    hideFullScreenControl?: boolean;
    /**
     * Callback when the video playback state changes
     */
    onPlaybackChange?: (
        state: WistiaVideoState,
        event: PlaybackChangeEvent,
    ) => void;
    /**
     * Callback when video playback is ready and the video
     * object can be interacted with
     */
    onPlaybackReady?: (video: WistiaVideo) => void;
    /**
     * If the video should display with the aspect ratio
     * (width / height) of the originally uploaded video
     */
    preserveAspectRatio?: boolean;
    /**
     * Fallback image URL to display while the video is loading
     */
    fallbackImageUrl?: string;
    /**
     * Fallback image alt text from video title
     */
    fallbackImageAlt?: string;
} & HTMLAttributes<HTMLDivElement>;

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
    loopVideo,
    requestAutoplay,
    requestFullscreenOnPlay,
    playerColor: playerThemeColor,
    playerColorToken: playerThemeToken,
    loadingSkeletonClassName,
    hideFullScreenControl,
    utilityButtonPosition = "right",
    videoId,
    onPlaybackChange,
    onPlaybackReady,
    preserveAspectRatio,
    fallbackImageUrl,
    fallbackImageAlt,
    hideMainButton,
    ...rest
}) => {
    const [videoName, setVideoName] = useState("");
    const [videoAspectRatio, setVideoAspectRatio] = useState(0);
    const [isTouchControls, setIsTouchControls] = useState(false);
    const { ref: componentWrapperRef, entry } = useIntersection({
        root: typeof window !== "undefined" ? window.document : null,
        threshold: 0.25,
    });
    const [isReady, setIsReady] = useState(false);
    const [videoState, setVideoState] = useState<WistiaVideoState>(
        WistiaVideoState.BeforePlay,
    );
    const hasStarted = videoState !== WistiaVideoState.BeforePlay;
    const isPlaying = videoState === WistiaVideoState.Playing;
    const containerId = useId();
    useEffect(() => {
        /**
         * The Wistia JavaScript API is loaded via a script provided directly from Wistia
         * There is no NPM package for it. We loaded it on-demand the first time a VideoPlayer renders
         */
        const url = "https://fast.wistia.net/assets/external/E-v1.js";
        const existingScript = document.querySelector(`script[src="${url}"]`);
        if (!existingScript) {
            const script = document.createElement("script");
            script.src = url;
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    useEffect(() => {
        if (!videoId) {
            return;
        }
        const playerColor = playerThemeColor
            ? playerThemeColor
            : getComputedStyle(document.body).getPropertyValue(
                  playerThemeToken ?? "--g-color-primary-500",
              );

        const embedConfig: WistiaConfig = {
            id: containerId,
            options: {
                bigPlayButton: false,
                controlsVisibleOnLoad: false,
                endVideoBehavior: loopVideo ? "loop" : "default",
                autoPlay: false,
                fitStrategy: preserveAspectRatio ? "contain" : "cover",
                muted: requestAutoplay || undefined,
                playerColor,
            },
            onReady: (video) => {
                setVideoName(video.name());
                setVideoAspectRatio(video.aspect());
                setIsTouchControls(!!window.Wistia?.detect.touchScreen);
                setIsReady(true);
                const shouldShowBasicControls =
                    !requestAutoplay || utilityButtonPosition === "hidden";
                setControlsVisibility(video, {
                    audioDescriptionButton: shouldShowBasicControls,
                    captionsButton: shouldShowBasicControls,
                    chapters: shouldShowBasicControls,
                    playbar: shouldShowBasicControls,
                    fullscreenControl: !hideFullScreenControl,
                    settingsButton: shouldShowBasicControls,
                    smallPlayButton: shouldShowBasicControls,
                    shareButton: shouldShowBasicControls,
                    volumeButton: shouldShowBasicControls,
                });
            },
        };
        window._wq = window._wq || [];
        window._wq.push(embedConfig);

        return () => {
            window._wq!.push({ revoke: embedConfig });
        };
    }, [
        containerId,
        requestAutoplay,
        loopVideo,
        videoId,
        playerThemeColor,
        playerThemeToken,
        utilityButtonPosition,
        hideFullScreenControl,
        preserveAspectRatio,
    ]);

    useEffect(() => {
        /**
         * Event listeners are bound separately from initialization because we don't want
         * updated event handlers to reset the video state. Doing so can result in
         * events not being heard or being heard multiple times.
         */
        const video = window.Wistia?.api(containerId);

        if (!video || !isReady) {
            return;
        }

        const handlePlaybackChange = (event: WistiaPlaybackEvent) => {
            setVideoState(video.state());
            onPlaybackChange?.(video.state(), {
                source: event.source,
                video,
            });

            if (
                video.state() === WistiaVideoState.Playing &&
                requestFullscreenOnPlay &&
                !video.inFullscreen()
            ) {
                video.requestFullscreen();
            }
        };

        const handleEnterFullscreen = () => {
            if (requestFullscreenOnPlay) {
                setControlsVisibility(video, {
                    audioDescriptionButton: true,
                    captionsButton: true,
                    chapters: true,
                    playbar: true,
                    fullscreenControl: true,
                    settingsButton: true,
                    smallPlayButton: true,
                    shareButton: true,
                    volumeButton: true,
                });
            }
        };

        const handleCancelFullscreen = () => {
            if (requestFullscreenOnPlay) {
                setControlsVisibility(video, {
                    audioDescriptionButton: true,
                    captionsButton: true,
                    chapters: true,
                    playbar: true,
                    fullscreenControl: !hideFullScreenControl,
                    settingsButton: true,
                    smallPlayButton: true,
                    shareButton: true,
                    volumeButton: true,
                });
                video.pause();
            }
        };

        video.bind("end", handlePlaybackChange);
        video.bind("pause", handlePlaybackChange);
        video.bind("play", handlePlaybackChange);
        video.bind("enterfullscreen", handleEnterFullscreen);
        video.bind("cancelfullscreen", handleCancelFullscreen);

        return () => {
            video.unbind("end", handlePlaybackChange);
            video.unbind("pause", handlePlaybackChange);
            video.unbind("play", handlePlaybackChange);
            video.unbind("enterfullscreen", handleEnterFullscreen);
            video.unbind("cancelfullscreen", handleCancelFullscreen);
        };
    }, [
        containerId,
        requestFullscreenOnPlay,
        hideFullScreenControl,
        onPlaybackReady,
        onPlaybackChange,
        isReady,
    ]);

    useEffect(() => {
        const video = window.Wistia?.api(containerId);
        if (video && isReady) {
            onPlaybackReady?.(video);
            return;
        }
    }, [containerId, isReady, onPlaybackReady]);

    useEffect(() => {
        const video = window.Wistia?.api(containerId);
        if (!video || !isReady) {
            return;
        }
        const reduceMotion = window.matchMedia(
            "(prefers-reduced-motion: reduce)",
        ).matches;
        if (entry?.isIntersecting) {
            if (
                !reduceMotion &&
                requestAutoplay &&
                (video.state() === WistiaVideoState.BeforePlay ||
                    video.state() === WistiaVideoState.Paused)
            ) {
                video.play();
            }
        } else {
            if (video.state() === WistiaVideoState.Playing) {
                video.pause();
            }
        }
    }, [containerId, isReady, requestAutoplay, entry?.isIntersecting]);

    if (!videoId) {
        return null;
    }

    const togglePlayback: MouseEventHandler<HTMLButtonElement> = (e) => {
        const video = window.Wistia?.api(containerId);
        const isPlaying = video?.state() === WistiaVideoState.Playing;

        isPlaying ? video.pause() : video?.play();
        if (
            e.currentTarget.classList.contains(styles["main-button"]) &&
            !isPlaying
        ) {
            const wistiaPlayerWrapper = document.getElementById(containerId);
            wistiaPlayerWrapper?.focus();
        }
    };

    return (
        <div
            {...rest}
            ref={componentWrapperRef}
            className={cn(rest.className, styles.wrapper)}
            style={{
                ...rest.style,
                aspectRatio:
                    preserveAspectRatio && videoAspectRatio
                        ? `${videoAspectRatio} / 1`
                        : undefined,
            }}
        >
            {isReady && (
                <button
                    type="button"
                    onClick={togglePlayback}
                    className={cn({
                        [styles["utility-button"]]: requestAutoplay,
                        [styles[`utility-button--${utilityButtonPosition}`]]:
                            requestAutoplay,
                        [styles["main-button"]]: !requestAutoplay,
                        [styles.playing]: isPlaying,
                        [styles.started]: hasStarted,
                        [styles["main-button--hidden"]]: hideMainButton,
                    })}
                    aria-label={
                        isPlaying
                            ? `Pause video: ${videoName}`
                            : `Play video: ${videoName}`
                    }
                    hidden={
                        utilityButtonPosition === "hidden" ||
                        hideMainButton ||
                        (hasStarted && !requestAutoplay && !isTouchControls)
                    }
                >
                    {isPlaying ? (
                        <TbPlayerPauseFilled aria-hidden />
                    ) : (
                        <TbPlayerPlayFilled aria-hidden />
                    )}
                </button>
            )}

            <div
                key={videoId}
                id={containerId}
                tabIndex={-1}
                className={cn(
                    "wistia_embed",
                    `wistia_async_${videoId}`,
                    styles["wistia-player"],
                )}
            />

            <div
                aria-hidden={isReady || undefined}
                className={cn(
                    styles["loading-wrapper"],
                    loadingSkeletonClassName,
                )}
            >
                {fallbackImageUrl && (
                    <Image
                        src={fallbackImageUrl}
                        alt={`Loading ${
                            fallbackImageAlt ?? "video placeholder"
                        }`}
                        className={styles["loading-image"]}
                        fill={true}
                        priority={true}
                    />
                )}
            </div>
        </div>
    );
};
