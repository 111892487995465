/**
 * These are the types for the Wistia JavaScript Player API v1.0
 * which is imported through a script tag rather than npm
 *
 * There is no official type definition for this API but there is a DefinitelyTyped one.
 * However those types are 2 years old and for the Legacy API, not the API as of Jan 2024.
 *
 * The types in this file define a subset of what the Wistia JavaScript Player API provides
 *
 * Wistia DOCS: https://docs.wistia.com/docs/javascript-player-api
 * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/wistia-player-browser/index.d.ts
 */

/**
 * Global object attached to `window` by the Wistia JavaScript Player API upon loading
 */
export type WistiaApi = {
    /**
     * Retrieve a `WistiaVideo` for the given id
     * If more than one instance is on the page then only the first instance is returned
     *
     * @param id: The Wistia video id or the id of the element containing the video
     */
    api: (id: string) => WistiaVideo | null;
    /**
     * Features browser detected by the Wistia JavaScript Player API
     */
    detect: {
        /**
         * If truthy Wistia considers user input to be on a touch screen
         */
        touchScreen: boolean | number | object | undefined;
    };
};

/**
 * Item that can be pushed to the `_wq` array on `window`
 * Items are consumed when the Wistia Javascript Player API is fully loaded on the page
 */
export type WistiaQueueItem = WistiaConfig | { revoke: WistiaConfig };

/**
 * Object used to initialize a `WistiaVideo` via the Wistia JavaScript Player API
 */
export type WistiaConfig = {
    /**
     * Id of the video in the Wistia content library UI
     */
    id: string;
    /**
     * Options for the Wistia video
     */
    options?: WistiaVideoOptions;
    /**
     * Event handler for when the video is ready for visible and ready for playback
     */
    onReady?(video: WistiaVideo): void;
};

export type WistiaVideoEventName =
    | "play"
    | "pause"
    | "end"
    | "enterfullscreen"
    | "cancelfullscreen";

export type WistiaPlaybackEvent = {
    source: WistiaPlaybackEventSource;
};

export type WistiaPlaybackEventSource = "user-event" | "non-user-event";

export type WistiaVideoOptions = {
    /**
     * Should auto play the video
     */
    autoPlay?: boolean;
    /**
     * Should show the default big play button
     */
    bigPlayButton?: boolean;
    /**
     * Should show the control bar when the video loads
     */
    controlsVisibleOnLoad?: boolean;
    /**
     * Action taken when
     * default: stay at end
     * reset: seek to start and remain paused
     * loop: set to start and replay video
     */
    endVideoBehavior?: "default" | "reset" | "loop";
    /**
     * How to fit the video in it's container. Similar to CSS `object-fit`
     */
    fitStrategy?: "cover" | "contain" | "fill" | "none";
    /**
     * Should mute the video by default
     */
    muted?: boolean;
    /**
     * Color of the default player controls expressed as a hex string
     */
    playerColor?: string;
};

export enum WistiaVideoState {
    BeforePlay = "beforeplay",
    Playing = "playing",
    Paused = "paused",
    Ended = "ended",
}

/**
 * An instance of a Wistia JavaScript API video
 */
export type WistiaVideo = {
    /** Returns the aspect ratio (width / height) of the originally uploaded video. */
    aspect: () => number;
    /**
     *
     * Bind an event handler to a video player event
     */
    bind(
        wistiaPlayerEvent: Extract<
            WistiaVideoEventName,
            "play" | "pause" | "end"
        >,
        callback: (event: WistiaPlaybackEvent) => void,
    ): void;
    bind(
        wistiaPlayerEvent: Exclude<
            WistiaVideoEventName,
            "play" | "pause" | "end"
        >,
        callback: () => void,
    ): void;
    /**
     * Metadata about the video
     */
    data: {
        media: {
            /**
             * Id of the video, referred to by wojo as the wistiaVideoId
             */
            hashedId: string;
        };
    };
    /**
     * Get the name of the video
     */
    name: () => string;
    /**
     * Pause the video
     */
    pause: () => void;
    /**
     * Play the video
     */
    play: () => void;
    /**
     * Safely end playback, end network activity end remove the video from the DOM
     */
    remove: () => void;
    /**
     * Request the video to enter fullscreen mode
     */
    requestFullscreen: () => void;
    /**
     * Is the video in fullscreen mode
     */
    inFullscreen: () => boolean;
    /**
     * Show or hide a player control (button, slider, etc)
     */
    setControlEnabled: (name: WistiaVideoControl, enabled: boolean) => void;
    /**
     * Get the current state of the player
     */
    state: () => WistiaVideoState;
    /**
     *
     * Unbind an event handler to a video player event
     */
    unbind(
        wistiaPlayerEvent: Extract<
            WistiaVideoEventName,
            "play" | "pause" | "end"
        >,
        callback: (event: WistiaPlaybackEvent) => void,
    ): void;
    unbind(
        wistiaPlayerEvent: Exclude<
            WistiaVideoEventName,
            "play" | "pause" | "end"
        >,
        callback: () => void,
    ): void;
    /**
     * Unmute the video
     */
    unmute: () => WistiaVideoState;
    /**
     * Seek to a specific time in the video
     */
    time: (videoTime: number) => void;
};

export type WistiaVideoControl =
    /**
     * Button that toggles the audio description menu
     */
    | "audioDescriptionButton"
    /**
     * Hidden button that can receive keyboard focus for toggling playback state
     */
    | "backgroundFocus"
    /**
     * Play button in the center of the video player
     */
    | "bigPlayButton"
    /**
     * Button that toggles the captions menu
     */
    | "captionsButton"
    /**
     * Chapters menu
     */
    | "chapters"
    /**
     * Context menu that appears when right-clicking a video
     */
    | "contextMenu"
    /**
     * Outline around the video player that appears when "backgroundFocus" has focus
     */
    | "focusOutline"
    /**
     * Button that toggles fullscreen mode
     */
    | "fullscreenControl"
    /**
     * Slider for seeking
     */
    | "playbar"
    /**
     * Button that appears when the player gains focus
     */
    | "playPauseLoading"
    /**
     * Icon that appears briefly when the video state changes to playing or paused
     */
    | "playPauseNotifier"
    /**
     * Button that toggles the settings menu
     */
    | "settingsButton"
    /**
     * Play button inline with the other controls in the bottom bar
     */
    | "smallPlayButton"
    /**
     * Button that toggles the share button
     */
    | "shareButton"
    /**
     * Button that toggles the volume slider
     */
    | "volumeButton"
    /**
     * Wistia branding
     */
    | "wistiaLogo";

declare const window: Window & {
    Wistia?: WistiaApi;
    _wq?: WistiaQueueItem[];
};

export const getVideoPlayerHandler = (videoId: string) => {
    return window.Wistia?.api(videoId);
};
