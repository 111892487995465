import { gtmSendEvent } from "@wojo/analytics";
import { Modal, ModalBody, ModalHeader } from "@wojo/ui";
import React from "react";
import { useLocation } from "react-router-dom";
import { useInternalAuth } from "../client/context/InternalAuthContextProvider";
import { AuthBody } from "../components/router/AuthBody";
import { AuthFooter } from "../components/router/AuthFooter";
import { AuthPaths } from "../components/router/AuthPaths";
import { AuthTitle } from "../components/router/AuthTitle";

export const AuthModal: React.FC = () => {
    const {
        setIsModalOpen,
        isModalOpen,
        omitPaths,
        loginOrigin,
        lastFilledField,
        setLastFilledField,
    } = useInternalAuth();
    const { pathname } = useLocation();
    const isOpen =
        isModalOpen &&
        typeof window !== "undefined" &&
        !omitPaths?.includes(window.location.pathname);
    return (
        <Modal
            onCloseRequested={() => {
                if (pathname === AuthPaths.CreateAccount) {
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            ga4_object: "LEAD_FORM",
                            ga4_action: "ABANDONED",
                            abandonmentField: lastFilledField || "None",
                            leadFormId: `registration${loginOrigin}`,
                        },
                    });
                    setLastFilledField?.("");
                }
                setIsModalOpen?.(false);
            }}
            isOpen={!!isOpen}
            accessibleTitle={"Login"}
            hideDefaultHeader
        >
            <ModalHeader>
                <AuthTitle />
            </ModalHeader>
            <ModalBody>
                <AuthBody />
            </ModalBody>
            <AuthFooter />
        </Modal>
    );
};
