"use client";

import { useMediaQuery } from "@mantine/hooks";
import cn from "clsx";
import { FC, ReactNode } from "react";
import styles from "./Pagination.module.scss";

type PaginationItemsProps = {
    range: (number | "dots")[];
    active: number;
    siblings: number | undefined;
    truncationElement: ReactNode;
    setPage: (item: number) => void;
};

export const PaginationItems: FC<PaginationItemsProps> = ({
    range,
    active,
    siblings,
    truncationElement,
    setPage,
}) => {
    const isBreakpointMd = useMediaQuery("(min-width: 768px)");

    const multipleTruncationElementsInRange =
        range.filter((item) => item === "dots").length > 1;

    const renderTruncation = (index: number) => {
        // manually display the next item before the last truncation when
        // there are two truncation elements in the range
        const showNextItem =
            !siblings &&
            multipleTruncationElementsInRange &&
            index === range.lastIndexOf("dots");

        if (showNextItem) {
            const manualNextItem = isBreakpointMd ? 2 : 1;
            return (
                <>
                    <button
                        type="button"
                        onClick={() => setPage(active + manualNextItem)}
                        aria-label={`Page ${active + manualNextItem}`}
                        className={styles["pagination-item"]}
                    >
                        {active + manualNextItem}
                    </button>
                    <span className={styles["truncation-wrapper"]}>
                        {truncationElement}
                    </span>
                </>
            );
        } else {
            return (
                <span className={styles["truncation-wrapper"]}>
                    {truncationElement}
                </span>
            );
        }
    };

    return (
        <ul className={styles["pagination-items"]} aria-label="Pages">
            {range.map((item, index) => {
                return (
                    <li key={`pagination-item-${index}`}>
                        {item === "dots" ? (
                            renderTruncation(index)
                        ) : (
                            <button
                                className={cn(styles["pagination-item"], {
                                    [styles["active"]]: active === item,
                                })}
                                aria-label={`Page ${item}`}
                                type="button"
                                onClick={() => setPage(item)}
                                aria-current={active === item || undefined}
                            >
                                {item}
                            </button>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};
