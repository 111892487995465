import type { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { Container } from "@wojo/ui";
import cn from "clsx";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { Border, BorderProps } from "../../components/border";
import { ContentPosition } from "../../types/position";
import { Spacing } from "../../types/spacing";
import { TextWidth } from "../../types/text";
import styles from "./SectionContainer.module.scss";

export type SectionContainerProps = {
    /**
     * Name of the anchor link associated with this section for use with the `AnchorNavigation` component
     */
    anchorLinkName?: string;

    /**
     * ID of the anchor link associated with this section for use with buttons
     */
    anchorId?: string;
    /**
     * Padding after the main content of the section to extend the background color
     */
    backgroundBottomInnerSpacing?: Spacing;
    /**
     * Background color of the section
     */
    backgroundColor?: string;
    /**
     * End color of the background gradient, overrides `backgroundColor` when present with `backgroundStartColor`
     */
    backgroundEndColor?: string;
    /**
     * Start color of the background gradient, overrides `backgroundColor` when present with `backgroundEndColor`
     */
    backgroundStartColor?: string;
    /**
     * Padding before the main content of the section to extend the background color
     */
    backgroundTopInnerSpacing?: Spacing;
    /**
     * Main bloks of the section
     */
    body?: SbBlokData[];
    /**
     * Bloks rendered directly after the `body`
     */
    footer?: SbBlokData[];
    /**
     * Angle of the background gradient
     */
    gradientAngle?: string;
    /**
     * Bloks rendered directly before the `body`
     */
    header?: SbBlokData[];
    /**
     * Alignment of the header and footer text and content
     */
    headerFooterTextAlignmentContentPosition?: ContentPosition;
    /**
     * Width of the header and footer text and content
     */
    headerFooterTextWidth?: TextWidth;
} & Pick<SpacingProps, "spaceAbove" | "spaceBelow"> &
    BorderProps &
    SbBlokData;

export const SectionContainer: React.FC<SectionContainerProps> = (props) => {
    const {
        anchorId,
        backgroundBottomInnerSpacing,
        backgroundColor,
        backgroundEndColor,
        backgroundStartColor,
        backgroundTopInnerSpacing,
        body,
        borderColor,
        borderEndColor,
        borderGradientAngle,
        borderImage,
        borderPosition,
        borderRadius,
        borderSize,
        borderStartColor,
        footer,
        gradientAngle,
        header,
        headerFooterTextAlignmentContentPosition,
        headerFooterTextWidth,
        spaceAbove,
        spaceBelow,
    } = props;

    const showBackgroundGradient = backgroundEndColor && backgroundStartColor;
    const showBackgroundColor = backgroundColor && !showBackgroundGradient;

    const containerPosition =
        headerFooterTextAlignmentContentPosition === ContentPosition.Center
            ? "center"
            : "left";

    return (
        <Border
            {...storyblokEditable(props)}
            borderColor={borderColor}
            borderEndColor={borderEndColor}
            borderGradientAngle={borderGradientAngle}
            borderImage={borderImage}
            borderPosition={borderPosition}
            borderRadius={borderRadius}
            borderSize={borderSize}
            borderStartColor={borderStartColor}
            className={getSpacingClassName({
                spaceAbove,
                spaceBelow,
            })}
            data-ga4-object="SECTION_CONTAINER"
        >
            <div
                className={cn(
                    styles["section-container"],
                    getSpacingClassName({
                        innerSpacingBottom: backgroundBottomInnerSpacing,
                        innerSpacingTop: backgroundTopInnerSpacing,
                    }),
                    {
                        [styles["wrapper-background-gradient"]]:
                            showBackgroundGradient,
                        [styles["wrapper-background-color"]]:
                            showBackgroundColor,
                    },
                )}
                style={
                    {
                        "--section-background-color": backgroundColor,
                        "--section-background-end-color": backgroundEndColor,
                        "--section-background-gradient-angle":
                            gradientAngle || "180deg",
                        "--section-background-start-color":
                            backgroundStartColor,
                    } as React.CSSProperties
                }
            >
                <Container
                    width={headerFooterTextWidth}
                    position={containerPosition}
                    contentsClassName={styles["section-container"]}
                    className={styles[`text-align--${containerPosition}`]}
                >
                    {header?.map((blok) => (
                        <div key={blok._uid}>
                            <StoryblokComponent blok={blok} />
                        </div>
                    ))}
                </Container>
                {body?.map((blok) => (
                    <div key={blok._uid} id={anchorId}>
                        <StoryblokComponent blok={blok} />
                    </div>
                ))}
                <Container
                    width={headerFooterTextWidth}
                    position={containerPosition}
                    contentsClassName={styles["section-container"]}
                    className={styles[`text-align--${containerPosition}`]}
                >
                    {footer?.map((blok) => (
                        <div key={blok._uid}>
                            <StoryblokComponent blok={blok} />
                        </div>
                    ))}
                </Container>
            </div>
        </Border>
    );
};
