export enum Opacity {
    Percent0 = 0,
    Percent10 = 0.1,
    Percent20 = 0.2,
    Percent30 = 0.3,
    Percent40 = 0.4,
    Percent50 = 0.5,
    Percent60 = 0.6,
    Percent70 = 0.7,
    Percent80 = 0.8,
    Percent90 = 0.9,
    Percent100 = 1,
}
