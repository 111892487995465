import cn from "clsx";
import React, { CSSProperties } from "react";
import { extractAspectRatioFromImageFile } from "../../client/extract-aspect-ratio-from-image-file";
import { Border } from "../../components/border";
import { StoryblokImage } from "../../components/storyblok-image";
import {
    imageAspectRatioValueForCSSMap,
    imageAspectRatioValueMap,
} from "../../constants/image-ratio";
import { ImageRatio } from "../../types/image";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import styles from "./ContentCard.module.scss";

type ContentCardImageProps = {
    image: AssetStoryblok;
    imageRatio: ImageRatio;
};

// 1232px is the max width of desktop layouts, multiple by 2 for high density screens
const desktopImageWidth = 1232 * 2;
// 768px is the max width of mobile layouts, multiple by 2 for high density screens
const mobileImageWidth = 768 * 2;

export const ContentCardImage: React.FC<ContentCardImageProps> = ({
    image,
    imageRatio,
}) => {
    if (!image.filename) {
        return null;
    }

    const imageRadius =
        imageRatio === ImageRatio.Circle1By1 ? "50%" : undefined;

    const ratioValue = imageAspectRatioValueMap[imageRatio];
    const mobileImageHeight = ratioValue
        ? Math.round(mobileImageWidth / ratioValue)
        : undefined;
    const desktopImageHeight = ratioValue
        ? Math.round(desktopImageWidth / ratioValue)
        : undefined;

    return (
        <Border
            className={cn(
                styles.border,
                imageRatio === ImageRatio.Circle1By1 &&
                    styles["border--circular"],
            )}
        >
            <StoryblokImage
                mobile={{
                    asset: image,
                    size: {
                        height: mobileImageHeight,
                        width: mobileImageWidth,
                    },
                }}
                desktop={{
                    asset: image,
                    size: {
                        height: desktopImageHeight,
                        width: desktopImageWidth,
                    },
                }}
                className={styles.image}
                style={
                    {
                        "--aspect-ratio":
                            imageRatio !== ImageRatio.Original
                                ? imageAspectRatioValueForCSSMap[imageRatio]
                                : extractAspectRatioFromImageFile(image),
                        "--border-radius": imageRadius,
                    } as CSSProperties
                }
            />
        </Border>
    );
};
