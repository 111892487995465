import { gtmSendEvent } from "@wojo/analytics";
import { Card } from "@wojo/ui";
import cn from "clsx";
import React from "react";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";
import { getSpacingClassName } from "../../client/get-spacing-class-name";
import type { PageLinkProps } from "./PageLink";
import styles from "./PageLinkWrapper.module.scss";

export type PageLinkWrapperProps = React.PropsWithChildren<
    {
        textId?: string;
        imageAspectRatio?: string;
        imageRadius?: string;
    } & Partial<PageLinkProps>
>;

export const PageLinkWrapper: React.FC<PageLinkWrapperProps> = (props) => {
    const {
        backgroundColor,
        backgroundEndColor,
        backgroundStartColor,
        cardStyle,
        customLinkTextAndIconColor,
        children,
        descriptionId,
        gradientAngle,
        imageAspectRatio,
        imageRadius,
        link,
        linkTextAndIconColor,
        linkTextStyle,
        spaceAbove,
        spaceBelow,
        text,
        textId,
        ...rest
    } = props;

    const showBackgroundColor = cardStyle !== "card" && backgroundColor;
    const showBackgroundGradient =
        cardStyle !== "card" && backgroundEndColor && backgroundStartColor;

    const { href, isExternal } = getCleanStoryblokLink(link);
    const [target, rel] = isExternal
        ? ["_blank", "noopener"]
        : [undefined, undefined];

    const getWrapperType = () => {
        if (cardStyle === "card") {
            return Card;
        } else {
            return href ? "a" : "div";
        }
    };

    const Wrapper = getWrapperType();
    return (
        <Wrapper
            {...rest}
            aria-labelledby={textId}
            href={href || undefined}
            tag={href && cardStyle === "card" ? "a" : undefined}
            target={target}
            rel={rel}
            onClick={() => {
                gtmSendEvent({
                    event: "track",
                    ga4_object: "PAGE_LINK",
                    ga4_action: "CLICKED",
                    clickText: text,
                    destinationUrl: href,
                });
            }}
            className={cn(
                getSpacingClassName({ spaceAbove, spaceBelow }),
                styles.wrapper,
                {
                    [styles.card]: cardStyle === "card",
                    [styles["wrapper-background-color"]]: showBackgroundColor,
                    [styles["wrapper-background-gradient"]]:
                        showBackgroundGradient,
                },
            )}
            style={
                {
                    "--page-link-background-color": backgroundColor,
                    "--page-link-background-start-color": backgroundStartColor,
                    "--page-link-background-end-color": backgroundEndColor,
                    "--page-link-gradient-angle": gradientAngle || "180deg",
                    "--page-link-text-style": linkTextStyle,
                    "--page-link-text-and-icon-color": linkTextAndIconColor,
                    "--page-link-custom-text-and-icon-color":
                        customLinkTextAndIconColor,
                    "--page-link-image-aspect-ratio": imageAspectRatio,
                    "--page-link-border-radius": imageRadius,
                } as React.CSSProperties
            }
        >
            {children}
        </Wrapper>
    );
};
