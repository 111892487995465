import { Text } from "@wojo/ui";
import styles from "./CountdownTimer.module.scss";

type DateTimeDisplayProps = {
    value: number | string;
    label: string;
    size?: "2" | "3" | "4" | "5" | "6";
    color?: string;
    showSeparator: boolean;
};

export const DateTimeDisplay: React.FC<DateTimeDisplayProps> = ({
    value,
    label,
    size,
    color,
    showSeparator,
}) => {
    const colorStyle = color ? { color } : undefined;

    return (
        <div className={styles["timer-unit"]}>
            <Text.Heading
                order={size}
                style={colorStyle}
                tag={"span"}
                suppressHydrationWarning={true} // We are always running a risk of hydration mismatch here because time ticks off the clock.
            >
                {value}
                {label}
            </Text.Heading>
            {showSeparator ? (
                <Text.Heading order={size} style={colorStyle} tag={"span"}>
                    <span className={styles.separator}>:</span>
                </Text.Heading>
            ) : null}
        </div>
    );
};
