import { useSuspenseQuery } from "@tanstack/react-query";
import { Text } from "@wojo/ui";
import { TitleAtom } from "../../atoms/title";
import { fetchStoryblokData } from "../../client/fetch-storyblok-data";
import { Spacing } from "../../types/spacing";

export type BlogListingTitleProps = {
    name: string | undefined;
};

export const BlogListingTitle: React.FC<BlogListingTitleProps> = ({ name }) => {
    const { data } = useSuspenseQuery({
        queryKey: ["configurations/blog"],
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            return fetchStoryblokData("configurations/blog", {});
        },
    });
    if (!name) {
        return null;
    }

    return (
        <>
            <Text.Detail variant="2">
                {data.data?.story?.content?.blogTitle}
            </Text.Detail>
            <TitleAtom
                heading={name}
                headingTag="h1"
                headingSize="h1"
                spaceBelow={Spacing.XLarge}
            />
        </>
    );
};
