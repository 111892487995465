import { SbBlokData, storyblokEditable } from "@storyblok/react";
import React from "react";
import { StoryblokImage } from "../../components/storyblok-image";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import styles from "./FlexibleImage.module.scss";

export type FlexibleImageProps = {
    /**
     * Image displayed in the flexible image component.
     */
    image: AssetStoryblok;
} & SbBlokData;

export const FlexibleImage: React.FC<FlexibleImageProps> = (props) => {
    const { image } = props;

    return (
        <div {...storyblokEditable(props)} className={styles.container}>
            <StoryblokImage
                coverContainer
                mobile={{
                    asset: image,
                }}
            />
        </div>
    );
};
