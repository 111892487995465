import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import { FormCombobox, Text } from "@wojo/ui";
import { useResultsContext } from "../results-provider/ResultsProvider";
import styles from "./GatewaySelector.module.scss";

export const GatewaySelector: React.FC = ({}) => {
    const { facets } = useResultsContext();
    const i18n = useI18n("storyblok").storyblok;
    const gatewayFacet = facets.find(
        (facet) => facet.name === "gateways.displayName",
    );
    const gatewayNames = Object.keys(gatewayFacet?.data ?? {}).filter(
        (name) => name !== "Land Only (LO1)",
    );
    const comboboxOptions = ["No Flights Included", ...gatewayNames].map(
        (name) => ({
            label: name,
            value: name === "No Flights Included" ? "Land Only (LO1)" : name,
        }),
    );

    return (
        <div className={styles.wrapper}>
            <Text.Body emphasis="1" aria-hidden>
                {i18n.gatewaySelector.flightsFromLabel.l()}
            </Text.Body>
            <div className={styles["combobox-wrapper"]}>
                <FormCombobox
                    items={comboboxOptions}
                    label={i18n.gatewaySelector.flightsFromLabel.l()}
                    hideLabel
                    name="gatewayName"
                    onChange={(value) => {
                        if (value) {
                            gtmSendEvent({
                                event: "track",
                                ga4Tracking: {
                                    ga4_object: "ELEMENT",
                                    ga4_action: "FILTERED",
                                    filterType: "gatewayName",
                                    filteredBy: value,
                                },
                            });
                        }
                    }}
                />
            </div>
        </div>
    );
};
