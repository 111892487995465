import { QuoteCheckoutType } from "generated/graphql";
import { useDraftQuote } from "./context";

const depositTypes = [QuoteCheckoutType.AutoPay, QuoteCheckoutType.Manual];

type CheckoutOptions = ReturnType<
    typeof useDraftQuote
>["draftQuote"]["order"]["checkoutOptions"];

export const getDefaultMinimumDeposit = (options: CheckoutOptions) => {
    const depositOptions = options.filter((option) =>
        depositTypes.includes(option.type),
    );

    return depositOptions.length
        ? Math.min(...depositOptions.map((option) => option.amount.value))
        : null;
};
