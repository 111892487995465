import { Grid, LoadingSkeleton } from "@wojo/ui";
import styles from "./DealsTourCardGridFallback.module.scss";

type DealsTourCardGridFallbackProps = {
    numberOfResults: number;
    hitLabel: string;
};

export const DealsTourCardGridFallback: React.FC<
    DealsTourCardGridFallbackProps
> = ({ hitLabel, numberOfResults }) => {
    return (
        <>
            <LoadingSkeleton
                width="100%"
                height="50px"
                accessibleText={`Loading ${hitLabel}`}
                style={{ marginBottom: "var(--g-spacing-md)" }}
            />
            <LoadingSkeleton width="250px" height="20px" accessibleText="" />
            <Grid
                columns={1}
                gap={{
                    xs: "var(--g-spacing-lg)",
                    sm: "var(--g-spacing-lg)",
                }}
                className={styles.grid}
            >
                {new Array(numberOfResults).fill(null).map((_, index) => (
                    <div className={styles.card} key={index}>
                        <div className={styles.image}>
                            <LoadingSkeleton
                                width="100%"
                                height="100%"
                                accessibleText=""
                            />
                        </div>
                        <div className={styles["card-content"]}>
                            <LoadingSkeleton
                                width="90%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="70%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="100%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="100%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="100%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="50%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="10%"
                                height="30px"
                                accessibleText=""
                            />
                        </div>
                    </div>
                ))}
            </Grid>
            <LoadingSkeleton
                width="260px"
                height="50px"
                accessibleText=""
                style={{ margin: "0 auto", display: "block" }}
            />
        </>
    );
};
