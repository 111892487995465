import { useDraftQuote } from "../context";
import { getLineItems } from "../get-line-items";
import currency from "currency.js";
import { FlightPackageLineItem, FlightType } from "../../generated/graphql";
import groupBy from "lodash/groupBy";

export const useFlights = () => {
    const { draftQuote } = useDraftQuote();

    const { flightPackageLineItems } = getLineItems(draftQuote.order.trips);
    const hasAirportFlights = flightPackageLineItems.some(
        (item) => !item.isLandOnly && item.flightType === FlightType.Departing,
    );
    const hasLandOnlyFlights = flightPackageLineItems.some(
        (item) => item.isLandOnly,
    );

    const departingFlightPackages = flightPackageLineItems.filter(
        (item) => item.flightType === FlightType.Departing,
    );
    const uniqueDepartingLineItemCodes = [
        ...new Set(
            departingFlightPackages.map(
                (flightPackage) => flightPackage.lineItemCode,
            ),
        ),
    ];
    const allTripsHaveSameHomeGateway =
        uniqueDepartingLineItemCodes.length === 1;

    const tripsByDepartingFlightPackage = groupBy(
        draftQuote.order.trips,
        (trip) =>
            trip.lineItems.find(
                (item) =>
                    item.__typename === "FlightPackageLineItem" &&
                    item.flightType === FlightType.Departing,
            )?.lineItemCode,
    );

    const perGatewaySummary = Object.entries(tripsByDepartingFlightPackage).map(
        ([_, trips]) => {
            const flightPackages = trips[0].lineItems.filter(
                (item): item is FlightPackageLineItem =>
                    item.__typename === "FlightPackageLineItem",
            );
            const gateway = flightPackages
                .filter((flight) => flight.flightType === FlightType.Departing)
                .at(0)?.departureGateway;
            const totalPrice = trips
                .flatMap((trip) =>
                    trip.lineItems.filter(
                        (item): item is FlightPackageLineItem =>
                            item.__typename === "FlightPackageLineItem",
                    ),
                )
                .reduce(
                    (acc, cur) => acc.add(cur.price.value),
                    currency(0),
                ).value;
            return {
                count: trips.length,
                totalPrice,
                isLandOnly: flightPackages.at(0)?.isLandOnly,
                gateway,
                lineItemCode:
                    trips[0].openDepartingFlightLineItem?.lineItemCode,
            };
        },
    );

    return allTripsHaveSameHomeGateway
        ? {
              perGatewaySummary,
              hasAirportFlights,
              hasLandOnlyFlights,
              flightPackageLineItems,
              allTripsHaveSameHomeGateway,
              allLandOnly: departingFlightPackages.every(
                  (item) => item.isLandOnly,
              ),
              cityName:
                  departingFlightPackages[0].departureGateway
                      ?.internalCityName ?? "",
          }
        : {
              perGatewaySummary,
              hasAirportFlights,
              hasLandOnlyFlights,
              flightPackageLineItems,
              allTripsHaveSameHomeGateway,
          };
};
