import { gtmSendEvent } from "@wojo/analytics";
import { Card, Text } from "@wojo/ui";
import cn from "clsx";
import { ContentAsset } from "../../../generated/graphql";
import { SchemaScript } from "../../components/schema-script";
import { StoryblokImage } from "../../components/storyblok-image";
import styles from "./BlogCard.module.scss";

export type BlogCardProps = {
    author: string;
    category: string;
    featuredImage: ContentAsset;
    fullSlug: string;
    id: string;
    internalTags: string[];
    tags: string[];
    title: string;
    tourCodes: string[];
    places: string[];
    objectID: string;
    variant?: "flat" | "card";
};

// 1232px is the max width of desktop layouts, multiple by 2 for high density screens
const desktopImageWidth = 1232 * 2;
const desktopImageHeight = desktopImageWidth / (16 / 9); // 16:9 aspect ratio

// 768px is the max width of mobile layouts, multiple by 2 for high density screens
const mobileImageWidth = 768 * 2;
const mobileImageHeight = mobileImageWidth / (16 / 9); // 16:9 aspect ratio

export const BlogCard: React.FC<BlogCardProps> = ({
    category,
    title,
    fullSlug,
    featuredImage,
    variant,
    author,
    objectID,
    tourCodes,
}) => {
    return (
        <Card
            aria-label={title}
            className={cn(
                styles["blog-card"],
                variant === "flat" && styles["blog-card--flat"],
            )}
        >
            <SchemaScript
                data={{
                    "@type": "Article",
                    "@context": "https://schema.org",
                    headline: title,
                    image: featuredImage.filename,
                    category,
                    author: {
                        "@type": "Person",
                        name: author,
                        url: `https://www.efultimatebreak.com`,
                    },
                }}
            />
            <a
                href={`/${fullSlug}`}
                className={styles["blog-card-wrapper"]}
                onClick={() => {
                    gtmSendEvent({
                        event: "track",
                        ga4_object: "BLOG_CARD",
                        ga4_action: "CLICKED",
                        category,
                        title,
                        author,
                        objectID,
                        tourCodes: tourCodes.join(","),
                    });
                }}
            >
                <div className={cn(styles["blog-card-media"])}>
                    <StoryblokImage
                        desktop={{
                            asset: featuredImage,
                            size: {
                                height: desktopImageHeight,
                                width: desktopImageWidth,
                            },
                        }}
                        mobile={{
                            asset: featuredImage,
                            size: {
                                height: mobileImageHeight,
                                width: mobileImageWidth,
                            },
                        }}
                    />
                </div>

                <div
                    className={cn(
                        styles["blog-card-body"],
                        variant === "flat" && styles["blog-card-body--flat"],
                    )}
                >
                    <Text.Detail
                        style={{ paddingBottom: "var(--g-spacing-xs)" }}
                    >
                        {category}
                    </Text.Detail>

                    <Text.Body
                        emphasis="2"
                        tag="p"
                        className={styles["blog-card-title"]}
                    >
                        {title}
                    </Text.Body>
                </div>
            </a>
        </Card>
    );
};
