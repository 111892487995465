"use client";
import { useAuth } from "@wojo/auth";
import {
    BusinessCode,
    useContactInfoContentSuspenseQuery,
} from "generated/graphql";

export const usePhoneNumber = (): {
    phoneNumber: string;
    formattedPhoneNumber: string;
} => {
    const { data: contactInfoData } = useContactInfoContentSuspenseQuery({
        variables: {
            language: process.env.NEXT_PUBLIC_STORYBLOK_LANGUAGE ?? "",
            businessCode: process.env.NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
        },
        context: {
            fetchOptions: { next: { revalidate: 900 } },
            includeCookie: false,
        },
    });
    const { webSource } = useAuth();
    const gcDeparture = webSource === "GROUPS";
    const contactDepartment = !!gcDeparture
        ? contactInfoData?.contactInfoContent?.groupSales
        : contactInfoData?.contactInfoContent?.indiesSales;

    return {
        phoneNumber: contactDepartment?.phoneNumber ?? "",
        formattedPhoneNumber: contactDepartment?.phoneNumberFormatted ?? "",
    };
};
