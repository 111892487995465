import React from "react";
import { CheckboxGroup, CheckboxGroupProps } from "../../checkbox-group";
import { FormField, FormFieldProps } from "../form-field";

export type FormCheckboxGroupProps = FormFieldProps<CheckboxGroupProps>;

export const FormCheckboxGroup: React.FC<FormCheckboxGroupProps> = ({
    validation,
    name,
    ...checkboxGroupProps
}) => {
    return (
        <FormField name={name} validation={validation}>
            <CheckboxGroup name={name} {...checkboxGroupProps} />
        </FormField>
    );
};
