"use client";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
    DraftQuoteFragmentFragment,
    PaymentScheduleFrequency,
    QuoteCheckoutType,
} from "generated/graphql";
import React, { createContext, useContext, useState } from "react";
import { useWebConfigSuspenseQuery } from "client/hooks";
import { getDefaultMinimumDeposit } from "../../get-default-minimum-deposit";
dayjs.extend(utc);

type DraftQuoteContextValue = React.PropsWithChildren<{
    draftQuote: DraftQuoteFragmentFragment;
    hasManualPaymentFees: boolean;
    minimumDeposit: number | null;
    setHasManualPaymentFees: React.Dispatch<React.SetStateAction<boolean>>;
    setMinimumDeposit: React.Dispatch<React.SetStateAction<number | null>>;
}>;

const DraftQuoteContext = createContext<DraftQuoteContextValue>({
    draftQuote: {} as DraftQuoteFragmentFragment,
    hasManualPaymentFees: false,
    minimumDeposit: null,
    setHasManualPaymentFees: () => {},
    setMinimumDeposit: () => {},
});

type DraftQuoteContextProviderProps = React.PropsWithChildren<{
    draftQuote: DraftQuoteFragmentFragment;
}>;

export const DraftQuoteContextProvider: React.FC<
    DraftQuoteContextProviderProps
> = ({ children, draftQuote }) => {
    const calculatedMinimum =
        draftQuote.order.__typename === "Quote"
            ? getDefaultMinimumDeposit(draftQuote.order.checkoutOptions)
            : null;

    const [minimumDeposit, setMinimumDeposit] = useState<number | null>(
        calculatedMinimum,
    );
    const [hasManualPaymentFees, setHasManualPaymentFees] =
        useState<boolean>(false);

    return (
        <DraftQuoteContext.Provider
            value={{
                draftQuote,
                hasManualPaymentFees,
                minimumDeposit,
                setHasManualPaymentFees,
                setMinimumDeposit,
            }}
        >
            {children}
        </DraftQuoteContext.Provider>
    );
};

export const useDraftQuote = () => {
    const {
        draftQuote,
        minimumDeposit,
        hasManualPaymentFees,
        setMinimumDeposit,
        setHasManualPaymentFees,
    } = useContext(DraftQuoteContext);
    const config = useWebConfigSuspenseQuery();

    if (draftQuote.order.__typename !== "Quote") {
        // TODO: this could be better if we change the fragment in use to be a QuoteFragment instead of a DraftQuoteFragment. It's not like we need the id of the draftQuote on the same object
        throw new Error("DraftQuoteContext must be used within a Quote");
    }

    const tripsWithExtension = draftQuote.order.trips.filter(
        (trip) => !!trip.openExtensionLineItem,
    );

    const shouldCollectPassport = draftQuote.order.checkoutOptions.every(
        (option) => option.type === QuoteCheckoutType.PayInFull,
    );

    const primaryTrip = draftQuote.order.trips.find((trip) => trip.isPrimary);

    const paymentFrequency =
        config.defaultPaymentFrequency || PaymentScheduleFrequency.Monthly;

    return {
        draftQuote: {
            ...draftQuote,
            // This cast should not be needed as we would have thrown above and the following line compiles ....
            // const _typename: "Quote" = draftQuote.order.__typename;
            order: draftQuote.order as DraftQuoteFragmentFragment["order"] & {
                __typename: "Quote";
            },
        },
        shouldCollectPassport,
        primaryTrip,
        tripsWithExtension,
        minimumDeposit,
        setMinimumDeposit,
        hasManualPaymentFees,
        setHasManualPaymentFees,
        paymentFrequency,
        isRebooking:
            !!draftQuote.order.rebookedFromOrderId &&
            process.env.NEXT_PUBLIC_UNRELEASED_FEATURE_REBOOKING_ENABLED ===
                "true",
        draftId: draftQuote._id,
    };
};
