import { LoadingSkeleton } from "@wojo/ui";
import styles from "./ReviewsListFallback.module.scss";

type ReviewsListFallbackProps = {
    numberOfResults: number;
};

export const ReviewsListFallback: React.FC<ReviewsListFallbackProps> = ({
    numberOfResults,
}) => {
    return (
        <>
            <LoadingSkeleton
                width="160px"
                height="50px"
                accessibleText="Loading reviews"
                style={{ marginBottom: "var(--g-spacing-sm)" }}
            />
            <LoadingSkeleton
                width="100%"
                height="30px"
                accessibleText=""
                style={{ marginBottom: "var(--g-spacing-lg)" }}
            />
            <div className={styles["reviews-list"]}>
                {new Array(numberOfResults).fill(null).map((_, index) => (
                    <LoadingSkeleton
                        key={index}
                        width="100%"
                        height="200px"
                        accessibleText=""
                    />
                ))}
            </div>
            <LoadingSkeleton
                width="260px"
                height="50px"
                accessibleText=""
                style={{ margin: "0 auto", display: "block" }}
            />
        </>
    );
};
