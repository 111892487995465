import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent } from "@storyblok/react/rsc";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { StoryblokLink } from "../../types/storyblok/storyblok-link";

export type SeoProps = {
    title: string;
    description: string;
    ogTitle: string;
    ogDescription: string;
    ogUrl: string;
    ogType: string;
    ogImage?: AssetStoryblok | string;
    ogVideo: string;
    ogVideoSecureUrl: string;
    ogVideoWidth: string;
    ogVideoHeight: string;
    canonicalUrl?: StoryblokLink;
    twitterCard: string;
    twitterSite: string;
    noIndex: boolean;
    noFollow: boolean;
    schemas?: SbBlokData[];
};

export const Seo: React.FC<SeoProps> = (props) => {
    //Metadata tags are generated in the app/[...slug]/page.tsx file
    return (
        <>
            {props.schemas?.length
                ? props.schemas.map((schema) => (
                      <StoryblokComponent blok={schema} key={schema._uid} />
                  ))
                : null}
        </>
    );
};
