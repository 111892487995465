import { gtmSendEvent } from "@wojo/analytics";
import { Card, Container } from "@wojo/ui";
import cn from "clsx";
import React from "react";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";
import { getSpacingClassName } from "../../client/get-spacing-class-name";
import { BorderRadius } from "../../types/border";
import { type TextBannerProps } from "./TextBanner";
import styles from "./TextBannerWrapper.module.scss";

export type TextBannerWrapperProps = React.PropsWithChildren<
    {
        titleId?: string;
        descriptionId?: string;
    } & Partial<TextBannerProps>
>;

export const TextBannerWrapper: React.FC<TextBannerWrapperProps> = (props) => {
    const {
        backgroundColor,
        backgroundEndColor,
        backgroundStartColor,
        bannerStyle,
        borderRadius,
        buttonLink,
        children,
        gradientAngle,
        spaceAbove,
        spaceBelow,
        text,
        descriptionId,
        titleId,
        buttonText,
        ...rest
    } = props;

    const { href, isExternal } = getCleanStoryblokLink(buttonLink);
    const [target, rel] = isExternal
        ? ["_blank", "noopener"]
        : [undefined, undefined];

    const getWrapperType = () => {
        if (bannerStyle === "card") {
            return Card;
        } else {
            return href ? "a" : "div";
        }
    };

    const Wrapper = getWrapperType();
    return (
        <Container
            {...rest}
            className={getSpacingClassName({ spaceAbove, spaceBelow })}
        >
            <Wrapper
                aria-labelledby={titleId}
                aria-describedby={descriptionId}
                href={href || undefined}
                tag={href && bannerStyle === "card" ? "a" : undefined}
                target={target}
                rel={rel}
                className={cn(styles.wrapper, {
                    [styles["wrapper-border-radius"]]:
                        borderRadius !== BorderRadius.NoBorderRadius,
                })}
                onClick={() => {
                    gtmSendEvent({
                        event: "track",
                        ga4_object: "TEXT_BANNER",
                        ga4_action: "CLICKED",
                        heading: text?.[0]?.heading,
                        clickText: buttonText,
                        destinationUrl:
                            getCleanStoryblokLink(buttonLink).href || "",
                    });
                }}
                style={
                    {
                        "--text-banner-background-color": backgroundColor,
                        "--text-banner-background-end-color":
                            backgroundEndColor,
                        "--text-banner-gradient-angle":
                            gradientAngle || "180deg",
                        "--text-banner-background-start-color":
                            backgroundStartColor,
                    } as React.CSSProperties
                }
            >
                {children}
            </Wrapper>
        </Container>
    );
};
