import { useDraftQuote } from "client/context";
import {
    CheckoutDepartureDocument,
    CheckoutDepartureQuery,
    CheckoutDepartureQueryVariables,
} from "../../generated/graphql";
import { useSuspenseQuery } from "@apollo/client";

export const useDeparture = () => {
    const { draftQuote } = useDraftQuote();
    const result = useSuspenseQuery<
        CheckoutDepartureQuery,
        CheckoutDepartureQueryVariables
    >(CheckoutDepartureDocument, {
        variables: {
            departureId: draftQuote.order.departureId,
            language: process.env.NEXT_PUBLIC_STORYBLOK_LANGUAGE ?? "",
        },
        context: { fetchOptions: { cache: "no-store" } },
    });
    return result.data.departure;
};
