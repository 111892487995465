import type { AssetStoryblok } from "../types/storyblok/asset-storyblok";

export function extractAspectRatioFromImageFile(
    file: AssetStoryblok,
): string | undefined {
    const url = file.filename;
    if (!url) {
        return undefined;
    }
    const regex = /\/(\d+x\d+)\//;
    const match = url.match(regex);
    if (!match) {
        return undefined;
    }

    let [width, height] = match[1].split("x").map(Number);
    const gcd = (a: number, b: number): number => (b === 0 ? a : gcd(b, a % b));
    //reduce by greatest divisor cuz it feels weird returning 1000/1000 instead of 1/1
    const divisor = gcd(width, height);

    width /= divisor;
    height /= divisor;

    return `${width}/${height}`;
}
