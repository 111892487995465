import cn from "clsx";
import styles from "./Modal.module.scss";

export type ModalBodyProps = React.PropsWithChildren<{}> &
    React.HTMLAttributes<HTMLElement>;

export const ModalBody = ({
    children,
    className,
    ...props
}: ModalBodyProps) => {
    if (!children) {
        return null;
    }

    return (
        <div className={cn(styles.body, className)} {...props}>
            {children}
        </div>
    );
};
