import {
    ApolloLink,
    FetchResult,
    NextLink,
    Observable,
    Observer,
    Operation,
} from "@apollo/client";
import { executingMutations } from "./executing-mutation-reactive-vars";

type OperationItem = {
    operation: Operation;
    forward: NextLink;
    observer: Observer<FetchResult>;
    subscription?: { unsubscribe: () => void };
};

export class EnqueueMutationLink extends ApolloLink {
    private operationItems: OperationItem[] = [];
    private mutations: string[] = [];
    private isActive = false;
    private executeOperation(item: OperationItem) {
        const { operation, observer, forward } = item;
        this.isActive = true;

        this.mutations.push(operation.operationName);
        executingMutations(this.mutations);

        forward(operation).subscribe({
            next: (res) => {
                this.isActive = false;
                this.mutations = this.mutations.filter(
                    (item) => item !== operation.operationName,
                );
                executingMutations(this.mutations);

                observer.next?.(res);
                if (this.operationItems.length) {
                    this.executeOperation(this.operationItems.shift()!);
                } else {
                    executingMutations([]);
                }
            },
            error: (error) => {
                this.isActive = false;
                this.mutations = this.mutations.filter(
                    (item) => item !== operation.operationName,
                );
                executingMutations(this.mutations);

                observer.error?.(error);
                if (this.operationItems.length) {
                    this.executeOperation(this.operationItems.shift()!);
                } else {
                    this.mutations = [];
                    executingMutations(this.mutations);
                }
            },
            complete: () => {
                this.mutations = [];
                observer.complete?.bind(observer);
            },
        });
    }

    private cancelOperation(item: OperationItem) {
        this.operationItems = this.operationItems.filter((e) => e !== item);
    }

    private enqueue(item: OperationItem) {
        this.operationItems.push(item);
    }

    public request(
        operation: Operation,
        forward: NextLink,
    ): Observable<FetchResult> | null {
        const isMutation = operation.query.definitions.some((definition) => {
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "mutation"
            );
        });
        if (isMutation) {
            return new Observable((observer) => {
                const item: OperationItem = { operation, forward, observer };
                if (!this.isActive) {
                    this.executeOperation(item);
                } else {
                    this.enqueue(item);
                }
                return () => this.cancelOperation(item);
            });
        } else {
            // @ts-ignore bad vendor types
            return forward(operation);
        }
    }
}
