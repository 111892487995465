import { FiX } from "react-icons/fi";
import styles from "./Tag.module.scss";

export type TagProps = React.PropsWithChildren<{
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}> &
    (
        | {
              isDismissible: true;
              accessibleDismissText: string;
          }
        | {
              isDismissible?: false;
          }
    );

// TODO: This component is a stub pending the completion of this final UX spec https://ef-wojo.atlassian.net/browse/WWB-339
export const Tag: React.FC<TagProps> = (props) => {
    const { children, isDismissible, onClick } = props;
    return (
        <div className={styles.wrapper}>
            {isDismissible && <div className="visually-hidden">{children}</div>}
            <button type="button" className={styles.button} onClick={onClick}>
                <span aria-hidden={isDismissible || undefined}>{children}</span>
                {props.isDismissible && (
                    <>
                        <span className="visually-hidden">
                            {props.accessibleDismissText}
                        </span>
                        <FiX aria-hidden />
                    </>
                )}
            </button>
        </div>
    );
};
