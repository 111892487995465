import cn from "clsx";
import { forwardRef, HtmlHTMLAttributes } from "react";
import styles from "./DropdownList.module.scss";

export type DropdownItemProps = HtmlHTMLAttributes<HTMLLIElement> & {
    isSelected?: boolean;
    isHighlighted?: boolean;
};

export const DropdownItem = forwardRef<HTMLLIElement, DropdownItemProps>(
    ({ children, isSelected, isHighlighted, ...props }, ref) => (
        <li
            {...props}
            ref={ref}
            className={cn(
                [styles["list-item"]],
                props.className,
                {
                    [styles["list-item--selected"]]: isSelected,
                },
                {
                    [styles["list-item--highlighted"]]: isHighlighted,
                },
            )}
        >
            {children}
        </li>
    ),
);
DropdownItem.displayName = "DropdownItem";
