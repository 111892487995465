import cn from "clsx";
import { ComponentPropsWithoutRef, HTMLAttributes, ReactNode } from "react";
import { LoadingSpinner } from "../loading-spinner";
import styles from "./IconButton.module.scss";

type ValidHTMLTags = "div" | "button";
type ReactComponent = Exclude<React.ElementType, keyof JSX.IntrinsicElements>;

type ValidTags = ValidHTMLTags | ReactComponent;

export type IconButtonProps<T extends ValidTags> =
    (ComponentPropsWithoutRef<T> & HTMLAttributes<HTMLOrSVGElement>) & {
        /**
         * Set the color palette. Will typically be primary, secondary, or monochrome.
         * @default primary
         */
        palette?: string;
        /**
         * Pick which variant to use: primary or secondary
         * @default primary
         */
        variant?: "primary" | "secondary";
        /**
         * Size of the button: small or standard
         * @default standard
         */
        size?: "small" | "standard";
        /**
         * The icon to display, typically from "react-icons"
         */
        icon: ReactNode;
        /**
         * The aria-label to give the button
         */
        ariaLabel: string;
        /**
         * Set the icon to the loading indicator
         */
        loading?: boolean;
        /**
         * Alternate JSX element to use for the button
         * @default button
         */
        tag?: T | ValidTags;
    };

const DEFAULT_TAG = "button" as const;

export const IconButton = <T extends ValidTags = typeof DEFAULT_TAG>({
    palette = "primary",
    variant = "primary",
    size = "standard",
    icon,
    ariaLabel,
    loading,
    tag = DEFAULT_TAG,
    className,
    ...rest
}: IconButtonProps<T>) => {
    const disabled =
        (rest as JSX.IntrinsicElements["button"]).disabled ?? false;
    const iconClassName = cn(
        styles.container,
        styles[`container--${variant}`],
        styles[`container--${variant}--size-${size}`],
        `icon-button-${variant}-fallback-palette`,
        `icon-button-${variant}-${palette}-palette`,
        "icon-button-shared-component-tokens",
        `icon-button-${variant}-component-tokens`,
        className,
    );

    const Tag: ValidTags = tag;
    return (
        <Tag
            {...rest}
            className={iconClassName}
            disabled={disabled || loading}
            aria-label={ariaLabel ? ariaLabel : undefined}
            aria-live="polite"
            aria-atomic={true}
            data-loading={loading || undefined}
        >
            {loading ? <LoadingSpinner /> : icon}
        </Tag>
    );
};
