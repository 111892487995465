"use client";

import { useFullscreen } from "@mantine/hooks";
import { SbBlokData, storyblokEditable } from "@storyblok/react";
import { getGa4Object, gtmSendEvent } from "@wojo/analytics";
import { Text, VideoPlayer } from "@wojo/ui";
import { useVideoMetadata } from "@wojo/services";
import cn from "clsx";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import styles from "./FlexibleVideo.module.scss";

export type FlexibleVideoProps = {
    /**
     * Id for the video as defined in wistia
     */
    wistiaVideoId?: string;
    /**
     * Video type: Autoplay without sound on loop | click to play fullscreen video.
     * @default autoPlay
     */
    videoType?: "autoPlay" | "clickToPlayFullscreen";
} & SbBlokData;

const FlexibleVideoInternal: React.FC<FlexibleVideoProps> = (props) => {
    const { videoType, wistiaVideoId } = props;
    const { fullscreen, ref } = useFullscreen();
    const silentAutoPlayLoop = !videoType || videoType === "autoPlay";
    const isVideoFullscreen =
        fullscreen && videoType === "clickToPlayFullscreen";

    const { data: videoMetadata } = useVideoMetadata(wistiaVideoId);

    if (!wistiaVideoId) {
        return null;
    }

    return (
        <div
            {...storyblokEditable(props)}
            className={cn(styles.wrapper, {
                [styles["wrapper--fullscreen"]]: isVideoFullscreen,
            })}
            ref={ref}
            id="flexible-video"
        >
            <VideoPlayer
                videoId={wistiaVideoId}
                className={styles["video-player"]}
                fallbackImageUrl={videoMetadata?.url}
                fallbackImageAlt={videoMetadata?.videoTitle}
                requestFullscreenOnPlay={videoType === "clickToPlayFullscreen"}
                requestAutoplay={silentAutoPlayLoop}
                loopVideo={silentAutoPlayLoop}
                loadingSkeletonClassName={styles["loading-skeleton"]}
                onPlaybackChange={(videoState) => {
                    gtmSendEvent({
                        event: "track",
                        ga4_object: "FLEXIBLE_VIDEO",
                        ga4_action: videoState.toUpperCase(),
                        parentObject: getGa4Object(
                            document.getElementById("flexible-video"),
                        ),
                        videoType,
                        videoId: wistiaVideoId,
                    });
                }}
            />
        </div>
    );
};
export const FlexibleVideo: React.FC<FlexibleVideoProps> = (props) => {
    return (
        <ErrorBoundary fallback={<Text.Body>Error loading content</Text.Body>}>
            <FlexibleVideoInternal {...props} />
        </ErrorBoundary>
    );
};
