import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { Container, MediaSlider as WojoUiMediaSlider } from "@wojo/ui";
import cn from "clsx";
import { ImageAtomProps } from "../../atoms/image";
import { VideoAtomProps } from "../../atoms/video";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import styles from "./MediaSlider.module.scss";

export enum MobileContentHeight {
    OptimizedForHorizontalMedia = "horizontal",
    OptimizedForVerticalMedia = "vertical",
}
export type MediaSliderProps = {
    /**
     * Media bloks to display in the slider. Either ImageAtom or VideoAtom
     */
    media: (ImageAtomProps | VideoAtomProps)[];
    /**
     * Sets the max height of the slider. The aspect ratio of the slider is determined by screen size breakpoints
     * @default MobileContentHeight.OptimizedForHorizontalMedia
     */
    mobileContentHeight?: MobileContentHeight;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const MediaSlider: React.FC<MediaSliderProps> = (props) => {
    const { media, mobileContentHeight, spaceAbove, spaceBelow } = props;

    return (
        <Container
            {...storyblokEditable(props)}
            className={cn(
                getSpacingClassName({ spaceAbove, spaceBelow }),
                styles[
                    `slider-container--${
                        mobileContentHeight ||
                        MobileContentHeight.OptimizedForHorizontalMedia
                    }`
                ],
            )}
        >
            <WojoUiMediaSlider
                showThumbnailNav={false}
                loop={true}
                sliderNumberIndicatorClassName={styles["slider-nav"]}
                slides={media.map(
                    (mediaItem: ImageAtomProps | VideoAtomProps, index) => {
                        const slide = (
                            <div className={styles.slide} key={index}>
                                <StoryblokComponent
                                    blok={mediaItem}
                                    key={mediaItem._uid}
                                />
                            </div>
                        );
                        return {
                            media: slide,
                            thumbnail: slide,
                        };
                    },
                )}
            />
        </Container>
    );
};
