import { useMemo } from "react";

export type SchemaScriptProps = {
    data?: Record<string, unknown> | null;
};
export const SchemaScript: React.FC<SchemaScriptProps> = ({ data }) => {
    const dataText = useMemo(() => {
        if (!data) {
            return null;
        }
        return JSON.stringify(data);
    }, [data]);
    if (!dataText) {
        return null;
    }
    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: dataText }}
        />
    );
};
