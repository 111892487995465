import { getGa4Object, gtmSendEvent } from "@wojo/analytics";
import { BusinessCode } from "@wojo/services/generated/graphql";
import { Grid } from "@wojo/ui";
import uniq from "lodash/uniq";
import React from "react";
import { TourCard } from "../../../components/tour-card";
import { TextCard } from "../../../molecules/text-card";
import { TourCardGridContentProps } from "../types";
import styles from "./TourCardGridContent.module.scss";

export const TourCardGridContent: React.FC<TourCardGridContentProps> = ({
    actionCard,
    currentPage,
    getIsInWishlist,
    merchandisingCard,
    merchandisingCardPlacement,
    pages,
    showDesktopCardOnMobile,
    tours,
    toggleWishlist,
}) => {
    const tourCardGridItems = tours.map((tour, index) => {
        return (
            <TourCard
                {...tour}
                forceDesktopLayout={showDesktopCardOnMobile}
                isInWishlist={getIsInWishlist(tour.tourCode)}
                key={tour.objectID}
                toggleWishlist={() => {
                    !getIsInWishlist(tour.tourCode) &&
                        gtmSendEvent({
                            event: "add_to_wishlist",
                            ecommerce: {
                                currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
                                value: tour.price,
                                items: [
                                    {
                                        item_id: tour.tourCode,
                                        item_name: tour.name,
                                        item_brand:
                                            process.env
                                                .NEXT_PUBLIC_BUSINESS_CODE,
                                        item_category: tour.countries.join(","),
                                        item_category2: tour.cities.join(","),
                                        item_variant: "Tour",
                                        market: process.env
                                            .NEXT_PUBLIC_MARKET_CODE,
                                        tourCode: tour.tourCode,
                                        price: tour.salePrice || tour.price,
                                        quantity: 1,
                                        activityLevel: tour.activityLevel?.name,
                                        classLevel: tour.classLevel?.name,
                                        rating: tour.rating,
                                    },
                                ],
                            },
                            ga4Tracking: {
                                ga4_object: "TOUR",
                                ga4_action: "ADD_TO_WISHLIST",
                                tourCode: tour.tourCode,
                                tourName: tour.name,
                                parentObject: "TOUR_CARD",
                            },
                        });

                    toggleWishlist(tour.tourCode);
                }}
                variant="grid"
                onClick={(e) =>
                    gtmSendEvent({
                        event: "select_item",
                        ga4Tracking: {
                            ga4_object: "TOUR_CARD",
                            ga4_action: "CLICKED",
                            tourCode: tour.tourCode,
                            tourName: tour.name,
                            parentObject: getGa4Object(e.currentTarget) ?? "",
                            tourBadge: tour.tourBadge?.text ?? "",
                            isInWishlist: `${getIsInWishlist(tour.tourCode)}`,
                        },
                        ecommerce: {
                            item_list_name: "Tour List",
                            items: [
                                {
                                    item_id: tour.tourCode,
                                    item_name: tour.name,
                                    highestDiscount: tour.highestDiscount,
                                    index,
                                    item_brand: process.env
                                        .NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
                                    item_category: uniq(tour.countries).join(
                                        ",",
                                    ),
                                    item_category2: uniq(tour.cities).join(","),
                                    item_variant: "Tour",
                                    item_list_name: "Tour List",
                                    market: process.env.NEXT_PUBLIC_MARKET_CODE,
                                    onSale: tour.isOnSale,
                                    tourCode: tour.tourCode,
                                    classLevel: tour.classLevel?.name,
                                    price: tour.price,
                                    salePrice: tour.salePrice,
                                    activityLevel: tour.activityLevel?.name,
                                    rating: tour.rating,
                                    isFeatured: tour.isFeatured,
                                },
                            ],
                        },
                    })
                }
            />
        );
    });

    const merchandisingCardIndex =
        parseInt(merchandisingCardPlacement || "1") - 1;

    const merchandisingCardGridItem =
        merchandisingCard?.map((card) => (
            <TextCard
                key={card._uid}
                {...card}
                itemIndex={merchandisingCardIndex}
            />
        )) ?? [];

    const actionCardGridItem =
        actionCard?.map((card) => (
            <TextCard
                key={card._uid}
                {...card}
                itemIndex={
                    tourCardGridItems.length +
                    merchandisingCardGridItem.length +
                    1
                }
            />
        )) ?? [];

    const showActionCard = currentPage + 1 === pages;

    const allGridItems = [
        ...tourCardGridItems.slice(0, merchandisingCardIndex),
        ...merchandisingCardGridItem,
        ...tourCardGridItems.slice(merchandisingCardIndex),
        ...(showActionCard ? actionCardGridItem : []),
    ];

    return (
        <Grid
            columns={3}
            className={styles["grid-wrapper"]}
            gap={{
                xs: "var(--g-spacing-lg)",
                sm: "var(--g-spacing-lg)",
            }}
        >
            {allGridItems.map((item) => (
                <React.Fragment key={item.key}>{item}</React.Fragment>
            ))}
        </Grid>
    );
};
