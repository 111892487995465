import { QueryType } from "@wojo/services";
import { LoadingSkeleton, Text } from "@wojo/ui";
import React, { Suspense, useId } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FilterBar } from "./filter-bar/FilterBar";
import { FilterName } from "./form-types";
import { GatewaySelector } from "./gateway-selector/GatewaySelector";
import { ResultsLabel } from "./results-label/ResultsLabel";
import { ResultsPagination } from "./results-pagination/ResultsPagination";
import { ResultsProvider } from "./results-provider/ResultsProvider";
import {
    ResultsRenderer,
    ResultsRenderProp,
} from "./results-renderer/ResultsRenderer";
import styles from "./SearchResults.module.scss";
import { SelectedFiltersList } from "./selected-filters-list/SelectedFiltersList";

export type SearchResultsProps<Type extends QueryType> = {
    queryKey: string[];
    filters: FilterName[];
    hitLabel?: string;
    hitsPerPage: number;
    render: ResultsRenderProp<Type>;
    ruleContexts?: string[];
    queryType: Type;
    showSort: boolean;
    distinct?: number;
    showResultsTopLabel?: boolean;
    loadingFallback?: React.ReactNode;
    attributesToRetrieve?: string[];
    showGatewaySelector?: boolean;
    defaultSort?: string;
};

export function SearchResults<Type extends QueryType>({
    queryKey,
    filters,
    hitsPerPage,
    hitLabel = "results",
    render,
    ruleContexts,
    queryType,
    showSort,
    distinct,
    attributesToRetrieve,
    showResultsTopLabel = true,
    loadingFallback,
    showGatewaySelector,
    defaultSort,
}: SearchResultsProps<Type>) {
    const wrapperId = useId();
    const resultsLabelId = useId();
    return (
        <div
            id={wrapperId}
            className={styles.wrapper}
            aria-label={hitLabel}
            role="region"
        >
            <ErrorBoundary
                fallback={<Text.Body>Failed to load {hitLabel}</Text.Body>}
            >
                <Suspense
                    fallback={
                        loadingFallback ?? (
                            <LoadingSkeleton
                                accessibleText={`Loading ${hitLabel}`}
                            />
                        )
                    }
                >
                    <ResultsProvider
                        queryKey={queryKey}
                        hitLabel={hitLabel}
                        hitsPerPage={hitsPerPage}
                        queryType={queryType}
                        ruleContexts={ruleContexts}
                        distinct={distinct}
                        defaultSort={defaultSort}
                        attributesToRetrieve={attributesToRetrieve}
                        retrieveGatewayPricing={!!showGatewaySelector}
                    >
                        {(!!filters.length ||
                            showSort ||
                            showGatewaySelector) && (
                            <form
                                aria-label={`${hitLabel} filter options`}
                                className={styles["filter-bar-wrapper"]}
                            >
                                {showGatewaySelector && (
                                    <div
                                        className={
                                            styles["gateway-selector-wrapper"]
                                        }
                                    >
                                        <GatewaySelector />
                                    </div>
                                )}
                                {(!!filters.length || showSort) && (
                                    <div className={styles["sticky-wrapper"]}>
                                        <FilterBar
                                            filters={filters}
                                            showSort={showSort}
                                        />
                                    </div>
                                )}
                            </form>
                        )}
                        <div
                            aria-labelledby={resultsLabelId}
                            className={styles["results-wrapper"]}
                        >
                            <ResultsPagination
                                onChange={() => {
                                    document
                                        .getElementById(wrapperId)
                                        ?.scrollIntoView({
                                            block: "start",
                                            behavior: "smooth",
                                        });
                                }}
                            >
                                <div
                                    className={
                                        styles["selected-filters-wrapper"]
                                    }
                                >
                                    {showResultsTopLabel && (
                                        <div id={resultsLabelId}>
                                            <ResultsLabel />
                                        </div>
                                    )}
                                    <SelectedFiltersList />
                                </div>
                                <ResultsRenderer render={render} />
                            </ResultsPagination>
                        </div>
                    </ResultsProvider>
                </Suspense>
            </ErrorBoundary>
        </div>
    );
}
