import { gtmSendEvent } from "@wojo/analytics";
import { BusinessCode } from "@wojo/services/generated/graphql";
import { Grid } from "@wojo/ui";
import uniq from "lodash/uniq";
import React from "react";
import {
    LightningDealCard,
    mapDepartureToProps,
} from "../../../components/lightning-deal-card";
import { LightningDealCardGridContentProps } from "../types";
import styles from "./LightningDealCardGridContent.module.scss";

export const LightningDealCardGridContent: React.FC<
    LightningDealCardGridContentProps
> = ({
    departures,
    hideDepartureBadge,
    getIsInWishlist,
    toggleWishlist,
    algoliaRuleTriggerContext,
}) => {
    return (
        <Grid
            columns={3}
            className={styles["grid-wrapper"]}
            gap={{
                xs: "var(--g-spacing-lg)",
                sm: "var(--g-spacing-lg)",
            }}
        >
            {departures.map((departure, index) => {
                const card = mapDepartureToProps(departure);
                return (
                    <div key={departure.objectID}>
                        <LightningDealCard
                            {...card}
                            hideDepartureBadge={hideDepartureBadge}
                            isInWishlist={getIsInWishlist(card.tourCode)}
                            toggleWishlist={() => {
                                !getIsInWishlist(card.tourCode) &&
                                    gtmSendEvent({
                                        event: "add_to_wishlist",
                                        ecommerce: {
                                            currency:
                                                process.env
                                                    .NEXT_PUBLIC_CURRENCY_CODE,
                                            value: departure.price,
                                            items: [
                                                {
                                                    item_id: departure.tourCode,
                                                    item_name: departure.name,
                                                    item_brand:
                                                        process.env
                                                            .NEXT_PUBLIC_BUSINESS_CODE,
                                                    item_variant: "Tour",
                                                    market: process.env
                                                        .NEXT_PUBLIC_MARKET_CODE,
                                                    tourCode:
                                                        departure.tourCode,
                                                    price:
                                                        departure.salePrice ||
                                                        departure.price,
                                                    quantity: 1,
                                                    activityLevel:
                                                        departure.activityLevel
                                                            ?.name,
                                                    classLevel:
                                                        departure.classLevel
                                                            ?.name,
                                                    rating: departure.rating,
                                                },
                                            ],
                                        },
                                        ga4_object: "TOUR",
                                        ga4_action: "ADD_TO_WISHLIST",
                                        tourCode: departure.tourCode ?? "",
                                        tourName: departure.name,
                                        parentObject:
                                            "LIGHTNING_DEAL_TOUR_CARD_GRID",
                                    });

                                toggleWishlist(card.tourCode);
                            }}
                            onClick={() => {
                                gtmSendEvent({
                                    event: "add_to_cart",
                                    ga4_object: "LIGHTNING_DEAL_TOUR_CARD_GRID",
                                    ga4_action: "CLICKED",
                                    tourCode: departure.tourCode ?? "",
                                    tourName: departure.name,
                                    algoliaRuleTriggerContext,
                                    ecommerce: {
                                        currency:
                                            process.env
                                                .NEXT_PUBLIC_CURRENCY_CODE ||
                                            "",
                                        value:
                                            departure.salePrice ??
                                            departure.price,
                                        items: [
                                            {
                                                item_id: departure.id,
                                                item_name: departure.name,
                                                discount:
                                                    departure.discountAmount,
                                                index,
                                                item_brand: process.env
                                                    .NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
                                                item_category: uniq(
                                                    departure.countries,
                                                ).join(","),
                                                item_category2: uniq(
                                                    departure.cities,
                                                ).join(","),
                                                item_variant: "Departure",
                                                price: departure.price,
                                                salePrice: departure.salePrice,
                                                hasExtension:
                                                    departure.hasExtension,
                                                isLastMinuteSpecial:
                                                    departure.isLastMinuteSpecial,
                                                quantity: 1,
                                                classLevel:
                                                    departure.classLevel?.name,
                                                onSale: departure.isOnSale,
                                                groupTrip: false,
                                                rating: departure.rating,
                                                tourCode: departure.tourCode,
                                            },
                                        ],
                                    },
                                });
                            }}
                        />
                    </div>
                );
            })}
        </Grid>
    );
};
