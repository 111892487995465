"use client";
import React from "react";
import {
    InlineCalendar,
    InlineCalendarProps,
} from "../../calendars/inline-calendar";
import { CalendarValue } from "../../calendars/shared/calendar-utils";
import {
    FormFieldController,
    FormFieldControllerProps,
} from "../form-field-controller";

export type FormInlineCalendarProps =
    FormFieldControllerProps<InlineCalendarProps> & {
        onChange?: (date?: CalendarValue) => void;
    };

export const FormInlineCalendar: React.FC<FormInlineCalendarProps> = ({
    validation,
    name,
    onChange,
    ...inputProps
}) => {
    return (
        <FormFieldController name={name} validation={validation}>
            <InlineCalendar
                fieldId={name}
                {...inputProps}
                onChange={onChange}
            />
        </FormFieldController>
    );
};
