import { useSuspenseQuery } from "@apollo/client";
import { useMediaQuery } from "@mantine/hooks";
import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { gtmSendEvent } from "@wojo/analytics";
import { Container, Slider } from "@wojo/ui";
import cn from "clsx";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
    BusinessCode,
    ReviewsDocument,
    ReviewSortField,
    ReviewsQuery,
    ReviewsQueryVariables,
    ReviewStatus,
} from "../../../generated/graphql";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { StoryblokSlider } from "../../types/storyblok/storyblok-slider";
import { StoryblokTags } from "../../types/storyblok/storyblok-tags";
import { ReviewItem } from "./ReviewItem";
import styles from "./ReviewsSlider.module.scss";
import { ReviewsSliderFallback } from "./ReviewsSliderFallback";

export type ReviewsSliderProps = {
    /**
     * Number of reviews to display
     * @default 8
     */
    numberOfReviews?: StoryblokSlider;
    /**
     * Tour code(s) to filter reviews by
     *
     */
    tourCode?: StoryblokTags;
    /**
     * Maximum number of characters to display in the review description
     * @default 280
     */
    maximumDescriptionCharacterCount?: StoryblokSlider;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

const ReviewsSliderContent: React.FC<ReviewsSliderProps> = (props) => {
    const { numberOfReviews, tourCode, maximumDescriptionCharacterCount } =
        props;

    const { data } = useSuspenseQuery<ReviewsQuery, ReviewsQueryVariables>(
        ReviewsDocument,
        {
            variables: {
                language: process.env.NEXT_PUBLIC_STORYBLOK_LANGUAGE ?? "",
                filter: {
                    businessCode: process.env
                        .NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
                    tourCodes: tourCode?.value || [],
                    isFeatured:
                        process.env.NEXT_PUBLIC_REVIEWS_ISFEATURED_ENABLED ===
                        "true"
                            ? true
                            : undefined,
                    status: ReviewStatus.Approved,
                    hasDetail: true,
                },
                sort: {
                    sortAscending: false,
                    sortBy: ReviewSortField.Rating,
                },
                pagination: {
                    limit: numberOfReviews?.value ?? 8,
                },
            },
            context: {
                fetchOptions: {
                    next: { revalidate: 900 },
                },
                includeCookie: false,
            },
        },
    );
    const maxCharCountValue = maximumDescriptionCharacterCount?.value ?? 280;
    const breakpointDesktop = "(min-width: 992px)";
    const isDesktop = useMediaQuery(breakpointDesktop, false);

    const results = data.reviews.results.filter(
        (result) => result.detail?.title && result.detail.body,
    );

    return (
        <Slider
            accessibleLabel="Reviews"
            slidesToScroll={isDesktop ? 2 : 1}
            desktopWidth={"calc(50% - .5 * var(--item-gap))"}
            mobileWidth="100%"
            tabletWidth="70%"
            gap={"var(--g-spacing-xl)"}
            onSliderButtonClick={(direction) =>
                gtmSendEvent({
                    event: "track",
                    ga4_object: "REVIEWS_SLIDER",
                    ga4_action: "SCROLLED",
                    direction,
                })
            }
        >
            {results.map((card) => (
                <ReviewItem
                    key={card._id}
                    review={card}
                    maxDescriptionLength={maxCharCountValue}
                />
            ))}
        </Slider>
    );
};

export const ReviewsSlider: React.FC<ReviewsSliderProps> = (props) => {
    const { spaceAbove, spaceBelow } = props;

    const fallback = <ReviewsSliderFallback />;

    return (
        <Container
            {...storyblokEditable(props)}
            className={cn(
                styles["reviews-slider"],
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
        >
            <ErrorBoundary fallback={<div>Error loading reviews...</div>}>
                <Suspense fallback={fallback}>
                    <ReviewsSliderContent {...props} />
                </Suspense>
            </ErrorBoundary>
        </Container>
    );
};
