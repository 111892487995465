import cn from "clsx";
import React from "react";
import { ButtonAtom, ButtonAtomProps } from "../../atoms/button";
import {
    ImageGraphicAtom,
    ImageGraphicAtomProps,
} from "../../atoms/image-graphic";
import { TextAtom, TextAtomProps } from "../../atoms/text";
import { TitleAtom, TitleAtomProps } from "../../atoms/title";
import styles from "./SplitScreenContent.module.scss";

type SplitScreenContentBlocksProps = React.PropsWithChildren<{
    titleId?: string;
    imageGraphic?: ImageGraphicAtomProps[];
    title?: TitleAtomProps[];
    description?: TextAtomProps[];
    actions?: ButtonAtomProps[];
    textAlignmentContentPosition?: "left" | "center";
    backgroundColor?: string;
    backgroundStartColor?: string;
    backgroundEndColor?: string;
}>;

export const SplitScreenContentBlocks: React.FC<
    SplitScreenContentBlocksProps
> = ({
    imageGraphic,
    title,
    titleId,
    description,
    actions,
    textAlignmentContentPosition,
    backgroundColor,
    backgroundStartColor,
    backgroundEndColor,
}) => {
    return (
        <div
            className={cn(styles["content"], {
                [styles[`content--x-${textAlignmentContentPosition}`]]:
                    textAlignmentContentPosition,
                [styles["content--background-color"]]: backgroundColor,
                [styles["content--background-gradient"]]:
                    backgroundEndColor && backgroundStartColor,
            })}
            data-ga4-object="SPLIT_SCREEN_CONTENT"
        >
            {imageGraphic?.length ? (
                <ImageGraphicAtom
                    {...imageGraphic[0]}
                    key={imageGraphic[0]._uid}
                />
            ) : null}
            {title?.length ? (
                <TitleAtom
                    {...title[0]}
                    key={title[0]._uid}
                    titleId={titleId}
                />
            ) : null}
            {description?.length ? (
                <TextAtom {...description[0]} key={description[0]._uid} />
            ) : null}
            {actions?.length ? (
                <div
                    className={cn(styles.actions, {
                        [styles[`actions--${textAlignmentContentPosition}`]]:
                            textAlignmentContentPosition,
                    })}
                >
                    {actions.map((action) => (
                        <ButtonAtom
                            key={action._uid}
                            heading={title?.[0]?.heading || actions[0]?.heading}
                            {...action}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
