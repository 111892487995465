import { HTMLAttributes } from "react";
import { FieldDescription } from "../../form-fields/field-description";
import { FieldLabel } from "../../form-fields/field-label";
import { FieldProps } from "../../form-fields/field-props";

export type CalendarWrapperProps = HTMLAttributes<HTMLDivElement> &
    FieldProps &
    React.PropsWithChildren;

/**
 * Shared elements to wrap around the calendar components
 * @param param0
 * @returns
 */
export const CalendarWrapper: React.FC<CalendarWrapperProps> = ({
    fieldId,
    errorText,
    label,
    required,
    disabled,
    descriptionText,
    id,
    children,
    hideErrorIcon,
    hideErrorText,
    hideLabel,
    hideMarker,
    showOptionalValidationText,
    ...otherProps
}) => {
    return (
        <div {...otherProps}>
            {label && (
                <FieldLabel
                    hasError={!!errorText}
                    fieldId={fieldId}
                    disabled={disabled}
                    required={required}
                    hideLabel={hideLabel}
                    hideMarker={hideMarker}
                    showOptionalValidationText={showOptionalValidationText}
                >
                    {label}
                </FieldLabel>
            )}
            {children}
            {(descriptionText || errorText) && (
                <FieldDescription
                    fieldId={fieldId}
                    errorText={errorText}
                    hideErrorIcon={hideErrorIcon}
                    hideErrorText={hideErrorText}
                    disabled={disabled}
                >
                    {descriptionText}
                </FieldDescription>
            )}
        </div>
    );
};
