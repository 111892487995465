"use client";

import { useMediaQuery, usePagination } from "@mantine/hooks";
import isNumber from "lodash/isNumber";
import { FC, ReactNode } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import styles from "./Pagination.module.scss";
import { PaginationItems } from "./PaginationItems";

export type PaginationProps = {
    /**
     *  Label displayed above the pagination
     */
    paginationLabel?: ReactNode;

    /**
     * Trunction element displayed when there are more pages than siblings and boundaries combined. Defaults to "..."
     *
     */
    truncationElement?: ReactNode;

    /**
     * Icon displayed for the next button
     */
    nextButtonIcon?: ReactNode;

    /**
     * Icon displayed for the previous button
     */
    previousButtonIcon?: ReactNode;

    /**
     * Accessibility label for the pagination component
     */
    accessibleLabel?: string;

    /**
     * Amount of items on the left and right side of the selected page item
     */
    siblings?: number;

    /**
     * Amount of items visible on the left and right edges
     * @default 1
     */
    boundaries?: number;

    /**
     * Page selected on initial render
     * @default 0
     */
    page?: number;

    /**
     * The number of pages
     */
    nbPages: number;

    /**
     * Callback fired after each page change
     *  * @param {page}: number - 0 indexed selected page
     */
    onChange?: (page: number) => void;
};

export const Pagination: FC<PaginationProps> = ({
    page = 0,
    nbPages,
    onChange,
    paginationLabel,
    siblings,
    truncationElement = "...",
    boundaries = 1,
    accessibleLabel = "Page selector",
    nextButtonIcon = <FiArrowRight title="Next Page" role="img" />,
    previousButtonIcon = <FiArrowLeft title="Previous Page" role="img" />,
}) => {
    const isBreakpointMd = useMediaQuery("(min-width: 768px)");
    // just in case page is not a number
    // we add 1 to the page to adjust for the 0 indexed page (usePagination uses 1 indexed page)
    const safePage = (isNumber(page) ? page : 0) + 1;
    const { range, active, setPage, next, previous } = usePagination({
        page: safePage,
        total: nbPages,
        onChange: (page) => {
            // adjust to 0 indexed page
            onChange?.(page === 0 ? 0 : page - 1);
        },
        siblings: siblings ?? (isBreakpointMd ? 1 : 0),
        boundaries: boundaries,
    });

    const isFirstItem = active === 1;
    const isLastItem = active === nbPages;

    if (!nbPages) {
        return null;
    }

    return (
        <div className={styles["pagination-container"]}>
            {paginationLabel && (
                <span className={styles["pagination-label"]}>
                    {paginationLabel}
                </span>
            )}

            <nav className={styles["pagination"]} aria-label={accessibleLabel}>
                <button
                    className={styles["previous-button"]}
                    type="button"
                    aria-label="Previous page"
                    aria-disabled={isFirstItem}
                    tabIndex={isFirstItem ? -1 : undefined}
                    onClick={() => {
                        if (!isFirstItem) {
                            previous();
                        }
                    }}
                >
                    {previousButtonIcon}
                </button>

                <PaginationItems
                    siblings={siblings}
                    range={range}
                    active={active}
                    setPage={setPage}
                    truncationElement={truncationElement}
                />

                <button
                    className={styles["next-button"]}
                    type="button"
                    aria-label="Next page"
                    aria-disabled={isLastItem}
                    tabIndex={isLastItem ? -1 : undefined}
                    onClick={() => {
                        if (!isLastItem) {
                            next();
                        }
                    }}
                >
                    {nextButtonIcon}
                </button>
            </nav>
        </div>
    );
};
