import { useI18n } from "@wojo/localization";
import currency from "currency.js";
import { BasicLineItemFragment, Maybe } from "../../generated/graphql";
import { useDraftQuoteCurrency } from "./use-draft-quote-currency";

export const useFormattedLineItemsPriceSum = () => {
    const { formatCurrency } = useDraftQuoteCurrency();
    const { checkout } = useI18n("checkout");

    const getFormattedPriceOfLineItems = (
        lineItems: Array<Maybe<BasicLineItemFragment> | undefined>,
    ) => {
        const total = lineItems.reduce((prev, curr) => {
            if (!curr?.price.value) {
                return prev;
            }
            return currency(prev).add(curr.price.value);
        }, currency(0)).value;

        return total === 0
            ? checkout.summary.included.l()
            : formatCurrency(total);
    };

    return { getFormattedPriceOfLineItems };
};
