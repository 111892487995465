"use client";

import { useMediaQuery } from "@mantine/hooks";
import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import cn from "clsx";
import { CSSProperties, useId } from "react";
import { ButtonAtomProps } from "../../atoms/button";
import { ImageGraphicAtomProps } from "../../atoms/image-graphic";
import { TextAtomProps } from "../../atoms/text";
import { TitleAtomProps } from "../../atoms/title";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { FlexibleImageProps } from "../../molecules/flexible-image";
import { FlexibleVideoProps } from "../../molecules/flexible-video";
import styles from "./SplitScreenContent.module.scss";
import { SplitScreenContentBlocks } from "./SplitScreenContentBlocks";
import { SplitScreenContentMedia } from "./SplitScreenContentMedia";

export type SplitScreenContentProps = {
    /**
     * Media bloks to display. FlexibleImage | FlexibleVideo
     */
    media: (FlexibleImageProps | FlexibleVideoProps)[];
    /**
     *  ImageGraphic bloks to display. ImageGraphicAtom
     */
    imageGraphic?: ImageGraphicAtomProps[];
    /**
     * Title Atom bloks to display. TitleAtom
     */
    title: TitleAtomProps[];
    /**
     * Description bloks to display. TextAtom
     */
    description?: TextAtomProps[];
    /**
     * Button bloks to display. ButtonAtom
     */
    actions?: ButtonAtomProps[];
    /**
     * Reverse the order of the content
     */
    reverseLayout?: boolean;
    /**
     * Sets the horizontal alignment of content.
     * @default "left"
     */
    textAlignmentContentPosition?: "left" | "center";
    /**
     * Background color of the content
     */
    backgroundColor?: string;
    /**
     * Start color of the background gradient, overrides `backgroundColor` when present with `backgroundEndColor`
     */
    backgroundStartColor?: string;
    /**
     * End color of the background gradient, overrides `backgroundColor` when present with `backgroundStartColor`
     */
    backgroundEndColor?: string;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const SplitScreenContent: React.FC<SplitScreenContentProps> = (
    props,
) => {
    const {
        media,
        imageGraphic,
        title,
        description,
        actions,
        reverseLayout,
        textAlignmentContentPosition,
        spaceAbove,
        spaceBelow,
        backgroundColor,
        backgroundStartColor,
        backgroundEndColor,
        gradientAngle,
    } = props;

    const isBreakpointLg = useMediaQuery("(min-width: 992px)");
    const showLeadingMedia =
        !Boolean(reverseLayout) || (!Boolean(isBreakpointLg) && reverseLayout);
    const titleId = useId();

    return (
        <div
            {...storyblokEditable(props)}
            role="group"
            aria-labelledby={titleId}
            className={cn(
                styles["container"],
                {
                    [styles[`container--reverse`]]: reverseLayout,
                },
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
            data-ga4-object="SPLIT_SCREEN_CONTENT"
            style={
                {
                    "--background-color": backgroundColor,
                    "--background-start-color": backgroundStartColor,
                    "--background-end-color": backgroundEndColor,
                    "--gradient-angle": gradientAngle || "180deg",
                } as CSSProperties
            }
        >
            {showLeadingMedia && (
                <SplitScreenContentMedia
                    media={media}
                    reverseLayout={reverseLayout}
                />
            )}

            <SplitScreenContentBlocks
                imageGraphic={imageGraphic}
                title={title}
                titleId={titleId}
                description={description}
                actions={actions}
                textAlignmentContentPosition={
                    textAlignmentContentPosition || "left"
                }
                backgroundColor={backgroundColor}
                backgroundStartColor={backgroundStartColor}
                backgroundEndColor={backgroundEndColor}
            />

            {!showLeadingMedia && (
                <SplitScreenContentMedia
                    media={media}
                    reverseLayout={reverseLayout}
                />
            )}
        </div>
    );
};
