import { gtmSendEvent } from "@wojo/analytics";
import { Grid } from "@wojo/ui";
import { DealsTourCard } from "../../../components/deals-tour-card";
import { DealsTourCardDeparture } from "../../../components/deals-tour-card/types";
import { DealsTourCardGridContentProps } from "../types";
import styles from "./DealsTourCardGridContent.module.scss";

export const DealsTourCardGridContent: React.FC<
    DealsTourCardGridContentProps
> = ({ departures, getIsInWishlist, showGatewayPricing, toggleWishlist }) => {
    const groupedDepartures = departures.reduce((groups, hit) => {
        const key = `${hit.tourCode}-${hit.tourPagePath}`;
        groups[key] = [...(groups[key] ?? []), hit];
        return groups;
    }, {} as Record<string, DealsTourCardDeparture[]>);

    return (
        <Grid columns={1} className={styles["grid-wrapper"]}>
            {Object.values(groupedDepartures).map((group) => {
                const tour = group[0];
                const tourCode = group[0]?.tourCode;
                if (!tourCode) {
                    return null;
                }
                return (
                    <DealsTourCard
                        departures={group}
                        isInWishlist={getIsInWishlist(tourCode)}
                        key={group[0]?.objectID}
                        toggleWishlist={() => {
                            !getIsInWishlist(tourCode) &&
                                gtmSendEvent({
                                    event: "add_to_wishlist",
                                    ecommerce: {
                                        currency:
                                            process.env
                                                .NEXT_PUBLIC_CURRENCY_CODE,
                                        value: tour.price,
                                        items: [
                                            {
                                                item_id: tourCode,
                                                item_name: tour.name,
                                                item_brand:
                                                    process.env
                                                        .NEXT_PUBLIC_BUSINESS_CODE,
                                                item_variant: "Tour",
                                                market: process.env
                                                    .NEXT_PUBLIC_MARKET_CODE,
                                                tourCode: tourCode,
                                                price:
                                                    tour.salePrice ||
                                                    tour.price,
                                                quantity: 1,
                                                activityLevel:
                                                    tour.activityLevel?.name,
                                                classLevel:
                                                    tour.classLevel?.name,
                                                rating: tour.rating,
                                            },
                                        ],
                                    },
                                    ga4Tracking: {
                                        ga4_object: "TOUR",
                                        ga4_action: "ADD_TO_WISHLIST",
                                        tourCode: tour.tourCode ?? "",
                                        tourName: tour.name ?? "",
                                        parentObject: "DEAL_TOUR_CARD_GRID",
                                    },
                                });

                            toggleWishlist(tourCode);
                        }}
                        showGatewayPricing={showGatewayPricing}
                    />
                );
            })}
        </Grid>
    );
};
