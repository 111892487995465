import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { Container } from "@wojo/ui";
import cn from "clsx";
import { CSSProperties } from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { StoryblokImage } from "../../components/storyblok-image";
import { Opacity } from "../../types/opacity";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { StoryblokSlider } from "../../types/storyblok/storyblok-slider";
import styles from "./Quote.module.scss";

export type QuoteProps = {
    /**
     * Quote text to display
     */
    quoteText: string;
    /**
     * Author name to display
     */
    authorNameText: string;
    /**
     * Author image to display if available. AssetStoryblok
     */
    authorImage?: AssetStoryblok;
    /**
     * Background Image to display. AssetStoryblok
     */
    backgroundImage?: AssetStoryblok;
    /**
     * Quote mark color
     */
    quoteMarkColor?: string;
    /**
     * Quote text color
     */
    quoteTextColor?: string;
    /**
     * Author name text color
     */
    authorNameTextColor?: string;
    /**
     * Overlay opacity of the background image
     */
    overlayOpacity?: StoryblokSlider<Opacity>;
    /**
     * Quote mark color override
     */
    quoteMarkColorOverride?: string;
    /**
     * Quote text color override
     */
    quoteTextColorOverride?: string;
    /**
     * Author name text color override
     */
    authorNameTextColorOverride?: string;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const Quote: React.FC<QuoteProps> = (props) => {
    const {
        quoteText,
        quoteTextColor,
        quoteTextColorOverride,
        quoteMarkColor,
        quoteMarkColorOverride,
        authorNameText,
        authorImage,
        authorNameTextColor,
        authorNameTextColorOverride,
        spaceAbove,
        spaceBelow,
        backgroundImage,
        overlayOpacity,
    } = props;

    const hasBackgroundImage = backgroundImage && backgroundImage.filename;
    const quoteMarkColorVar = hasBackgroundImage
        ? "var(--g-color-white)"
        : quoteMarkColorOverride || quoteMarkColor;
    const quoteTextColorVar = hasBackgroundImage
        ? "var(--g-color-white)"
        : quoteTextColorOverride || quoteTextColor;
    const authorNameColorVar = hasBackgroundImage
        ? "var(--g-color-white)"
        : authorNameTextColorOverride || authorNameTextColor;

    return (
        <Container
            {...storyblokEditable(props)}
            contentsClassName={cn(
                getSpacingClassName({ spaceAbove, spaceBelow }),
                styles.container,
                {
                    [styles["container-background"]]: hasBackgroundImage,
                },
            )}
            width="full"
        >
            {backgroundImage?.filename && (
                <StoryblokImage
                    coverContainer
                    mobile={{
                        asset: backgroundImage,
                    }}
                    className={styles.background}
                />
            )}
            {backgroundImage?.filename ? (
                <div
                    data-testid="overlay"
                    className={styles.overlay}
                    style={
                        {
                            ["--overlay-opacity"]: overlayOpacity?.value,
                        } as CSSProperties
                    }
                />
            ) : null}
            <span
                className={styles.quoteMark}
                style={{
                    color: quoteMarkColorVar ? quoteMarkColorVar : undefined,
                }}
            >
                {authorNameText && (
                    <span className="visually-hidden">
                        {/* Safari VO reads this as two separate lines if it isn't in the literal */}
                        {`Quote by ${authorNameText}`}
                    </span>
                )}
                <span aria-hidden>“</span>
            </span>
            <blockquote
                className={cn(styles.quoteText, {
                    [styles["quoteText-background"]]: hasBackgroundImage,
                })}
                style={{
                    color: quoteTextColorVar ? quoteTextColorVar : undefined,
                }}
            >
                {quoteText}
            </blockquote>
            {authorImage && authorImage.filename && (
                <div className={styles.authorContainer}>
                    <p
                        className={styles.authorNameText}
                        style={{
                            color: authorNameColorVar
                                ? authorNameColorVar
                                : undefined,
                        }}
                    >
                        {authorNameText}
                    </p>
                    <StoryblokImage
                        desktop={{
                            asset: authorImage,
                            size: {
                                width: 120,
                            },
                        }}
                        mobile={{
                            asset: authorImage,
                            size: {
                                width: 120,
                            },
                        }}
                        className={styles.authorImage}
                    />
                </div>
            )}

            {!authorImage?.filename && authorNameText && (
                <p
                    className={styles.authorNameText}
                    style={{
                        color: authorNameColorVar
                            ? authorNameColorVar
                            : undefined,
                    }}
                >
                    {authorNameText.length > 0 && "—"} {authorNameText}
                </p>
            )}
        </Container>
    );
};
