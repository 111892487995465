import React from "react";
import { DesktopCard } from "./desktop-card";
import { MobileCard } from "./mobile-card";
import { DealsTourCardProps } from "./types";

export const DealsTourCard: React.FC<DealsTourCardProps> = (props) => {
    return (
        <>
            <MobileCard {...props} />
            <DesktopCard {...props} />
        </>
    );
};
