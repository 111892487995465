import { DepartureHit } from "@wojo/services";
import currency from "currency.js";
import { LightningDealCardProps } from "./LightningDealCard";

export const mapDepartureToProps = (
    hit: DepartureHit,
): LightningDealCardProps => {
    const {
        activityLevel,
        altText,
        assets,
        discountAmount,
        isExtensionHidden,
        isOnSale,
        price,
        salePrice,
        promoCode,
        departureStatusBadge,
        estimatedBiweeklyPayment,
        specialDepartureContent,
        tourBadge,
        ...rest
    } = hit;

    const discountAmountFormatted = currency(discountAmount || 0, {
        precision: 0,
    }).format();
    const priceFormatted = currency(price || 0, { precision: 0 }).format();
    const salePriceFormatted = currency(salePrice || price || 0, {
        precision: 0,
    }).format();

    return {
        ...rest,
        id: hit.id,
        discountAmountFormatted,
        cardImage: hit.cardImage ?? {},
        name: hit.name ?? "",
        priceFormatted,
        salePriceFormatted,
        isOnSale,
        extensionName: hit.extensionName ?? "",
        nbDays: hit.nbDays ?? 0,
        nbCities: hit.nbCities ?? 0,
        nbDaysWithExtension: hit.nbDaysWithExtension ?? 0,
        tourUrl: hit.tourPagePath,
        departureDate: hit.departureDate,
        departureStatusBadge: hit.departureStatusBadge ?? null,
        bookableSpots: hit.bookableSpots,
        classLevel: hit.classLevel?.name ?? undefined,
        tourCode: hit.tourCode ?? "",
        isInWishlist: false,
        toggleWishlist: () => {},
    };
};
