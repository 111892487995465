import type { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { Container } from "@wojo/ui";
import cn from "clsx";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { Border, BorderProps } from "../../components/border";
import { ContentPosition } from "../../types/position";
import { Spacing } from "../../types/spacing";
import styles from "./TextSectionContainer.module.scss";

export type TextSectionContainerProps = {
    /**
     * Name of the anchor link associated with this section
     */
    anchorLinkName?: string;
    /**
     * Padding after the main content of the section to extend the background color
     */
    backgroundBottomInnerSpacing?: Spacing;
    /**
     * Background color of the section
     */
    backgroundColor?: string;
    /**
     * End color of the background gradient, overrides `backgroundColor` when present with `backgroundStartColor`
     */
    backgroundEndColor?: string;
    /**
     * Start color of the background gradient, overrides `backgroundColor` when present with `backgroundEndColor`
     */
    backgroundStartColor?: string;
    /**
     * Padding before the main content of the section to extend the background color
     */
    backgroundTopInnerSpacing?: Spacing;
    /**
     * Main bloks of the section
     */
    body?: SbBlokData[];
    /**
     * Bloks rendered directly after the `body`
     */
    footer?: SbBlokData[];
    /**
     * Angle of the background gradient
     */
    gradientAngle?: string;
    /**
     * Bloks rendered directly before the `body`
     */
    header?: SbBlokData[];
    /**
     * Alignment of text and content
     */
    textAlignmentContentPosition?: ContentPosition;
} & Pick<SpacingProps, "spaceAbove" | "spaceBelow"> &
    BorderProps &
    SbBlokData;

export const TextSectionContainer: React.FC<TextSectionContainerProps> = (
    props,
) => {
    const {
        backgroundBottomInnerSpacing,
        backgroundColor,
        backgroundEndColor,
        backgroundStartColor,
        backgroundTopInnerSpacing,
        body,
        borderColor,
        borderEndColor,
        borderGradientAngle,
        borderImage,
        borderPosition,
        borderRadius,
        borderSize,
        borderStartColor,
        footer,
        gradientAngle,
        header,
        spaceAbove,
        spaceBelow,
        textAlignmentContentPosition,
    } = props;

    const showBackgroundGradient = backgroundEndColor && backgroundStartColor;
    const showBackgroundColor = backgroundColor && !showBackgroundGradient;

    return (
        <Border
            {...storyblokEditable(props)}
            borderColor={borderColor}
            borderEndColor={borderEndColor}
            borderGradientAngle={borderGradientAngle}
            borderImage={borderImage}
            borderPosition={borderPosition}
            borderRadius={borderRadius}
            borderSize={borderSize}
            borderStartColor={borderStartColor}
            className={getSpacingClassName({
                spaceAbove,
                spaceBelow,
            })}
        >
            <div
                data-ga4-object="TEXT_SECTION_CONTAINER"
                className={cn(
                    styles["text-section-container"],
                    getSpacingClassName({
                        innerSpacingBottom: backgroundBottomInnerSpacing,
                        innerSpacingTop: backgroundTopInnerSpacing,
                    }),
                    {
                        [styles["background-gradient"]]: showBackgroundGradient,
                        [styles["background-color"]]: showBackgroundColor,
                    },
                )}
                style={
                    {
                        "--section-background-color": backgroundColor,
                        "--section-background-end-color": backgroundEndColor,
                        "--section-background-gradient-angle":
                            gradientAngle || "180deg",
                        "--section-background-start-color":
                            backgroundStartColor,
                    } as React.CSSProperties
                }
            >
                <Container
                    width="small"
                    position="center"
                    contentsClassName={styles["text-container"]}
                    className={
                        styles[
                            `text-align--${
                                textAlignmentContentPosition || "left"
                            }`
                        ]
                    }
                >
                    {header?.map((blok) => (
                        <div key={blok._uid}>
                            <StoryblokComponent blok={blok} />
                        </div>
                    ))}
                    {body?.map((blok) => (
                        <div key={blok._uid}>
                            <StoryblokComponent blok={blok} />
                        </div>
                    ))}
                    {footer?.map((blok) => (
                        <div key={blok._uid}>
                            <StoryblokComponent blok={blok} />
                        </div>
                    ))}
                </Container>
            </div>
        </Border>
    );
};
