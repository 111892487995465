"use client";
import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { Container, Text } from "@wojo/ui";
import cn from "clsx";
import dayjs from "dayjs";
import { CSSProperties, useId } from "react";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { TitleDescription } from "../../components/title-description";
import { GradientAngle } from "../../types/gradient";
import { TitleDescriptionSize } from "../../types/title";
import styles from "./CountdownTimer.module.scss";
import { Counter } from "./Counter";

const getTimerUnitMinWidth = (counterSize: string): string => {
    switch (counterSize) {
        case "2":
            return "70px";
        case "3":
            return "60px";
        case "4":
            return "50px";
        case "5":
            return "40px";
        case "6":
            return "30px";
        default:
            return "50px";
    }
};
export type CountdownTimerProps = {
    /**
     * The countdown end date
     */
    countdownEndDate: string;
    /**
     * Show days in the countdown
     */
    showDay: boolean;
    /**
     * Text above the countdown
     */
    eyebrow?: string;
    /**
     * The description to be rendered
     */
    description?: string;
    /**
     * Theme Color for the eyebrow
     */
    eyebrowColor?: string;
    /**
     * Theme Color for the heading
     */
    countdownColor?: string;
    /**
     * Theme Color for the description
     */
    descriptionColor?: string;
    /**
     * The countdown size to be rendered
     * @default h4
     */
    countdownSize?: "2" | "3" | "4" | "5" | "6";
    /**
     * Description size
     * @default body1
     */
    descriptionSize?: TitleDescriptionSize;
    /**
     * Text alignment of the content
     * @default center
     */
    textAlignmentContentPosition?: "left" | "center";
    /**
     * Background style of the countdown timer
     */
    backgroundStyle?: "backgroundColor" | "flat";
    /**
     * Background Color of the countdown timer
     */
    backgroundColor?: string;
    /**
     * Custom Color override for the eyebrow
     */
    eyebrowColorOverride?: string;
    /**
     * Custom Color override for the countdown
     */
    countdownColorOverride?: string;
    /**
     * Custom Color override for the description
     */
    descriptionColorOverride?: string;
    /**
     * Start color of the background gradient, overrides `backgroundColor` when present with `backgroundEndColor`
     */
    backgroundStartColor?: string;
    /**
     * End color of the background gradient, overrides `backgroundColor` when present with `backgroundStartColor`
     */
    backgroundEndColor?: string;
    /**
     * Angle of the background gradient
     */
    gradientAngle?: GradientAngle;
} & SbBlokData &
    Pick<SpacingProps, "spaceAbove" | "spaceBelow">;

export const CountdownTimer: React.FC<CountdownTimerProps> = (props) => {
    const {
        countdownEndDate,
        showDay,
        eyebrow,
        description,
        eyebrowColor,
        countdownColor,
        descriptionColor,
        countdownSize,
        descriptionSize,
        textAlignmentContentPosition,
        backgroundColor,
        eyebrowColorOverride,
        countdownColorOverride,
        descriptionColorOverride,
        backgroundStyle,
        backgroundStartColor,
        backgroundEndColor,
        gradientAngle,
        spaceAbove,
        spaceBelow,
    } = props;
    const descriptionId = useId();
    const eyebrowId = useId();
    const eyebrowColorVar = eyebrowColorOverride || eyebrowColor;
    const now = dayjs.utc();
    const endDate = dayjs.utc(countdownEndDate);

    if (now.isAfter(endDate)) {
        return null;
    }
    const timerUnitMinWidth = getTimerUnitMinWidth(countdownSize || "4");
    const showBackgroundGradient = backgroundEndColor && backgroundStartColor;
    function getBackgroundColor() {
        return backgroundColor || "var(--s-color-surface-secondary)";
    }

    return (
        <Container
            {...storyblokEditable(props)}
            className={getSpacingClassName({ spaceAbove, spaceBelow })}
            contentsClassName={cn(styles.timer, {
                [styles[`timer--${textAlignmentContentPosition}`]]:
                    textAlignmentContentPosition,
                [styles["timer--background-color"]]: !showBackgroundGradient,
                [styles["timer--background-gradient"]]: showBackgroundGradient,
                [styles["timer--flat"]]: backgroundStyle === "flat",
            })}
            style={
                {
                    "--background-color": getBackgroundColor(),
                    "--background-start-color": backgroundStartColor,
                    "--background-end-color": backgroundEndColor,
                    "--timer-unit-min-width": timerUnitMinWidth,
                    "--background-color-gradient-angle":
                        gradientAngle || "0deg",
                } as CSSProperties
            }
        >
            {eyebrow ? (
                <Text.Detail
                    id={eyebrowId}
                    variant="2"
                    style={{
                        color: eyebrowColorVar ? eyebrowColorVar : undefined,
                    }}
                >
                    {eyebrow}
                </Text.Detail>
            ) : null}
            <div
                role="timer"
                aria-labelledby={eyebrow ? eyebrowId : undefined}
                aria-describedby={description ? descriptionId : undefined}
            >
                <Counter
                    countdownColor={countdownColorOverride || countdownColor}
                    countdownSize={countdownSize}
                    countdownEndDate={countdownEndDate}
                    showDay={showDay}
                />
            </div>
            {description ? (
                <TitleDescription
                    id={descriptionId}
                    size={descriptionSize || "body1"}
                    color={descriptionColorOverride || descriptionColor}
                    content={description}
                />
            ) : null}
        </Container>
    );
};
