import cn from "clsx";
import { forwardRef, HtmlHTMLAttributes } from "react";
import styles from "./DropdownList.module.scss";

export const DropdownList = forwardRef<
    HTMLUListElement,
    HtmlHTMLAttributes<HTMLUListElement>
>(({ children, ...props }, ref) => (
    <ul
        {...props}
        ref={ref}
        className={cn([styles["list-wrapper"]], props.className)}
    >
        {children}
    </ul>
));
DropdownList.displayName = "DropdownList";
