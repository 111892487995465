import { SbBlokData, storyblokEditable } from "@storyblok/react";
import { Text } from "@wojo/ui";
import cn from "clsx";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";
import { TitleDescription } from "../../components/title-description";
import { TitleHeading } from "../../components/title-heading";
import { Spacing } from "../../types/spacing";
import {
    TitleDescriptionSize,
    TitleHeadingSize,
    TitleHeadingTag,
} from "../../types/title";

export type TitleAtomProps = {
    /**
     * The heading size to be rendered
     * @default h2
     */
    headingSize?: TitleHeadingSize;
    /**
     * The heading tag to be rendered
     * @default h2
     */
    headingTag?: TitleHeadingTag;
    /**
     * The heading to be rendered
     */
    heading?: string;
    /**
     * Text above the main title
     */
    eyebrow?: string;
    /**
     * The description to be rendered
     */
    description?: string;
    /**
     * Theme Color for the eyebrow
     */
    eyebrowColor?: string;
    /**
     * Theme Color for the heading
     */
    headingColor?: string;
    /**
     * Theme Color for the description
     */
    descriptionColor?: string;
    /**
     * Description size
     * @default body1
     */
    descriptionSize?: TitleDescriptionSize;
    /**
     * Custom Color override for the eyebrow
     */
    eyebrowColorOverride?: string;
    /**
     * Custom Color override for the heading
     */
    headingColorOverride?: string;
    /**
     * Custom Color override for the description
     */
    descriptionColorOverride?: string;
    /**
     * Id for the title
     */
    titleId?: string;
    /**
     * Id for the description
     */
    descriptionId?: string;
} & SbBlokData &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;

export const TitleAtom: React.FC<TitleAtomProps> = ({
    headingTag,
    heading,
    eyebrow,
    eyebrowColor,
    headingSize,
    headingColor,
    description,
    descriptionSize,
    descriptionColor,
    eyebrowColorOverride,
    descriptionColorOverride,
    headingColorOverride,
    titleId,
    descriptionId,
    spaceAbove,
    spaceBelow,
    ...props
}) => {
    const eyebrowColorVar = eyebrowColorOverride || eyebrowColor;
    const spaceBelowHeading = () => {
        switch (headingSize) {
            case "display-h1":
            case "display-h2":
            case "h1":
            case "h2":
                return Spacing.Small;
            case "h3":
                return Spacing.XSmall;
            case "h4":
            case "h5":
            case "h6":
                return Spacing.XxSmall;
        }
    };

    return (
        <div
            {...storyblokEditable(props)}
            className={cn(
                getSpacingClassName({ spaceAbove, spaceBelow }),
                props.className,
            )}
        >
            {eyebrow ? (
                <Text.Detail
                    variant="2"
                    style={{
                        marginBottom: "var(--g-spacing-sm)",
                        color: eyebrowColorVar ? eyebrowColorVar : undefined,
                    }}
                >
                    {eyebrow}
                </Text.Detail>
            ) : null}
            <div
                className={getSpacingClassName({
                    spaceAbove: Spacing.None,
                    spaceBelow: spaceBelowHeading(),
                })}
            >
                <TitleHeading
                    size={headingSize || "h2"}
                    tag={headingTag || "h2"}
                    color={headingColorOverride || headingColor}
                    content={heading}
                    id={titleId}
                />
            </div>
            {description ? (
                <TitleDescription
                    size={descriptionSize || "body1"}
                    color={descriptionColorOverride || descriptionColor}
                    content={description}
                    id={descriptionId}
                />
            ) : null}
        </div>
    );
};
