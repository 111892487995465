export enum Spacing {
    "None" = "none",
    "XxSmall" = "xxsmall",
    "XSmall" = "xsmall",
    "Small" = "small",
    "Medium" = "medium",
    "Large" = "large",
    "LargeResponsive" = "large-responsive",
    "XLarge" = "xlarge",
    "XLargeResponsive" = "xlarge-responsive",
    "2xLarge" = "2xlarge",
}
