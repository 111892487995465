import { HTMLAttributes } from "react";
import { Button } from "../../button";

export type CalendarFooterProps = HTMLAttributes<HTMLDivElement> &
    React.PropsWithChildren<{
        onReset: React.MouseEventHandler<HTMLOrSVGElement>;
        onDone?: React.MouseEventHandler<HTMLOrSVGElement>;
        disableReset?: boolean;
        disableDone?: boolean;
    }>;

/**
 * Shared elements to wrap around the calendar components
 * @param param0
 * @returns
 */
export const CalendarFooter: React.FC<CalendarFooterProps> = ({
    onReset,
    onDone,
    disableReset,
    disableDone,
    children,
    ...otherProps
}) => {
    return (
        <div {...otherProps}>
            <Button
                variant="quiet"
                type="button"
                disabled={disableReset}
                onClick={onReset}
            >
                Reset
            </Button>
            {onDone && !children && (
                <Button
                    variant="quiet"
                    disabled={disableDone}
                    onClick={(e) => {
                        onDone(e);
                    }}
                >
                    Done
                </Button>
            )}
            {children}
        </div>
    );
};
