import { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { Markdown } from "@wojo/ui";
import cn from "clsx";
import {
    getSpacingClassName,
    SpacingProps,
} from "../../client/get-spacing-class-name";

export type TextAtomProps = {
    /**
     * The Markdown text to be rendered
     */
    markdown?: string;
    /**
     * Theme Color for the text
     */
    textColor?: string;
    /**
     * Custom Color campaign override for the text
     */
    textColorOverride?: string;
} & SbBlokData &
    Pick<SpacingProps, "spaceBelow" | "spaceAbove">;
export const TextAtom: React.FC<TextAtomProps> = (props) => {
    const { markdown, textColor, textColorOverride, spaceAbove, spaceBelow } =
        props;
    const textColorVar = textColorOverride || textColor;

    return (
        <Markdown
            {...storyblokEditable(props)}
            className={cn(
                getSpacingClassName({ spaceAbove, spaceBelow }),
                props.className,
            )}
            markdown={markdown || ""}
            markdownColor={textColorVar}
        />
    );
};
