export const logRocketWhitelistedPaths = [
    { path: "/myaccount", includeNestedPages: true },
    { path: "/checkout", includeNestedPages: true },
    { path: "/booking", includeNestedPages: true },
    {
        path: "/tours/europe",
        includeNestedPages: false,
        businessCodes: ["UB"],
    },
    {
        path: "/trips/highlights-of-japan",
        includeNestedPages: false,
        businessCodes: ["UB"],
    },
    {
        path: "/trips/the-greek-islands",
        includeNestedPages: false,
        businessCodes: ["UB"],
    },
    {
        path: "/trips/thailand-getaway",
        includeNestedPages: false,
        businessCodes: ["UB"],
    },
    {
        path: "/trips/costa-rica-adventure",
        includeNestedPages: false,
        businessCodes: ["UB"],
    },
    {
        path: "/travel-styles/solo-travel-tours",
        includeNestedPages: false,
        businessCodes: ["GAT"],
    },
    {
        path: "/tours/europe-tours",
        includeNestedPages: false,
        businessCodes: ["GAT"],
    },
    {
        path: "/guided-tours/venice-florence-rome",
        includeNestedPages: false,
        businessCodes: ["GAT"],
    },
    {
        path: "/guided-tours/a-week-in-greece-athens-mykonos-santorini",
        includeNestedPages: false,
        businessCodes: ["GAT"],
    },
    {
        path: "/guided-tours/highlights-of-england-scotland-ireland",
        includeNestedPages: false,
        businessCodes: ["GAT"],
    },
    {
        path: "/guided-tours/london-paris-rome",
        includeNestedPages: false,
        businessCodes: ["GAT"],
    },
    {
        path: "/guided-tours/bali-for-solo-travelers",
        includeNestedPages: false,
        businessCodes: ["GAT"],
    },
    {
        path: "/guided-tours/oktoberfest-historic-germany",
        includeNestedPages: false,
        businessCodes: ["GAT"],
    },
    {
        path: "/tours/europe-tours/greece-tours",
        includeNestedPages: false,
        businessCodes: ["GAT"],
    },
    {
        path: "/explore",
        includeNestedPages: false,
        businessCodes: ["UB"],
    },
    {
        path: "/tours/essential",
        includeNestedPages: false,
        businessCodes: ["UB"],
    },
    {
        path: "/tours/plus",
        includeNestedPages: false,
        businessCodes: ["UB"],
    },
    {
        path: "/trips/germany-italy-switzerland",
        includeNestedPages: false,
        businessCodes: ["UB"],
    },
];
