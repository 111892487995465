import { getLineItems } from "../get-line-items";
import { calculateTotalPricePerPromoTarget } from "@wojo/web-components";
import currencyJs from "currency.js";
import { PromoLineItem as PromoLineItemType } from "../../generated/graphql";
import { useDraftQuote } from "client/context";

export type UniqPromo = Pick<
    PromoLineItemType,
    "promoCode" | "price" | "lineItemCode" | "targetType" | "campaignCode"
>;

export const usePromos = () => {
    const { draftQuote } = useDraftQuote();
    const { promoLineItems } = getLineItems(draftQuote.order.trips);
    const promoTargetTotalsByCode =
        calculateTotalPricePerPromoTarget(promoLineItems);

    const promosGroupedByCode: Map<string, UniqPromo> = promoLineItems.reduce(
        (acc, promo) => {
            const existingPromo = acc.get(promo.promoCode);
            if (existingPromo) {
                existingPromo.price.value = currencyJs(
                    existingPromo.price.value,
                ).add(promo.price.value).value;
            } else {
                acc.set(promo.promoCode, {
                    lineItemCode: promo.lineItemCode,
                    promoCode: promo.promoCode,
                    campaignCode: promo.campaignCode,
                    price: { ...promo.price },
                });
            }
            return acc;
        },
        new Map<string, UniqPromo>(),
    );

    return {
        promosGroupedByCode,
        promoLineItems,
        promoTargetTotalsByCode,
    };
};
