import { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { useI18n } from "@wojo/localization";
import { Container } from "@wojo/ui";
import React from "react";
import { ImageAtom } from "../../atoms/image";
import { TextAtom } from "../../atoms/text";
import { TitleAtom } from "../../atoms/title";
import { BlogArticleSlider } from "../../organisms/blog-article-slider";
import { TourCardSlider } from "../../organisms/tour-card-slider";
import { ImageRatio } from "../../types/image";
import { Spacing } from "../../types/spacing";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import styles from "./BlogAuthorPage.module.scss";

export type BlogAuthorPageProps = {
    _uid: string;
    name?: string;
    profileImage?: AssetStoryblok;
    authorRole?: string;
    shortBio?: string;
    fullBio?: string;
    favoriteToursAlgoliaContext?: string;
    latestArticlesAlgoliaContext?: string;
    brandContent?: SbBlokData[];
    seo?: SbBlokData[];
    analytics?: SbBlokData[];
};

export const BlogAuthorPage: React.FC<BlogAuthorPageProps> = (props) => {
    const i18n = useI18n("storyblok").storyblok;
    return (
        <>
            <Container>
                <div {...storyblokEditable(props)} className={styles.page}>
                    {!!props.profileImage?.filename && (
                        <div className={styles["profile-image"]}>
                            <ImageAtom
                                image={props.profileImage}
                                imageRatio={ImageRatio.Circle1By1}
                            />
                        </div>
                    )}
                    {!!(props.name || props.authorRole) && (
                        <TitleAtom
                            headingTag="h1"
                            heading={props.name}
                            description={props.authorRole}
                        />
                    )}
                    {!!props.fullBio && (
                        <div className={styles.bio}>
                            <TextAtom markdown={props.fullBio} />
                        </div>
                    )}
                </div>
                {!!props.favoriteToursAlgoliaContext && (
                    <>
                        <Container>
                            <TitleAtom
                                heading={i18n.blogAuthorPage.favoriteToursLabel.l()}
                                spaceAbove={Spacing.Large}
                                spaceBelow={Spacing.Large}
                            />
                        </Container>
                        <TourCardSlider
                            _uid={props._uid}
                            algoliaRuleTriggerContext={
                                props.favoriteToursAlgoliaContext
                            }
                            spaceBelow={Spacing.XLarge}
                        />
                    </>
                )}
                {!!props.latestArticlesAlgoliaContext && (
                    <>
                        <Container>
                            <TitleAtom
                                heading={i18n.blogAuthorPage.latestArticlesLabel.l()}
                                spaceAbove={Spacing.Large}
                                spaceBelow={Spacing.Large}
                            />
                        </Container>
                        <BlogArticleSlider
                            _uid={props._uid}
                            algoliaRuleTriggerContext={
                                props.latestArticlesAlgoliaContext
                            }
                            numberOfArticles={{
                                plugin: "storyblok-slider",
                                value: 20,
                            }}
                            spaceBelow={Spacing.XLarge}
                        />
                    </>
                )}
            </Container>
            {props.brandContent?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
            {props.seo?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
            {props.analytics?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </>
    );
};
