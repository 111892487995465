import { Grid, LoadingSkeleton } from "@wojo/ui";
import styles from "./TourCardGridFallback.module.scss";

type TourCardGridFallbackProps = {
    numberOfResults: number;
    showDesktopCardOnMobile: boolean;
};

export const TourCardGridFallback: React.FC<TourCardGridFallbackProps> = ({
    showDesktopCardOnMobile,
    numberOfResults,
}) => {
    return (
        <>
            <LoadingSkeleton
                width="100%"
                height="50px"
                accessibleText="Loading tours"
                style={{ marginBottom: "var(--g-spacing-md)" }}
            />
            <LoadingSkeleton width="250px" height="20px" accessibleText="" />
            <Grid
                columns={3}
                gap={{
                    xs: "var(--g-spacing-lg)",
                    sm: "var(--g-spacing-lg)",
                }}
                className={styles.grid}
            >
                {new Array(numberOfResults).fill(null).map((_, index) => (
                    <div
                        className={
                            showDesktopCardOnMobile
                                ? styles["forced-desktop-card"]
                                : styles.card
                        }
                        key={index}
                    >
                        <LoadingSkeleton
                            className={styles.image}
                            accessibleText=""
                        />
                        <div className={styles["card-content"]}>
                            <LoadingSkeleton
                                width="100%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="100%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="50%"
                                height="30px"
                                accessibleText=""
                            />
                        </div>
                    </div>
                ))}
            </Grid>
            <LoadingSkeleton
                width="260px"
                height="50px"
                accessibleText=""
                style={{ margin: "0 auto", display: "block" }}
            />
        </>
    );
};
