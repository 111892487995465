import { LoadingSkeleton } from "@wojo/ui";
import styles from "./CountdownTimer.module.scss";
import { DateTimeDisplay } from "./DateTimeDisplay";
import { useCountdown } from "./useCountdown";

type CounterProps = {
    countdownSize?: "2" | "3" | "4" | "5" | "6";
    countdownColor?: string;
    showDay: boolean;
    countdownEndDate: string;
};

export const Counter: React.FC<CounterProps> = ({
    countdownColor,
    countdownSize,
    showDay,
    countdownEndDate,
}) => {
    const {
        days = 0,
        hours = 0,
        minutes = 0,
        seconds = 0,
        isInitialized,
    } = useCountdown(countdownEndDate, showDay) ?? {};

    return isInitialized ? (
        <>
            <div className={"visually-hidden"}>
                {`${days} days, ${hours} hours, ${minutes} minutes, ${seconds}
                seconds remaining`}
            </div>
            <div className={styles["timer-container"]} aria-hidden={true}>
                {showDay && (
                    <DateTimeDisplay
                        value={days}
                        label="d"
                        color={countdownColor}
                        size={countdownSize}
                        showSeparator={true}
                    />
                )}
                <DateTimeDisplay
                    value={hours.toString().padStart(2, "0")}
                    label="h"
                    color={countdownColor}
                    size={countdownSize}
                    showSeparator={true}
                />
                <DateTimeDisplay
                    value={minutes.toString().padStart(2, "0")}
                    label="m"
                    color={countdownColor}
                    size={countdownSize}
                    showSeparator={true}
                />
                <DateTimeDisplay
                    value={seconds.toString().padStart(2, "0")}
                    label="s"
                    color={countdownColor}
                    size={countdownSize}
                    showSeparator={false}
                />
            </div>
        </>
    ) : (
        <LoadingSkeleton accessibleText="Loading please wait" height="30px" />
    );
};
