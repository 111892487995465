import { ContentLink } from "@wojo/services/generated/graphql";
import { StoryblokLink } from "../types/storyblok/storyblok-link";

/**
 * Gets a clean href from a storyblok link.
 * - Prepends a slash to the link if its a story type.
 * - Cleans the slash at the end of the url.
 * - Returns an isExternal boolean returning true if it's an external url
 * and false if not.
 */
export function getCleanStoryblokLink(
    link?: Pick<StoryblokLink, "url" | "cached_url" | "linktype"> | ContentLink,
): {
    isExternal: boolean;
    href: string;
} {
    if (!link) {
        return { isExternal: false, href: "" };
    }
    const cachedUrl = "cached_url" in link ? link.cached_url : link.cachedUrl;
    if (!cachedUrl) {
        return { isExternal: false, href: "" };
    }

    const linkType = "linktype" in link ? link.linktype : link.linkType;
    const isStory = linkType === "story";
    const nonDefaultLanguagePrefix =
        process.env.NEXT_PUBLIC_STORYBLOK_LANGUAGE !== "default"
            ? new RegExp(`^/${process.env.NEXT_PUBLIC_STORYBLOK_LANGUAGE}/`)
            : "";

    const cleanedUrl = (
        isStory
            ? cachedUrl.replace(nonDefaultLanguagePrefix, "")
            : link.url ?? ""
    ).replace(/^\/|\/$|\/\/$/g, "");

    if (cleanedUrl === "") {
        return { isExternal: false, href: "" };
    }

    const isExternal = /^(http|https):\/\//.test(cleanedUrl);
    const href =
        isExternal ||
        cleanedUrl.startsWith("#") ||
        cleanedUrl.startsWith("tel:") ||
        cleanedUrl.startsWith("mailto:")
            ? cleanedUrl
            : `/${cleanedUrl}`;

    return { href, isExternal };
}
