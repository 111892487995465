import { Text } from "@wojo/ui";
import { TitleDescriptionSize } from "../../types/title";

export type TitleDescriptionProps = {
    size: TitleDescriptionSize;
    color?: string;
    content?: string;
    id?: string;
    style?: React.CSSProperties;
};
export const TitleDescription: React.FC<TitleDescriptionProps> = ({
    size,
    color,
    content,
    id,
    style,
}) => {
    const colorStyle = color ? { color } : undefined;
    switch (size) {
        case "display-body1":
            return (
                <Text.DisplayBody
                    variant="1"
                    style={{ ...colorStyle, ...style }}
                    id={id}
                >
                    {content}
                </Text.DisplayBody>
            );
        case "display-body2":
            return (
                <Text.DisplayBody
                    variant="2"
                    style={{ ...colorStyle, ...style }}
                    id={id}
                >
                    {content}
                </Text.DisplayBody>
            );
        case "body1":
            return (
                <Text.Body
                    order="1"
                    style={{ ...colorStyle, ...style }}
                    id={id}
                >
                    {content}
                </Text.Body>
            );
        default:
            return null;
    }
};
