import React, { ReactNode } from "react";
import { Text } from "../../text";
import styles from "./SliderTrack.module.scss";

type EmblaViewportRefType = <ViewportElement extends HTMLElement>(
    instance: ViewportElement | null,
) => void;

export type SliderTrackProps = {
    emblaMainRef: EmblaViewportRefType;
    slideIds: string[];
    captions?: string[];
    children: ReactNode;
    totalSlides: number;
    selectedScrollSnap: number | undefined;
};

export const SliderTrack: React.FC<SliderTrackProps> = ({
    emblaMainRef,
    slideIds,
    captions,
    selectedScrollSnap,
    totalSlides,
    children,
}) => {
    const renderCaption = (captions: string[] | undefined, index: number) => {
        if (!!captions && captions[index]) {
            return (
                <Text.Body className={styles.text} variant="2" tag="figcaption">
                    {captions[index]}
                </Text.Body>
            );
        }

        return null;
    };

    return (
        <div className={styles.viewport} ref={emblaMainRef}>
            <div
                className={styles.track}
                aria-live="polite"
                style={
                    {
                        "--nb-items": `${totalSlides}`,
                    } as React.CSSProperties
                }
            >
                {React.Children.map(children, (child, index) => {
                    return (
                        <figure
                            className={styles.slide}
                            id={slideIds[index]}
                            key={slideIds[index]}
                            aria-hidden={
                                selectedScrollSnap !== index || undefined
                            }
                            role="tabpanel"
                            aria-roledescription="slide"
                        >
                            {child}
                            {renderCaption(captions, index)}
                        </figure>
                    );
                })}
            </div>
        </div>
    );
};
