"use client";

import { useCallback, useEffect } from "react";

type CuralateExperience = {
    containerId?: string;
};

type CuralateScript = {
    ready?: (callback: () => void) => void;
    createExperience?: (crl8ContainerId?: string) => void;
    destroyExperience?: (crl8ContainerId?: string) => void;
    pixel?: unknown;
    debug?: boolean;
    EventBus?: {
        addEventListener: (
            event: "experienceReady",
            callback: (exp?: CuralateExperience) => void,
        ) => void;
        removeEventListener: (
            event: "experienceReady",
            callback: (exp?: CuralateExperience) => void,
        ) => void;
    };
};

declare var window: Window & { crl8?: CuralateScript };

/**
 *  This is the Curalate/BaazarVoice provided embed script with types added.
 *  Curalate docs: https://developers.bazaarvoice.com/v1.0-SocialCommerceAPI/docs/deploy-galleries-on-your-store
 */
const loadCuralate = (onReady: () => void, crl8ContainerId?: string) => {
    if (!crl8ContainerId) {
        return;
    }
    if (window.crl8) {
        window.crl8.ready?.(() => {
            window.crl8?.EventBus?.addEventListener("experienceReady", onReady);
            window.crl8?.createExperience?.(crl8ContainerId);
        });
        return;
    }
    const curalate: CuralateScript = (window.crl8 ??= {});
    let isReady = false;
    const readyQueue: (() => void)[] = [];

    curalate.ready = (callback: () => void) => {
        if (isReady) {
            callback();
        } else {
            readyQueue.push(callback);
        }
    };

    const curalateScript = document.createElement("script");
    curalateScript.async = true;
    curalateScript.onload = () => {
        isReady = true;
        readyQueue.forEach((callback) => {
            callback();
        });
    };
    const siteName = process.env.NEXT_PUBLIC_CURALATE_SITENAME;
    const scriptType =
        curalate.debug || -1 !== location.search.indexOf("crl8-debug=true")
            ? "js"
            : "min.js";
    curalateScript.src = `https://edge.curalate.com/sites/${siteName}/site/latest/site.${scriptType}`;

    document.body.appendChild(curalateScript);

    curalate.ready(() => {
        window.crl8?.EventBus?.addEventListener("experienceReady", onReady);
        window.crl8?.createExperience?.(crl8ContainerId);
    });
};

const unloadCuralate = (onReady: () => void, crl8ContainerId?: string) => {
    window.crl8?.ready?.(() => {
        window.crl8?.destroyExperience?.(crl8ContainerId);
        window.crl8?.EventBus?.removeEventListener("experienceReady", onReady);
    });
};

export type CuralateScriptProps = {
    crl8ContainerId?: string;
    onReady?: () => void;
};

export const CuralateScript: React.FC<CuralateScriptProps> = ({
    crl8ContainerId,
    onReady,
}) => {
    const readyEventCallback = useCallback(
        (exp?: CuralateExperience) => {
            if (exp?.containerId === crl8ContainerId) {
                onReady?.();
            }
        },
        [crl8ContainerId, onReady],
    );
    useEffect(() => {
        loadCuralate(readyEventCallback, crl8ContainerId);
        return () => unloadCuralate(readyEventCallback, crl8ContainerId);
    }, [crl8ContainerId, readyEventCallback]);
    return null;
};
