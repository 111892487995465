"use client";

import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import {
    IconButton,
    Modal,
    Text,
    VideoPlayer,
    VideoPlayerProps,
    WistiaVideo,
    WistiaVideoState,
} from "@wojo/ui";
import cn from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import React, {
    CSSProperties,
    useEffect,
    useId,
    useRef,
    useState,
} from "react";
import { FiX } from "react-icons/fi";
import { VerticalVideoProps } from "../../molecules/vertical-video/VerticalVideo";
import { Opacity } from "../../types/opacity";
import styles from "./FullscreenVideoSlider.module.scss";
import { FullscreenVideoSliderNavigation } from "./FullscreenVideoSliderNavigation";
enum TextAndOverlayStyle {
    lightTextOverDarkOverlay = "lightTextOverDarkOverlay",
    darkTextOverLightOverlay = "darkTextOverLightOverlay",
}

type SliderStateType = {
    prevBtnDisabled: boolean;
    nextBtnDisabled: boolean;
    selectedIndex: number;
    scrollSnaps: number[];
};

export type FullscreenVideoSliderProps = {
    /**
     * Is modal open
     */
    isOpen: boolean;
    /**
     * Close modal requested callback
     */
    onCloseRequested: () => void;
    /**
     * List of videos to display in the slider
     */
    videos: VerticalVideoProps[];
};

export const FullscreenVideoSlider: React.FC<FullscreenVideoSliderProps> = ({
    videos,
    isOpen,
    onCloseRequested,
}) => {
    const sliderId = useId();
    const i18n = useI18n("storyblok").storyblok;
    const [emblaRef, emblaApi] = useEmblaCarousel({
        axis: "y",
    });
    const [sliderState, setSliderState] = useState<SliderStateType>({
        prevBtnDisabled: true,
        nextBtnDisabled: true,
        selectedIndex: 0,
        scrollSnaps: [],
    });
    const [activeVideoId, setActiveVideoId] = useState(
        videos[0]?.wistiaVideoId,
    );
    const [hasAutoPlayed, setHasAutoPlayed] = useState(false);
    const textAndOverlayStyleMap = {
        [TextAndOverlayStyle.lightTextOverDarkOverlay]:
            "light-text-over-dark-overlay",
        [TextAndOverlayStyle.darkTextOverLightOverlay]:
            "dark-text-over-light-overlay",
    };
    const videoRefs = useRef<Record<string, WistiaVideo>>({});

    useEffect(() => {
        if (!emblaApi) {
            return;
        }

        const updateSliderState = () => {
            const selectedIndex = emblaApi.selectedScrollSnap();
            setSliderState((prevSliderState) => ({
                ...prevSliderState,
                selectedIndex,
                prevBtnDisabled: !emblaApi.canScrollPrev(),
                nextBtnDisabled: !emblaApi.canScrollNext(),
                scrollSnaps: emblaApi.scrollSnapList(),
            }));
        };

        const onSelect = () => {
            const selectedIndex = emblaApi.selectedScrollSnap();
            const selectedVideoProps = videos.at(selectedIndex);
            const selectedVideoHandle = selectedVideoProps?.wistiaVideoId
                ? videoRefs.current[selectedVideoProps.wistiaVideoId]
                : null;
            selectedVideoHandle?.play();
            const otherVideos = Object.entries(videoRefs.current).filter(
                ([videoId]) =>
                    videoId !== selectedVideoHandle?.data.media.hashedId,
            );
            for (const [, otherVideo] of otherVideos) {
                if (otherVideo.state() === WistiaVideoState.Playing) {
                    otherVideo.pause();
                }
            }
        };

        updateSliderState();
        emblaApi.on("reInit", updateSliderState);
        emblaApi.on("select", updateSliderState);
        emblaApi.on("select", onSelect);

        return () => {
            emblaApi.off("reInit", updateSliderState);
            emblaApi.off("select", updateSliderState);
            emblaApi.off("select", onSelect);
        };
    }, [emblaApi, videos]);

    useEffect(() => {
        const handleBreakpointChange = (event: MediaQueryListEvent) => {
            if (event.matches) {
                onCloseRequested();
            }
        };
        const mediaQueryList = window.matchMedia("(min-width: 768px)");
        mediaQueryList.addEventListener("change", handleBreakpointChange);
        return () => {
            mediaQueryList.removeEventListener(
                "change",
                handleBreakpointChange,
            );
        };
    }, [onCloseRequested]);

    const handlePlaybackChange: VideoPlayerProps["onPlaybackChange"] = (
        eventVideoState,
        playbackEvent,
    ) => {
        const eventVideo = playbackEvent.video;
        const eventVideoId = eventVideo.data.media.hashedId;
        const eventVideoIndex = videos.findIndex(
            (video) => video.wistiaVideoId === eventVideoId,
        );
        const eventVideoProps =
            eventVideoIndex >= 0 ? videos.at(eventVideoIndex) : null;

        if (
            eventVideoState === WistiaVideoState.Playing ||
            (eventVideoState === WistiaVideoState.Paused &&
                playbackEvent.source === "user-event")
        ) {
            gtmSendEvent({
                event: "track",
                ga4_object: "VERTICAL_VIDEO",
                ga4_action: eventVideoState.toUpperCase(),
                heading: eventVideoProps?.heading,
                parentObject: "VIDEO_GALLERY",
                videoId: eventVideoId,
                videoTitle: eventVideo.name(),
                videoType: "VideoGallery",
            });
        }

        if (eventVideoId !== activeVideoId) {
            setActiveVideoId(eventVideoId);
            gtmSendEvent({
                event: "track",
                ga4_object: "VIDEO_GALLERY",
                ga4_action: "Changed Video",
                heading: eventVideoProps?.heading,
                videoId: eventVideoId,
                videoPosition: `Video ${eventVideoIndex + 1}`,
                videoTitle: eventVideo.name(),
            });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            accessibleTitle={
                videos[sliderState.selectedIndex].heading ||
                i18n.fullScreenVideoSlider.accessibleTitle.l()
            }
            onCloseRequested={() => {
                onCloseRequested();
            }}
            contentClassName={cn(
                styles.modal,
                styles[
                    `modal--${
                        textAndOverlayStyleMap[
                            videos[sliderState.selectedIndex]
                                ?.textAndOverlayStyle ||
                                TextAndOverlayStyle.lightTextOverDarkOverlay
                        ]
                    }`
                ],
            )}
            hideDefaultHeader={true}
            disableModalTranslate={true}
            style={
                {
                    ["--overlay-opacity"]:
                        videos[sliderState.selectedIndex].overlayOpacity
                            ?.value || Opacity.Percent40,
                } as CSSProperties
            }
        >
            <IconButton
                size="small"
                ariaLabel={i18n.fullScreenVideoSlider.closeAriaLabel.l()}
                className={styles.close}
                onClick={() => {
                    onCloseRequested();
                }}
                icon={<FiX strokeWidth="1.25" color="currentColor" />}
            />

            {videos[sliderState.selectedIndex].heading && (
                <Text.Heading className={styles.heading} tag="h1" order="6">
                    {videos[sliderState.selectedIndex].heading}
                </Text.Heading>
            )}

            <div
                id={sliderId}
                ref={emblaRef}
                className={styles.slider}
                role="group"
                aria-roledescription="carousel"
            >
                <div
                    className={styles.track}
                    aria-live="polite"
                    style={
                        {
                            "--nb-items": `${videos.length}`,
                        } as CSSProperties
                    }
                >
                    {videos.map((video, index) => (
                        <div
                            key={index}
                            id={`${sliderId}_${index}`}
                            className={styles.slide}
                            aria-hidden={
                                index !== sliderState.selectedIndex || undefined
                            }
                            role="tabpanel"
                            aria-roledescription="slide"
                            aria-label={`${index + 1} of ${videos.length}`}
                            {...{
                                inert:
                                    index !== sliderState.selectedIndex
                                        ? "true"
                                        : undefined,
                            }}
                        >
                            <VideoPlayer
                                videoId={video.wistiaVideoId}
                                className={styles["video-player"]}
                                onPlaybackReady={(eventVideo) => {
                                    videoRefs.current[video.wistiaVideoId] =
                                        eventVideo;
                                    if (
                                        video.wistiaVideoId === activeVideoId &&
                                        !hasAutoPlayed
                                    ) {
                                        eventVideo.play();
                                        setHasAutoPlayed(true);
                                    }
                                }}
                                onPlaybackChange={handlePlaybackChange}
                                playerColorToken="--g-color-ink-black"
                                loopVideo={video.loopVideo}
                                hideMainButton
                                hideFullScreenControl
                            />
                        </div>
                    ))}
                </div>

                <FullscreenVideoSliderNavigation
                    scrollTo={(index) => emblaApi?.scrollTo(index)}
                    selectedIndex={sliderState.selectedIndex}
                    scrollSnaps={sliderState.scrollSnaps}
                    prevBtnDisabled={sliderState.prevBtnDisabled}
                    nextBtnDisabled={sliderState.nextBtnDisabled}
                    slideIds={videos.map((_, index) => `${sliderId}_${index}`)}
                    scrollPrev={(e) => {
                        if (sliderState.prevBtnDisabled) {
                            e.preventDefault();
                        } else {
                            emblaApi?.scrollPrev();
                        }
                    }}
                    scrollNext={(e) => {
                        if (sliderState.nextBtnDisabled) {
                            e.preventDefault();
                        } else {
                            emblaApi?.scrollNext();
                        }
                    }}
                />
            </div>
        </Modal>
    );
};
