import cookies from "js-cookie";
import qs from "qs";
import { useEffect } from "react";
import { Cookie } from "../cookie";

/**
 * A hook to set cid cookie from the corresponding query param, if present.
 */
export const useAnalyticsCustomer = (): void => {
    useEffect(() => {
        const queryString = window.location.search;
        if (!queryString) {
            return;
        }

        const queryParams = qs.parse(queryString, {
            ignoreQueryPrefix: true,
        });
        const cid = queryParams?.cid as string;

        if (cid) {
            cookies.set(Cookie.cid, cid, { expires: 365 });
        }
    }, []);
};
