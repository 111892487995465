import { Container, Grid, LoadingSkeleton } from "@wojo/ui";
import styles from "./BlogArticleSliderFallback.module.scss";

type BlogArticleSliderFallbackProps = {
    layout: number;
};

export const BlogArticleSliderFallback: React.FC<
    BlogArticleSliderFallbackProps
> = ({ layout }) => {
    return (
        <Container className={styles.container}>
            <Grid
                columns={layout}
                className={styles.grid}
                style={
                    {
                        "--layout": layout,
                    } as React.CSSProperties
                }
            >
                {new Array(layout).fill(null).map((_, index) => (
                    <div className={styles.card} key={index}>
                        <LoadingSkeleton
                            className={styles.image}
                            accessibleText={
                                index === 0 ? "Loading articles" : ""
                            }
                        />
                        <div className={styles["card-content"]}>
                            <LoadingSkeleton
                                width="100%"
                                height="30px"
                                accessibleText=""
                            />
                            <LoadingSkeleton
                                width="75%"
                                height="30px"
                                accessibleText=""
                            />
                        </div>
                    </div>
                ))}
            </Grid>
        </Container>
    );
};
