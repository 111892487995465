import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchStoryblokData, WojoCheckoutConfig } from "@wojo/storyblok";
import { getStoryblokParams } from "./get-storyblok-params";

export const useCheckoutConfigSuspenseQuery = ():
    | WojoCheckoutConfig
    | undefined => {
    const { data: sbResponse } = useSuspenseQuery({
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["configurations/checkout"],
        queryFn: async () => {
            const { fullSlug, sbParams } = getStoryblokParams(
                "configurations/web-checkout-config/checkout-config",
                {},
                "wojo-checkout-config.footer",
            );
            return fetchStoryblokData(fullSlug, sbParams);
        },
    });
    return {
        ...sbResponse.data?.story?.content,
        footer: sbResponse.data?.story?.content?.footer?.content,
    };
};
