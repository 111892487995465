import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import { Button, Pagination } from "@wojo/ui";
import cn from "clsx";
import React, { useId } from "react";
import { ResultsLabel } from "../results-label/ResultsLabel";
import { useResultsContext } from "../results-provider/ResultsProvider";
import styles from "./ResultsPagination.module.scss";

export type ResultsPaginationProps = React.PropsWithChildren<{
    onChange?: (page: number) => void;
}>;

export const ResultsPagination: React.FC<ResultsPaginationProps> = ({
    children,
    onChange,
}) => {
    const i18n = useI18n("storyblok").storyblok.resultsPagination;
    const childrenWrapperId = useId();
    const skipToSelectorLinkId = useId();
    const skipToStartOfListLinkId = useId();
    const { form, hitLabel, hitsPerPage, pages, totalHits } =
        useResultsContext();
    const showPagination = totalHits > hitsPerPage;
    return (
        <>
            {showPagination && (
                <Button
                    tag="a"
                    href={`#${skipToStartOfListLinkId}`}
                    id={skipToSelectorLinkId}
                    variant="quiet"
                    className={cn(styles.skip, styles["skip--visually-hidden"])}
                >
                    {i18n.skipToPageSelector.l()}
                </Button>
            )}
            <div id={childrenWrapperId} role="group" tabIndex={-1}>
                {children}
            </div>
            {showPagination && (
                <div
                    style={{
                        paddingBottom: "var(--g-spacing-sm)",
                    }}
                >
                    <Button
                        tag="a"
                        href={`#${skipToSelectorLinkId}`}
                        id={skipToStartOfListLinkId}
                        variant="quiet"
                        className={cn(
                            styles.skip,
                            styles["skip--visually-hidden"],
                        )}
                    >
                        {i18n.skipToStart.l()}
                    </Button>
                    <Pagination
                        nbPages={pages}
                        page={form.watch("page")}
                        accessibleLabel={i18n.a11y.paginationLabel.l({
                            hitLabel,
                        })}
                        paginationLabel={<ResultsLabel />}
                        onChange={(page) => {
                            const wrapperElement =
                                document.getElementById(childrenWrapperId);
                            form.setValue("page", page);
                            gtmSendEvent({
                                event: "track",
                                eventLabel: page,
                                ga4_object: "PAGINATION",
                                parentObject: "TOUR_GRID",
                                ga4_action: "CLICKED",
                            });
                            onChange?.(page);
                            wrapperElement?.focus({
                                preventScroll: true,
                            });
                        }}
                    />
                </div>
            )}
        </>
    );
};
