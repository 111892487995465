import { fetchStoryblokData } from "@wojo/storyblok";
import { getStoryblokParams } from "./get-storyblok-params";

export const getTourPageConfigData = async function fetchData(searchParams?: {
    [key: string]: string | string[] | undefined;
}) {
    const { fullSlug, sbParams } = getStoryblokParams(
        "configurations/web-tour-config/tour-page-config",
        searchParams,
        "wojo-tour-page-config.tourPageMarketing",
    );

    return fetchStoryblokData(fullSlug, sbParams);
};
