import { useMediaQuery } from "@mantine/hooks";
import { Image, transformAsset } from "@wojo/ui";
import React from "react";
import { ReviewsAggregateQuery } from "../../../../generated/graphql";
import styles from "./ReviewImages.module.scss";

type ReviewImagesProps = {
    images?: ReviewsAggregateQuery["reviews"]["results"][number]["images"];
    reviewName: string;
    setSelectedImageIndex: (index: number) => void;
    setIsModalOpen: (isOpen: boolean) => void;
};

export const ReviewImages: React.FC<ReviewImagesProps> = ({
    images,
    reviewName,
    setSelectedImageIndex,
    setIsModalOpen,
}) => {
    const breakpointMobile = "(max-width: 767px)";
    const isMobile = useMediaQuery(breakpointMobile, false);
    const maxThumbnails = isMobile ? 4 : 6;
    const imagesLength = images?.length || 0;
    const showOverlay = imagesLength > maxThumbnails;
    const displayImages = images
        ?.filter((image) => !!image.asset?.location)
        .slice(0, maxThumbnails);

    return (
        <div className={styles["images"]}>
            {displayImages?.map((image, index) => {
                if (!image.asset?.location) {
                    return null;
                }

                return (
                    <button
                        type="button"
                        className={styles.image}
                        key={image.asset._id}
                        onClick={() => {
                            setSelectedImageIndex(index);
                            setIsModalOpen(true);
                        }}
                    >
                        <Image
                            src={transformAsset(image.asset.location, {
                                size: {
                                    width: 160,
                                    height: 160,
                                },
                            })}
                            alt={"Traveler photo " + (index + 1)}
                            loading="lazy"
                        />
                        <span className="visually-hidden">
                            Open gallery for {reviewName}
                        </span>
                        {index === maxThumbnails - 1 && showOverlay && (
                            <div className={styles.overlay}>
                                <span aria-hidden>
                                    +{imagesLength - maxThumbnails}
                                </span>
                                <span className="visually-hidden">
                                    plus {imagesLength - maxThumbnails} more
                                    photos images
                                </span>
                            </div>
                        )}
                    </button>
                );
            })}
        </div>
    );
};
