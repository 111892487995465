import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import { StatusLabel, useComputedStyleObserver } from "@wojo/ui";
import cn from "clsx";
import currency from "currency.js";
import uniq from "lodash/uniq";
import React from "react";
import { Theme } from "../../../types/theme";
import { getIsOnSaleFiltersParam } from "../get-is-on-sale-filters-param";
import { DealsTourCardDeparture } from "../types";
import styles from "./DepartureListItem.module.scss";
import { useUrgencyBadgesExperiment } from "@wojo/services";

export type DepartureListItemProps = {
    departure: DealsTourCardDeparture;
    showGatewayPricing: boolean;
    itemIndex?: number;
};

export const DepartureListItem: React.FC<DepartureListItemProps> = ({
    departure,
    showGatewayPricing,
    itemIndex = 0,
}) => {
    const {
        dateRangeLabel,
        extensionName,
        tourPagePath,
        classLevel,
        departureStatusBadge,
        nbSpotsLeft,
    } = departure;

    const experimentBadge = useUrgencyBadgesExperiment(
        departureStatusBadge,
        nbSpotsLeft,
    );

    const i18n = useI18n("storyblok").storyblok;
    const removeFirstYear = (dateString: string) => {
        return dateString.replace(/, \d{4}(?=.*\d{4})/, "");
    };

    const price = showGatewayPricing
        ? departure.gatewayPricing?.price
        : departure.price;
    const priceFormatted = currency(price || 0, {
        precision: 0,
    }).format();

    const salePrice = showGatewayPricing
        ? departure.gatewayPricing?.salePrice
        : departure.salePrice;
    const salePriceFormatted = currency(salePrice || price || 0, {
        precision: 0,
    }).format();

    const discountAmount = showGatewayPricing
        ? departure.gatewayPricing?.discountAmount
        : departure.discountAmount;
    const discountAmountFormatted = currency(discountAmount || 0, {
        precision: 0,
    }).format();
    const theme = useComputedStyleObserver("--g-ui-theme");
    const prefersDarkTheme = theme === Theme.dark;
    return (
        <li className={styles["list-item"]}>
            <div className={styles["inner-wrapper"]}>
                <div className={styles["circle-wrapper"]}>
                    <span className={styles["circle"]} />
                </div>
                <div className={styles["dates-wrapper"]}>
                    <a
                        href={`${tourPagePath}?departure=${
                            departure.id
                        }${getIsOnSaleFiltersParam("&")}#tripbuilder`}
                        className={styles["date-range"]}
                        onClick={() => {
                            gtmSendEvent({
                                event: "add_to_cart",
                                ga4_object: "DEAL_TOUR_CARD_GRID",
                                ga4_action: "CLICKED",
                                tourCode: departure.tourCode ?? "",
                                tourName: departure.name ?? "",
                                ecommerce: {
                                    currency:
                                        process.env.NEXT_PUBLIC_CURRENCY_CODE ||
                                        "",
                                    value:
                                        departure.salePrice ?? departure.price,
                                    items: [
                                        {
                                            item_id: departure.id,
                                            item_name: departure.name ?? "",
                                            discount: showGatewayPricing
                                                ? departure.gatewayPricing
                                                      ?.discountAmount
                                                : departure.discountAmount,
                                            index: itemIndex,
                                            item_brand:
                                                process.env
                                                    .NEXT_PUBLIC_BUSINESS_CODE ??
                                                "",
                                            item_category: uniq(
                                                departure.countries,
                                            ).join(","),
                                            item_category2: uniq(
                                                departure.cities,
                                            ).join(","),
                                            item_variant: "Departure",
                                            price: showGatewayPricing
                                                ? departure.gatewayPricing
                                                      ?.price
                                                : departure.price,
                                            salePrice: showGatewayPricing
                                                ? departure.gatewayPricing
                                                      ?.price
                                                : departure.salePrice,
                                            hasExtension:
                                                departure.hasExtension,
                                            isLastMinuteSpecial:
                                                departure.isLastMinuteSpecial,
                                            quantity: 1,
                                            classLevel:
                                                departure.classLevel?.name ??
                                                "",
                                            onSale: departure.isOnSale,
                                            groupTrip: false,
                                            rating: departure.rating,
                                            tourCode: departure.tourCode ?? "",
                                        },
                                    ],
                                },
                            });
                        }}
                    >
                        {removeFirstYear(dateRangeLabel)}
                    </a>
                    <div className={styles["availability-mobile"]}>
                        {experimentBadge && (
                            <StatusLabel
                                size="small"
                                status={experimentBadge.variant}
                                text={experimentBadge.text}
                            />
                        )}
                    </div>
                    <div className={styles["extra-info"]}>
                        {!!extensionName && (
                            <span>
                                Extension{" "}
                                <span
                                    className={styles["desktop-only--inline"]}
                                >
                                    available
                                </span>
                            </span>
                        )}
                        {!extensionName && !!classLevel?.name && (
                            <div
                                className={cn(styles.classlevel, {
                                    [styles["classlevel--dark"]]:
                                        prefersDarkTheme,
                                })}
                            >
                                {!!classLevel.icon?.filename && (
                                    <span>
                                        <img
                                            src={classLevel.icon.filename}
                                            alt={classLevel.icon.alt || ""}
                                        />
                                    </span>
                                )}
                                <span>{classLevel.name}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles["pricing-wrapper"]}>
                    <span
                        aria-hidden={departure.isOnSale}
                        className={cn(styles["price"], {
                            [styles["price--strike-through"]]:
                                departure.isOnSale,
                        })}
                    >
                        {priceFormatted}
                    </span>{" "}
                    {departure.isOnSale && (
                        <>
                            <span aria-hidden className={styles["sales-price"]}>
                                {salePriceFormatted}
                            </span>{" "}
                            <span aria-hidden className={styles["sales-price"]}>
                                <StatusLabel
                                    size="small"
                                    status="deal"
                                    text={i18n.departureListItem.discountStatusLabel.l(
                                        {
                                            discountAmountFormatted,
                                        },
                                    )}
                                />
                            </span>
                            <span className="visually-hidden">
                                {i18n.departureListItem.visuallyHiddenSaleText.l(
                                    {
                                        discountAmountFormatted,
                                        priceFormatted,
                                        salePriceFormatted,
                                    },
                                )}
                            </span>
                        </>
                    )}
                </div>
                <div className={styles["availability-desktop"]}>
                    {experimentBadge && (
                        <StatusLabel
                            size="small"
                            status={experimentBadge.variant}
                            text={experimentBadge.text}
                        />
                    )}
                </div>
            </div>
        </li>
    );
};
