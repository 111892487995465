export const SortIcon: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M8 2.667v10.666M12 9.333l-4 4-4-4"
                stroke="var(--s-color-text-primary)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.25"
            />
        </svg>
    );
};
