import cn from "clsx";
import React, { CSSProperties, HTMLAttributes } from "react";
import { BorderPosition, BorderRadius, BorderSize } from "../../types/border";
import { GradientAngle } from "../../types/gradient";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import borderStyles from "./Border.module.scss";

export type StoryblokBorderProps = {
    /** Rounds the corners based on the theme */
    borderRadius?: BorderRadius;
    /** Sets the thickness of the border */
    borderSize?: BorderSize;
    /** Determines which sides should display a border */
    borderPosition?: BorderPosition;
    /** Sets a solid color on the border*/
    borderColor?: string;
    /** Sets the first color when adding a gradient to the border */
    borderStartColor?: string;
    /** Sets the second color when adding a gradient to the border */
    borderEndColor?: string;
    /** Sets the gradient direction */
    borderGradientAngle?: GradientAngle;
    /** Applies an image to the border */
    borderImage?: AssetStoryblok;
} & HTMLAttributes<HTMLDivElement>;

export type BorderProps = React.PropsWithChildren<StoryblokBorderProps> &
    HTMLAttributes<HTMLDivElement>;

export const Border: React.FC<BorderProps> = ({
    borderRadius,
    borderSize,
    borderPosition,
    borderColor,
    borderStartColor,
    borderEndColor,
    borderGradientAngle,
    borderImage,
    children,
    className,
    style,
    ...rest
}) => {
    const borderImageGradient =
        borderStartColor && borderEndColor
            ? `linear-gradient(${
                  borderGradientAngle || "0deg"
              }, ${borderStartColor}, ${borderEndColor})`
            : "";

    // Limit border images to only SVGs for MVP
    const borderImageGraphic = borderImage?.filename?.endsWith(".svg")
        ? `url("${borderImage.filename}")`
        : "";

    const classNames = cn(className, borderStyles.border, {
        [borderStyles[`border-radius--${borderRadius}`]]: borderRadius,
        [borderStyles[`border-radius--${borderRadius}-${borderPosition}`]]:
            borderRadius && borderPosition,
        [borderStyles[`border--${borderPosition}`]]: borderPosition,
        [borderStyles["border-image"]]:
            borderImageGraphic || borderImageGradient,
    });

    const styles: CSSProperties = {
        // storyblok returns an empty string not null/undefined
        ["--border-styles-width"]: borderSize || undefined,
        ["--border-styles-image-src"]: borderImageGraphic || undefined,
        ["--border-styles-gradient"]: borderImageGradient || undefined,
        ["--border-styles-color"]: borderColor || undefined,
        ...style,
    } as CSSProperties;

    return (
        <div {...rest} className={classNames} style={styles}>
            {children}
        </div>
    );
};
